import React, { Component } from "react";
import './css/App.css';
import './css/HelpDialog.css';
import './css/SearchCriteriaReactSuperTreeView.css'
// import '../node_modules/react-super-treeview/dist/style.css';
import { Rnd } from "react-rnd";
import {ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails ,  DialogContent, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SuperTreeview from 'react-super-treeview';
import equal from 'fast-deep-equal';
import { withLocalize, Translate ,getTranslate} from "react-localize-redux";
import * as properties from './config/properties.js';


export default class SearchCriteria extends Component {

    constructor(props) {
        super(props);
        this.state = {
          loading:true,
          // searchParams:{},      
          data: [],//this.setUpData(this.props.searchCriteria,this.props.placementRowData,this.props.filterData,this.props.translate,true),
            openPopover:false,
            zIndex:"801",
            // panelIsOpenData: {
            //   simple:true,
            //   pasukSettings: true,
            //   partsOfSpeech: true,
            //   distances: true,
            //   placement: true,
            //   perTerm:true,
            // },
        };
        this.onClose = this.onClose.bind(this);
        this.showOnTop = this.showOnTop.bind(this);
        this.findHighestZIndex = this.findHighestZIndex.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleFullscreen = this.handleFullscreen.bind(this);
        this.handleRestoreScreenSize = this.handleRestoreScreenSize.bind(this);
        this.toggleExpansionPanel = this.toggleExpansionPanel.bind(this);
        this.setUpData = this.setUpData.bind(this);
        this.setUpPerWord = this.setUpPerWord.bind(this);
        this.setUpPasukSettings =this.setUpPasukSettings.bind(this);
        this.setUpDistancesPerWord = this.setUpDistancesPerWord.bind(this);
        this.setUpPlacement = this.setUpPlacement.bind(this);
        this.seforimToIncludeExclude = this.seforimToIncludeExclude.bind(this);
        this.resetSuperTreeData = this.resetSuperTreeData.bind(this);
        this.setUpGeneralDistances = this.setUpGeneralDistances.bind(this);
    }
    async componentDidMount(){
      if (this.props.searchCriteria.bituiParameters == null || this.props.searchCriteria.bituiParameters.length == 0)
          return;
      if (this.props.searchCriteria.bituiParameters[0].letterNekudos) await this.setUpData(this.props.searchCriteria,this.props.placementRowData,this.props.filterData,this.props.translate,!this.state.openPopover)
            .then((theData)=>{this.setState({data:theData})})
    }    
    async componentDidUpdate(prevProps,prevState){
      if (this.props.searchCriteria.bituiParameters == null || this.props.searchCriteria.bituiParameters.length == 0)
          return;
      if(this.state.openPopover!==prevState.openPopover && this.props.searchCriteria.bituiParameters[0].letterNekudos){
        await this.setUpData(this.props.searchCriteria,this.props.placementRowData,this.props.filterData,this.props.translate,!this.state.openPopover)
        .then((theData)=>{this.setState({data:theData})})
      }
      if(this.props.updateLeftBar !== prevProps.updateLeftBar && this.props.searchCriteria.bituiParameters[0].letterNekudos){
        await this.setUpData(this.props.searchCriteria,this.props.placementRowData,this.props.filterData,this.props.translate,!this.state.openPopover)
        .then((theData)=>{this.setState({data:theData})})
      }
    }
    resetSuperTreeData(updatedData){
      this.setState({data:updatedData});
    }
  
    onClose(){
        this.setState({openPopover:false},()=>{this.props.inLeftBar(true)})
    }
    findHighestZIndex(elem) {
        var elems = document.getElementsByTagName("*");
        var highest = 0;
        for (var i = 0; i < elems.length; i++)
        {
          var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
          if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998) && (zindex != 100000090))
          {
            highest = zindex;
          }
        }
        return parseFloat(highest);
      }
    
      showOnTop(){
        this.setState({
          zIndex: this.findHighestZIndex() + 1
        })
      }
      handleClick(e){
        this.showOnTop();
      }

      toggleExpansionPanel(panelName){
        var panelIsOpenData = this.state.panelIsOpenData;
        panelIsOpenData[panelName] = !panelIsOpenData[panelName];
        this.setState({
          panelIsOpenData
        })
      }
    
      handleFullscreen(){
        const x = 0;
        const y = 0;
        const heightNum = window.innerHeight;
        const widthNum = window.innerWidth;
        this.props.setSize(widthNum, heightNum, x, y)
      }
    
      handleRestoreScreenSize(){
        this.props.resetPosition()
      }


      // setUpPerWord(bitui){
      //   var dataArray=[];
      //   for(let i=0; i<bitui.length; i++){

      //         var data={
      //           id:dataArray.length+1,
      //           name:`Search Term: ${bitui[i].term}`,
      //           isExpandable:true,
      //           isExpanded:true,
      //           children:[],
      //         };
      //         if(bitui[i].isNegative){
      //           data.children.push({
      //             id:data.children.length+1,
      //             name:"Your word is negative"
      //           })
      //         }
      //         if(bitui[i].isCaseSensitive){
      //           data.children.push({
      //             id:data.children.length+1,
      //             name:"Your word is Case Sensitive"
      //           })
      //         }
      //         if(bitui[i].repetitions>1){
      //           data.children.push({
      //             id:data.children.length+1,
      //             name:`Repetitions: ${bitui[i].repetitions}`
      //           })
      //         }
      //         if(bitui[i].transformation){
      //           data.children.push({
      //             id:data.children.length+1,
      //             name:`Transformation: ${bitui[i].transformation}`
      //           })
      //         }
      //         var PartsOfSpeech=
      //           {
      //             id:data.children.length+1,
      //             name:"Parts Of Speech ++",
      //             isExpandable:true,
      //             isExpanded:true,
      //             children:[],  
      //           }
              
      //         if(bitui[i].atbash && (bitui[i].atbash.min>2 || bitui[i].atbash.max<1390)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Atbash"
      //           })
      //         }
      //         if(bitui[i].gematria && (bitui[i].gematria.min>3 || bitui[i].gematria.max<1640)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Gematria"
      //           })
      //         }
      //         if(bitui[i].frequency && (bitui[i].frequency.min>1 || bitui[i].frequency.max<1000)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Frequency"
      //           })
      //         }
      //         if(bitui[i].lettersQty && (bitui[i].lettersQty.min>1 || bitui[i].lettersQty.max<11)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Number of Letters"
      //           })
      //         }
      //         if(bitui[i].tenuosQty && (bitui[i].tenuosQty.min>0 || bitui[i].tenuosQty.max<6)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Number of Vowels"
      //           })
      //         }
      //         if(bitui[i].vowelsQty && (bitui[i].vowelsQty.min>0 || bitui[i].vowelsQty.max<9)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Changed Number of Nekudos"
      //           })
      //         }
      //         if(bitui[i].languages && bitui[i].languages.includes(1)&& bitui[i].languages.includes(3)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Your languages are Hebrew and Aramaic"
      //           })
      //         }else if(bitui[i].languages && bitui[i].languages.includes(i)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Your language is Hebrew"
      //           })
      //         }else if(bitui[i].languages && bitui[i].languages.includes(3)){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Your language is Aramaic"
      //           })
      //         }
      //         if(bitui[i].hasInfix ){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Has Infix"
      //           })
      //         }else if(bitui[i].hasInfix===false){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"No Infix"
      //           })
      //         }
      //         if(bitui[i].hasSuffix ){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"Has Suffix"
      //           })
      //         }else if(bitui[i].hasSuffix===false){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:"No Suffix"
      //           })
      //         }
      //         if(bitui[i].prefixes.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Prefixes: 
      //             ${bitui[i].prefixes.includes(-2)?"X: no prefixes ": ""}
      //             ${bitui[i].prefixes.includes(-1)?"*: all prefixes ": ""}
      //             ${bitui[i].prefixes.includes(1)?"ה:article ": ""}
      //             ${bitui[i].prefixes.includes(2)?"ה:question ": ""}
      //             ${bitui[i].prefixes.includes(3)?"ו:conjunction ": ""}
      //             ${bitui[i].prefixes.includes(4)?"ו:inversion ": ""}
      //             ${bitui[i].prefixes.includes(5)?"ב:preposition ": ""}
      //             ${bitui[i].prefixes.includes(6)?"כ:preposition ": ""}
      //             ${bitui[i].prefixes.includes(7)?"ל:preposition ": ""}
      //             ${bitui[i].prefixes.includes(8)?"מ:preposition ": ""}
      //             ${bitui[i].prefixes.includes(9)?"א:future ": ""}
      //             ${bitui[i].prefixes.includes(10)?"י:future ": ""}
      //             ${bitui[i].prefixes.includes(11)?"נ:future ": ""}
      //             ${bitui[i].prefixes.includes(12)?"ת:future ": ""}
      //             ${bitui[i].prefixes.includes(13)?"נ:past ": ""}`
      //           })
      //         }
      //         if(bitui[i].numberCategories.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Numbers: 
      //             ${bitui[i].numberCategories.includes(1)?"NUMBER ": ""}
      //             ${bitui[i].numberCategories.includes(2)?"CARDINAL ": ""}
      //             ${bitui[i].numberCategories.includes(3)?"ORDINAL ": ""}
      //             ${bitui[i].numberCategories.includes(4)?"FRACTIONAL ": ""}`
      //           })
      //         }
      //         if(bitui[i].objectCategories.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Objects: 
      //             ${bitui[i].objectCategories.includes(10)?"Family: FAMILY ": ""}
      //             ${bitui[i].objectCategories.includes(1)?"Person: MAN ": ""}
      //             ${bitui[i].objectCategories.includes(9)?"G_d: G_D ": ""}
      //             ${bitui[i].objectCategories.includes(2)?"Person: WOMAN ": ""}
      //             ${bitui[i].objectCategories.includes(4)?"Place: LAND ": ""}
      //             ${bitui[i].objectCategories.includes(5)?"Place: MOUNTAIN ": ""}
      //             ${bitui[i].objectCategories.includes(6)?"Place: RIVER ": ""}
      //             ${bitui[i].objectCategories.includes(3)?"Place: PLACE ": ""}
      //             ${bitui[i].objectCategories.includes(7)?"Place: CITY ": ""}
      //             ${bitui[i].objectCategories.includes(8)?"Name: NAME ": ""}`
      //           })
      //         }
      //         if(bitui[i].wordTypes.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Word Types: 
      //             ${bitui[i].wordTypes.includes(-1)?"Uncategorized ": ""}
      //             ${bitui[i].wordTypes.includes(1)?"Noun ": ""}
      //             ${bitui[i].wordTypes.includes(9)?"Pronoun ": ""}
      //             ${bitui[i].wordTypes.includes(2)?"Verb ": ""}
      //             ${bitui[i].wordTypes.includes(3)?"Adjective ": ""}
      //             ${bitui[i].wordTypes.includes(4)?"Adverb ": ""}
      //             ${bitui[i].wordTypes.includes(5)?"Preposition ": ""}
      //             ${bitui[i].wordTypes.includes(6)?"Conjunction ": ""}
      //             ${bitui[i].wordTypes.includes(7)?"Interjection ": ""}
      //             ${bitui[i].wordTypes.includes(8)?"Determiner ": ""}`
      //           })
      //         }
      //         if(bitui[i].binyanim.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Binyanim: 
      //             ${bitui[i].binyanim.includes(1)?"Simple-active ": ""}
      //             ${bitui[i].binyanim.includes(2)?"Simple-passive ": ""}
      //             ${bitui[i].binyanim.includes(3)?"Causative-active ": ""}
      //             ${bitui[i].binyanim.includes(4)?"Causative-passive ": ""}
      //             ${bitui[i].binyanim.includes(5)?"Intensive-active ": ""}
      //             ${bitui[i].binyanim.includes(6)?"Intensive-passive ": ""}
      //             ${bitui[i].binyanim.includes(7)?"Reflexive ": ""}`
      //           })
      //         }
      //         if(bitui[i].genders.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Gender:
      //             ${bitui[i].genders.includes(1)?"Male ": ""}
      //             ${bitui[i].genders.includes(2)?"Female ": ""}`
      //           })
      //         }
      //         if(bitui[i].gendersSof.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Gender For Suffix: 
      //             ${bitui[i].gendersSof.includes(1)?"Male ": ""}
      //             ${bitui[i].gendersSof.includes(2)?"Female ": ""}`
      //           })
      //         }
      //         if(bitui[i].gufim.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Gufim: 
      //             ${bitui[i].gufim.includes(1)?"First ": ""}
      //             ${bitui[i].gufim.includes(2)?"Second ": ""}
      //             ${bitui[i].gufim.includes(3)?"Third ": ""}`
      //           })
      //         }
      //         if(bitui[i].gufimSof.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Gufim For Suffix: 
      //             ${bitui[i].gufimSof.includes(1)?"First ": ""}
      //             ${bitui[i].gufimSof.includes(2)?"Second ": ""}
      //             ${bitui[i].gufimSof.includes(3)?"Third ": ""}`
      //           })
      //         }
      //         if(bitui[i].misparim.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Number: 
      //             ${bitui[i].misparim.includes(1)?"Singular ": ""}
      //             ${bitui[i].misparim.includes(2)?"Plural ": ""}
      //             ${bitui[i].misparim.includes(3)?"Dual ": ""}`
      //           })
      //         }
      //         if(bitui[i].misparimSof.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Number For Suffix:
      //             ${bitui[i].misparimSof.includes(1)?"Singular ": ""}
      //             ${bitui[i].misparimSof.includes(2)?"Plural ": ""}
      //             ${bitui[i].misparimSof.includes(3)?"Dual ": ""}`
      //           })
      //         }
      //         if(bitui[i].zmanim.length>0){
      //           PartsOfSpeech.children.push({
      //             id:PartsOfSpeech.children.length+1,
      //             name:`Tense: 
      //             ${bitui[i].zmanim.includes(1)?"Past ": ""}
      //             ${bitui[i].zmanim.includes(2)?"Present ": ""}
      //             ${bitui[i].zmanim.includes(3)?"Future ": ""}
      //             ${bitui[i].zmanim.includes(4)?"Imperative ": ""}`
      //           })
      //         }

      //         if(PartsOfSpeech.children.length>0){
      //           data.children.push(PartsOfSpeech);
      //         }
      //         var Distances={
      //           id:data.children.length+1,
      //           name:"Distances",
      //           isExpandable:true,
      //           isExpandable:true,
      //           children:this.setUpDistancesPerWord(bitui[i])
      //         }

      //         if(Distances.children.length>0){
      //           data.children.push(Distances);
      //         }

      //         if(!data.children.length>0){
      //           data={
      //             id:dataArray.length+1,
      //             name:`Search Term: ${bitui[i].term}`,
      //           }
      //         }
      //         dataArray.push(data)
      //   }
      //   return dataArray;

        
      // }
      // setUpDistancesPerWord(termParameters){
      //   var data=[];
      //   if(termParameters.containerDistances && (termParameters.containerDistances[0].distance1 > 0 || termParameters.containerDistances[0].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[0].unitLevelId===1?"Letters":"Words"} to the end of the Pasuk.`
      //     })
      //   }
      //   if(termParameters.containerDistances && (termParameters.containerDistances[1].distance1 > 0 || termParameters.containerDistances[1].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[1].unitLevelId===1?"Letters":"Words"} to the beginning of the Pasuk.`
      //     })
      //   }
      //   if(termParameters.containerDistances && (termParameters.containerDistances[2].distance1 > 0 || termParameters.containerDistances[2].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[2].unitLevelId===1?"Letters":termParameters.containerDistances[2].unitLevelId===2?"Words":"Pesukim"} to the end of the Parsha.`
      //     })
      //   }
      //   if(termParameters.containerDistances && (termParameters.containerDistances[3].distance1 > 0 || termParameters.containerDistances[3].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[3].unitLevelId===1?"Letters":termParameters.containerDistances[3].unitLevelId===2?"Words":"Pesukim"} to the beginning of the Parsha.`
      //     })
      //   }
      //   if(termParameters.containerDistances && (termParameters.containerDistances[4].distance1 > 0 || termParameters.containerDistances[4].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[4].unitLevelId===1?"Letters":termParameters.containerDistances[4].unitLevelId===2?"Words":termParameters.containerDistances[4].unitLevelId===3?"Pesukim":"Parshios"} to the end of the Sefer.`
      //     })
      //   }
      //   if(termParameters.containerDistances && (termParameters.containerDistances[5].distance1 > 0 || termParameters.containerDistances[5].distance2 < 100)){
      //     data.push({
      //       id:data.length+1,
      //       name:`You changed the distance in ${termParameters.containerDistances[5].unitLevelId===1?"Letters":termParameters.containerDistances[5].unitLevelId===2?"Words":termParameters.containerDistances[5].unitLevelId===3?"Pesukim":"Parshios"} to the beginning of the Sefer.`
      //     })
      //   }
      //   return data;
      // }
      // setUpPlacement(params){
      //   var data=[];
      //   if(params.namedLocations.length>0){
      //     params.namedLocations.map((loc,i)=>{
      //       data.push(
      //         {
      //           id:data.length+1,
      //           name: `Kria: ${loc.kriaId}${loc.aliyaId!==0?`Aliya: ${loc.aliyaId}`:""}`,
      //         }
      //       )
      //     })
      //   }
      //   if(params.pasukRangeLocations.length>0){
      //     params.pasukRangeLocations.map((loc,i)=>{
      //       data.push(
      //         {
      //           id:data.length+1,
      //           name: `Sefer: ${loc.seferId1}, Perek: ${loc.perekNum1}, Pasuk: ${loc.pasukNum1} to Sefer: ${loc.seferId2}, Perek: ${loc.perekNum2}, Pasuk: ${loc.pasukNum2}`,
      //         }
      //       )
      //     })
      //   }
      //   return data;
      // }

      // setUpPasukSettings(params){
      //   var data=[];
      //   if(params.includeExcludeLNTs[0].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Letters To Include: ${params.includeExcludeLNTs[0].ids.toString()}`
      //     })
      //   }
      //   if(params.includeExcludeLNTs[1].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Nekudos To Include: ${params.includeExcludeLNTs[1].ids.toString()}`//turn this into Nekudos
      //     })
      //   }
      //   if(params.includeExcludeLNTs[2].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Taamim To Include: ${params.includeExcludeLNTs[2].ids.toString()}`//turn this into Taamim
      //     })
      //   }
      //   if(params.includeExcludeLNTs[3].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Letters To Exclude: ${params.includeExcludeLNTs[3].ids.toString()}`
      //     })
      //   }
      //   if(params.includeExcludeLNTs[4].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Nekudos To Exclude: ${params.includeExcludeLNTs[4].ids.toString()}`//turn this into Nekudos
      //     })
      //   }
      //   if(params.includeExcludeLNTs[5].ids.length>0){
      //     data.push({
      //       id:data.length+1,
      //       name:`Taamim To Exclude: ${params.includeExcludeLNTs[5].ids.toString()}`//turn this into Taamim
      //     })
      //   }
      //   if(params.wordsPerPasuk.min!==2 || params.wordsPerPasuk.max!==64){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Words Per Pasuk"
      //     })
      //   }
      //   if(params.lettersPerPasuk.min!==6 || params.lettersPerPasuk.max!==254){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Letters Per Pasuk"
      //     })
      //   }
      //   if(params.lettersPerWord.min!==2.5 || params.lettersPerWord.max!==8){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Letters Per Word"
      //     })
      //   }
      //   if(params.uniqueLetter.min!==5 || params.uniqueLetter.max!==22){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Unique Letters"
      //     })
      //   }
      //   if(params.uniqueLetterCS.min!==5 || params.uniqueLetterCS.max!==27){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Unique Letters CS"
      //     })
      //   }
      //   if(params.tenuosPerPasuk.min!==4 || params.tenuosPerPasuk.max!==96){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Tenuos Per Pasuk"
      //     })
      //   }
      //   if(params.tenuosPerWord.min!==1.25 || params.tenuosPerWord.max!==4.5){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Tenuos Per Word"
      //     })
      //   }
      //   if(params.tenuosPerLetter.min!==.31 || params.tenuosPerLetter.max!==.75){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Tenuos Per Letter"
      //     })
      //   }
      //   if(params.taamimPerPasuk.min!==2 || params.taamimPerPasuk.max!==35){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Taamim Per Pasuk"
      //     })
      //   }
      //   if(params.taamimPerWord.min!==.43 || params.taamimPerWord.max!==1.5){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Taamim Per Word"
      //     })
      //   }
      //   if(params.taamimPerTenua.min!==.22 || params.taamimPerTenua.max!==.82){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Taamim Per Tenua"
      //     })
      //   }
      //   if(params.gematriaPerPasuk.min!==160 || params.gematriaPerPasuk.max!==18377){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Gematria Per Pasuk"
      //     })
      //   }
      //   if(params.gematriaPerWord.min!==48.8 || params.gematriaPerWord.max!==731.3){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Gematria Per Word"
      //     })
      //   }
      //   if(params.gematriaPerLetter.min!==13.8 || params.gematriaPerLetter.max!==164.8){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Gematria Per Letter"
      //     })
      //   }
      //   if(params.atbashPerPasuk.min!==268 || params.atbashPerPasuk.max!==19145){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Atbash Per Pasuk"
      //     })
      //   }
      //   if(params.atbashPerWord.min!==69.5 || params.atbashPerWord.max!==791){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Atbash Per Word"
      //     })
      //   }
      //   if(params.atbashPerLetter.min!==20.2 || params.atbashPerLetter.max!==173.8){
      //     data.push({
      //       id:data.length+1,
      //       name:"You changed Atbash Per Letter"
      //     })
      //   }

      //   return null;
        
      // }

      // setUpData(){
      //   var searchParameters = this.props.searchCriteria//[0].searchParams;

      //   var fakeData = searchParameters?[
      //     {
      //       id: 1,
      //       name:"Search Per Word",
      //       isExpandable:true,
      //       isExpanded: true,
      //       children: this.setUpPerWord(searchParameters.bituiParameters),
      //     },
      //     { id:2,
      //       name:"General Basic Search Settings",
      //       isExpandable:true,
      //       isExpanded:true,
      //       children: [ {
      //           id:1,
      //           name: `Search On: ${searchParameters.morphInflections==="L"?"Letters":
      //                               searchParameters.morphInflections==="N"?"Nekudos":
      //                               searchParameters.morphInflections==="T"?"Taamim":
      //                               searchParameters.morphInflections==="LN"?"Letters and Nekudos":
      //                               searchParameters.morphInflections==="LT"?"Letters and Taamim":
      //                               searchParameters.morphInflections==="NT"?"Nekudos and Taamim":
      //                               searchParameters.morphInflections==="LNT"?"Letters, Nekudos, and Taamim":
      //                               searchParameters.morphInflections==="S"?"Shoresh":
      //                               searchParameters.morphInflections==="G"?"Gematria":
      //                               searchParameters.morphInflections==="A"?"Atbash":""}`
      //         },{
      //           id:2,
      //           name: `Exactness: ${searchParameters.bituiParameters[0].exactnessLevel===0?"Exact":
      //                               searchParameters.bituiParameters[0].exactnessLevel===1?"Somewhat Exact":
      //                               searchParameters.bituiParameters[0].exactnessLevel===2?"Somewhat Fuzzy":
      //                               searchParameters.bituiParameters[0].exactnessLevel===3?"Fuzzy":""}`
      //         },{
      //           id:3,
      //           name: `In Order? ${searchParameters.isInSequence?"Yes":"No"}`
      //         },{
      //           id:4,
      //           name: `Every Word? ${searchParameters.scope==='any'?'No':'Yes'}`
      //         },{
      //           id:5,
      //           name: `Sort Results by: 
      //         ${searchParameters.sortOrder===0?"Appearance":
      //           searchParameters.sortOrder===1?"Time Of Occurence":
      //           searchParameters.sortOrder===2?"Words":
      //           searchParameters.sortOrder===3?"Letters":
      //           searchParameters.sortOrder===4?"Letters Per Word":
      //           searchParameters.sortOrder===5?"Unique Letters":
      //           searchParameters.sortOrder===6?"Unique Letters including Sofios":
      //           searchParameters.sortOrder===7?"Nekodos":
      //           searchParameters.sortOrder===8?"Nekudos Per Word":
      //           searchParameters.sortOrder===9?"Nekudos Per Letter":
      //           searchParameters.sortOrder===10?"Taamim":
      //           searchParameters.sortOrder===11?"Taamim Per Word":
      //           searchParameters.sortOrder===12?"Taamim Per Nekuda":
      //           searchParameters.sortOrder===13?"Gematria":
      //           searchParameters.sortOrder===14?"Gematria Per Word":
      //           searchParameters.sortOrder===15?"Gematria Per Letter":
      //           searchParameters.sortOrder===16?"Atbash":
      //           searchParameters.sortOrder===17?"Atbash Per Word":
      //           searchParameters.sortOrder===18?"Atbash Per Letter":""}`+
      //           `${searchParameters.sortDirection===1?` (Desc)`:` (Asc)`}`
      //         }
            
      //       ]}
      //       // ,{
      //       //   id:3,
      //       //   name: "Pasuk Settings",
      //       //   isExpanded:true,
      //       //   isExpandable:true,
      //       //   children:this.setUpPasukSettings(searchParameters),
      //       // },{
      //       //   id:4,
      //       //   name: "Placement",
      //       //   isExpanded:true,
      //       //   isExpandable:true,
      //       //   children: this.setUpPlacement(searchParameters),
      //       // }
      //   ]:[]
      //   var pasukSettingsObject={
      //     id:fakeData.length+1,
      //     name: "Pasuk Settings",
      //     isExpandable:true,
      //     isExpanded:true,
      //     children:this.setUpPasukSettings(searchParameters),
      //   }
      //   if(pasukSettingsObject.children){
      //     fakeData.push(pasukSettingsObject)
      //   }
      //   var placementObject={
      //     id:fakeData.length+1,
      //     name:"Placement",
      //     isExpanded:true,
      //     isExpandable:true,
      //     cildren:this.setUpPlacement(searchParameters),
      //   }
      //   if(placementObject.children){
      //     fakeData.push(placementObject)
      //   }

      //   this.setState({data:fakeData})

      //   return fakeData;
      // }




      async setUpPerWord(bitui,mainExactnessLevel,translate,inLeftBar){
        var dataArray=[];
        var leftBar=inLeftBar;
        for(let i=0; i<bitui.length; i++){

              var data={
                id:dataArray.length+1+"SC",
                name:<div title={leftBar?bitui[i].term:""}><b><Translate id="searchTerm"/>{bitui[i].term}</b></div>,
                isExpandable:true,
                isExpanded:true,
                children:[],
              };
              if(bitui[i].isNegative){
                data.children.push({
                  id:data.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("negativeWord"):""} ><Translate id="negativeWord"/></div>
                })
              }
              if(bitui[i].isCaseSensitive){
                data.children.push({
                  id:data.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("caseSensitive"):""}><Translate id="caseSensitive"/></div>
                })
              }
              if(bitui[i].repetitions>1){
                data.children.push({
                  id:data.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].repetitions:""}><Translate  id="occurrences"/>: {bitui[i].repetitions}</div>
                })
              }
              if(bitui[i].transformation){
                data.children.push({
                  id:data.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].transformation===1?translate("anagram"):bitui[i].transformation===2?translate("reverse"):bitui[i].transformation===3?translate("root"):"Regex":""}>
                    <Translate id="transformationSearchCriteria"/>&nbsp;{bitui[i].transformation===1?<Translate id="anagram" title={translate("anagram")}/>:
                  bitui[i].transformation===2?<span><Translate id="reverse"/></span>:
                  bitui[i].transformation===3?<span><Translate id="root" /></span>:
                  bitui[i].transformation===4?<span>Regex</span>: ""}</div>
                })
              }
              if(bitui[i].exactnessLevel!==mainExactnessLevel){
                data.children.push({
                  id: data.children.length+1+"SC",
                  name: <div title={inLeftBar?bitui[i].exactnessLevel===0?translate("preciseSearch.caption"):bitui[i].exactnessLevel===1?translate("somewhatExact"):bitui[i].exactnessLevel===2?translate("somewhatFuzzy"):translate("fuzzySearch.caption"):""}>
                  <Translate id="exactness"/>&nbsp;{bitui[i].exactnessLevel===0?<span><Translate id="preciseSearch.caption" /></span>:
                                      bitui[i].exactnessLevel===1?<span ><Translate id="somewhatExact" /></span>:
                                      bitui[i].exactnessLevel===2?<span ><Translate id="somewhatFuzzy"/></span>:
                                      bitui[i].exactnessLevel===3?<span ><Translate id="fuzzySearch.caption"/></span>:""}</div>
                })
                
              }
              var NikudAndTaamim={
                id:data.children.length+1+"SC",
                name:<div title={inLeftBar?translate("advTabVowelizeCantillate"):""}><b><Translate id="advTabVowelizeCantillate"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              var NikudPerLetter={
                id:NikudAndTaamim.children.length+1+"SC",
                name:<div title={inLeftBar?translate("nikudPerLetter"):""}><b><Translate id="nikudPerLetter"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              bitui[i].letterNekudos.map((letterNikud,i)=>{//todo: fix the id of special characters-it is not always 18, 19, 20
                        /*
                        BUG: THE NUMBER THAT IS PASSED IN FOR THE X:NO NEKUDA, 
                        *:ALL NEKUDOS, AND ?:ALL NEKUDA IS NOT CONSISTANT. 
                        IN NEKUDA BY LETTER, THE SEARCH CRITERIA MAY NOT BE CORRECT 
                        BECAUSE IT WORKS BY SPECIFIC ID. WHEN THERE IS NO SHIN OR SIN 
                        OR MAPIK HEY, THOSE SPECIAL CHARACTERS HAVE A LOWER ID 
                        AND THE CRITERIA READS IT AS A SHIN OR SIN...*/
                var array=[];
                if(letterNikud.includes(1))array.push(" שוא")
                if(letterNikud.includes(2))array.push(" חטף סגול")
                if(letterNikud.includes(3))array.push(" חטף פתח")
                if(letterNikud.includes(4))array.push(" חטף קמץ")
                if(letterNikud.includes(5))array.push(" חיריק")
                if(letterNikud.includes(6))array.push(" צירי")
                if(letterNikud.includes(7))array.push(" סגול")
                if(letterNikud.includes(8))array.push(" פתח")
                if(letterNikud.includes(9))array.push(" קמץ")
                if(letterNikud.includes(10))array.push(" חולם")
                if(letterNikud.includes(11))array.push(" חולם חסר")
                if(letterNikud.includes(12))array.push(" קבוץ")
                if(letterNikud.includes(13))array.push(" שורוק")
                if(letterNikud.includes(14))array.push(" דגש")
                if(letterNikud.includes(15))array.push(" מפיק")
                if(letterNikud.includes(16))array.push(" שין ימיני")
                if(letterNikud.includes(17))array.push(" שין שמאלי")
                if(letterNikud.includes(18))array.push(translate("noNekuda"))
                if(letterNikud.includes(19))array.push(translate("allNekudos?"))
                if(letterNikud.includes(20))array.push(translate("allNekudos*"))

                if(letterNikud.length>0){
                  NikudPerLetter.children.push({
                    id:NikudPerLetter.children.length+1+"SC",
                    name:<div title={inLeftBar?array.toString():""}><span><Translate id="nikudPerLetterName"/>&nbsp;{i+1}: {array.toString()}</span></div>
                  })
                }
              })
              if(NikudPerLetter.children.length>0){
                NikudAndTaamim.children.push(NikudPerLetter);
              }

              var GeneralNekudos={
                id:NikudAndTaamim.children.length+1+"SC",
                name:<div title={inLeftBar?translate("nikudGeneral"):""}><b><Translate id="nikudGeneral"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }
              bitui[i].nekudos.map((nikud)=>{ 
                var array=[];
                if(nikud.includes(1))array.push(" שוא")
                if(nikud.includes(2))array.push(" חטף סגול")
                if(nikud.includes(3))array.push(" חטף פתח")
                if(nikud.includes(4))array.push(" חטף קמץ")
                if(nikud.includes(5))array.push(" חיריק")
                if(nikud.includes(6))array.push(" צירי")
                if(nikud.includes(7))array.push(" סגול")
                if(nikud.includes(8))array.push(" פתח")
                if(nikud.includes(9))array.push(" קמץ")
                if(nikud.includes(10))array.push(" חולם")
                if(nikud.includes(11))array.push(" חולם חסר")
                if(nikud.includes(12))array.push(" קבוץ")
                if(nikud.includes(13))array.push(" שורוק")
                if(nikud.includes(14))array.push(" דגש")
                if(nikud.includes(15))array.push(" מפיק")
                if(nikud.includes(16))array.push(" שין ימיני")
                if(nikud.includes(17))array.push(" שין שמאלי")
                if(nikud.includes(18))array.push(translate("noNekuda"))//the id is fine here because all of the other nekudos are allowed.
                if(nikud.includes(19))array.push(translate("allNekudos?"))
                if(nikud.includes(20))array.push(translate("allNekudos*"))

                if(nikud.length>0){
                  GeneralNekudos.children.push({
                    id:GeneralNekudos.children.length+1+"SC",
                    name:<div title={inLeftBar?array.toString():""}><Translate id="position"/>&nbsp;{GeneralNekudos.children.length+1} <Translate id="nekudos"/>:&nbsp;{array.toString()}</div>
                  })
                }
              })
              if(GeneralNekudos.children.length>0){
                NikudAndTaamim.children.push(GeneralNekudos);
              }

              var GeneralTaamim={
                id:NikudAndTaamim.children.length+1+"SC",
                name:<div title={inLeftBar?translate("tataama"):""}><b><Translate id="hataama"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:[],
              }

              var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
              var taamBody = await taamResponse.json()
              bitui[i].taamim.map((taam)=>{ 
              var taamArray=[];
                if(taam.length>0){
                  for(let j=0; j<taam.length;j++){
                    if(taam[j]===60){
                      taamArray.push(" כל טעמים *")
                    }else if(taam[j]===59){
                      taamArray.push(" כל טעם ?")
                    }else if(taam[j]===58){
                      taamArray.push(" ללא טעם X")
                    }
                    else{
                      for(let i=0; i<taamBody.length; i++){
                        if(taamBody[i].id===taam[j]){
                          taamArray.push(" "+taamBody[i].name)
                        }
                      }
                    }
                  }
                  GeneralTaamim.children.push({
                    id:GeneralTaamim.children.length+1+"SC",
                    name:<div title={inLeftBar?taamArray.toString():""}><Translate id="position"/> {GeneralTaamim.children.length+1} <Translate id="hataama"/>: {taamArray.toString()}</div>
                  })
                }
              })
              if(GeneralTaamim.children.length>0){
                NikudAndTaamim.children.push(GeneralTaamim);
              }
              if(NikudAndTaamim.children.length>0){
                data.children.push(NikudAndTaamim);
              }
               
              var PartsOfSpeech=
                {
                  id:data.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("advTabPOS"):""}><b><Translate id="advTabPOS"/></b></div>,
                  isExpandable:true,
                  isExpanded:true,
                  children:[],  
                }
              
              if(bitui[i].atbash && (bitui[i].atbash.min>2 || bitui[i].atbash.max<1390)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].atbash.min+"-"+(bitui[i].atbash.max===1390?'∞':bitui[i].atbash.max):""}><Translate id="atbash"/>: {bitui[i].atbash.min}-{bitui[i].atbash.max===1390?'∞':bitui[i].atbash.max}</div>
                })
              }
              if(bitui[i].gematria && (bitui[i].gematria.min>3 || bitui[i].gematria.max<1640)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].gematria.min+"-"+(bitui[i].gematria.max===1640?'∞':bitui[i].gematria.max):""}><Translate id="gematria"/>: {bitui[i].gematria.min}-{(bitui[i].gematria.max===1640?'∞':bitui[i].gematria.max)}</div>
                })
              }
              if(bitui[i].frequency && (bitui[i].frequency.min>1 || bitui[i].frequency.max<1000)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].frequency.min+"-"+(bitui[i].frequency.max===1000?'∞':bitui[i].frequency.max):""}><Translate id="frequency.caption"/>: {bitui[i].frequency.min}-{(bitui[i].frequency.max===1000?'∞':bitui[i].frequency.max)}</div>
                })
              }
              if(bitui[i].lettersQty && (bitui[i].lettersQty.min>1 || bitui[i].lettersQty.max<11)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].lettersQty.min+"-"+(bitui[i].lettersQty.max===11?'∞':bitui[i].lettersQty.max):""}><Translate id="lettersPerWord"/>: {bitui[i].lettersQty.min}-{(bitui[i].lettersQty.max===11?'∞':bitui[i].lettersQty.max)}</div>
                })
              }
              if(bitui[i].tenuosQty && (bitui[i].tenuosQty.min>0 || bitui[i].tenuosQty.max<6)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].tenuosQty.min+"-"+(bitui[i].tenuosQty.max===6?'∞':bitui[i].tenuosQty.max):""}><Translate id="tenuosPerWord"/>: {bitui[i].tenuosQty.min}-{(bitui[i].tenuosQty.max===6?'∞':bitui[i].tenuosQty.max)}</div>
                })
              }
              if(bitui[i].vowelsQty && (bitui[i].vowelsQty.min>0 || bitui[i].vowelsQty.max<9)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].vowelsQty.min+"-"+(bitui[i].vowelsQty.max===9?'∞':bitui[i].vowelsQty.max):""}><Translate id="vowelsPerWord"/>: {bitui[i].vowelsQty.min}-{(bitui[i].vowelsQty.max===9?'∞':bitui[i].vowelsQty.max)}</div>
                })
              }
              if(bitui[i].languages && bitui[i].languages.includes(1)&& bitui[i].languages.includes(3)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("hebrew")+", "+translate("aramaic"):""}><Translate id="language"/>: <Translate id="hebrew"/>, <Translate id="aramaic"/></div>
                })
              }else if(bitui[i].languages && bitui[i].languages.includes(1)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("hebrew"):""}><Translate id="language"/>: <Translate id="hebrew"/></div>
                })
              }else if(bitui[i].languages && bitui[i].languages.includes(3)){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("aramaic"):""}><Translate id="language"/>: <Translate id="aramaic"/></div>
                })
              }
              if(bitui[i].hasInfix ){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("yes"):""}><Translate id="infix"/>: <Translate id="yes"/></div>
                })
              }else if(bitui[i].hasInfix===false){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("no"):""}><Translate id="infix"/>: <Translate id="no"/></div>
                })
              }
              if(bitui[i].hasSuffix ){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("yes"):""}><Translate id="suffix"/>: <Translate id="yes"/></div>
                })
              }else if(bitui[i].hasSuffix===false){
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?translate("no"):""}><Translate id="suffix"/>: <Translate id="no"/></div>
                })
              }
              if(bitui[i].prefixes.length>0){
                var array=[];
                if(bitui[i].prefixes.includes(-2))array.push(translate("noPrefix"))
                if(bitui[i].prefixes.includes(-1))array.push(translate("allPrefix"))
                if(bitui[i].prefixes.includes(1))array.push(translate("article"))
                if(bitui[i].prefixes.includes(2))array.push(translate("question"))
                if(bitui[i].prefixes.includes(3))array.push(translate("conjunctionAffix"))
                if(bitui[i].prefixes.includes(4))array.push(translate("inversion"))
                if(bitui[i].prefixes.includes(5))array.push(translate("2Preposition"))
                if(bitui[i].prefixes.includes(6))array.push(translate("20Preposition"))
                if(bitui[i].prefixes.includes(7))array.push(translate("30Preposition"))
                if(bitui[i].prefixes.includes(8))array.push(translate("40Preposition"))
                if(bitui[i].prefixes.includes(9))array.push(translate("1Future"))
                if(bitui[i].prefixes.includes(10))array.push(translate("10Future"))
                if(bitui[i].prefixes.includes(11))array.push(translate("50Future"))
                if(bitui[i].prefixes.includes(12))array.push(translate("400Future"))
                if(bitui[i].prefixes.includes(13))array.push(translate("50Past"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="prefix"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].numberCategories.length>0){
                var array=[];
                  if(bitui[i].numberCategories.includes(1))array.push(translate("mispar"))
                  if(bitui[i].numberCategories.includes(2))array.push(translate("cardinal"))
                  if(bitui[i].numberCategories.includes(3))array.push(translate("ordinal"))
                  if(bitui[i].numberCategories.includes(4))array.push(translate("fractional"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="numberCategories"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].objectCategories.length>0){
                var array=[];
                  if(bitui[i].objectCategories.includes(10)) array.push(translate("famFam"))
                  if(bitui[i].objectCategories.includes(1))array.push(translate("personMan"))
                  if(bitui[i].objectCategories.includes(9))array.push(translate("GdGd"))
                  if(bitui[i].objectCategories.includes(2))array.push(translate("personWoman"))
                  if(bitui[i].objectCategories.includes(4))array.push(translate("placeLand"))
                  if(bitui[i].objectCategories.includes(5))array.push(translate("placeMtn"))
                  if(bitui[i].objectCategories.includes(6))array.push(translate("placeRiver"))
                  if(bitui[i].objectCategories.includes(3))array.push(translate("placePlace"))
                  if(bitui[i].objectCategories.includes(7))array.push(translate("placeCity"))
                  if(bitui[i].objectCategories.includes(8))array.push(translate("nameName"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="objectCategory"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].wordTypes.length>0){
                  var array=[];
                  if(bitui[i].wordTypes.includes(-1)) array.push(translate("uncategorized"))
                  if(bitui[i].wordTypes.includes(1)) array.push(translate("noun"))
                  if(bitui[i].wordTypes.includes(9))array.push(translate("pronoun"))
                  if(bitui[i].wordTypes.includes(2))array.push(translate("verb"))
                  if(bitui[i].wordTypes.includes(3))array.push(translate("adj"))
                  if(bitui[i].wordTypes.includes(4))array.push(translate("adverb"))
                  if(bitui[i].wordTypes.includes(5))array.push(translate("preposition"))
                  if(bitui[i].wordTypes.includes(6))array.push(translate("conjunction"))
                  if(bitui[i].wordTypes.includes(7))array.push(translate("interjection"))
                  if(bitui[i].wordTypes.includes(8))array.push(translate("determiner"))

                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="wordTypes"/>:&nbsp;{array.toString()} </div>
                })
              }
              if(bitui[i].binyanim.length>0){
                var array=[];
                  if(bitui[i].binyanim.includes(1))array.push(translate("simpleActive"))
                  if(bitui[i].binyanim.includes(2))array.push(translate("simplePassive"))
                  if(bitui[i].binyanim.includes(3))array.push(translate("causativeActive"))
                  if(bitui[i].binyanim.includes(4))array.push(translate("causativePassive"))
                  if(bitui[i].binyanim.includes(5))array.push(translate("intensiveActive"))
                  if(bitui[i].binyanim.includes(6))array.push(translate("intensivePassive"))
                  if(bitui[i].binyanim.includes(7))array.push(translate("reflexive"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="binyan"/>:&nbsp;{array.toString()}</div>
                  
                })
              }
              if(bitui[i].genders.length>0){
                var array=[];
                  if(bitui[i].genders.includes(1))array.push(translate("male"))
                  if(bitui[i].genders.includes(2))array.push(translate("female"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="gender"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gendersSof.length>0){
                var array=[];
                  if(bitui[i].gendersSof.includes(1))array.push(translate("male"))
                  if(bitui[i].gendersSof.includes(2))array.push(translate("female"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="gender"/>&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gufim.length>0){
                var array=[];
                  if(bitui[i].gufim.includes(1))array.push(translate("firstPerson"))
                  if(bitui[i].gufim.includes(2))array.push(translate("secondPerson"))
                  if(bitui[i].gufim.includes(3))array.push(translate("thirdPerson"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="guf"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].gufimSof.length>0){
                var array=[];
                  if(bitui[i].gufimSof.includes(1))array.push(translate("firstPerson"))
                  if(bitui[i].gufimSof.includes(2))array.push(translate("secondPerson"))
                  if(bitui[i].gufimSof.includes(3))array.push(translate("thirdPerson"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="guf"/>&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].misparim.length>0){
                var array=[];
                  if(bitui[i].misparim.includes(1))array.push(translate("singular"))
                  if(bitui[i].misparim.includes(2))array.push(translate("plural"))
                  if(bitui[i].misparim.includes(3))array.push(translate("dual"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="mispar"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].misparimSof.length>0){
                var array=[];
                  if(bitui[i].misparimSof.includes(1))array.push(translate("singular"))
                  if(bitui[i].misparimSof.includes(2))array.push(translate("plural"))
                  if(bitui[i].misparimSof.includes(3))array.push(translate("dual"))
                  let mispar=<Translate id="mispar"/>
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}>{mispar}&nbsp;<Translate id="suffixPOS"/>:&nbsp;{array.toString()}</div>
                })
              }
              if(bitui[i].zmanim.length>0){
                var array=[];
                  if(bitui[i].zmanim.includes(1))array.push(translate("past"))
                  if(bitui[i].zmanim.includes(2))array.push(translate("present"))
                  if(bitui[i].zmanim.includes(3))array.push(translate("future"))
                  if(bitui[i].zmanim.includes(4))array.push(translate("imperitive"))
                PartsOfSpeech.children.push({
                  id:PartsOfSpeech.children.length+1+"SC",
                  name:<div title={inLeftBar?array.toString():""}><Translate id="zman"/>:&nbsp;{array.toString()}</div>
                })
              }

              if(PartsOfSpeech.children.length>0){
                data.children.push(PartsOfSpeech);
              }
              var Distances={
                id:data.children.length+1+"SC",
                name:<div title={inLeftBar?translate("advTabDistances"):""}><b><Translate id="advTabDistances"/></b></div>,
                isExpanded:true,
                isExpandable:true,
                children:this.setUpDistancesPerWord(bitui[i],inLeftBar)
              }

              if(Distances.children.length>0){
                data.children.push(Distances);
              }

              if(!data.children.length>0){
                data={
                  id:dataArray.length+1+"SC",
                  name:<div title={inLeftBar?bitui[i].term:""}><Translate id="searchTerm"/>{bitui[i].term}</div>,
                }
              }
              dataArray.push(data)
        }
        if(dataArray.length==0){
          data={
            id:dataArray.length+1+"SC",
            name:<div title={inLeftBar?"":""}><Translate id="searchTerm"/></div>,
          }
          dataArray.push(data)
        }
        return dataArray;

        
      }
    setUpDistancesPerWord(termParameters,inLeftBar){
        var words=<Translate id="wordUnit"></Translate>
        var letters=<Translate id="letterUnit"></Translate>
        var pesukim=<Translate id="pasukUnit"></Translate>
        var parshios=<Translate id="parshaUnit"></Translate>
        var data=[];
        if(termParameters.containerDistances && termParameters.containerDistances[0] && (termParameters.containerDistances[0].distance1 > 0 || termParameters.containerDistances[0].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[0].distance1+"-"+(termParameters.containerDistances[0].distance2===100?'∞':termParameters.containerDistances[0].distance2):""}><Translate id="distanceToPasukEnd"/>: {termParameters.containerDistances[0].distance1}-{termParameters.containerDistances[0].distance2===100?'∞':termParameters.containerDistances[0].distance2} {termParameters.containerDistances[0].unitLevelId===1?letters:words}</div>
          })
        }
        if(termParameters.containerDistances && termParameters.containerDistances[1] && (termParameters.containerDistances[1].distance1 > 0 || termParameters.containerDistances[1].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[1].distance1+"-"+(termParameters.containerDistances[1].distance2===100?'∞':termParameters.containerDistances[1].distance2):""}><Translate id="distanceToPasukStart"/>: {termParameters.containerDistances[1].distance1}-{termParameters.containerDistances[1].distance2===100?'∞':termParameters.containerDistances[1].distance2} {termParameters.containerDistances[1].unitLevelId===1?letters:words}</div>
          })
        }
        if(termParameters.containerDistances && termParameters.containerDistances[2] && (termParameters.containerDistances[2].distance1 > 0 || termParameters.containerDistances[2].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[2].distance1+"-"+(termParameters.containerDistances[2].distance2===100?'∞':termParameters.containerDistances[2].distance2):""}><Translate id="distanceToParshaEnd"/>: {termParameters.containerDistances[2].distance1}-{termParameters.containerDistances[2].distance2===100?'∞':termParameters.containerDistances[2].distance2} {termParameters.containerDistances[2].unitLevelId===1?letters:termParameters.containerDistances[2].unitLevelId===2?words:pesukim}</div>
          })
        }
        if(termParameters.containerDistances && termParameters.containerDistances[3] && (termParameters.containerDistances[3].distance1 > 0 || termParameters.containerDistances[3].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[3].distance1+"-"+(termParameters.containerDistances[3].distance2===100?'∞':termParameters.containerDistances[3].distance2):""}><Translate id="distanceToParshaStart"/>: {termParameters.containerDistances[3].distance1}-{termParameters.containerDistances[3].distance2===100?'∞':termParameters.containerDistances[3].distance2} {termParameters.containerDistances[3].unitLevelId===1?letters:termParameters.containerDistances[3].unitLevelId===2?words:pesukim}</div>
          })
        }
        if(termParameters.containerDistances && termParameters.containerDistances[4] && (termParameters.containerDistances[4].distance1 > 0 || termParameters.containerDistances[4].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[4].distance1+"-"+(termParameters.containerDistances[4].distance2===100?'∞':termParameters.containerDistances[4].distance2):""}><Translate id="distanceToSeferEnd"/>: {termParameters.containerDistances[4].distance1}-{termParameters.containerDistances[4].distance2===100?'∞':termParameters.containerDistances[4].distance2} {termParameters.containerDistances[4].unitLevelId===1?letters:termParameters.containerDistances[4].unitLevelId===2?words:termParameters.containerDistances[4].unitLevelId===3?pesukim:parshios}</div>
          })
        }
        if(termParameters.containerDistances && termParameters.containerDistances[5] && (termParameters.containerDistances[5].distance1 > 0 || termParameters.containerDistances[5].distance2 < 100)){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?termParameters.containerDistances[5].distance1+"-"+(termParameters.containerDistances[5].distance2===100?'∞':termParameters.containerDistances[5].distance2):""}><Translate id="distanceToSeferStart"/>: {termParameters.containerDistances[5].distance1}-{termParameters.containerDistances[5].distance2===100?'∞':termParameters.containerDistances[5].distance2} {termParameters.containerDistances[5].unitLevelId===1?letters:termParameters.containerDistances[5].unitLevelId===2?words:termParameters.containerDistances[5].unitLevelId===3?pesukim:parshios}</div>
          })
        }
        return data;
      }

    setUpPlacement(placementRowData,translate,inLeftBar){
      var data=[];

      for(let i=0; i<placementRowData.length-1; i++){
        if(placementRowData[i].type===true){//if it is a namedLocation

          var selectedVal1 = placementRowData[i].valList1.filter(obj => {return obj.id === placementRowData[i].val1});
          var selectedVal2 = placementRowData[i].valList2.filter(obj => {return obj.id === placementRowData[i].val2});
          var selectedVal3 = placementRowData[i].valList3.filter(obj => {return obj.id === placementRowData[i].val3});
          var selectedVal4 = placementRowData[i].valList4.filter(obj => {return obj.id === placementRowData[i].val4});
          var selectedVal5 = placementRowData[i].valList5.filter(obj => {return obj.id === placementRowData[i].val5});
  
          // var kria = placementRowData[i].valList4.filter(obj => {return obj.id === placementRowData[i].val4});
          // var aliya = placementRowData[i].valList5.filter(obj => {return obj.id === placementRowData[i].val5});
          var name=`${selectedVal1[0]?selectedVal1[0].value+" > ":''}${selectedVal2[0]?selectedVal2[0].value+" > ":''}${selectedVal3[0]?selectedVal3[0].value+" > ":''}${selectedVal4[0]? selectedVal5[0]? selectedVal4[0].value+" > ":selectedVal4[0].value:''}${selectedVal5[0]?selectedVal5[0].value:''}`
          data.push({
              id:data.length+1+"SC",
              name:<div title={inLeftBar?name:""}>{name}</div>,
            })
        }else{//if it is a range
          var selectedVal1 = placementRowData[i].valList1.filter(obj => {return obj.seferId === placementRowData[i].val1});
          var selectedVal2 = placementRowData[i].valList2.filter(obj => {return obj.perekNum === placementRowData[i].val2});
          var selectedVal3 = placementRowData[i].valList3.filter(obj => {return obj.pasukNum === placementRowData[i].val3});
          var selectedVal4 = placementRowData[i].valList4.filter(obj => {return obj.seferId === placementRowData[i].val4});
          var selectedVal5 = placementRowData[i].valList5.filter(obj => {return obj.perekNum === placementRowData[i].val5});
          var selectedVal6 = placementRowData[i].valList6.filter(obj => {return obj.pasukNum === placementRowData[i].val6});
          var name=(selectedVal1[0].value +" "+selectedVal2[0].value+" : "+selectedVal3[0].value+" "+translate("to")+" "+selectedVal4[0].value+" "+selectedVal5[0].value+" : "+selectedVal6[0].value)
          data.push({
            id:data.length+1+"SC",
            name: <div title={inLeftBar?name:""}>{name}</div>,
          })
        }
      }

      // for(let i=0;i<params.termDistances.length; i++){
      //   if(params.termDistances[i].distance1!==0 || params.termDistances[i].distance2 !== 100){
      //     var term = params.termDistances[i].unitLevelId===1?translate("letterUnit"):
      //                 params.termDistances[i].unitLevelId===2?translate("wordUnit"):
      //                 params.termDistances[i].unitLevelId===3?translate("pasukUnit"):translate("parshaUnit");
      //       var name= `${params.termDistances[i].directionId===0?`The distance in ${term} between word ${params.termDistances[i].termPosition1} and word ${params.termDistances[i].termPosition2} is between ${params.termDistances[i].distance1} and ${params.termDistances[i].distance2}.`:
      //             `${params.termDistances[i].directionId===-1?`Word ${params.termDistances[i].termPosition1} is at least ${params.termDistances[i].distance1} ${term} and at most ${params.termDistances[i].distance2} ${term} after ${params.termDistances[i].termPosition2}.`:
      //                                                         `Word ${params.termDistances[i].termPosition1} is at least ${params.termDistances[i].distance1} ${term} and at most ${params.termDistances[i].distance2} ${term} before ${params.termDistances[i].termPosition2}.`}`}`//todo: I still have to translate this.
      //     data.push({
      //       id: data.length+1,
      //       name:<div title={inLeftBar?name:""}>{name}</div>
      //     })
      //   }
      // }

      if(data.length>0){
        return data;
      }
      return null;
    }

    setUpGeneralDistances(params,translate,inLeftBar){
      var data=[];

      for(let i=0;i<params.termDistances.length; i++){
        if(params.termDistances[i].distance1!==0 || params.termDistances[i].distance2 !== 100){
          var term = params.termDistances[i].unitLevelId===1?translate("letterUnit"):
                      params.termDistances[i].unitLevelId===2?translate("wordUnit"):
                      params.termDistances[i].unitLevelId===3?translate("pasukUnit"):translate("parshaUnit");
            var name= `${params.termDistances[i].directionId===0?`${translate("theDistIn")}${term} ${translate("btwnTerm")} ${params.termDistances[i].termPosition1} ${translate("andTerm")} ${params.termDistances[i].termPosition2} ${translate("isBtwn")}${params.termDistances[i].distance1} ${translate("and")} ${params.termDistances[i].distance2===100?'∞':params.termDistances[i].distance2}.`:
                  `${params.termDistances[i].directionId===-1?`${translate("term")} ${params.termDistances[i].termPosition1} ${translate("atLeast")} ${params.termDistances[i].distance1} ${term} ${translate("atMost")} ${params.termDistances[i].distance2===100?'∞':params.termDistances[i].distance2} ${term} ${translate("after")} ${translate("term")} ${params.termDistances[i].termPosition2}.`:
                                                              `${translate("term")} ${params.termDistances[i].termPosition1} ${translate("atLeast")} ${params.termDistances[i].distance1} ${term} ${translate("atMost")} ${params.termDistances[i].distance2===100?'∞':params.termDistances[i].distance2} ${term} ${translate("before")} ${translate("term")} ${params.termDistances[i].termPosition2}.`}`}`//todo: I still have to translate this.
          data.push({
            id: data.length+1+"SC",
            name:<div title={inLeftBar?name:""}>{name}</div>
          })
        }
      }

      if(data.length>0){
        return data;
      }
      return null;
    }

    async setUpPasukSettings(params,inLeftBar){
        var data=[];
        if(params.includeExcludeLNTs[0].ids.length>0){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.includeExcludeLNTs[0].ids.toString():""}><Translate id="lettersToInclude"/>: {params.includeExcludeLNTs[0].ids.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[1].ids.length>0){
          var array=[];
          if(params.includeExcludeLNTs[1].ids.includes(1))array.push(" שוא")
          if(params.includeExcludeLNTs[1].ids.includes(2))array.push(" חטף סגול")
          if(params.includeExcludeLNTs[1].ids.includes(3))array.push(" חטף פתח")
          if(params.includeExcludeLNTs[1].ids.includes(4))array.push(" חטף קמץ")
          if(params.includeExcludeLNTs[1].ids.includes(5))array.push(" חיריק")
          if(params.includeExcludeLNTs[1].ids.includes(6))array.push(" צירי")
          if(params.includeExcludeLNTs[1].ids.includes(7))array.push(" סגול")
          if(params.includeExcludeLNTs[1].ids.includes(8))array.push(" פתח")
          if(params.includeExcludeLNTs[1].ids.includes(9))array.push(" קמץ")
          if(params.includeExcludeLNTs[1].ids.includes(10))array.push(" חולם")
          if(params.includeExcludeLNTs[1].ids.includes(11))array.push(" חולם חסר")
          if(params.includeExcludeLNTs[1].ids.includes(12))array.push(" קבוץ")
          if(params.includeExcludeLNTs[1].ids.includes(13))array.push(" שורוק")
          if(params.includeExcludeLNTs[1].ids.includes(14))array.push(" דגש")
          if(params.includeExcludeLNTs[1].ids.includes(15))array.push(" מפיק")
          if(params.includeExcludeLNTs[1].ids.includes(16))array.push(" שין ימיני")
          if(params.includeExcludeLNTs[1].ids.includes(17))array.push(" שין שמאלי")
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?array.toString():""}><Translate id="nekudosToInclude"/>: {array.toString()}</div>
          })
        }

        var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
        var taamBody = await taamResponse.json()

        if(params.includeExcludeLNTs[2].ids.length>0){
          var taamArray=[];
          for(let j=0; j<params.includeExcludeLNTs[2].ids.length; j++){
            for(let i=0; i<taamBody.length; i++){
              if(taamBody[i].id===params.includeExcludeLNTs[2].ids[j]){
                taamArray.push(" "+taamBody[i].name)
              }
            }
          }
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?taamArray.toString():""}><Translate id="taamimToInclude"/>: {taamArray.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[3].ids.length>0){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.includeExcludeLNTs[3].ids.toString():""}><Translate id="lettersToExclude"/>: {params.includeExcludeLNTs[3].ids.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[4].ids.length>0){
          var array=[];
            if(params.includeExcludeLNTs[4].ids.includes(1))array.push(" שוא")
            if(params.includeExcludeLNTs[4].ids.includes(2))array.push(" חטף סגול")
            if(params.includeExcludeLNTs[4].ids.includes(3))array.push(" חטף פתח")
            if(params.includeExcludeLNTs[4].ids.includes(4))array.push(" חטף קמץ")
            if(params.includeExcludeLNTs[4].ids.includes(5))array.push(" חיריק")
            if(params.includeExcludeLNTs[4].ids.includes(6))array.push(" צירי")
            if(params.includeExcludeLNTs[4].ids.includes(7))array.push(" סגול")
            if(params.includeExcludeLNTs[4].ids.includes(8))array.push(" פתח")
            if(params.includeExcludeLNTs[4].ids.includes(9))array.push(" קמץ")
            if(params.includeExcludeLNTs[4].ids.includes(10))array.push(" חולם")
            if(params.includeExcludeLNTs[4].ids.includes(11))array.push(" חולם חסר")
            if(params.includeExcludeLNTs[4].ids.includes(12))array.push(" קבוץ")
            if(params.includeExcludeLNTs[4].ids.includes(13))array.push(" שורוק")
            if(params.includeExcludeLNTs[4].ids.includes(14))array.push(" דגש")
            if(params.includeExcludeLNTs[4].ids.includes(15))array.push(" מפיק")
            if(params.includeExcludeLNTs[4].ids.includes(16))array.push(" שין ימיני")
            if(params.includeExcludeLNTs[4].ids.includes(17))array.push(" שין שמאלי")
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?array.toString():""}><Translate id="nekudosToExclude"/>: {array.toString()}</div>
          })
        }
        if(params.includeExcludeLNTs[5].ids.length>0){
          var taamArray2=[];
          for(let j=0; j<params.includeExcludeLNTs[5].ids.length; j++){
            for(let i=0; i<taamBody.length; i++){
              if(taamBody[i].id===params.includeExcludeLNTs[5].ids[j]){
                taamArray2.push(" "+taamBody[i].name)
              }
            }
          }
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?taamArray2.toString():""}><Translate id="taamimToExclude"/>: {taamArray2.toString()}</div>
          })
        }
        if(params.wordsPerPasuk.min!==2 || params.wordsPerPasuk.max!==64){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.wordsPerPasuk.min+"-"+(params.wordsPerPasuk.max===64?'∞':params.wordsPerPasuk.max):""}><Translate id="wordsPerPasuk"/>: {params.wordsPerPasuk.min}-{params.wordsPerPasuk.max===64?'∞':params.wordsPerPasuk.max}</div>
          })
        }
        if(params.lettersPerPasuk.min!==6 || params.lettersPerPasuk.max!==254){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.lettersPerPasuk.min+"-"+(params.lettersPerPasuk.max===254?'∞':params.lettersPerPasuk.max):""}><Translate id="lettersPerPasuk"/>: {params.lettersPerPasuk.min}-{params.lettersPerPasuk.max===254?'∞':params.lettersPerPasuk.max}</div>
          })
        }
        if(params.lettersPerWord.min!==2.5 || params.lettersPerWord.max!==8){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.lettersPerWord.min+"-"+(params.lettersPerWord.max===8?'∞':params.lettersPerWord.max):""}><Translate id="letters"/> <Translate id="perWord"/>: {params.lettersPerWord.min}-{params.lettersPerWord.max===8?'∞':params.lettersPerWord.max}</div>
          })
        }
        if(params.uniqueLetter.min!==5 || params.uniqueLetter.max!==22){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.uniqueLetter.min+"-"+(params.uniqueLetter.max===22?'∞':params.uniqueLetter.max):""}><Translate id="byUniqueLetters"/>: {params.uniqueLetter.min}-{params.uniqueLetter.max===22?'∞':params.uniqueLetter.max}</div>
          })
        }
        if(params.uniqueLetterCS.min!==5 || params.uniqueLetterCS.max!==27){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.uniqueLetterCS.min+"-"+(params.uniqueLetterCS.max===27?'∞':params.uniqueLetterCS.max):""}><Translate id="byUniqueLettersCS"/>: {params.uniqueLetterCS.min}-{params.uniqueLetterCS.max===27?'∞':params.uniqueLetterCS.max}</div>
          })
        }
        if(params.tenuosPerPasuk.min!==4 || params.tenuosPerPasuk.max!==96){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.tenuosPerPasuk.min+"-"+(params.tenuosPerPasuk.max===96?'∞':params.tenuosPerPasuk.max):""}><Translate id="tenuosPerPasuk"/>: {params.tenuosPerPasuk.min}-{params.tenuosPerPasuk.max===96?'∞':params.tenuosPerPasuk.max}</div>
          })
        }
        if(params.tenuosPerWord.min!==1.25 || params.tenuosPerWord.max!==4.5){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.tenuosPerWord.min+"-"+(params.tenuosPerWord.max===4.5?'∞':params.tenuosPerWord.max):""}><Translate id="tenuos"/> <Translate id="perWord"/>: {params.tenuosPerWord.min}-{params.tenuosPerWord.max===4.5?'∞':params.tenuosPerWord.max}</div>
          })
        }
        if(params.tenuosPerLetter.min!==.31 || params.tenuosPerLetter.max!==.75){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.tenuosPerLetter.min+"-"+(params.tenuosPerLetter.max===.75?'∞':params.tenuosPerLetter.max):""}><Translate id="tenuos"/> <Translate id="perLetter"/>: {params.tenuosPerLetter.min}-{params.tenuosPerLetter.max===.75?'∞':params.tenuosPerLetter.max}</div>
          })
        }
        if(params.taamimPerPasuk.min!==2 || params.taamimPerPasuk.max!==35){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.taamimPerPasuk.min+"-"+(params.taamimPerPasuk.max===35?'∞':params.taamimPerPasuk.max):""}><Translate id="taamimPerPasuk"/>: {params.taamimPerPasuk.min}-{params.taamimPerPasuk.max===35?'∞':params.taamimPerPasuk.max}</div>
          })
        }
        if(params.taamimPerWord.min!==.43 || params.taamimPerWord.max!==1.5){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.taamimPerWord.min+"-"+(params.taamimPerWord.max===1.5?'∞':params.taamimPerWord.max):""}><Translate id="taamim"/> <Translate id="perWord"/>: {params.taamimPerWord.min}-{params.taamimPerWord.max===1.5?'∞':params.taamimPerWord.max}</div>
          })
        }
        if(params.taamimPerTenua.min!==.22 || params.taamimPerTenua.max!==.82){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.taamimPerTenua.min+"-"+(params.taamimPerTenua.max===.82?'∞':params.taamimPerTenua.max):""}><Translate id="taamim"/> <Translate id="perTenua"/>: {params.taamimPerTenua.min}-{params.taamimPerTenua.max===.82?'∞':params.taamimPerTenua.max}</div>
          })
        }
        if(params.gematriaPerPasuk.min!==160 || params.gematriaPerPasuk.max!==18377){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.gematriaPerPasuk.min+"-"+(params.gematriaPerPasuk.max===18377?'∞':params.gematriaPerPasuk.max):""}><Translate id="gematria"/>: {params.gematriaPerPasuk.min}-{params.gematriaPerPasuk.max===18377?'∞':params.gematriaPerPasuk.max}</div>
          })
        }
        if(params.gematriaPerWord.min!==48.8 || params.gematriaPerWord.max!==731.3){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.gematriaPerWord.min+"-"+(params.gematriaPerWord.max===731.3?'∞':params.gematriaPerWord.max):""}><Translate id="gematria"/> <Translate id="perWord"/>: {params.gematriaPerWord.min}-{params.gematriaPerWord.max===731.3?'∞':params.gematriaPerWord.max}</div>
          })
        }
        if(params.gematriaPerLetter.min!==13.8 || params.gematriaPerLetter.max!==164.8){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.gematriaPerLetter.min+"-"+(params.gematriaPerLetter.max===164.8?'∞':params.gematriaPerLetter.max):""}><Translate id="gematria"/> <Translate id="perLetter"/>: {params.gematriaPerLetter.min}-{params.gematriaPerLetter.max===164.8?'∞':params.gematriaPerLetter.max}</div>
          })
        }
        if(params.atbashPerPasuk.min!==268 || params.atbashPerPasuk.max!==19145){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.atbashPerPasuk.min+"-"+(params.atbashPerPasuk.max===19145?'∞':params.atbashPerPasuk.max):""}><Translate id="atbash"/>: {params.atbashPerPasuk.min}-{params.atbashPerPasuk.max===19145?'∞':params.atbashPerPasuk.max}</div>
          })
        }
        if(params.atbashPerWord.min!==69.5 || params.atbashPerWord.max!==791){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.atbashPerWord.min+"-"+(params.atbashPerWord.max===791?'∞':params.atbashPerWord.max):""}><Translate id="atbash"/> <Translate id="perWord"/>: {params.atbashPerWord.min}-{params.atbashPerWord.max===791?'∞':params.atbashPerWord.max}</div>
          })
        }
        if(params.atbashPerLetter.min!==20.2 || params.atbashPerLetter.max!==173.8){
          data.push({
            id:data.length+1+"SC",
            name:<div title={inLeftBar?params.atbashPerLetter.min+"-"+(params.atbashPerLetter.max===173.8?'∞':params.atbashPerLetter.max):""}><Translate id="atbash"/> <Translate id="perLetter"/>: {params.atbashPerLetter.min}-{params.atbashPerLetter.max===173.8?'∞':params.atbashPerLetter.max}</div>
          })
        }
        if(data.length>0){
          return data;
        }

        return null;
        
      }
      seforimToIncludeExclude(filterData, filterDataArrayInc, filterDataArrayExc,inLeftBar){
        var seforimInclude=(filterDataArrayInc != undefined)?filterDataArrayInc:[];//array of seforim to include
        var seforimExclude=(filterDataArrayExc != undefined)?filterDataArrayExc:[];
        filterData.forEach((data)=>{
          if(data.children){
            this.seforimToIncludeExclude(data.children, seforimInclude, seforimExclude,inLeftBar);
          }else{
            if(data.isChecked){
              seforimInclude.push(" "+data.name);
            } else{
              seforimExclude.push(" "+data.name);
            }
          } 
        })
        if(seforimExclude.length!==0) {
          if(seforimInclude.length>seforimExclude.length){
            return {
              id:7+"SC",
              name:<div title={inLeftBar?seforimExclude.toString():""}><Translate id="seforimToExclude"/>: {seforimExclude.toString()}</div>
            }
          }
          return {
            id:7+"SC",
            name:<div title={inLeftBar?seforimInclude.toString():""}><Translate id="seforimToInclude"/>: {seforimInclude.toString()}</div>
          }
        }
      }

     async setUpData(searchParameters,placementRowData,filterData, translate, inLeftBar){
        var sortOrder = (searchParameters.sortOrder===0?translate("byAppearance"):
        searchParameters.sortOrder===1?translate("byTimeOccurred"):
        searchParameters.sortOrder===2?translate("words"):
        searchParameters.sortOrder===3?translate("letters"):
        searchParameters.sortOrder===4?translate("lettersPerWord"):
        searchParameters.sortOrder===5?translate("byUniqueLetters"):
        searchParameters.sortOrder===6?translate("byUniqueLettersCS"):
        searchParameters.sortOrder===7?translate("nekudos"):
        searchParameters.sortOrder===8?translate("nekudos")+" "+translate("perWord"):
        searchParameters.sortOrder===9?translate("nekudos")+" "+translate("perLetter"):
        searchParameters.sortOrder===10?translate("taamim"):
        searchParameters.sortOrder===11?translate("taamim")+" "+translate("perWord"):
        searchParameters.sortOrder===12?translate("taamim")+" "+translate("perTenua"):
        searchParameters.sortOrder===13?translate("gematria"):
        searchParameters.sortOrder===14?translate("gematria")+" "+translate("perWord"):
        searchParameters.sortOrder===15?translate("gematria")+" "+translate("perLetter"):
        searchParameters.sortOrder===16?translate("atbash"):
        searchParameters.sortOrder===17?translate("atbash")+" "+translate("perWord"):
        searchParameters.sortOrder===18?translate("atbash")+" "+translate("perLetter"):"").concat(searchParameters.sortDirection===1?" ("+(translate("descend"))+")":" ("+(translate("ascend"))+")")
        
        var searchOn= (searchParameters.morphInflections==="L"?translate("letters"):
                      searchParameters.morphInflections==="N"?translate("nekudos"):
                      searchParameters.morphInflections==="T"?translate("taamim"):
                      searchParameters.morphInflections==="LN"?translate("LN"):
                      searchParameters.morphInflections==="LT"?translate("LT"):
                      searchParameters.morphInflections==="NT"?translate("NT"):
                      searchParameters.morphInflections==="LNT"?translate("LNT"):
                      searchParameters.morphInflections==="S"?translate("root"):
                      searchParameters.morphInflections==="G"?translate("gematria"):
                      searchParameters.morphInflections==="A"?translate("atbash"):"")
        var sourceText= (searchParameters.textSource===1?translate("tanach"):translate("targum"))

        var exactnessLevel=(searchParameters.exactnessLevel===0?translate("preciseSearch.caption"):
                          searchParameters.exactnessLevel===1?translate("somewhatExact"):
                          searchParameters.exactnessLevel===2?translate("somewhatFuzzy"):
                          searchParameters.exactnessLevel===3?translate("fuzzySearch.caption"):"")
        var sequence = (searchParameters.isInSequence?translate("yes"):translate("no"))
        var scope = (searchParameters.scope==='any'?translate("no"):translate("yes"))
        var fakeData = [
          {
            id: 1+"SC",
            name:<div title={inLeftBar?translate("searchPerWord"):""}><b><Translate id="searchPerWord"/></b></div>,
            isExpandable:true,
            isExpanded: true,
            children: await this.setUpPerWord(searchParameters.bituiParameters,searchParameters.exactnessLevel,translate,inLeftBar),
          },
          { id:2+"SC",
            name:<div title={inLeftBar?translate("basicSearchSettings"):""}><b><Translate id="basicSearchSettings"/></b></div>,
            isExpandable:true,
            isExpanded:true,
            children: [ {
                id:1+"SC",
                name: <div title={inLeftBar?sourceText:""}><Translate id="source"/>: {sourceText}</div>
              },{
                id:2+"SC",
                name: <div title={inLeftBar?searchOn:""}><Translate id="searchOn"/> {searchOn}</div>
              },{
                id:3+"SC",
                name: <div title={inLeftBar?exactnessLevel:""}><Translate id="exactness"/>&nbsp;{exactnessLevel}</div>
              },{
                id:4+"SC",
                name: <div title={inLeftBar?sequence:""}><Translate id="inOrder.caption"/>? {sequence}</div>
              },{
                id:5+"SC",
                name: <div title={inLeftBar?scope:""}><Translate id="everyWord.caption"/>? {scope}</div>
              },{
                id:6+"SC",
                name: <div title={inLeftBar?sortOrder:""}><Translate id="sortBy"/>: {sortOrder}</div>
              }                
            ]}
        ]
        var IncludeExclude = this.seforimToIncludeExclude(filterData,null,null,inLeftBar);
        if(IncludeExclude){
          fakeData[1].children.push(IncludeExclude)
        }
        var generalDistancesObject={
          id:fakeData.length+1+"SC",
          name:<div title={inLeftBar?translate("distances"):""}><b><Translate id="distances"/></b></div>,
          isExpanded:true,
          isExpandable:true,
          children:this.setUpGeneralDistances(searchParameters,translate,inLeftBar),
        }
        if(generalDistancesObject.children){
          fakeData.push(generalDistancesObject)
        }

        var pasukSettingsObject={
          id:fakeData.length+1+"SC",
          name: <div title={inLeftBar?translate("advTabPasukSettings"):""}><b><Translate id="advTabPasukSettings"/></b></div>,
          isExpandable:true,
          isExpanded:true,
          children:await this.setUpPasukSettings(searchParameters,inLeftBar),
        }
        if(pasukSettingsObject.children){
          fakeData.push(pasukSettingsObject)
        }
        var placementObject={
          id:fakeData.length+1+"SC",
          name:<div title={inLeftBar?translate("advTabPlacement"):""}><b><Translate id="advTabPlacement"/></b></div>,
          isExpanded:true,
          isExpandable:true,
          children:this.setUpPlacement(placementRowData,translate,inLeftBar),
        }
        if(placementObject.children){
          fakeData.push(placementObject)
        }

        return fakeData;
      }




    
    render() {
        const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";
        const fullScreen = this.props.translate("fullScreen");
        const restore = this.props.translate("restore");
        const close = this.props.translate('close');
    
        var directionStyling={direciton:'ltr'};
        if(isHebrew){
            directionStyling = {direction: 'rtl'};
        }

        return(
            <div >
            {this.state.isChildrenLoading?<div>Loading...</div>:this.state.openPopover?
                <div>
                    <Rnd 
                          size={{ width: `${this.props.widthNum}px`,  height: `${this.props.heightNum}px` }}
                          position={{ x: this.props.x, y: this.props.y }}
                          onDragStop={(e, d) => { 
                            this.props.setPosition(d.x, d.y)
                          }}
                          onClick={(e) => {this.handleClick()}}
                          onResize={(e, direction, ref, delta, position) => {
                            this.props.setSize(
                              parseInt(ref.style.width.slice(0, -1)),
                              parseInt(ref.style.height.slice(0, -1)),
                              position.x,
                              position.y
                            )
                          }}
                    
                          style = {{
                            background: 'black !important',
                            visibility: this.state.openPopover ? 'visible' : 'hidden',
                            boxShadow: '0 3px 6px rgba(0,0,0,0.48), 0 3px 6px rgba(0,0,0,0.69)',
                            borderRadius: '5px',
                            zIndex: this.state.zIndex,
                            direction: directionStyling.direction,
                            textAlign: directionStyling.textAlign
                          }}
                          className="help-rnd"
                          cancel = '.help-dialog-content-root, .help-dialog-close-button, .mdl-demo, .help-dialog-resize-buttons'
                          minWidth={375}
                          minHeight={220}
                          bounds='#root'>        
                        <div className="help-dialog-resize-buttons"
                            style={{
                                position: 'absolute',
                                right: isHebrew ? 'unset' : 0,
                                left: isHebrew ? 0 : 'unset',
                                direction: directionStyling.direciton,
                                top: 0,
                                borderBottomLeftRadius: isHebrew ? 0 : '3px',
                                borderBottomRightRadius: isHebrew ? '3px' : 0,
                            }}
                            >
                            <Button size="small" onClick={() => {this.onClose()}} color="primary" className="help-dialog-resize-button" title={close}> 
                                <CloseIcon />
                            </Button>
                            <Button className="help-dialog-resize-button" onClick={() => {this.handleFullscreen()}} title={fullScreen}>
                                <FullscreenIcon />
                            </Button>
                            <Button className="help-dialog-resize-button" onClick={() => {this.handleRestoreScreenSize()}} title={restore}> 
                                <FullscreenExitIcon />
                            </Button>
                        </div>

                        <div id="help-dialog-title"
        onDoubleClick={(e) => {this.handleTitleDoubleClick(e)}}
        classes={{root: "help-dialog-title"}}
      >
        <div style={{position: 'relative', margin: '25px'}}>
        </div>

      </div>
      <DialogContent classes={{
        root: 'help-dialog-content-root'
      }}>

          <div className={`${isHebrew?"superTreeViewCSHeb":"superTreeViewCS"} scrollableDiv popover-search-criteria`}
             style={{ textAlign:isHebrew?"right":"left", direction:directionStyling.direciton, maxHeight: `${this.props.heightNum-88}px`}} >
                    <div style={{textAlign:"center",fontSize:"18px", fontWeight:"bold",}}>{this.props.translate("searchCriteriaTitle")}:</div>
                    {//this.props.superTreeData? 
                    <SuperTreeview 
                        data={ this.state.data}
                        isDeletable={() => { return false; }}
                        isCheckable={() => { return false; }}
                        onUpdateCb={(updatedData) => {
                          this.resetSuperTreeData(updatedData)
                        }}
                        isExpandable={(node)=> { return node.isExpandable; }}
                    />//:<div></div>}
                      }</div>
        </DialogContent>

                    </Rnd>
                </div>:
                  <div style={{width:"168px",maxWidth:"99%"}}>
                    <div className={`${isHebrew?"superTreeViewCSHeb":"superTreeViewCS"} scrollableDiv leftBar-search-criteria`} style={{textAlign:isHebrew?"right":"left", direction:directionStyling.direciton}}>
                      <div style={{textAlign:"center",fontSize:"16px", fontWeight:"bold",}}>{this.props.translate("searchCriteriaTitle")}:</div>
                      
                      {//this.props.superTreeData? 
                      <div><SuperTreeview 
                          data={ this.state.data}
                          isDeletable={() => { return false; }}
                          isCheckable={() => { return false; }}
                          onUpdateCb={(updatedData) => {
                            this.resetSuperTreeData(updatedData)
                          }}
                          isExpandable={(node)=> { return node.isExpandable; }}
                      />
                      </div>
                      //:<div></div>
                      }
                    </div>
                    <div style={{textAlign:"center", backgroundColor:"white",color:"black",paddingTop:"3px"}}><OpenInNewIcon onClick={()=>this.setState({openPopover:true},()=>{this.props.inLeftBar(false)})}></OpenInNewIcon></div> 
                  </div>
            }
            </div>
        )
    }
}
