import React, { Component, useState } from 'react';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { UncontrolledCollapse } from 'reactstrap';
import { AgGridReact, ClientSideRowModelModule } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import { css } from '@emotion/core';
import { FadeLoader } from 'react-spinners';
import LoadingOverlay from 'react-loading-overlay';
import { Button, Popover, Typography, Paper } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { contextMenu } from 'react-contexify';
import ContextMenu from './ContextMenu';
import TaamParser from './TaamParser';
import PasukStatsTooltip from './PasukStatsTooltip';
import * as properties from './config/properties.js';
import { Select, MenuItem } from '@material-ui/core';
import StatPicker2D from './StatPicker2D';
import zIndex from '@material-ui/core/styles/zIndex';
import WordStatsTooltip from './WordStatsTooltip';
// import ReactTooltip from "react-tooltip";
// import "semantic-ui-css/semantic.min.css";
import FullWidthCellRenderer from './FullWidthCellRenderer';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DialogActions} from '@material-ui/core';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
    typography: {
      padding: theme.spacing.unit * 0,
    },
    toggleContainer: {
      maxHeight: '22px',
    //   padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
      backgroundColor: 'transparent',
      display: 'flex !important',
      flexDirection: 'row' ,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    //   margin: `${theme.spacing.unit}px 0`,
      background: theme.palette.background.default,
    },
  });
  
  
// import {  Popup } from "semantic-ui-react";


const bcrypt = require('bcryptjs');

function PickerModel() {
  this.list = [];
  this.values = [];
}

function getAlternateRowStyle(params) {
    if (params.node.rowIndex % 2 === 0) {
        return {background: '#F0F0F0'};
    }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 0, width: '1400px', maxWidth: 'calc(100vw - 520px)', minWidth: '848px' }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const theme = createMuiTheme({ //can also use 2 toggleButton containers inside a Grid element to accomplish groups on same line as is done here https://material-ui.com/lab/toggle-button/
  overrides: {
    MuiToggleButtonGroup: {
      root: {
          display: 'inline',
      },
      selected: {
          display: 'inline',
      },
    },
    MuiTab: {
      selected: {
        textColor: 'white',
        textColorPrimary: 'white',
        backgroundColor: 'dodgerBlue'
      },
      primary: {
          color: 'white',
          textColor: 'white',
      },
    },
  },
  direction: 'rtl',
  typography: { useNextVariants: true },
});

const textGridPos = 23, targumGridPos = 22;

class SearchResults extends Component {

 constructor(props) {
    super(props);
    this.state = {
      menuValue: 1,
    //   isLoading: true,
      getPesukimWithWords: [],
      columnDefs: this.getGridColumns("he"),
      frameworkComponents: {customTooltip: PasukStatsTooltip,
                            anotherTooltip:WordStatsTooltip,
                            fullWidthCellRenderer: FullWidthCellRenderer },//not using this now but it is here for a later restructuring of the code.
      fullWidthCellRenderer: "fullWidthCellRenderer",
      rowData: [],
      defaultLanguage: '',
      resultsFontSize: 14,
      resultsFontSizeSm: 12,
      resultsRowHeight: 25,
      pasukColumnWidth: 140,
      saveResultsPopoverAnchorEl: null,
      saveResultsPopoverOpen: false,
      searchResultsBlob: new Blob([''], {type: "text/plain;charset=utf-8"}),
      tabValue: this.props.tabValue,
      openResults: this.props.openResults,
      textToCopy: '',
      useKinuim: false,
      includeMekorot: false,
      searchAsQuote: false,
      taamParserOpen: false,
      taamParseData: {},
      taamParserPasukId: null,
      taamParserLocation: null,
      doubleOpeningContextMenu: true,
      pageIsLoading: false,
      resultsPerPage: 10,
      statisticsPicker: {list:this.getPickerList(), values:[]},
      pasukTextPosition: "left",
      hoverSelect: "all",
      hoverWords: "none",
      statsToCopy: "selected above",
      howToPaste: "header row",
      includeStatistics: false,
    //   wordTooltipCoordinates:{x:"100",y:"100"},
      timer:null,
      loadingPage:1,
      openPesukim:[[]],
      contentDisplayed:this.props.sentContentDisplayed?this.props.sentContentDisplayed:[this.props.textSource],
      currentSize:0,
      tooltipOpen:false,
      sizingArray : [{fontSize:14,smFontSize:12,rowHeight:25,pasukColumnWidth:140,rowHeightWithTargum:50,lineHeight:20},{fontSize:16,smFontSize:13,rowHeight:28,pasukColumnWidth:155,rowHeightWithTargum:56,lineHeight:23},
        {fontSize:18,smFontSize:14,rowHeight:31,pasukColumnWidth:170,rowHeightWithTargum:62,lineHeight:27},{fontSize:20,smFontSize:15,rowHeight:34,pasukColumnWidth:185,rowHeightWithTargum:68,lineHeight:29},
        {fontSize:22,smFontSize:16,rowHeight:37,pasukColumnWidth:200,rowHeightWithTargum:111,lineHeight:31},{fontSize:24,smFontSize:17,rowHeight:40,pasukColumnWidth:215,rowHeightWithTargum:120,lineHeight:33}],
      seferArray:["בראשית","שמות","ויקרא","במדבר","דברים","יהושע","שופטים","שמואל א","שמואל ב","מלכים א",
        "מלכים ב","ישעיהו","ירמיהו","יחזקאל","הושע","יואל","עמוס","עובדיה",
        "יונה","מיכה","נחום","חבקוק","צפניה","חגי","זכריה","מלאכי","תהלים","משלי","איוב","שיר השירים","רות","איכה","קהלת","אסתר","דניאל","עזרא","נחמיה","דברי הימים א","דברי הימים ב"],
      tanachArray:["תורה","נביאים","כתובים"],
    };
    this.clicked=false;
    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.increaseFontSize = this.increaseFontSize.bind(this);
    this.decreaseFontSize = this.decreaseFontSize.bind(this);
    this.setResultsFontSize = this.setResultsFontSize.bind(this);
    this.handleOpenSaveResultsPopover = this.handleOpenSaveResultsPopover.bind(this);
    this.handleCloseSaveResultsPopover = this.handleCloseSaveResultsPopover.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.resultsGridReady = this.resultsGridReady.bind(this);
//    this.keepResultsInTab = this.keepResultsInTab.bind(this);
    this.closeResultsTab = this.closeResultsTab.bind(this);
    this.handleLoadSearchResults = this.handleLoadSearchResults.bind(this);
    this.getPasukColumWidth = this.getPasukColumWidth.bind(this);
    this.deleteSelectedResults = this.deleteSelectedResults.bind(this);
    this.handleResultsCellClicked = this.handleResultsCellClicked.bind(this);
    this.handleResultsCellMouseDown = this.handleResultsCellMouseDown.bind(this);
    this.selectAllResults = this.selectAllResults.bind(this);
    this.unselectAllResults = this.unselectAllResults.bind(this);

    this.handleDoubleRightClick = this.handleDoubleRightClick.bind(this);
    this.onCellContextMenu = this.onCellContextMenu.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.openTaamParser = this.openTaamParser.bind(this);
    this.closeTaamParser = this.closeTaamParser.bind(this);

    this.getGridColumns = this.getGridColumns.bind(this);
    this.setPickerValues = this.setPickerValues.bind(this);
    this.applyPickerValues = this.applyPickerValues.bind(this);
    this.shiftPasukTextColumn = this.shiftPasukTextColumn.bind(this);
    this.handleHoverSelect = this.handleHoverSelect.bind(this);
    this.changeHoverWithCheckbox = this.changeHoverWithCheckbox.bind(this);
    this.changeHoverWordsWithCheckbox = this.changeHoverWordsWithCheckbox.bind(this);
    this.setHowToPaste = this.setHowToPaste.bind(this);
    this.setStatsToCopy = this.setStatsToCopy.bind(this);
    this.toggleIncludeStatistics = this.toggleIncludeStatistics.bind(this);
    this.setUpText = this.setUpText.bind(this);
    this.setWordStat = this.setWordStat.bind(this);
    this.leave = this.leave.bind(this);
    this.mouseHoverInAGGrid = this.mouseHoverInAGGrid.bind(this);
    this.buttonCellRenderer = this.buttonCellRenderer.bind(this);
    this.getDisplayRows = this.getDisplayRows.bind(this);
    this.fetchWordsForOpenPesukim = this.fetchWordsForOpenPesukim.bind(this);
    this.isNewBuffer = this.isNewBuffer.bind(this);
    this.closeAllOpenPesukim = this.closeAllOpenPesukim.bind(this);
    this.closeThisPasuk = this.closeThisPasuk.bind(this);
    // this.mouseMove = this.mouseMove.bind(this);
    this.handleToggleContentDisplayed = this.handleToggleContentDisplayed.bind(this);
    this.clickContentDisplayed = this.clickContentDisplayed.bind(this);
    this.getTextColumnWidth = this.getTextColumnWidth.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
    this.calculateTotalAppearances = this.calculateTotalAppearances.bind(this);
    this.searchTanach = this.searchTanach.bind(this);
    this.sortTable = this.sortTable.bind(this);
    this.setTootlipOpenClose = this.setTootlipOpenClose.bind(this);
    this.onBtnExport = this.onBtnExport.bind(this);
}

    getGridColumns(language) {
        var fakeThis=this;
        return [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"
            {id: -3, headerName: "-/+", colId: 'toggleTextButtonColumn',suppressSizeToFit:true, width: 24, cellClass: "row-header-center search-results-button-cell parsha-stats-row", filter: 'agNumberColumnFilter', pinned: 'right', cellRenderer: function (params) {return fakeThis.buttonCellRenderer(params)}},
            {id: -2, headerName: "language", field: "language",suppressSizeToFit:true, tooltipField: "location", tooltipComponent: 'customTooltip',hide: true, cellRenderer: function(params) {return language;} },
            {id: -1, headerName: "#", tooltipField: "location",suppressSizeToFit:true, tooltipComponent: 'customTooltip',autoHeight: true, field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center search-results-row", sortable: "true", filter: 'agNumberColumnFilter', pinned: "right" },
            {id: 0, headerName: "פסוק", field: "location",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: this.getPasukColumWidth(), cellClass: "row-header search-results-row", pinned: "right" },
            {id: 1, headerName: "קריאה/הפטרה לפרשת השבוע", field: "parshaName",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: 110, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin search-results-row", sortable: "true", pinned: "right" }, //, pinned: "right"
            {id: 2, headerName: "סדרת ג' שנים", field: "sidraName",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: 125, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" }, //, pinned: "right"
            {id: 3, headerName: "שאר קריאות והפטרות", field: "otherKriosName",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: 220, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" }, //, pinned: "right"
            {id: 4, headerName: "תיבות", field: "teiva",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 5, headerName: "אותיות", field: "letter",suppressSizeToFit:true,tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 6, headerName: "אותיות לתיבה", suppressSizeToFit:true,field: "lettersPerWord",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.lettersPerWord).toFixed(2);} },
            {id: 7, headerName: "אותיות מיוחדות",suppressSizeToFit:true, field: "uniqueLetter",tooltipField: "location", tooltipComponent: 'customTooltip', width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 8, headerName: "בהבחנת סופיות",suppressSizeToFit:true, field: "uniqueLetterCS",tooltipField: "location", tooltipComponent: 'customTooltip', width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 9, headerName: "הופעת אותיות",suppressSizeToFit:true, field: "letterCoverage",tooltipField: "location", tooltipComponent: 'customTooltip', width: 240, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return util.showLetterCoverage(params.data.text);} },
            {id: 10, headerName: "תנועות",suppressSizeToFit:true, field: "tenua",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 11, headerName: "תנועות לתיבה",suppressSizeToFit:true, field: "tenuosPerWord",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.tenuosPerWord).toFixed(2);} },
            {id: 12, headerName: "תנועות לאות",suppressSizeToFit:true, field: "tenuosPerLetter",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.tenuosPerLetter).toFixed(2);} },
            {id: 13, headerName: "טעמים",suppressSizeToFit:true, field: "taam",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 14, headerName: "טעמים לתיבה", suppressSizeToFit:true, field: "taamimPerWord",tooltipField: "location", tooltipComponent: 'customTooltip', width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.taamimPerWord).toFixed(2);} },
            {id: 15, headerName: "טעמים לתנועה",suppressSizeToFit:true, field: "taamimPerTenua",tooltipField: "location", tooltipComponent: 'customTooltip', width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.taamimPerTenua).toFixed(2);} },
            {id: 16, headerName: "גמטריא",suppressSizeToFit:true, field: "standard",tooltipField: "location", tooltipComponent: 'customTooltip', width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 17, headerName: "גמטריא לתיבה",suppressSizeToFit:true, field: "gematriaPerWord",tooltipField: "location", tooltipComponent: 'customTooltip', width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.gematriaPerWord).toFixed(1);} },
            {id: 18, headerName: "גמטריא לאות",suppressSizeToFit:true, field: "gematriaPerLetter",tooltipField: "location", tooltipComponent: 'customTooltip', width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.gematriaPerLetter).toFixed(1);} },
            {id: 19, headerName: "אתבש",suppressSizeToFit:true, field: "atbash",tooltipField: "location", tooltipComponent: 'customTooltip', width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", sortable: "true" },
            {id: 20, headerName: "אתבש לתיבה",suppressSizeToFit:true, field: "atbashPerWord",tooltipField: "location", tooltipComponent: 'customTooltip', width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.atbashPerWord).toFixed(1);} },
            {id: 21, headerName: "אתבש לאות",suppressSizeToFit:true, field: "atbashPerLetter",tooltipField: "location", tooltipComponent: 'customTooltip', width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", sortable: "true", cellRenderer: function(params) {return (params.data.atbashPerLetter).toFixed(1);} },
            {id: textGridPos, headerName: "טקסט",wrapText:true, field: "text", minWidth:300,  width: this.getTextColumnWidth(), cellClass: "row-thin search-results-row",tooltipField: "location", tooltipComponent: 'anotherTooltip',  cellRenderer: ((params)=> {return this.setUpText(params.data.text, params.data.matchIndex,params.data.pasukId,params)})},
            {id: targumGridPos, headerName: "תרגום",wrapText:true, field: "targumText", minWidth:300, width: this.getTextColumnWidth(), cellClass: "row-thin search-results-row",tooltipField: "location", tooltipComponent: 'customTooltip',hide: true, cellRenderer: ((params)=> {return this.setUpTargum(params.data.targumText, params.data.matchIndex,params.data.pasukId,params)})}
            //this width can even handle אסתר ח:ט which has 235 chars including spaces
        ];

/*
        return [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"
            {id: -2, headerName: "language", field: "language", hide: true, cellRenderer: function(params) {return language;} },
            {id: -1, headerName: "#", autoHeight: true, field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center search-results-row", filter: 'agNumberColumnFilter', pinned: "right" },
            {id: 0, headerName: "פסוק", field: "location", width: this.getPasukColumWidth(), cellClass: "row-header search-results-row", pinned: "right" },
            {id: 1, headerName: "תיבות", field: "teiva", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 2, headerName: "אותיות", field: "letter", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 3, headerName: "אותיות לתיבה", field: "lettersPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.letter/params.data.teiva).toFixed(2);} },
            {id: 4, headerName: "אותיות מיוחדות", field: "uniqueLetter", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 5, headerName: "בהבחנת סופיות", field: "uniqueLetterCS", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 6, headerName: "הופעת אותיות", field: "letterCoverage", width: 240, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return util.showLetterCoverage(params.data.text);} },
            {id: 7, headerName: "תנועות", field: "tenua", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 8, headerName: "תנועות לתיבה", field: "tenuosPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenua/params.data.teiva).toFixed(2);} },
            {id: 9, headerName: "תנועות לאות", field: "tenuosPerLetter", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenua/params.data.letter).toFixed(2);} },
            {id: 10, headerName: "טעמים", field: "taam", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 11, headerName: "טעמים לתיבה", field: "taamimPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taam/params.data.teiva).toFixed(2);} },
            {id: 12, headerName: "טעמים לתנועה", field: "taamimPerTenua", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taam/params.data.tenua).toFixed(2);} },
            {id: 13, headerName: "גמטריא", field: "standard", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 14, headerName: "גמטריא לתיבה", field: "gematriaPerWord", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.standard/params.data.teiva).toFixed(1);} },
            {id: 15, headerName: "גמטריא לאות", field: "gematriaPerLetter", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.standard/params.data.letter).toFixed(1);} },
            {id: 16, headerName: "אתבש", field: "atbash", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 17, headerName: "אתבש לתיבה", field: "atbashPerWord", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbash/params.data.teiva).toFixed(1);} },
            {id: 18, headerName: "אתבש לאות", field: "atbashPerLetter", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbash/params.data.letter).toFixed(1);} },
            {id: 19, headerName: "טקסט", field: "text", width: 1500, cellClass: "row-thin search-results-row", cellRenderer: function(params) {return util.decorateText(params.data.text, params.data.matchIndex);}}
            //this width can even handle אסתר ח:ט which has 235 chars including spaces
        ];
*/
    }
    clickContentDisplayed(event, contentTypes){
        if((!this.state.contentDisplayed.includes('targum')) && contentTypes.includes('targum'))this.props.extSetBeta()//if targum was not already there
        this.handleToggleContentDisplayed(event, contentTypes);
    }
    handleToggleContentDisplayed (event, contentTypes) {
        if(contentTypes.length > 0){
            // if((!this.state.contentDisplayed.includes('targum')) && contentTypes.includes('targum'))this.props.extSetBeta()//if targum was not already there
            this.setState({contentDisplayed: contentTypes}, () => {
                var newVals = this.state.statisticsPicker.values;
                if(contentTypes.includes('targum')){
                    if(!newVals.includes(targumGridPos)){
                        newVals.push(targumGridPos)
                    }
                } else{
                    const index = newVals.indexOf(targumGridPos);
                    if (index > -1) {
                        newVals.splice(index, 1);
                    }
                }
                if(contentTypes.includes('tanach')){
                    if(!newVals.includes(textGridPos)){
                        newVals.push(textGridPos)
                    }
                } else{
                    const index = newVals.indexOf(textGridPos);
                    if (index > -1) {
                        newVals.splice(index, 1);
                    }
                }//in here I should set width of columns to be smaller or larger based on if targum is selected.
                //then column height should change to 2 or 3 times the size if the width is smaller than 1500
                if(contentTypes.length===2){
                    this.handleHoverSelect('none')
                    this.setState({resultsRowHeight:this.state.sizingArray[this.state.currentSize].rowHeightWithTargum, withTargum:true})
                    //Also, the height of the full width row is affected here. Fix it.
                    this.resultsColumnApi.setColumnWidths([{key:'targumText',newWidth:((1310-this.state.sizingArray[this.state.currentSize].pasukColumnWidth)/2)},{key:'text',newWidth:((1310-this.state.sizingArray[this.state.currentSize].pasukColumnWidth)/2)}])
                }else if(contentTypes.includes('targum')){
                    this.leave(true)
                    this.setState({resultsRowHeight:this.state.sizingArray[this.state.currentSize].rowHeight, withTargum:true})
                    this.resultsColumnApi.setColumnWidths([{key:'targumText',newWidth:1500}])
                }else if(contentTypes.includes('tanach')){
                    this.leave(true)
                    this.setState({resultsRowHeight:this.state.sizingArray[this.state.currentSize].rowHeight, withTargum:false})
                    this.resultsColumnApi.setColumnWidths([{key:'text',newWidth:1500}])
                }
                this.setPickerValues(newVals);
                this.applyPickerValues();
            });
        }
        
      }
      

    buttonCellRenderer = (params) => {
        var cellContents = document.createElement('div');
        if(!params.data.textIsOpen){
            cellContents.innerHTML = '+';
        } else if (params.data.textIsOpen){
            cellContents.innerHTML = '-';
        }
        cellContents.className = 'search-results-button-cell-text';
        cellContents.style.width = 16;
        return cellContents;
    }

    setUpTargumArray(params){
        if(params.data.targumMap ){
            var torahMap = params.data.torahMap;
            var torahMapArr = torahMap.split(";")
            var torahWordMapObj = {};
            for(let i = 0; i < torahMapArr.length; i++){
              var torahMapWordArr = torahMapArr[i].split(",");
              if(!torahWordMapObj[torahMapWordArr[0]]) {
                torahWordMapObj[torahMapWordArr[0]] = [torahMapWordArr[1]];
              } else {
                torahWordMapObj[torahMapWordArr[0]].push(torahMapWordArr[1]);
              }
            }
            var targumMap = params.data.targumMap;
            var targumMapArr = targumMap.split(";")
            var targumWordMapObj = {};
            for(let i = 0; i < targumMapArr.length; i++){
              var targumMapWordArr = targumMapArr[i].split(",");
              if(!targumWordMapObj[targumMapWordArr[0]]) {
                targumWordMapObj[targumMapWordArr[0]] = [targumMapWordArr[1]];
              } else {
                targumWordMapObj[targumMapWordArr[0]].push(targumMapWordArr[1]);
              }
            }
      
            var torahPhraseMapObj = Object.assign({}, torahWordMapObj);
            var targumPhraseMapObj = Object.assign({}, targumWordMapObj);
            var torahPhraseMapObjKeys = Object.keys(torahPhraseMapObj);
            var targumPhraseMapObjKeys = Object.keys(targumPhraseMapObj);
      
            for(let i = 0; i < torahPhraseMapObjKeys.length; i++){
              var currKey = torahPhraseMapObjKeys[i];
              var matchIds = torahPhraseMapObj[currKey];
              if(matchIds.length > 1){
                var targumIndices = [];
                for(let j = 0; j < matchIds.length; j++){
                  var matchId = matchIds[j]
                  var targumIndex = Object.keys(targumPhraseMapObj).find(key => targumPhraseMapObj[key].includes(matchId));
                  targumIndices.push(targumIndex)
                }
                for(let k = 0; k < targumIndices.length; k++){
                  var currTargumIndex = targumIndices[k]
                  targumPhraseMapObj[currTargumIndex] = targumPhraseMapObj[currTargumIndex].concat(matchIds.filter((item) => targumPhraseMapObj[currTargumIndex].indexOf(item) < 0))
                }
              }
            }
            for(let i = 0; i < targumPhraseMapObjKeys.length; i++){
              var currKey = targumPhraseMapObjKeys[i];
              var matchIds = targumPhraseMapObj[currKey];
              if(matchIds.length > 1){
                var torahIndices = [];
                for(let j = 0; j < matchIds.length; j++){
                  var matchId = matchIds[j]
                  var torahIndex = Object.keys(torahPhraseMapObj).find(key => torahPhraseMapObj[key].includes(matchId));
                  torahIndices.push(torahIndex)
                }
                for(let k = 0; k < torahIndices.length; k++){
                  var currTorahIndex = torahIndices[k]
                  torahPhraseMapObj[currTorahIndex] = torahPhraseMapObj[currTorahIndex].concat(matchIds.filter((item) => torahPhraseMapObj[currTorahIndex].indexOf(item) < 0))
                }
              }
            }
      
            params.data.torahWordMapObj = torahWordMapObj;
            params.data.targumWordMapObj = targumWordMapObj;
            params.data.torahPhraseMapObj = torahPhraseMapObj;
            params.data.targumPhraseMapObj = targumPhraseMapObj;
          }
    
    }


    setUpText(text, matchIndex,pasukId,params){//"10,1;16,2" numberInPasuk,termNumber;
        if(this.props.openResults[this.state.tabValue]){
            this.setUpTargumArray(params)
            var fakeThis = this;
            window.setWordStat=this.setWordStat;
            window.leave=this.leave;
            // window.mouseMove = this.mouseMove;
            var words=text.split(" ");
            var torahhMapObj = params.data.torahPhraseMapObj;
            for(var j=1; j<=words.length; j++){
                var matchIds = [];
                if(torahhMapObj){
                matchIds = torahhMapObj[j];
                }
                if(!matchIds){
                matchIds = [];
                }
                var isHoveredMatch = false;
                matchIds.map((matchId, key) => {
                if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(parseInt(matchId))){
                    isHoveredMatch = true;
                }
                })
                if(/*j===params.data.highlight*/isHoveredMatch && fakeThis.state.contentDisplayed.length===2){
                    words[j-1]="<span class='wordHover' style='background-color:deepskyblue' onmouseout='leave()' onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],true)'>" + words[j-1] + " </span>";
                }else if(fakeThis.state.hoveredMatchIds && params.data.highlight>0 && fakeThis.state.contentDisplayed.length===2){
                    words[j-1]= "<span class='wordHover' style='background-color:powderblue' onmouseout='leave()' onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],true)'>" + words[j-1] + " </span>";
                }else{
                    words[j-1]= "<span class='wordHover' onmouseout='leave()' onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],true)'>" + words[j-1] + " </span>";
                }
            }
            var textField=""
            // if(params.data.highlight>0)
            //     textField = words.join("<span style='background-color:powderblue'></span>")
            // else
                textField = words.join("")
            textField = util.decorateText(text,words,matchIndex,params.data.targumPhraseMapObj, params.data.torahPhraseMapObj, null,this.props.textSourceSearched[this.props.tabValue]==='tanach',true,this.props.textSourceSearched[this.props.tabValue]==='targum',false);
            return "<span style='word-break:keep-all; line-height:"+this.state.sizingArray[this.state.currentSize].lineHeight+"px' class='fullLineOfText'>"+textField+"</span>";
        }
    }
    mouseDown(){
        this.clicked=true;
    }
    mouseUp(){
        this.clicked=false;
    }
    setUpTargum(text, matchIndex,pasukId,params){//"10,1;16,2" numberInPasuk,termNumber;   //use the targum array to set up the table
       if(this.props.openResults[this.state.tabValue]){
            this.setUpTargumArray(params)
            var fakeThis=this;
            window.setWordStat=this.setWordStat;
            window.leave=this.leave;
            window.mouseDown = this.mouseDown;
            window.mouseUp = this.mouseUp;
            if(!text) text=""
            var words=text.split(" ");
            var targumMapObj = params.data.targumPhraseMapObj;
            for(var j=1; j<=words.length; j++){
                var matchIds = [];
                if(targumMapObj){
                matchIds = targumMapObj[j];
                }
                if(!matchIds){
                matchIds = [];
                }
                var isHoveredMatch = false;
                matchIds.map((matchId, key) => {
                if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(parseInt(matchId))){
                    isHoveredMatch = true;
                }
                })
                if(/*j===params.data.highlight*/isHoveredMatch && fakeThis.state.contentDisplayed.length===2){
                    words[j-1]="<span matchIds='"+matchIds+"' class='wordHover' style='background-color:deepskyblue' onmouseout='leave()' onmouseup='mouseUp()' onmousedown='mouseDown()'onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],false)'>" + words[j-1] + " </span>";
                }else if(fakeThis.state.hoveredMatchIds && params.data.highlight>0 && fakeThis.state.contentDisplayed.length===2){
                    words[j-1]= "<span class='wordHover' style='background-color:powderblue' onmouseout='leave()' onmousedown='mouseDown()' onmouseup='mouseUp()' onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],false)'>" + words[j-1] + " </span>";
                }else{
                    words[j-1]= "<span class='wordHover' onmouseout='leave()'onmousedown='mouseDown()' onmouseup='mouseUp()' onmouseenter='setWordStat(event,"+j+","+pasukId+",["+matchIds+"],false)'>" + words[j-1] + " </span>";
                }
            }
            // var textField = ""
            // if(params.data.highlight>0)
            //     textField = words.join("<span style='background-color:powderblue'> </span>")
            // else
            //     textField = words.join(" ")
            var textField = util.decorateText(text,words,matchIndex,targumMapObj,params.data.torahPhraseMapObj, null,this.props.textSourceSearched[this.props.tabValue]==='tanach',false,this.props.textSourceSearched[this.props.tabValue]==='targum',true);
            return "<span style='word-break:keep-all; line-height:"+this.state.sizingArray[this.state.currentSize].lineHeight+"px' class='fullLineOfText'>"+textField+"</span>";

            // return "<span style='word-break:keep-all; line-height:"+this.state.sizingArray[this.state.currentSize].lineHeight+"px' class='fullLineOfText'>"+textField+"</span>";
        }
    }
    // mouseMove(e){
    //     this.props.setCoordinatesWordTooltip(e);
    // }
    leave(forceLeave){
        var rowData=this.props.openResults[this.state.tabValue].rowData
        if(rowData){
            var rowNode;
            properties.setWordStat(null);
            this.setState({timer:clearTimeout(this.state.timer)});
            var rowNumber = rowData.findIndex(e=>e.highlight > 0)
            rowNumber = typeof(this.state.resultsPerPage)=='string'? rowNumber : rowNumber % this.state.resultsPerPage
            if(rowNumber>=0 && this.resultsApi.gridOptionsWrapper && this.resultsApi.gridOptionsWrapper.gridOptions.api){
                rowNode = this.resultsApi.gridOptionsWrapper.gridOptions.api.getDisplayedRowAtIndex(rowNumber);
                rowNode.data.highlight=0

                var params = {
                    force: true,
                    suppressFlash: true,            
                    rowNodes: [rowNode],
                    columns: ['text','targumText'],
                }
                this.setState({hoveredMatchIds:null},()=>{
                    if(this.state.contentDisplayed.length===2 && !this.clicked && !window.getSelection().toString()){//.includes('targum')){
                        this.resultsApi.gridOptionsWrapper.gridOptions.api.refreshCells(params);
                    }
                })
                console.log("left")
            }
            if (forceLeave && this.resultsApi.gridOptionsWrapper.gridOptions.api){
                    this.resultsApi.gridOptionsWrapper.gridOptions.api.redrawRows()
            }
        }
    }
    async setWordStat(event,pasukSequenceNum,pasukId,matchIds,tanach,lastMatchIds){//toDo: try to get it to not refresh if the hovered word is the same as last one. check it against state.hoveredMatchIds
        if(matchIds /*&& matchIds !== this.state.hoveredMatchIds*/) this.setState({hoveredMatchIds:matchIds},()=>this.setWordStat(event,pasukSequenceNum,pasukId,null,tanach,matchIds))
        else{
            var body 
            var rowData = this.props.openResults[this.state.tabValue].rowData
            if(tanach){
                const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTeivaStatistics?source=bhsWebApp&pasukId=' + pasukId + '&pasukSequenceNum=' + pasukSequenceNum)
                try{
                body = await response.json();}
                catch{
                    body = null;
                }
                if(body){
                    body.wordNumber = pasukSequenceNum;
                    properties.setWordStat(body);
                    this.props.setCoordinatesWordTooltip(event);
                }
            }
            // console.log(body)
            var rowNumber = rowData.findIndex(e=>e.pasukId === pasukId)
            rowNumber = typeof(this.state.resultsPerPage)=='string'? rowNumber : rowNumber % this.state.resultsPerPage
            var rowNode = this.resultsApi.gridOptionsWrapper.gridOptions.api.getDisplayedRowAtIndex(rowNumber)
            // var decoratedText = rowNode.data.text;
            // var words = rowNode.data.text.split(" ");
            // var style = "background-color:blueviolet";//this is changing the text and then it messes up because it has spaces
            // decoratedText = decoratedText.replace(words[pasukSequenceNum-1], "<span style='" + style+";'>"+words[pasukSequenceNum-1]+"</span>");
            // decoratedText="וַיִּקְרָ֨א אֱלֹהִ֤ים לָאוֹר֙ י֔וֹם וְלַחֹ֖שֶׁךְ קָ֣רָא לָ֑יְלָה וַֽיְהִי עֶ֥רֶב וַֽיְהִי בֹ֖קֶר י֥וֹם <span style='background-color:blueviolet'>אֶחָֽד</span>";
            var rowNodes=[];
            for(let i=0;i<rowData.length;i++){
                var otherRowNode = this.resultsApi.gridOptionsWrapper.gridOptions.api.getDisplayedRowAtIndex(i)
                if(otherRowNode && otherRowNode.data.highlight>0){
                    otherRowNode.data.highlight = 0;
                    rowNodes.push(otherRowNode)
                }
            }
            rowNode.data.highlight = pasukSequenceNum// decoratedText//util.highlightText(rowNode.data.text,pasukSequenceNum)
            rowNodes.push(rowNode)
            var params = {
                force: true,
                suppressFlash: true,
                rowNodes: rowNodes,
                columns: ['text','targumText']
            }
            if(this.state.contentDisplayed.length===2 && !this.clicked && !window.getSelection().toString()){//.includes('targum') 
                this.resultsApi.gridOptionsWrapper.gridOptions.api.refreshCells(params);
            }
        }
        // this.resultsApi.gridOptionsWrapper.gridOptions.api.redrawRows()
        // this.setState({timer:window.setTimeout(()=>this.leave(),9000)})

        // this.setState({frameworkComponents:{customTooltip:WordStatsTooltip}},()=>
        //     {return <div>Shoresh Appearence in Tanach: {body.appearancesShoresh}</div>})
    }

    mouseHoverInAGGrid(e){
        var elements = document.getElementsByClassName('fullLineOfText');//this part is to make sure that if mouse is not over a word, the tooltip should not show at all
        var overWord=false;                                         //even if the mouseout didn't fire properly.
        if (elements.length>0){
            for(let i=0;i<elements.length;i++){
                if(window.getComputedStyle(elements[i]).getPropertyValue('border-top-style')==='hidden'){
                    overWord = true
                    break;
                }
            }
        }
        if(!overWord && this.state.contentDisplayed.length===2 && !this.clicked && !window.getSelection().toString()){
            this.leave()}       

    }

    handleHoverSelect(val) {
        properties.setHoverSelect(val); //this is a hack since can't pass props to ag-grid custome tooltip
        this.setState({hoverSelect: val});
    }

    changeHoverWithCheckbox(){
        if(this.state.hoverSelect==="none"){
            this.handleHoverSelect("all")
        }else{
            this.handleHoverSelect("none")
        }
    }
    changeHoverWordsWithCheckbox(){
        if(this.state.hoverWords==="none"){
            properties.setHoverWords("perWordStat")
            this.setState({hoverWords:"perWordStat"})
        }else{
            properties.setHoverWords("none")
            this.setState({hoverWords:"none"})
        }
    }

    shiftPasukTextColumn(pos) {
        this.setPasukTextColumn(pos);
        this.setState({pasukTextPosition: pos});
    }

    setPasukTextColumn(pos) {
        const maxIndex = this.resultsColumnApi.getAllColumns().length-1;
        this.resultsColumnApi.moveColumn("text", pos == "left" ? maxIndex : 3);
        this.resultsColumnApi.moveColumn("targum", pos == "left" ? maxIndex : 4);
    }

    getPasukColumWidth(){
        if(this.state){
            return this.state.pasukColumnWidth;
        } else {
            return 140
        }
    }

    handleResultsPerPage(e){
        var value = e.target.value;
        var navOption = null;
        var newPage = null; 
        var wasShowAll = false;
        
        if(typeof(value) === 'number'){
            if(this.state.resultsPerPage === "showAll") {
                newPage = 1;
                wasShowAll = true;
            } else {
                const currentPage = this.state.openResults[this.props.tabValue].currentPage;
                var currLastRowIndex = this.state.resultsPerPage * currentPage;
                var currFirstRowIndex = currLastRowIndex - (this.state.resultsPerPage - 1);
                newPage = Math.ceil(currFirstRowIndex/value);
            }
            this.setState({
                resultsPerPage: value
            }, () => {
                this.props.extUpdateSearchResultsPerPage(value)
                if(wasShowAll){
                    navOption = "first";
                }
                this.goToPage(e, newPage, navOption, wasShowAll)
            })

        } else if (value === 'showAll'){
            newPage = 0;
            navOption = 'showAll';
            this.goToPage(e, newPage, navOption, wasShowAll)
        }
    }

    setPickerValues(newVals) {
        if (this.state.statisticsPicker.list == null || this.state.statisticsPicker.list.length == 0) {
            properties.setStatisticsPicker({list: this.getPickerList(), values: newVals});
            this.setState(Object.assign(this.state.statisticsPicker, {list: this.getPickerList(), values: newVals})); //force lazy initialization of item list here
        }
        else {
            properties.setStatisticsPickerValues(newVals);
            this.setState(Object.assign(this.state.statisticsPicker, {values: newVals}));
        }
    }

    applyPickerValues() {
        var i;
        // var sortColumn = null; var changedStatsArray=this.props.changedStatsArray;
        // if(this.props.openResults.length > 0 && this.props.openResults[this.state.tabValue] && this.props.openResults[this.state.tabValue].searchParams){//putting in the sorted column into the table-
        //         sortColumn = this.props.openResults[this.state.tabValue].searchParams.sortedColumn;//each tab can be sorted by a different column,
        //         //but all of them have the same statistics columns
        // }
        var list = this.state.statisticsPicker.list;
        var vals = this.state.statisticsPicker.values;
        // if(sortColumn && sortColumn<19 && !vals.includes(sortColumn)){
        //     vals.push(sortColumn);
        // }
        // if(!vals.includes(19) && !vals.includes(20)){
        //     vals.push(20)
        // }
        // if(this.props.textSource==="tanach" && !vals.includes(20)){
        //     vals.push(20)
        // } else if(this.props.textSource==="targum" && !vals.includes(19)){
        //     vals.push(19)
        // }
        if(this.state.contentDisplayed.includes('targum') && !vals.includes(targumGridPos)){
            vals.push(targumGridPos)
        }
        if(this.state.contentDisplayed.includes('tanach') && !vals.includes(textGridPos)){
            vals.push(textGridPos)
        }
        var columnsToShow = [];
        var columnsToHide = [];
        for (i=1; i <= list.length; i++) {
            if (vals.includes(i))
                columnsToShow.push(list[i-1].colName);
            else
                columnsToHide.push(list[i-1].colName);
        }

        this.resultsColumnApi.setColumnsVisible(columnsToHide, false);
        this.resultsColumnApi.setColumnsVisible(columnsToShow, true);
    }
  
    isNewBuffer(newPageNum,navOption){
        if(this.state.openResults[this.state.tabValue].rowData[0]){
            var isNewBuffer = false;
            var curPage = this.state.openResults[this.state.tabValue].currentPage;
            //   if the first id of the selected page is not in range
            //   curr buffer: first id to last id
            var firstRowNum = this.state.openResults[this.state.tabValue].rowData?this.state.openResults[this.state.tabValue].rowData[0].rowNum:0;
            // rowNum = this.state.openResults[this.state.tabValue].rowData.findIndex(e => e.rowNum === rowNum);
            var resPerPage = this.state.resultsPerPage;
            // const lastRowIsOpen = this.state.openResults[this.state.tabValue].rowData?typeof(this.state.openResults[this.state.tabValue].rowData[0].pasukId) === "string":false;
            var currBufferLastFilterIndex = this.state.openResults[this.state.tabValue].rowData[this.state.openResults[this.state.tabValue].rowData.length-1].rowNum //lastRowIsOpen ? this.state.openResults[this.state.tabValue].rowData[this.state.openResults[this.state.tabValue].rowData.length-2].rowNum : this.state.openResults[this.state.tabValue].rowData? this.state.openResults[this.state.tabValue].rowData[this.state.openResults[this.state.tabValue].rowData.length-1].rowNum:0;
            // currBufferLastFilterIndex = this.state.openResults[this.state.tabValue].rowData.findIndex(e => e.rowNum === currBufferLastFilterIndex);
            const lastVisibleRowNum = curPage*resPerPage//((newPageNum-(navOption =="prev"?-1:1)) * resPerPage)===0?1:((newPageNum-(navOption =="prev"?-1:1)) * resPerPage)-1;
            const firstVisibleRowNum = curPage*resPerPage-(resPerPage-1)//((newPageNum-(navOption =="prev"?-1:1)) * resPerPage)===0?0:((newPageNum-(navOption =="prev"?-1:1)) * resPerPage)-resPerPage;
            var newFirstVisibleRowNum = newPageNum * resPerPage-(resPerPage-1)
            if(navOption == "next"){
                newFirstVisibleRowNum = lastVisibleRowNum+1
            } else if(navOption =="prev"){
                newFirstVisibleRowNum = firstVisibleRowNum - resPerPage;
            } else if(navOption == "showAll" || navOption == "first" || navOption == "last"){
                isNewBuffer=true;
            }
            if(newFirstVisibleRowNum < firstRowNum || newFirstVisibleRowNum > currBufferLastFilterIndex){//-1){
                isNewBuffer = true;
            }
            return isNewBuffer;
        }
        return false;
    }
  
    async goToPage(e, pageNumber, navOption, wasShowAll,newBuffer,download){
        if(this.state.openResults[this.props.tabValue].rowData){
            const isNewBuffer = newBuffer || this.isNewBuffer(pageNumber,navOption);
            if(!isNewBuffer){
                var openResults = this.state.openResults;
                openResults[this.state.tabValue].currentPage = pageNumber;
                var resApi=this.resultsApi;
                this.setState({openResults,loadingPage:pageNumber},()=>{
                    resApi.setRowData(this.getDisplayRows())
                    if(resApi.gridOptionsWrapper && resApi.gridOptionsWrapper.gridOptions && resApi.gridOptionsWrapper.gridOptions.api){
                        resApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(0,'top')
                    }
                })
            }
            else{
                this.setState({pageIsLoading:true,loadingPage:pageNumber},
                    async ()=>{
                        var url = properties.env.webServiceURL + '/BaseHaSefer/getResultsBatch?source=bhsWebApp';
                        const searchParams = this.state.openResults[this.state.tabValue].searchParams;
                        const sessionId = this.state.openResults[this.state.tabValue].rowData[0].sessionId;
                        var openResults = this.state.openResults;
                        var body = null;
                        var response = null;
                        url += '&batchType=' + navOption;
                        url += '&sessionId=' + sessionId;
                        if (navOption === 'showAll') {
                            url += '&getAllResults=true';
                            if  ((openResults[this.state.tabValue].rowData.length-this.state.openPesukim[this.state.tabValue].length) !== openResults[this.state.tabValue].rowData[0].totalResults) {
                                response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
                                body = await response.json();
                            } else{
                                body = openResults[this.state.tabValue].rowData;
                            }
                            openResults[this.state.tabValue].rowData = await this.fetchWordsForOpenPesukim(body);
                            openResults[this.state.tabValue].currentPage = 1;    
                            this.setState({resultsPerPage: 'showAll', openResults}, () => {
                                this.resultsApi.setRowData(this.getDisplayRows())
                                if(this.resultsApi.gridOptionsWrapper) this.resultsApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(0,'top')

                                this.props.extUpdateOpenResults(openResults);
                                this.props.extUpdateSearchResultsPerPage("showAll");
                            })
                            this.setState({pageIsLoading:false},()=>{
                                this.resultsApi.redrawRows()
                            })
                            if(download){
                                this.resultsApi.exportDataAsCsv();
                                openResults[this.state.tabValue].currentPage = this.state.lastOpenPage;    

                                this.setState({resultsPerPage:this.state.lastResultsPerPage,loadingPage:this.state.lastOpenPage,openResults},()=>{
                                    this.resultsApi.forEachNode((node, index) => {
                                        if(this.state.lastSelectedResults.find(res=>{res.pasukId = node.data.pasukId}))
                                            node.setSelected(true);
                                    })                        
                                    this.resultsApi.setRowData(this.getDisplayRows())
                                    this.resultsApi.redrawRows()
                                    this.props.extUpdateSearchResultsPerPage(this.state.lastResultsPerPage);
                                })
                            }

                        }
                        else{
                            var newFirstRow = ((pageNumber-1) * this.state.resultsPerPage) + 1;
                            // newFirstRow = (Math.floor(newFirstRow/200.0) * 200)+1;
                            url += '&firstResultNum=' + newFirstRow;

                            response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
                            body = await response.json();
                            // var openResults = this.state.openResults;
                            // openResults[this.props.tabValue].rowData = body;
                        
                            openResults[this.state.tabValue].rowData = await this.fetchWordsForOpenPesukim(body);
                            openResults[this.state.tabValue].currentPage = pageNumber;
                            this.setState({openResults},()=>{
                                this.resultsApi.setRowData(this.getDisplayRows())
                                var topIndex = this.state.openResults[this.props.tabValue].rowData.findIndex(e => e.rowNum-1 === newFirstRow)
                                if(topIndex<0) topIndex = 1
                                if(this.resultsApi.gridOptionsWrapper) this.resultsApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(topIndex-1,'top')
                            })
                            this.setState({pageIsLoading:false},()=>{
                                this.resultsApi.redrawRows()
                            })
                        }
                    })
            }
        // if (pageNumber !== this.state.openResults[this.props.tabValue].currentPage || wasShowAll) {
        //     this.setState({
        //         pageIsLoading: true,
        //         loadingPage: pageNumber,
        //     }, async () => {
    
        //         var currLastResult = null;
        //         var currFirstResult = null;
        //         if(this.state.openResults[this.props.tabValue].rowData){
        //             currLastResult = this.state.openResults[this.props.tabValue].rowData[this.state.openResults[this.props.tabValue].rowData.length - 1];
        //             currFirstResult = this.state.openResults[this.props.tabValue].rowData[0];
    
        //             const AGPageNum = pageNumber - 1;
        //             const totalAGRows = this.state.openResults[this.state.tabValue].rowData.length - this.state.openPesukim.length;// this.resultsApi.rowModel.rowsToDisplay.length//-this.state.openPesukim.length;
        //             const totalAGPages = Math.ceil(totalAGRows/this.state.resultsPerPage);
        //             const currentResultsPage = this.state.openResults[this.props.tabValue].currentPage;
        //             const isLastAGPage = currentResultsPage % totalAGPages === 0;
        //             const isFirstAGPage = this.resultsApi.paginationGetCurrentPage() === 0;
        //             const isFirstBatch = currentResultsPage <= totalAGPages;
        //             const isLastBatch = currLastResult.rowNum === currLastResult.totalResults || !this.state.openResults[this.props.tabValue].rowData[0];
        //             const sessionId = this.state.openResults[this.props.tabValue].rowData[0].sessionId;
        //             const searchParams = this.state.openResults[this.props.tabValue].searchParams;
                    
        //             var url = properties.env.webServiceURL + '/BaseHaSefer/getResultsBatch?source=bhsWebApp';
        //             url += '&batchType=' + navOption;
        //             url += '&sessionId=' + sessionId;
    
        //             var response = null;
            
        //             if (navOption === 'prev' && isFirstAGPage) {
        //                 var nextFirstResultNum = currFirstResult.rowNum - totalAGRows;
        //                 url += '&firstResultNum=' + nextFirstResultNum;
        //                 response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                 const body = await response.json();
        //                 var openResults = this.state.openResults;
        //                 openResults[this.props.tabValue].rowData = body;
        //                 this.setState({openResults}, () => {
        //                     this.resultsApi.paginationGoToLastPage();
        //                     this.props.extUpdateOpenResults(openResults);
        //                 })
        //             } else if (navOption === 'next' && isLastAGPage) {
        //                 var nextFirstResultNum = currLastResult.rowNum + 1;
        //                 url += '&firstResultNum=' + nextFirstResultNum;
        //                 response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                 const body = await response.json();
        //                 var openResults = this.state.openResults;
        //                 openResults[this.props.tabValue].rowData = body;
        //                 this.setState({openResults}, () => {
        //                     this.resultsApi.paginationGoToFirstPage();
        //                     this.props.extUpdateOpenResults(openResults);
        //                 })
        //             } else if (navOption === 'first' && !isFirstBatch || wasShowAll) {
        //                 var openResults = this.state.openResults;
        //                 if  (openResults[this.props.tabValue].rowData.length !== openResults[this.props.tabValue].rowData[0].totalResults) {
        //                     response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                     const body = await response.json();
        //                     openResults[this.props.tabValue].rowData = body;
        //                 }
        //                 this.setState({openResults}, () => {
        //                     this.resultsApi.paginationGoToFirstPage();
        //                     this.props.extUpdateOpenResults(openResults);
        //                 })
        //             } else if (navOption === 'last' && !isLastBatch) {
        //                 response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                 const body = await response.json();
        //                 var openResults = this.state.openResults;
        //                 openResults[this.props.tabValue].rowData = body;
        //                 this.setState({openResults}, () => {
        //                     this.resultsApi.paginationGoToLastPage();
        //                     this.props.extUpdateOpenResults(openResults);
        //                 })
        //             } else if (navOption === 'showAll') {
        //                 var openResults = this.state.openResults;
        //                 url += '&getAllResults=true';
        //                 if  (openResults[this.props.tabValue].rowData.length !== openResults[this.props.tabValue].rowData[0].totalResults) {
        //                     response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                     const body = await response.json();
        //                     openResults[this.props.tabValue].rowData = body;
        //                 }
        //                 this.setState({resultsPerPage: 'showAll', openResults}, () => {
        //                     this.props.extUpdateOpenResults(openResults);
        //                     this.props.extUpdateSearchResultsPerPage("showAll");
        //                 })
        //             } else {
        //                 const currLastPage = Math.ceil(currLastResult.rowNum/this.state.resultsPerPage);
        //                 const currFirstPage = Math.ceil(currFirstResult.rowNum/this.state.resultsPerPage);
        //                 if(pageNumber >= currFirstPage && pageNumber <= currLastPage){
        //                     this.resultsApi.paginationGoToPage(AGPageNum % totalAGPages);
        //                 } else {
        //                     const firstPageResultNum = pageNumber*this.state.resultsPerPage - (this.state.resultsPerPage - 1);
        //                     const firstBatchResultNum = Math.floor(firstPageResultNum/totalAGRows)*totalAGRows + 1;
        //                     url += '&firstResultNum=' + firstBatchResultNum;
        //                     response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
        //                     const body = await response.json();
        //                     var openResults = this.state.openResults;
        //                     openResults[this.props.tabValue].rowData = body;
        //                     this.setState({openResults}, () => {
        //                         this.resultsApi.paginationGoToPage(AGPageNum % totalAGPages);
        //                         this.props.extUpdateOpenResults(openResults);
        //                     })
        //                 }
        //             }
        //         }
    
        //         openResults = this.state.openResults;
        //         openResults[this.props.tabValue].rowData = await this.fetchWordsForOpenPesukim(openResults[this.props.tabValue].rowData);
        //         openResults[this.props.tabValue].currentPage = pageNumber;
        //         this.setState({
        //             openResults: openResults, pageIsLoading: false
        //         }, () => {
        //             this.resultsApi.setRowData(this.getDisplayRows())//this.state.openResults[this.props.tabValue].rowData)
        //             this.props.extUpdateOpenResults(openResults)
        //         })
        //     })
        // }
        }
    }

    async openTaamParser(pasukLocation){
        this.props.extOpenTaamParser(pasukLocation,false,this.resultsApi)
        const body = await this.props.extFetchTaamParseData(pasukLocation)
        this.setState({taamParseData: body})
        this.setState({taamParserOpen: true, taamParserPasukId: body.pasukId, taamParserLocation: body.pasukLocation, taamParserOpen: false}, () => {
            this.setState({taamParserOpen: true})
        })
    }

    closeTaamParser(){
        this.props.extCloseTaamParser()
        this.setState({taamParserOpen: false})
    }

    toggleUseKinuim(){
        this.setState({ useKinuim: !this.state.useKinuim })
    }

    toggleIncludeStatistics(){
        this.setState({ includeStatistics: !this.state.includeStatistics })
    }

    toggleIncludeMekorot(){
        this.setState({ includeMekorot: !this.state.includeMekorot })
    }

    toggleSearchAsQuote(){
        this.setState({ searchAsQuote: !this.state.searchAsQuote })
    }

    handleDoubleRightClick(e){
        if(e.event){
            var columnClicked =  e.colDef.headerName
            var textToCopy = '';

            var focusedCellRowIndex = null ;
            if(this.resultsApi.getFocusedCell()){
                var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
            }
            var focusedCellRowText = null;
            var focusedCellSelected = null;
            if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
                focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
                focusedCellSelected = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].selected;
            }
            var word = e.event.target.innerText.split(" ")
            word = word[1] && word[1].length>0? null : word.join("")
    
                //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
            if(this.resultsApi.getFocusedCell() && !window.getSelection().toString() && !focusedCellSelected ){
                textToCopy = 'focused';
            } else if(window.getSelection().toString()){
                textToCopy = 'highlighted';
            } else if (!!this.resultsApi.getSelectedRows()) {
                textToCopy = 'selected';
            }
        
            this.setState({noContextMenu:false,columnClicked,textToCopy,wordToCopy:word})
            var element = document.elementFromPoint(e.event.clientX, e.event.clientY)
            if(this.state.doubleOpeningContextMenu){ // forcing a second right click so taam parse gets disabled when clicking on middle columns
                contextMenu.hideAll();
                this.setState({doubleOpeningContextMenu: false}, () => {
                    var ev = new MouseEvent("contextmenu", {
                        bubbles: true,
                        cancelable: false,
                        view: window,
                        button: 2,
                        buttons: 0,
                        clientX: e.event.clientX,
                        clientY: e.event.clientY
                    });
                    element.dispatchEvent(ev);
                });
            } else {
                this.setState({doubleOpeningContextMenu: true}, () => {
                    this.onCellContextMenu(e);
                });
            }
        } else {
            this.setState({noContextMenu:true})//prevents the context menu from showing when click on the open Pasuk stats
        }
    }

    onCellContextMenu(e){
        this.setState({useKinuim: false, includeMekorot: false})
        const { innerWidth } = window;
        //style context menu
        var submenus = document.getElementsByClassName('react-contexify__submenu');
        for(let i = 0; i < submenus.length; i++){
            var submenu = submenus[i];
            var submenuWidth = parseInt(submenu.style.minWidth.slice(0, submenu.style.minWidth.length-2));
            if(this.props.activeLanguage.code === 'he'){
                if(e.event.clientX > submenuWidth){
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                } else {
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                }
            } else if(this.props.activeLanguage.code === 'en'){
                if(innerWidth-e.event.clientX-300 > submenuWidth){
                    submenu.style.left = '100%';
                    submenu.style.right = '';
                } else {
                    submenu.style.left = '';
                    submenu.style.right = '100%';
                }
            }
        }


            //set text to copy
        // var textToCopy = '';

        // var focusedCellRowIndex = null ;
        // if(this.resultsApi.getFocusedCell()){
        //     var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
        // }
        // var focusedCellRowText = null;
        // var focusedCellSelected = null;
        // if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
        //     focusedCellRowText = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.text;
        //     focusedCellSelected = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].selected;
        // }

        //     //focused = row of focused cell; highlighted = highlighted text; selected = all selected rows;
        // if(this.resultsApi.getFocusedCell() && !window.getSelection().toString() && !focusedCellSelected && !this.highlightedText){
        //     textToCopy = 'focused';
        // } else if(window.getSelection().toString() || this.highlightedText){
        //     textToCopy = 'highlighted';
        // } else if (!!this.resultsApi.getSelectedRows()) {
        //     textToCopy = 'selected';
        // }

        // this.setState({textToCopy},()=>{return true})
    }

    copyToClipboard(e, LNT){
        //get selection
        var selection = '';
        if(window.getSelection().toString()){
            selection = window.getSelection().toString();
        } else if (!!this.resultsApi.getSelectedRows()) {
            //if there are rows selected
            var selectedRows = this.resultsApi.getSelectedRows()
            for(let i = 0; i < selectedRows.length; i++){
                var selectedRow = selectedRows[i]
                selection = selection.concat(selectedRow.text + " ")
            }
            selection = selection.slice(0, selection.length-1)
        }

        //LNT formatting
        if(LNT === 'LNT'){
            navigator.clipboard.writeText(selection);
        } else if (LNT === "LN"){
            selection = this.removeTaamim(selection);
            navigator.clipboard.writeText(selection);
        } else if (LNT === "L"){
            selection = this.removeTaamim(this.removeNikud(selection));
            navigator.clipboard.writeText(selection);
        }
    }

    removeNikud(str){
        for (let i = str.length-1; i >= 0; i--){
            if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    removeTaamim(str){
        for (let i = str.length-1; i >= 0; i--){
            if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    handleTabChange(event, newValue) {
        this.props.extHandleSearchResultsTabChange(newValue);
    }

    resultsGridReady = params => {
        this.resultsApi = params.api;
        this.resultsColumnApi = params.columnApi;
        this.gridOptions = this.resultsApi.gridOptionsWrapper.gridOptions;
        // this.gridOptions.fullWidthCellRenderer = (params) =>{
        //     var cellContents = document.createElement('div');
        //     var wordsTable = '<table class="words-statistic-table">';
        //     var headerRow = `<tr class="word-stat-row">`+
        //                     `<td class="header-row-word-stats">#</td>` +
        //                     `<td class="header-row-word-stats">Word</td>` +
        //                     `<td class="header-row-word-stats">Letters and Nekudos</td>` +
        //                     `<td class="header-row-word-stats">Shoresh</td>` +
        //                     `<td class="header-row-word-stats">Appearences Per Shoresh</td>` +
        //                     `<td class="header-row-word-stats">Atbash</td>` +
        //                     `<td class="header-row-word-stats">Atbash-Katan</td>` +
        //                     `<td class="header-row-word-stats">Gematria</td>` +
        //                     `<td class="header-row-word-stats">Katan</td>` +
        //                     `<td class="header-row-word-stats">Appearences Word + Nekudos</td>` +
        //                     '</tr>';
        //     wordsTable += headerRow;
        //     for(let i=0; i<params.data.body.length; i++){
        //         var currWord = params.data.body[i];
        //         var wordRow =   `<tr wordId=${currWord.pasukSequenceNum} class="word-stat-row">`+
        //                         `<td class="parsha-viewer-text-table-makor" location=${currWord.pasukSequenceNum} >${currWord.pasukSequenceNum}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.word}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.ln}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.shoresh}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.appearancesShoresh}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.atbash}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.atbashKatan}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.gematria}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.katan}</td>` +
        //                         `<td class="parsha-viewer-text-table-text">${currWord.appearancesLN}</td>` +
        //                         '</tr>';

        //         wordsTable += wordRow;
        //     }
        //     wordsTable += '</table>';
        //     cellContents.innerHTML = wordsTable;
        //     cellContents.className = `parsha-viewer-text-cell parsha-id-${params.data.pasukId}`;
        //     cellContents.style.fontSize = `16px`;
        //     return cellContents;
        // }
        // this.gridOptions.isFullWidthCell = (rowNode) => {
        //     return rowNode.data.isWordRow};

        this.gridOptions.getRowHeight = (params)  => {
            if(!params.data.isWordRow){
                return this.state.resultsRowHeight;
            } else {
                var height = 7
                if(params.data.body.length<7){
                    height = params.data.body.length
                }
                return this.state.sizingArray[this.state.currentSize].rowHeight*(height+2)+10
            }
        }
        this.resultsApi.resetRowHeights();
        if(this.state.openResults[this.props.tabValue]){
            var currentPage = this.state.openResults[this.props.tabValue].currentPage?this.state.openResults[this.props.tabValue].currentPage:1;
            this.goToPage(null, currentPage)
        }
        this.resultsColumnApi = params.columnApi;
        if(this.state.contentDisplayed.length===2){
            this.resultsColumnApi.setColumnWidths([{key:'targumText',newWidth:((1310-this.state.sizingArray[this.state.currentSize].pasukColumnWidth)/2)},{key:'text',newWidth:((1310-this.state.sizingArray[this.state.currentSize].pasukColumnWidth)/2)}])
        }else if(this.state.contentDisplayed.includes('tanach')){
            this.leave(true)
            this.resultsColumnApi.setColumnWidths([{key:'text',newWidth:1500}])
        }else if(this.state.contentDisplayed.includes('targum')){
            this.leave(true)
            this.resultsColumnApi.setColumnWidths([{key:'targumText',newWidth:1500}])
        }

        // var fakeThis = this
        // this.resultsApi.forEachNode( function (rowNode) {
        //     rowNode.setRowHeight(0);
        // })
        // this.resultsApi.onRowHeightChanged();
        // this.resultsApi.forEachNode( function (rowNode) {
        //     rowNode.setRowHeight(rowNode.getRowHeight);
        // })
        // this.resultsApi.onRowHeightChanged();
        // this.resultsApi.setRowData(this.getDisplayRows());//this.state.openResults[this.state.tabValue].rowData);

        this.applyPickerValues();//this puts the stat columns back into the grid
    };

    selectAllResults(){
        this.resultsApi.forEachNode((node, index) => {
            node.setSelected(true);
        })
    }

    unselectAllResults(){
        this.resultsApi.forEachNode((node, index) => {
            node.setSelected(false);
        })
    }
    handleResultsCellMouseDown(e){

        if(e.event.shiftKey === true){
            // if holding shift key, unhighlight all text
            window.getSelection().removeAllRanges();
        }
    }
    async fetchWordsForOpenPesukim(rowData){
        for(let i = rowData.length-1; i>=0; i--){
            var currRow = rowData[i];
            var willFetchPasukData = (this.state.openPesukim[this.state.tabValue].includes(currRow.pasukId) && !currRow.textIsOpen);
            if(willFetchPasukData){
                const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer//getPasukTeivaStatistics?source=bhsWebApp&pasukId=' + currRow.pasukId)
                var body = await response.json();

                body.analysis = this.props.translate("analysis")
                body.stam = this.props.translate("stam")
                body.ivri = this.props.translate("ivri")
                body.byAppearance = this.props.translate("appearances")
                body.root = this.props.translate("root")
                body.targumTitle = this.props.translate("targum")
                body.taamSingular = this.props.translate("taam")
                body.wordTypesTitle = this.props.translate("wordTypes")
                body.misc = this.props.translate("misc")
                body.letterAppearance = this.props.translate("letterAppearance")
                body.unique = this.props.translate("unique")
                body.numLettersPerWord = this.props.translate("lettersPerWord")
                body.nekudosTitle = this.props.translate("nekudos")
                body.tenuosTitle = this.props.translate("tenuos")
                body.gematriaTitle = this.props.translate("gematria")
                body.katanTitle = this.props.translate("gKatan")
                body.atbashTitle = this.props.translate("atbash")
                body.atbashKatanTitle = this.props.translate("aKatan")
                body.wordTitle = this.props.translate("word")
                body.inflectionTitle = this.props.translate("inflection")
                body.map(word=>{ return ( word.onlyLetters = this.removeNikud(word.ln)) })
                body.map(word=>{ return ( word.colored = this.props.colorWord(word.word,word.letterWordTypes,"black")) })
                body.map(word=>{ return ( word.fullTaam = this.props.setUpTaam(word)) })
                body.map(word=>{ return ( word.fullTargum = this.props.setUpTargum(word)) })
                body.map(word=>{ return ( word.fullWordType = this.props.setUpWordType(word)) })
                body.map(word=>{ return ( word.misc = this.props.setUpMisc(word, false)) })                
                body.map(word=>{ return ( word.realShoresh = this.props.setUpShoresh(word, false)) })  
                body.map(word=>{ return ( word.realAppearancesShoresh = this.props.setUpAppearancesShoresh(word,false)) })
            
                var textRowData = {
                    isWordRow:true,
                    pasukId: currRow.pasukId+'word',
                    atbash: currRow.atbash,
                    atbashPerLetter: currRow.atbashPerLetter,
                    atbashPerWord: currRow.atbashPerWord,
                    gematriaPerLetter: currRow.gematriaPerLetter,
                    gematriaPerWord: currRow.gematriaPerWord,
                    letter: currRow.letter,
                    lettersPerWord:currRow.lettersPerWord,
                    location: currRow.location,
                    matchIndex:currRow.matchIndex,
                    parshaId: currRow.parshaId,
                    parshaName: currRow.parshaName,
                    sidraName: currRow.sidraName,
                    otherKriosName: currRow.otherKriosName,
                    rowNum: currRow.rowNum,
                    sessionId: currRow.sessionId,
                    standard: currRow.standard,
                    taam: currRow.taam,
                    taamimPerTenua: currRow.taamimPerTenua,
                    taamimPerWord: currRow.taamimPerWord,
                    tanachYear: currRow.tanachYear,
                    teiva: currRow.teiva,
                    tenua: currRow.tenua,
                    tenuosPerLetter: currRow.tenuosPerLetter,
                    tenuosPerWord: currRow.tenuosPerWord,
                    text: currRow.text,
                    totalResults: currRow.totalResults,
                    uniqueLetter: currRow.uniqueLetter,
                    uniqueLetterCS: currRow.uniqueLetterCS,
                    body: body,
                }
                rowData.splice(i+1, 0, textRowData)
                rowData[i].textIsOpen = true;
            }
        }
        return rowData;
    }
    handleResultsCellClicked(e){
        if ( e.colDef.headerName === "-/+" ){
            // e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = true;

            var clickedRowPasukId = e.data.pasukId;

            if(!e.data.textIsOpen && !this.state.isFetchingWordData){
                this.props.extSetBeta();
                this.setState({isFetchingWordData:true},
                    async()=>{
                        const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer//getPasukTeivaStatistics?source=bhsWebApp&pasukId=' + clickedRowPasukId)
                        var body = await response.json();
                        body.wordTitle = this.props.translate("word")
                        body.analysis = this.props.translate("analysis")
                        body.stam = this.props.translate("stam")
                        body.ivri = this.props.translate("ivri")
                        body.byAppearance = this.props.translate("appearances")
                        body.root = this.props.translate("root")
                        body.targumTitle = this.props.translate("targum")
                        body.taamSingular = this.props.translate("taam")
                        body.wordTypesTitle = this.props.translate("wordTypes")
                        body.misc = this.props.translate("misc")
                        body.letterAppearance = this.props.translate("letterAppearance")
                        body.unique = this.props.translate("unique")
                        body.numLettersPerWord = this.props.translate("lettersPerWord")
                        body.nekudosTitle = this.props.translate("nekudos")
                        body.tenuosTitle = this.props.translate("tenuos")
                        body.gematriaTitle = this.props.translate("gematria")
                        body.katanTitle = this.props.translate("gKatan")
                        body.atbashTitle = this.props.translate("atbash")
                        body.atbashKatanTitle = this.props.translate("aKatan")
                        body.wordTitle = this.props.translate("word")
                        body.inflectionTitle = this.props.translate("inflection")
        
                        body.map(word=>{ return ( word.onlyLetters = this.removeNikud(word.ln)) })
                        body.map(word=>{ return ( word.colored = this.props.colorWord(word.word,word.letterWordTypes,"black")) })
                        body.map(word=>{ return ( word.fullTaam = this.props.setUpTaam(word)) })
                        body.map(word=>{ return ( word.fullTargum = this.props.setUpTargum(word)) })
                        body.map(word=>{ return ( word.fullWordType = this.props.setUpWordType(word)) })
                        body.map(word=>{ return ( word.misc = this.props.setUpMisc(word, false)) })  
                        body.map(word=>{ return ( word.realShoresh = this.props.setUpShoresh(word, false)) })    
                        body.map(word=>{ return ( word.realAppearancesShoresh = this.props.setUpAppearancesShoresh(word,false)) })
                          
                    
                    var textRowData = {
                        isWordRow:true,
                        pasukId: clickedRowPasukId+'word',
                        atbash: e.data.atbash,
                        atbashPerLetter: e.data.atbashPerLetter,
                        atbashPerWord: e.data.atbashPerWord,
                        gematriaPerLetter: e.data.gematriaPerLetter,
                        gematriaPerWord: e.data.gematriaPerWord,
                        letter: e.data.letter,
                        lettersPerWord:e.data.lettersPerWord,
                        location: e.data.location,
                        matchIndex:e.data.matchIndex,
                        parshaId: e.data.parshaId,
                        parshaName: e.data.parshaName,
                        sidraName: e.data.sidraName,
                        otherKriosName: e.data.otherKriosName,
                        rowNum: e.data.rowNum,
                        sessionId: e.data.sessionId,
                        standard: e.data.standard,
                        taam: e.data.taam,
                        taamimPerTenua: e.data.taamimPerTenua,
                        taamimPerWord: e.data.taamimPerWord,
                        tanachYear: e.data.tanachYear,
                        teiva: e.data.teiva,
                        tenua: e.data.tenua,
                        tenuosPerLetter: e.data.tenuosPerLetter,
                        tenuosPerWord: e.data.tenuosPerWord,
                        text: e.data.text,
                        totalResults: e.data.totalResults,
                        uniqueLetter: e.data.uniqueLetter,
                        uniqueLetterCS: e.data.uniqueLetterCS,
                        body: body,
                    }

                    var newRowData = this.state.openResults[this.state.tabValue].rowData;
                    for(let i = 0; i < newRowData.length; i++){
                        let currData = newRowData[i];
                        if(currData.pasukId === clickedRowPasukId){
                            currData.textIsOpen = true;
                            var firstHalf = newRowData.slice(0,i);
                            var secondHalf = newRowData.slice(i+1, newRowData.length);
                            newRowData = firstHalf.concat(currData).concat(textRowData).concat(secondHalf)
                        }
                    }
                    var openPesukim = this.state.openPesukim;
                    while(!openPesukim[this.state.tabValue] ){
                        openPesukim.push([])
                    }
                    if (!openPesukim[this.state.tabValue].includes(clickedRowPasukId)){
                        openPesukim[this.state.tabValue].push(clickedRowPasukId)
                    }
                    const fullData = this.state.openResults;
                    fullData[this.state.tabValue].rowData=newRowData;
                    this.setState({openResults:fullData, openPesukim:openPesukim},()=>{
                        this.resultsApi.setRowData(this.getDisplayRows());
                        // this.gridOptions.api.ensureIndexVisible(e.data.rowNum,'top')
                        this.resultsApi.redrawRows();
                        // var searchResultsScroll = document.getElementById("")
                        this.setState({isFetchingWordData:false},
                            this.props.openSearchResultsHeight())
                    })
                })

            } else if(e.data.textIsOpen === true){//close the details of the pasuk
                this.closeThisPasuk(clickedRowPasukId)
                // var openPesukim = this.state.openPesukim;
                // var pasukIndex = openPesukim[this.state.tabValue].indexOf(clickedRowPasukId);
                // if (pasukIndex > -1) {
                //     openPesukim[this.state.tabValue].splice(pasukIndex, 1);
                // }

                // var index = this.state.openResults[this.state.tabValue].rowData.findIndex(item => {
                //     return item.isWordRow === true && item.pasukId.substring(0, item.pasukId.length - 4) == clickedRowPasukId;
                // });
    
                // var newPasukStatsRow = this.state.openResults[this.state.tabValue].rowData[index-1];
                // newPasukStatsRow.textIsOpen = false;
    
                // var firstHalf = this.state.openResults[this.state.tabValue].rowData.slice(0, index-1);
                // var secondHalf = this.state.openResults[this.state.tabValue].rowData.slice(index+1, this.state.openResults[this.state.tabValue].rowData.length);
                // var newRowData = firstHalf.concat(newPasukStatsRow).concat(secondHalf)
        
                // const fullData = this.state.openResults;
                // fullData[this.state.tabValue].rowData=newRowData;
                // this.setState({openResults:fullData,openPesukim},()=>{
                // // this.setState({rowData:newRowData},()=>{
                //     this.resultsApi.setRowData(this.getDisplayRows())//this.state.openResults[this.state.tabValue].rowData);
                // this.resultsApi.redrawRows();
                // });
            }
            e.node.setSelected(!e.node.selected);//unselects the row that was clicked to open
        }else{
            // e.api.gridOptionsWrapper.gridOptions.suppressRowClickSelection = false;
            //prevent row selection/deselection if highlighting/unhighlighting text
            var highlightedText = "";
            if (window.getSelection) {
                highlightedText = window.getSelection().toString();
            }
            else if (document.selection && document.selection.type != "Control") {
                highlightedText = document.selection.createRange().text;
            }
            if(highlightedText != ""){ // if text was just highlighted, reverse selection
                e.node.setSelected(!e.node.selected)

                //set row as unselectable until after next mouse click (i.e. until text highlight is removed)
                e.node.selectable = false;
                var lastNodeHighlighted = e.node
                document.getElementById("home-component").addEventListener("click", function(){
                    lastNodeHighlighted.selectable = true;
                    // remove the event listener after first click
                    document.getElementById("home-component").removeEventListener("click", function(){
                        lastNodeHighlighted.selectable = true;
                    });
                });
            }
        }
    }
    closeThisPasuk(clickedRowPasukId){
        var openPesukim = this.state.openPesukim;
        var pasukIndex = openPesukim[this.state.tabValue].indexOf(clickedRowPasukId);
        if (pasukIndex > -1) {
            openPesukim[this.state.tabValue].splice(pasukIndex, 1);
        }

        var index = this.state.openResults[this.state.tabValue].rowData.findIndex(item => {
            return item.isWordRow === true && item.pasukId.substring(0, item.pasukId.length - 4) == clickedRowPasukId;
        });
        if(index >- 1){
            var newPasukStatsRow = this.state.openResults[this.state.tabValue].rowData[index-1];
            newPasukStatsRow.textIsOpen = false;

            var firstHalf = this.state.openResults[this.state.tabValue].rowData.slice(0, index-1);
            var secondHalf = this.state.openResults[this.state.tabValue].rowData.slice(index+1, this.state.openResults[this.state.tabValue].rowData.length);
            var newRowData = firstHalf.concat(newPasukStatsRow).concat(secondHalf)

            const fullData = this.state.openResults;
            fullData[this.state.tabValue].rowData=newRowData;
            this.setState({openResults:fullData,openPesukim},()=>{
            // this.setState({rowData:newRowData},()=>{
                this.resultsApi.setRowData(this.getDisplayRows())//this.state.openResults[this.state.tabValue].rowData);
            this.resultsApi.redrawRows();
            });
        }

    }

    handleResultsCellDoubleClicked(e){
        var location = e.data.location;
        this.props.toggleTanachViewer(true, location, this.resultsApi, e.colDef.headerName);
        if (window.getSelection) { // un-highlight the text that was clicked on
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
    }

    getDisplayRows(){
        if(this.state.openResults[this.state.tabValue]){
            var rowData = this.state.openResults[this.state.tabValue].rowData;
            var displayRows = [];
            var firstFilterIndex = this.state.resultsPerPage === "showAll" ? 0 : ((this.state.openResults[this.state.tabValue].currentPage-1 ) * this.state.resultsPerPage)//%200;
             if(rowData ){//&& firstFilterIndex > rowData.length-1) {
                firstFilterIndex = rowData.findIndex(e => e.rowNum-1 === firstFilterIndex);
             }
            if(firstFilterIndex === -1) firstFilterIndex = 0
            if(rowData){
                // var firstRowIndex = -1;
                // while(firstRowIndex === -1) {
                //     firstRowIndex = rowData.findIndex(e => e.rowNum-1 === firstFilterIndex);
                //     firstFilterIndex++;
                // }
                // const firstRow = rowData[firstRowIndex]
                const firstRow = rowData[firstFilterIndex]
                var rowCountPerPage = this.state.resultsPerPage === "showAll" ? rowData[0]?rowData[0].totalResults :0: this.state.resultsPerPage
                var i = firstFilterIndex//firstRowIndex;
                var currRow = firstRow;
                var rowsSoFar = 0;
                while(currRow && i < rowData.length && (rowsSoFar < rowCountPerPage || (typeof(currRow.pasukId) === "string" && rowsSoFar===rowCountPerPage))){// (typeof(currRow.pasukId) === "string" || currRow.rowNum < firstRow.rowNum + rowCountPerPage)){ 
                    displayRows.push(currRow)
                    if(typeof(currRow.pasukId) !== "string"){
                        rowsSoFar++
                    }
                    i++;
                    currRow = rowData[i];
                }
            }
            return displayRows;
        } else{
            return [];
        }
    }

    closeAllOpenPesukim(){
        var rowData = this.state.openResults;
        if(rowData[this.state.tabValue]){//catch if user closes results before they are fully loaded because rowData is null
            if(rowData[this.state.tabValue].rowData){
                for(let i = 0; i < rowData[this.state.tabValue].rowData.length; i++){
                    if(rowData[this.state.tabValue].rowData[i] && rowData[this.state.tabValue].rowData[i].isWordRow){
                        var newPasukStatsRow = rowData[this.state.tabValue].rowData[i-1];
                        newPasukStatsRow.textIsOpen = false;

                        var firstHalf = rowData[this.state.tabValue].rowData.slice(0, i-1);
                        var secondHalf = rowData[this.state.tabValue].rowData.slice(i+1, rowData[this.state.tabValue].rowData.length);
                        rowData[this.state.tabValue].rowData = firstHalf.concat(newPasukStatsRow).concat(secondHalf);
                    }
                }
                var openPesukim = this.state.openPesukim;
                openPesukim[this.state.tabValue] = [];
                this.setState({openPesukim,openResults:rowData},()=>{
                    this.resultsApi.setRowData(this.getDisplayRows())
                    this.resultsApi.redrawRows();
                })
            } 
            else if(!rowData){//[0].rowData){
                this.setState({openPesukim:[[]]})//maybe can take this out
            }
        }
    }
  

    deleteSelectedResults(){
        var selectedResults = this.resultsApi.getSelectedRows()
        for(let i=0; i<selectedResults.length; i++){
            this.closeThisPasuk(selectedResults[i].pasukId)
        }
        this.props.extDeleteSelectedResults(selectedResults
            // , 
        //     () => {
        //     this.setState({openResults: this.props.openResults})
        // }
        )
    }

    setStatsToCopy(val){
        if(val === "all") {
            this.setState({ statsToCopy: "all" });
        } else {
            this.setState({ statsToCopy: "selected above" })
        }
    }

    setHowToPaste(val){ //automatically it is Header row.
        this.setState({ howToPaste: val });//this will either be inline or headerRow
    }

    closeResultsTab(index){
        var openPesukim = this.state.openPesukim
        openPesukim.splice(index,1)
        this.setState({openPesukim})
        this.props.extCloseResultsTab(index);
    }

    handleCopyLink() {
        var params = [];
        if (this.props.lastSearchTerms && this.props.lastSearchTerms.trim() != "")
            params.push("terms=" + this.props.lastSearchTerms);
        params.push("inorder=" + ((this.props.seq == "noseq") ? "F" : "T"));
        params.push("precision=" + this.props.ssExactnessLevel); //eg. 0,1,2,3 for exact..fuzzy
        params.push("format=" + this.props.extSearchDefData); //eg. LNT
        this.props.handleCopyLink("search", params);
    }

    increaseFontSize(){
        // if(this.state.resultsFontSize < 24){
        if(this.state.currentSize<5){
            this.setState({currentSize:this.state.currentSize+1},()=>{
                this.setState({
                    resultsFontSize:this.state.sizingArray[this.state.currentSize].fontSize, //this.state.resultsFontSize + 2,
                    resultsFontSizeSm:this.state.sizingArray[this.state.currentSize].smFontSize, //this.state.resultsFontSizeSm + 1,
                    resultsRowHeight:this.state.contentDisplayed.length===2 ? this.state.sizingArray[this.state.currentSize].rowHeightWithTargum:this.state.sizingArray[this.state.currentSize].rowHeight, //this.state.resultsRowHeight + 3,
                    pasukColumnWidth:this.state.sizingArray[this.state.currentSize].pasukColumnWidth //this.state.pasukColumnWidth + 15,
                }, () => {
                    this.setResultsFontSize();
                })
            })
        }
    }

    decreaseFontSize(){
        if(this.state.currentSize>0){
            this.setState({currentSize:this.state.currentSize-1},()=>{
                this.setState({
                    resultsFontSize:this.state.sizingArray[this.state.currentSize].fontSize,
                    resultsFontSizeSm:this.state.sizingArray[this.state.currentSize].smFontSize,
                    resultsRowHeight:this.state.contentDisplayed.length===2 ? this.state.sizingArray[this.state.currentSize].rowHeightWithTargum:this.state.sizingArray[this.state.currentSize].rowHeight,
                    pasukColumnWidth:this.state.sizingArray[this.state.currentSize].pasukColumnWidth
                }, () => {
                    this.setResultsFontSize();
                })
            })
        }
    }

    setResultsFontSize(){
        // creating new stylesheet and adding new rule
        // Using document.getElementsByClassName() then changing the style property doesn't affect rows which haven't rendered yet
        var styleEl = document.createElement('style');
        document.head.appendChild(styleEl);
        var styleSheet = styleEl.sheet;
        var resultsFontSize = this.state.resultsFontSize;

        styleSheet.insertRule('.search-results-row span, .search-results-row.row-header, .search-results-row.row-header-center, .search-results-row.row-thin' + `{
                font-size: ${this.state.resultsFontSize}px;
            }`, styleSheet.cssRules.length);

        styleSheet.insertRule('.search-results-calc span, .search-results-row.row-thin-sm' + `{
                font-size: ${this.state.resultsFontSizeSm}px;
            }`, styleSheet.cssRules.length);

        var fakeThis = this;
        this.resultsApi.forEachNode( function (rowNode) {
            if(!rowNode.data.isWordRow){
                rowNode.setRowHeight(fakeThis.state.resultsRowHeight);
            }
        })
        this.resultsApi.onRowHeightChanged();

        this.setState({ columnDefs: this.getGridColumns(this.props.activeLanguage.code) });
        this.resultsApi.redrawRows();
        this.resultsApi.resetRowHeights();

    }

    getTextColumnWidth(){
        if(this.state){
            if(this.state.contentDisplayed.length===2)//.includes('targum'))
                return (1310-this.state.sizingArray[this.state.currentSize].pasukColumnWidth)/2;
            else return 1500;
        }else
            return 1500;
    }
    async handleOpenSaveResultsPopover(e){
        const anchorEl = e.currentTarget
        var blob = await this.getFocusedSearchResults()
        this.setState({ saveResultsPopoverOpen: true, saveResultsPopoverAnchorEl: anchorEl, searchResultsBlob: blob });
    }

    handleCloseSaveResultsPopover(e){
        this.setState({ saveResultsPopoverOpen: false });
    }

    async getFocusedSearchResults(){
        var focusedSearchResults = {}

        var salt = bcrypt.genSaltSync(10);
        var hash = bcrypt.hashSync("taryag_searchResults", salt);

        var rowData = this.state.openResults[this.props.tabValue].rowData;
        //renumber rows in case any results were deleted
        for(let i = 0; i < rowData.length; i++){
            rowData[i].rowNum = i+1;
        }

        focusedSearchResults.hash = hash;
        focusedSearchResults.rowData = rowData;
        //if only have batch, get full results
        if(rowData.length !== rowData[0].totalResults){
            const searchParams = this.state.openResults[this.props.tabValue].searchParams;
            var url = properties.env.webServiceURL + '/BaseHaSefer/getResultsBatch?source=bhsWebApp';
            url += '&getAllResults=true';
            url += '&sessionId=' + rowData[0].sessionId;
            var response = await fetch(url, {method: 'post', body: JSON.stringify(searchParams)});
            const body = await response.json();
            focusedSearchResults.rowData = body;
        }
        focusedSearchResults.terms = this.state.openResults[this.props.tabValue].terms;

        var blob = new Blob([JSON.stringify(focusedSearchResults)], {type: "text/plain;charset=utf-8"});

        return blob;
    }

    handleLoadSearchResults(e){
        this.props.extLoadSearchResults(e);
    }

  async componentDidMount() {
    if(this.props.openResults[this.props.tabValue] &&this.props.openResults[this.props.tabValue].rowData ){
        this.closeAllOpenPesukim()
    }

      this.setState({ pageIsLoading: false},()=>{this.setState({statisticsPicker: this.getPickerModel() },
        ()=>{properties.setStatisticsPicker(this.state.statisticsPicker)})});
  }

    headerHeightSetter() {
        var padding = 20;
        // this.resultsApi.setHeaderHeight();
    }


//   getContextMenuItems = (params) =>{
//     if(typeof(params.node.pasukId) == String){
//         return []
//     }
//   }


  getPickerList() {
    const parshaName = this.props.translate("parshaName");
    const sidraName = this.props.translate("sidraName");
    const otherKriosName = this.props.translate("otherKriosName");
    const words = this.props.translate("words");
    const letters = this.props.translate("letters");
    const uniqueLetters = this.props.translate("byUniqueLetters");
    const uniqueLettersCS = this.props.translate("byUniqueLettersCS");
    const tenua = this.props.translate("tenuos");
    const taam = this.props.translate("taamim");
    const gematria = this.props.translate("gematria");
    const atbash = this.props.translate("atbash");
    const letterCoverage = this.props.translate("letterCoverage");
    const perWord = this.props.translate("perWord");
    const perLetter = this.props.translate("perLetter");
    const perTenua = this.props.translate("perTenua");
    const targumText = this.props.translate("targum");
    const text = this.props.translate("text");
    const lettersPerWord = letters + " " + perWord;
    const tenuosPerWord = tenua + " " + perWord;
    const tenuosPerLetter = tenua + " " + perLetter;
    const taamimPerWord = taam + " " + perWord;
    const taamimPerTenua = taam + " " + perTenua;
    const gematriaPerWord = gematria + " " + perWord;
    const gematriaPerLetter = gematria + " " + perLetter;
    const atbashPerWord = atbash + " " + perWord;
    const atbashPerLetter = atbash + " " + perLetter;

    return [
        {id: 1, name: parshaName, colName: "parshaName", icon: ""},
        {id: 2, name: sidraName, colName: "sidraName", icon: ""},
        {id: 3, name: otherKriosName, colName: "otherKriosName", icon: ""},
        {id: 4, name: words, colName: "teiva", icon: ""},
        {id: 5, name: letters, colName: "letter", icon: ""},
        {id: 6, name: lettersPerWord, colName: "lettersPerWord", icon: ""},
        {id: 7, name: uniqueLetters, colName: "uniqueLetter", icon: ""},
        {id: 8, name: uniqueLettersCS, colName: "uniqueLetterCS", icon: ""},
        {id: 9, name: letterCoverage, colName: "letterCoverage", icon: ""},
        {id: 10, name: tenua, colName: "tenua", icon: ""},
        {id: 11, name: tenuosPerWord, colName: "tenuosPerWord", icon: ""},
        {id: 12, name: tenuosPerLetter, colName: "tenuosPerLetter", icon: ""},
        {id: 13, name: taam, colName: "taam", icon: ""},
        {id: 14, name: taamimPerWord, colName: "taamimPerWord", icon: ""},
        {id: 15, name: taamimPerTenua, colName: "taamimPerTenua", icon: ""},
        {id: 16, name: gematria, colName: "standard", icon: ""},
        {id: 17, name: gematriaPerWord, colName: "gematriaPerWord", icon: ""},
        {id: 18, name: gematriaPerLetter, colName: "gematriaPerLetter", icon: ""},
        {id: 19, name: atbash, colName: "atbash", icon: ""},
        {id: 20, name: atbashPerWord, colName: "atbashPerWord", icon: ""},
        {id: 21, name: atbashPerLetter, colName: "atbashPerLetter", icon: ""},
        {id: targumGridPos, name: targumText, colName: "targumText", icon: ""},
        {id: textGridPos, name: text, colName: "text", icon: ""}
    ];
  }

  getPickerModel() {
    var statisticsPicker = new PickerModel();
    statisticsPicker.list = this.getPickerList();
    statisticsPicker.values = [];
    return statisticsPicker;
  }
  async searchTanach(statisticsPicker,pasuk){
    contextMenu.hideAll();
    const body = await this.props.extSubmitSearch(null, null, null, null, null, statisticsPicker,pasuk);
    this.props.extOpenSearchResults(body, "דומה ל"+pasuk.location); //do this to update search results grid governed by Home.js
    this.props.setSearchIsLoading(false);
}

  componentDidUpdate(prevProps, prevState, snapshot){
        if(this.resultsApi){
            var fakeThis = this;
            this.resultsApi.forEachNode( function (rowNode) {
                if(!rowNode.data.isWordRow){
                    rowNode.setRowHeight(fakeThis.state.resultsRowHeight);
                }
            })
            this.resultsApi.setRowData(this.getDisplayRows());//this.state.openResults[this.state.tabValue].rowData);

            this.resultsApi.onRowHeightChanged();
            // if(this.gridOptions.api)this.resultsGridReady(this.gridOptions)
            this.applyPickerValues();
        }
        // if(this.resultsApi && this.props.textSourceSearched !== prevProps.textSourceSearched){
        //     var contentDisplayed = this.state.contentDisplayed;
        //     if(!contentDisplayed.includes(this.props.textSourceSearched)){
        //         contentDisplayed.push(this.props.textSourceSearched)
        //     }
        //     this.handleToggleContentDisplayed(null,contentDisplayed)
        // }else if(this.props.textSourceSearched !== prevProps.textSourceSearched){
        //     this.setState({contentDisplayed:[this.props.textSourceSearched]},()=>{
        //     })
        // }
        if(this.props.openResults.length===0 && prevProps.openResults.length>0 && !this.props.searchResultsOpen){
            this.setState({openPesukim:[[]]})
        }
        if(this.props.openResults !== this.state.openResults){
            this.setState({openResults: this.props.openResults});
        }
        if(this.props.deletedResults !== prevProps.deletedResults){
            this.setState({openResults:this.props.openResults},()=>{
                this.resultsApi.setRowData(this.getDisplayRows());
            })
        }
        if(this.resultsApi && this.props.sentContentDisplayed){
            var contentDisplayed = this.props.sentContentDisplayed
            // if(!this.state.contentDisplayed.includes("targum"))this.props.extSetBeta()
            this.handleToggleContentDisplayed(null,contentDisplayed)
            this.props.resetContentDisplayed()
        }
        if( this.props.newResults !== prevProps.newResults){
            if(!this.props.keepResultsOpen) this.closeAllOpenPesukim()
            if(this.resultsApi){
                this.resultsApi.setRowData(this.getDisplayRows());
                if(this.resultsApi.gridOptionsWrapper && this.resultsApi.gridOptionsWrapper.gridOptions.api) this.resultsApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(0,'top')
            
                var contentDisplayed = this.state.contentDisplayed;
                if(!contentDisplayed.includes(this.props.textSourceSearched[this.props.tabValue])){
                    contentDisplayed.push(this.props.textSourceSearched[this.props.tabValue])
                }
                if(this.props.sentContentDisplayed){
                    contentDisplayed=this.props.sentContentDisplayed;
                }
                this.handleToggleContentDisplayed(null,contentDisplayed)
                //add in the columns to the search results that are specified by statistics
                // var sPicker = this.state.statisticsPicker;
                // if(this.props.searchParams){//putting in the sorted column into the table-
                //     var sortColumn = this.props.searchParams.sortedColumn;
                //     if(sortColumn && sortColumn<19 && !sPicker.values.includes(sortColumn)){
                //         sPicker.values.push(sortColumn);
                //     }
                // }
                // for(var j=0;j<this.props.changedStatsArray.length;j++){
                //     if(!sPicker.values.includes(this.props.changedStatsArray[j]))sPicker.values.push(this.props.changedStatsArray[j])
                // }
                // this.setState({statisticsPicker:sPicker})
                                   
            }else if(this.props.textSourceSearched[this.props.tabValue] !== prevProps.textSourceSearched[this.props.tabValue]){
                this.setState({contentDisplayed:[this.props.textSourceSearched[this.props.tabValue]]},()=>{
                })
            }
        }
        if(this.props.changedStatsArrayBool !== prevProps.changedStatsArrayBool){
            var sPicker = this.state.statisticsPicker;
            var sortColumn = this.props.sortedColumn;
            if(sortColumn && sortColumn<targumGridPos && !sPicker.values.includes(sortColumn)){
                sPicker.values.push(sortColumn);
            }
            for(var j=0;j<this.props.changedStatsArray.length;j++){
                if(!sPicker.values.includes(this.props.changedStatsArray[j]))sPicker.values.push(this.props.changedStatsArray[j])
            }
            this.setState({statisticsPicker:sPicker})
        }
        if(this.props.tabValue !== this.state.tabValue){
            // this.goToPage(null,1,null,null,true)
            var openPesukim = this.state.openPesukim;
            if(!openPesukim[this.props.tabValue]) 
                openPesukim.push([]);//if that number is not there, add another array to openPesukim
            this.setState({tabValue: this.props.tabValue, openPesukim});
        }
        if(this.state.frameworkComponents.customTooltip !== prevState.frameworkComponents.customTooltip){//todo: see if can take this out b/c not using this
            var updated = this.state.frameworkComponents
            this.setState({frameworkComponents:updated})
        }
        if(this.props.activeLanguage && this.props.activeLanguage.code !== this.state.activeLanguage){
            this.setState({activeLanguage: this.props.activeLanguage.code},
            //      ()=>{this.setState({statisticsPicker: new PickerModel()},
            // ()=>{this.setState({statisticsPicker: this.getPickerModel()},
            // ()=>{properties.setStatisticsPicker(this.state.statisticsPicker)})})}
            )
        }

        if (this.props.isInitializedPasukFetched) { //only do this when direct URL called to load a specific pasuk
            var btnElements = document.getElementsByClassName("search-results-button-cell-text");
            if (btnElements && btnElements.length == 1) { //there should only be one element since only one pasuk would be retrieved
                btnElements[0].click();
                this.props.extSetInitializedPasukFetched();
            }
        }

  }
  calculateTotalAppearances(){
      var totalAppearances=0
      if(this.props.rowDataForTotalTooltip && this.props.rowDataForTotalTooltip[this.props.tabValue] && this.props.rowDataForTotalTooltip[this.props.tabValue].seferMin){
        var seferMin = this.props.rowDataForTotalTooltip[this.props.tabValue].seferMin
        for(var min in seferMin){
            totalAppearances+=seferMin[min]
        }
      }
      return totalAppearances
  }

  sortTable(n) {
    var table, rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    table = document.getElementById("totalPesukimTooltip");
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      /* Loop through all table rows (except the
      first, which contains table headers): */
      for (i = 0; i < (rows.length-1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        /* Get the two elements you want to compare,
        one from current row and one from the next: */
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        /* Check if the two rows should switch place,
        based on the direction, asc or desc: */
        var rightArray = null
        if(this.state.seferArray.findIndex(e=>e==x.innerHTML)>-1 && this.state.seferArray.findIndex(e=>e==y.innerHTML)>-1) rightArray=this.state.seferArray
        else if(this.state.tanachArray.findIndex(e=>e==x.innerHTML)>-1 && this.state.tanachArray.findIndex(e=>e==y.innerHTML)>-1) rightArray=this.state.tanachArray
        if (dir == "asc") {
            if(n>0){
                if (parseInt(x.innerHTML) > parseInt(y.innerHTML)) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }else{
                if (rightArray && rightArray.findIndex(e=>e==x.innerHTML) > rightArray.findIndex(e=>e==y.innerHTML)) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }
        }else if (dir == "desc") {
            if(n>0){
                if (parseInt(x.innerHTML) < parseInt(y.innerHTML)) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }else{
                if (rightArray && rightArray.findIndex(e=>e==x.innerHTML) < rightArray.findIndex(e=>e==y.innerHTML)) {
                    // If so, mark as a switch and break the loop:
                    shouldSwitch = true;
                    break;
                }
            }        
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark that a switch has been done: */
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount ++;
      } else {
        /* If no switching has been done AND the direction is "asc",
        set the direction to "desc" and run the while loop again. */
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }
  setTootlipOpenClose(){
    this.setState({tooltipOpen:!this.state.tooltipOpen},()=>{
        var divElement = document.getElementsByClassName("totalPesukimTooltip")[0];
        if(divElement && this.state.tooltipOpen){
            divElement.scroll({
                top: 0,//scroll to the bottom of the element
                // behavior: 'smooth' //auto, smooth, initial, inherit
            });
        }
    })
  }
  
  async onBtnExport(){
    if(this.resultsApi){
        var lastSelectedResults = this.resultsApi.getSelectedRows();
        this.setState({lastResultsPerPage:this.state.resultsPerPage,lastSelectedResults,lastOpenPage:this.state.loadingPage},async()=>{
            await this.goToPage(null, 0, "showAll", false, true, true)
        })
    }
  };


  render() {
    const { classes } = this.props;

    var isHebrew = false;
    if(this.props.activeLanguage && this.props.activeLanguage.code === 'he'){
      isHebrew = true;
    }

    var dummy=1;
    const {item} = this.state;
    const {rowData, isLoading} = this.state;
    const word = this.props.translate("word");
    const loadingText = this.props.translate("loading");
    const increaseFont = this.props.translate("increaseFont.caption");
    const decreaseFont = this.props.translate("decreaseFont.caption");
    const keepResultsOpen = this.props.translate("keepResultsOpen.caption");
    const searchWithinResults = this.props.translate("searchWithinResults.caption");
    const saveSearchResults = this.props.translate("saveSearchResults.caption");
    const downloadSearchResults = this.props.translate("downloadSearchResults");
    const loadSearchResults = this.props.translate("loadSearchResults.caption");
    const deleteSelectedResults = this.props.translate("deleteSelectedResults");
    const selectAll = this.props.translate("selectAll");
    const clearAll = this.props.translate("clearAll");
    const totalPesukim = this.props.translate("totalPesukim");
    const pesukim = this.props.translate("capitalPesukim");
    const place = this.props.translate("place");
    const totalAppearances = this.props.translate("appearances");
    const resultsPerPage = this.props.translate("resultsPerPage");
    const showAll = this.props.translate("showAll");
    const resultStatistics = this.props.translate("resultStatistics");
    const showNone = this.props.translate("showNone");
    const showColumns = this.props.translate("showColumns");
    const showRows = this.props.translate("showRows");
    const showDrilldown = this.props.translate("showDrilldown");
    const showHover = this.props.translate("showHover");
    const firstPage = this.props.translate("firstPage");
    const lastPage = this.props.translate("lastPage");
    const prevPage = this.props.translate("prevPage");
    const nextPage = this.props.translate("nextPage");
    const hoverPasukLabel = this.props.translate("hoverPasuk");
    const hoverWordLabel = this.props.translate("hoverWord");
    const hoverSelectTooltipTitle = this.props.translate("hoverSelectTooltipTitle");
    const sortByColumn = this.props.translate("sortByColumn")
    const wordTypes = this.props.translate("wordTypes");
    const nekudos = this.props.translate("nekudos");
    const tenuos = this.props.translate("tenuos");
    const analysis = this.props.translate("analysis");
    const letterAppearance = this.props.translate("letterAppearance");
    const unique = this.props.translate("unique");
    const taamSingular = this.props.translate("taam");
    const changes = this.props.translate("changes");
    const byAppearance = this.props.translate("appearances");
    const root = this.props.translate("root");
    const katan = this.props.translate("gKatan");
    const atbashKatan = this.props.translate("aKatan");
    const numLettersPerWord = this.props.translate("lettersPerWord");
    const close = this.props.translate("close");
    const copyLink = this.props.translate("copyLink");


    var directionStyling = {direction: 'ltr', textAlign: 'left'}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }

    var totalPesukimNum = '...';
    var totalAppearancesNum = '...'
    var ksuvimAppearances = '...';
    var ksuvimPesukim = '...'
    var neviimPesukim = '...';
    var neviimAppearances = '...'
    var torahPesukim = '...';
    var torahAppearances = '...'

    if(!!this.state.openResults[this.state.tabValue] && this.state.openResults[this.state.tabValue].rowData){
        if(!!this.state.openResults[this.state.tabValue].rowData[0]){
            totalPesukimNum = this.state.openResults[this.state.tabValue].rowData[0].totalResults;
            totalAppearancesNum = this.calculateTotalAppearances();        
            ksuvimPesukim = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].ksuvimPesukim:0;
            ksuvimAppearances = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].ksuvimAppearances:0;
            neviimPesukim = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].neviimPesukim:0;
            neviimAppearances = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].neviimAppearances:0;
            torahPesukim = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].torahPesukim:0;
            torahAppearances = this.props.rowDataForTotalTooltip[this.props.tabValue]?this.props.rowDataForTotalTooltip[this.props.tabValue].torahAppearances:0;
        } else {
            totalPesukimNum = 0;
            totalAppearancesNum = 0
        }
    }
    var noSeferMin = (this.props.rowDataForTotalTooltip && this.props.rowDataForTotalTooltip[this.props.tabValue] && ((!this.props.rowDataForTotalTooltip[this.props.tabValue].seferMin) || Object.keys(this.props.rowDataForTotalTooltip[this.props.tabValue].seferMin).length === 0))
    const resultsTableHeight = `${parseInt(this.props.searchResultsHeight.substring(0, this.props.searchResultsHeight.length - 2))-51}px`
    //const noRows = '<span style="font-size:28px; color: dodgerblue;"><i>' + this.props.translate("asdf") + '</i></span>';

    const posTop = this.props.posTop;
    const fakeThis = this;

    var increaseFontButton = <Button aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }}>
             <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="15" height="14" />
         </Button>
    var decreaseFontButton = <Button aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }}>
             <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="15" height="14" />
         </Button>

    if(this.state.resultsFontSize === 24){
        increaseFontButton = <Button className='disabled-font-icon' aria-label={increaseFont} title={increaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.increaseFontSize() }} disabled>
             <img src={require("./images/icons8-increase-font-52.png")} alt={increaseFont} width="15" height="14" />
         </Button>
    }
    if(this.state.resultsFontSize === 14){
        decreaseFontButton = <Button className='disabled-font-icon' aria-label={decreaseFont} title={decreaseFont} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.decreaseFontSize() }} disabled>
             <img src={require("./images/icons8-decrease-font-52.png")} alt={decreaseFont} width="15" height="14" />
         </Button>
    }

//    var resultsGrid =             <AgGridReact
//                                      className='search-results-table'
//                                      onGridReady={this.resultsGridReady}
//                                      columnDefs={this.state.columnDefs}
//                                      rowData={this.props.rowData}
//                                      enableFilter={true}
//                                      enableColResize={true}
//                                      frameworkComponents={this.state.frameworkComponents}
//                                      overlayNoRowsTemplate={'<span style="font-size:28px; color: dodgerblue;"><i>אין תוצאות</i></span>'}
//                                      enableRtl="true">
//                                  </AgGridReact>
    var keepResultsOpenCheckbox = <div id='keep-results-open' onClick={() => {this.props.extToggleKeepResultsOpen()}} >
             <Checkbox checked={this.props.keepResultsOpen} style={{display: 'inline-block', margin: '0 2px', zIndex: '2', position: 'relative'}} />
             <label htmlFor="save-this-search-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', fontSize: '13px', display: 'inline'}} >
                 {keepResultsOpen}
             </label>
         </div>

    var searchWithinTheseResultsCheckbox = <div id='keep-results-open' onClick={() => {this.props.extToggleSearchWithinResults()}} >
             <Checkbox checked={this.props.searchWithinResults} style={{display: 'inline-block', margin: '0 2px', zIndex: '2', position: 'relative'}} />
             <label htmlFor="save-this-search-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', fontSize: '13px', display: 'inline'}} >
                 {searchWithinResults}
             </label>
         </div>

    var closeAllOpenPesukimButton =this.state.openPesukim[this.state.tabValue]?this.state.openPesukim[this.state.tabValue].length > 0? <div><Button variant="contained" type="submit" className="closeOpenPesukimButton" onClick={this.closeAllOpenPesukim}><Translate id="closeAll" /></Button><div style={{direction:isHebrew?"rtl":"ltr"}} className="redItalic">{this.props.translate("wordStatWarning")}!!!</div></div>:<div></div>:<div></div> 

    var resultsApi = null;
    if(this.resultsApi && this.resultsApi.selectionController && this.state.openResults.length>0){//makes sure that there is a valid resultsApi before continuing
        resultsApi = this.resultsApi;
    }

    var focusedCellRowIndex = null;
    if(this.resultsApi && this.resultsApi.getFocusedCell()){
        var focusedCellRowIndex = this.resultsApi.getFocusedCell().rowIndex;
    }
    var focusedCellRowLocation = null;
    var focusedCellRowPasukId = null;
    if((typeof(focusedCellRowIndex) === 'number') && this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
        focusedCellRowLocation = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.location;
        focusedCellRowPasukId = this.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.pasukId;
    }

    const hasRowData = !!fakeThis.state.openResults[fakeThis.state.tabValue]
                            && !!fakeThis.state.openResults[fakeThis.state.tabValue].rowData
                            && fakeThis.state.openResults[fakeThis.state.tabValue].rowData.length > 0;


//    code below no longer needed. latest search result now saved with previous results in openResults.
//
//    var lastSearchResultsTab = <></>
//    var lastSearchResultsTabContent = <></>
//    if(this.props.showLastSearchResults){
//        lastSearchResultsTab = <Tab className='search-results-tab' label={
//                                     <table>
//                                         <tbody>
//                                             <tr>
//                                                 <td width='80px' style={{maxWidth: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
//                                                     {fakeThis.props.lastSearchTerms}
//                                                 </td>
//                                                 <td>
//                                                     <Button className='close-tab-button' onClick={() => {fakeThis.closeResultsTab(fakeThis.props.openResults.length)}} >
//                                                         <CloseIcon style={{fontSize: '15px', color: 'white'}} className='close-tab-button-icon'/>
//                                                     </Button>
//                                                 </td>
//                                             </tr>
//                                         </tbody>
//                                     </table>
//                                 }
//                                 {...a11yProps(0)} style={{maxHeight: '30px', minHeight: '0px'}} height='30px' wrapped>
//                              </Tab>
//        lastSearchResultsTabContent = <TabContainer className="">
//                                          <LoadingOverlay
//                                                          active={this.props.searchIsLoading}
//                                                          spinner={<FadeLoader color={'#E0E0E0'} top={'80px'} />}
//                                                            styles={{
//                                                              wrapper: (base) => ({
//                                                                width: '1400px',
//                                                                height: `${parseInt(fakeThis.props.searchResultsHeight.substring(0, fakeThis.props.searchResultsHeight.length - 2))-36}px`,
//                                                              }),
//                                                              overlay: (base) => ({
//                                                                ...base,
//                                                                width: '1400px',
//                                                                height: `${parseInt(fakeThis.props.searchResultsHeight.substring(0, fakeThis.props.searchResultsHeight.length - 2))-36}px`,
//                                                                position: 'absolute',
//                                                                left: '5px',
//                                                                margin: 'auto',
//                                                                top: {posTop},
//                                                              })
//                                                            }}
//                                                          text={loadingText}
//                                                      >
//                                                  <div
//                                                    className="ag-theme-balham search-results-table"
//                                                    style={{
//                                                      height: `${parseInt(fakeThis.props.searchResultsHeight.substring(0, fakeThis.props.searchResultsHeight.length - 2))-36}px`,
//                                                      width: '1400px'}}
//                                                      >
//
//                                                      <AgGridReact
//                                                          className='search-results-table'
//                                                          onGridReady={this.resultsGridReady}
//                                                          columnDefs={this.state.columnDefs}
//                                                          rowData={this.props.rowData}
//                                                          enableFilter={true}
//                                                          enableColResize={true}
//                                                          frameworkComponents={this.state.frameworkComponents}
//                                                          overlayNoRowsTemplate={'<span style="font-size:28px; color: dodgerblue;"><i>אין תוצאות</i></span>'}
//                                                          enableRtl="true">
//                                                      </AgGridReact>
//                                                  </div>
//
//
//                                  </LoadingOverlay>
//                                      </TabContainer>
//    }

//orig side widths = 9.2%
// var targum=false;
// if(this.state.openResults[this.state.tabValue] && this.state.openResults[this.state.tabValue].rowData) {
//     for(let i=0;i<this.state.openResults[this.state.tabValue].rowData.length; i++){
//         if(this.state.openResults[this.state.tabValue].rowData[i].targumText){
//             targum = true
//             break;}
//     }
// }
// var hasTargum = (targum)
var contentDisplayed = this.state.contentDisplayed;
// // if(!hasTargum && contentDisplayed.includes('targum')){
// //   var targumIndex = contentDisplayed.indexOf('targum');
// //   contentDisplayed.splice(targumIndex, 1);
// // }
// var targumIsOpen = contentDisplayed.includes('targum');
// var tanachIsOpen = contentDisplayed.includes('tanach');
// var isMultiPanel = contentDisplayed.length > 1;

// var hoverPasukHighlightColor = 'powderblue';
// var hoverWordHighlightColor = 'deepskyblue';


// // if(this.state.textDisplayType === "table"){
//     //not using AgGrid here to make it easier to set up multi-line cells and word-to-word hovering
//     var pasukRows = !this.state ? <></> : <>
//       {this.state.rowData.map(function(currPasuk, key){

//         var noSpaceLocation = currPasuk.location;
//         while(noSpaceLocation.includes(" ")){
//           noSpaceLocation = noSpaceLocation.replace(" ", "_");
//         }
//         var noSpaceText = currPasuk.text;
//         while(noSpaceText.includes(" ")){
//           noSpaceText = noSpaceText.replace(" ", "_");
//         }
//         var makorArray = currPasuk.location.split(':');
//         var perekPasuk = `${makorArray[1]}:${makorArray[2]}`
//         var seferPerekPasuk = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`


//         var text = currPasuk.text;
//         var words = text.split(" ");
//         var torahMapObj = targumIsOpen ? currPasuk.torahPhraseMapObj : currPasuk.torahWordMapObj;
//         var wordSpans = words.map((word, key) => {
//           var matchIds = [];
//           if(torahMapObj){
//             matchIds = torahMapObj[key+1];
//           }
//           if(!matchIds){
//             matchIds = [];
//           }
//           if(!hasTargum){
//             matchIds = [key];
//           }
//           var isHoveredMatch = false;
//           matchIds.map((matchId, key) => {
//             if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(matchId)){// && fakeThis.state.hoveredLocation === currPasuk.location){
//               isHoveredMatch = true;
//             }
//           })
//           //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
//           return  <span
//                     matchIds={matchIds}
//                     onMouseEnter={(e) => {fakeThis.setHoveredMatchIds(matchIds)}}
//                     onMouseLeave={(e) => {fakeThis.setHoveredMatchIds(null)}}
//                     style={{
//                       backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
//                     }}
//                     className='text-cell-pasuk-span'
//                   >
//                     {word + " "}
//                   </span>
//         })

//         var targumText = currPasuk.targum;
//         var words = targumText.split(" ");
//         var targumMapObj = currPasuk.targumPhraseMapObj;
//         var targumWordSpans = words.map((word, key) => {
//           var matchIds = [];
//           if(targumMapObj){
//             matchIds = targumMapObj[key+1];
//           }
//           if(!matchIds){
//             matchIds = [];
//           }
//           var isHoveredMatch = false;
//           matchIds.map((matchId, key) => {
//             if(fakeThis.state.hoveredMatchIds && fakeThis.state.hoveredMatchIds.includes(matchId)){
//               isHoveredMatch = true;
//             }
//           })
//          //IF TARGUM VISIBLE - HIGHLIGHT GROUPS, OTHERWISE WORD BY WORD
//           return  <span
//                     matchIds={matchIds}
//                     onMouseEnter={(e) => {fakeThis.setHoveredMatchIds(matchIds)}}
//                     onMouseLeave={(e) => {fakeThis.setHoveredMatchIds(null)}}
//                     style={{
//                       backgroundColor: `${isHoveredMatch && isMultiPanel ? hoverWordHighlightColor : 'unset'}`
//                     }}
//                     className='text-cell-pasuk-span'
//                   >
//                     {word + " "}
//                   </span>
//         })


//         var tanachText = <span
//           onMouseEnter={(e) => {fakeThis.setHoveredLocation(currPasuk.location)}}
//           onMouseLeave={(e) => {fakeThis.setHoveredLocation(null)}}
//           style={{
//             backgroundColor: `${fakeThis.state.hoveredLocation === currPasuk.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
//           }}
//         >{wordSpans}</span>;
//         var targumText = <span
//           onMouseEnter={(e) => {fakeThis.setHoveredLocation(currPasuk.location)}}
//           onMouseLeave={(e) => {fakeThis.setHoveredLocation(null)}}
//           style={{
//             backgroundColor: `${fakeThis.state.hoveredLocation === currPasuk.location && isMultiPanel ? hoverPasukHighlightColor : 'unset'}`
//           }}
//         >{targumWordSpans}</span>;
//         if(currPasuk.location === fakeThis.state.location){
//           tanachText = <b>{tanachText}</b>;
//           targumText = <b>{targumText}</b>;
//         }


//         var pasukRow =  <tr key={key} pasukId={currPasuk.pasukId} class='text-cell-pasuk-row' noSpaceText={noSpaceText} noSpaceLocation={noSpaceLocation} >
//                           <td class="tanach-viewer-text-table-makor" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}} location={noSpaceLocation} >
//                             {currPasuk.willShowAliyaName ? `[${currPasuk.aliya}] ` : ``}
//                             <span
//                               style={{
//                                 color : `${currPasuk.isPasukJump ? 'blue' : 'black'}`
//                               }}
//                             >{currPasuk.willShowSeferName ? seferPerekPasuk : perekPasuk}</span>
//                           </td>
//                           {tanachIsOpen && 
//                             <td class="parsha-viewer-text-table-text" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}} >{tanachText}</td>
//                           }
//                           {hasTargum && targumIsOpen && 
//                             <td class="parsha-viewer-text-table-text" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}} >{targumText}</td>
//                           }
//                         </tr>;
//           return pasukRow
//       })}
//     </>
//     var firstNoSpaceLocation;
//     var firstNoSpaceText;
//     var firstPasukId;
//     if(this.state.rowData[0]){
//       var firstPasuk = this.state.rowData[0];
//       firstNoSpaceLocation = firstPasuk.location;
//       while(firstNoSpaceLocation.includes(" ")){
//         firstNoSpaceLocation = firstNoSpaceLocation.replace(" ", "_");
//       }
//       firstNoSpaceText = firstPasuk.text;
//       while(firstNoSpaceText.includes(" ")){
//         firstNoSpaceText = firstNoSpaceText.replace(" ", "_");
//       }
//       firstPasukId = firstPasuk.pasukId
//     }

//     var cellContents = <table class="tanach-viewer-text-table"
//       style={{
//         fontSize: `${(this.state.fontSizeLevel * 2) + 12}px`
//       }}
//     > 
//       <thead pasukId={firstPasukId} class='text-cell-pasuk-row' noSpaceText={firstNoSpaceText} noSpaceLocation={firstNoSpaceLocation} >
//         <tr class='text-cell-pasuk-row'>
//           <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}>פסוק</th>
//           {tanachIsOpen && 
//             <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}>טקסט</th>
//           }
//           {hasTargum && targumIsOpen && 
//             <th class="tanach-viewer-text-table-header" onContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}>תרגום</th>
//           }
//         </tr>
//       </thead>
//       <tbody>
//         {pasukRows}
//       </tbody>
//     </table>
//     var textDisplay =               <MenuProvider className="context-menu" id='tanach-viewer-table-context-menu' > 
//                                   {cellContents}
//                                         {/* <div
//                                          className="ag-theme-balham context-results-table"
//                                          style={{
//                                            height: `${(this.state.rowData.length * (this.state.gridRowHeight+2) - 8)}px`,
//                                            width: `${this.state.widthNum - 45}px`,
//                                            resize: "both",
//                                            overflow: 'auto',
//                                          }}
//                                         >
//                                            <AgGridReact
//                                                className='context-results-table'
//                                                onGridReady={this.resultsGridReady}
//                                                columnDefs={this.state.columnDefs}
//                                                rowData={this.state.rowData}
//                                                enableFilter={true}
//                                                enableColResize={true}
//                                                rowMultiSelectWithClick={false}
//                                                onCellContextMenu={(e) => {fakeThis.onCellContextMenu(e)}}
//                                                frameworkComponents={this.state.frameworkComponents}
//                                                overlayNoRowsTemplate={'<span style="font-size:28px; color: dodgerblue;"><i>אין תוצאות</i></span>'}
//                                                enableRtl="true">
//                                            </AgGridReact>
//                                        </div> */}
//                                       </MenuProvider>








    var lastResultsPageNum = Math.ceil(totalPesukimNum/this.state.resultsPerPage);
    var pageMenuItems = [];
    for(let i = 0; i < lastResultsPageNum; i++){
        pageMenuItems.push(
            <MenuItem value={i+1}>{i+1}</MenuItem>
        )
    }
    if(this.state.resultsPerPage === 'showAll'){
        pageMenuItems.push(
            <MenuItem value={1}>{1}</MenuItem>
        )
    }

    var currentPage = 0;
    if(this.state.openResults[this.props.tabValue]){
        currentPage = this.state.openResults[this.props.tabValue].currentPage;
    }

    if(lastResultsPageNum === 0){ //using this to handle when no results are returned
        lastResultsPageNum = 1;
        currentPage = 1;
        pageMenuItems.push(
            <MenuItem value={1}>{1}</MenuItem>
        )
    }
    const isLastPage = currentPage === lastResultsPageNum;
    const isShowAll = this.state.resultsPerPage === "showAll";

//    const showHover = this.props.translate("showHover");
//    const firstPage = this.props.translate("firstPage");
//    const lastPage = this.props.translate("lastPage");
//    const prevPage = this.props.translate("prevPage");
//    const nextPage = this.props.translate("nextPage");

    const parshaName = this.props.translate("parshaName");
    const sidraName = this.props.translate("sidraName");
    const otherKriosName = this.props.translate("otherKriosName");
    const words = this.props.translate("words");
    const letters = this.props.translate("letters");
    const uniqueLetters = this.props.translate("byUniqueLetters");
    const uniqueLettersCS = this.props.translate("byUniqueLettersCS");
    const tenua = this.props.translate("tenuos");
    const taam = this.props.translate("taamim");
    const gematria = this.props.translate("gematria");
    const atbash = this.props.translate("atbash");
    const letterCoverage = this.props.translate("letterCoverage");
    const perWord = this.props.translate("perWord");
    const perLetter = this.props.translate("perLetter");
    const perTenua = this.props.translate("perTenua");
    const targumText = this.props.translate("targum");
    const text = this.props.translate("text");
    const lettersPerWord = letters + " " + perWord;
    const tenuosPerWord = tenua + " " + perWord;
    const tenuosPerLetter = tenua + " " + perLetter;
    const taamimPerWord = taam + " " + perWord;
    const taamimPerTenua = taam + " " + perTenua;
    const gematriaPerWord = gematria + " " + perWord;
    const gematriaPerLetter = gematria + " " + perLetter;
    const atbashPerWord = atbash + " " + perWord;
    const atbashPerLetter = atbash + " " + perLetter;
    const fullScreen = window.innerWidth>1600

    var statisticsPicker = new PickerModel();
    statisticsPicker.list = [
        {id: 1, name: parshaName, colName: "parshaName", icon: ""},
        {id: 2, name: sidraName, colName: "sidraName", icon: ""},
        {id: 3, name: otherKriosName, colName: "otherKriosName", icon: ""},
        {id: 4, name: words, colName: "teiva", icon: ""},
        {id: 5, name: letters, colName: "letter", icon: ""},
        {id: 6, name: lettersPerWord, colName: "lettersPerWord", icon: ""},
        {id: 7, name: uniqueLetters, colName: "uniqueLetter", icon: ""},
        {id: 8, name: uniqueLettersCS, colName: "uniqueLetterCS", icon: ""},
        {id: 9, name: letterCoverage, colName: "letterCoverage", icon: ""},
        {id: 10, name: tenua, colName: "tenua", icon: ""},
        {id: 11, name: tenuosPerWord, colName: "tenuosPerWord", icon: ""},
        {id: 12, name: tenuosPerLetter, colName: "tenuosPerLetter", icon: ""},
        {id: 13, name: taam, colName: "taam", icon: ""},
        {id: 14, name: taamimPerWord, colName: "taamimPerWord", icon: ""},
        {id: 15, name: taamimPerTenua, colName: "taamimPerTenua", icon: ""},
        {id: 16, name: gematria, colName: "standard", icon: ""},
        {id: 17, name: gematriaPerWord, colName: "gematriaPerWord", icon: ""},
        {id: 18, name: gematriaPerLetter, colName: "gematriaPerLetter", icon: ""},
        {id: 19, name: atbash, colName: "atbash", icon: ""},
        {id: 20, name: atbashPerWord, colName: "atbashPerWord", icon: ""},
        {id: 21, name: atbashPerLetter, colName: "atbashPerLetter", icon: ""},
        {id: targumGridPos, name: targumText, colName: "targumText", icon: ""},
        {id: textGridPos, name: text, colName: "text", icon: ""}

    ];
    statisticsPicker.values = this.state.statisticsPicker.values;

/*  todo - place this component as prop in StatPicker2D around line 1224
//toggle: enable hover, stats appear left/right of pasuk text
//general buttons: select all, deselect all
//buttons - align selection ids to defs/code class on server
        return [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"
            {headerName: "language", field: "language", hide: true, cellRenderer: function(params) {return language;} },
            {headerName: "#", autoHeight: true, field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center search-results-row", filter: 'agNumberColumnFilter', pinned: "right" },
            {headerName: "פסוק", field: "location", width: this.getPasukColumWidth(), cellClass: "row-header search-results-row", pinned: "right" },
            {headerName: "תיבות", field: "teiva", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "אותיות", field: "letter", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "אותיות לתיבה", field: "lettersPerWord", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.letter/params.data.teiva).toFixed(2);} },
            {headerName: "אותיות מיוחדות", field: "uniqueLetter", width: 65, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "בהבחנת סופיות", field: "uniqueLetterCS", width: 67, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "הופעת אותיות", field: "letterCoverage", width: 240, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return util.showLetterCoverage(params.data.text);} },
            {headerName: "תנועות", field: "tenua", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "תנועות לתיבה", field: "tenuosPerWord", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenua/params.data.teiva).toFixed(2);} },
            {headerName: "תנועות לאות", field: "tenuosPerLetter", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenua/params.data.letter).toFixed(2);} },
            {headerName: "טעמים", field: "taam", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "טעמים לתיבה", field: "taamimPerWord", width: 60, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taam/params.data.teiva).toFixed(2);} },
            {headerName: "טעמים לתנועה", field: "taamimPerTenua", width: 65, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taam/params.data.tenua).toFixed(2);} },
            {headerName: "גמטריא", field: "standard", width: 65, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {headerName: "גמטריא לתיבה", field: "gematriaPerWord", width: 67, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.standard/params.data.teiva).toFixed(1);} },
            {headerName: "גמטריא לאות", field: "gematriaPerLetter", width: 67, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.standard/params.data.letter).toFixed(1);} },
            {headerName: "אתבש", field: "atbash", width: 65, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row", hide: false },
            {headerName: "אתבש לתיבה", field: "atbashPerWord", width: 67, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbash/params.data.teiva).toFixed(1);} },
            {headerName: "אתבש לאות", field: "atbashPerLetter", width: 67, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbash/params.data.letter).toFixed(1);} },
            {headerName: "טקסט", field: "text", width: 1500, cellClass: "row-thin search-results-row", cellRenderer: function(params) {return util.decorateText(params.data.text, params.data.matchIndex);}}
            //this width can even handle אסתר ח:ט which has 235 chars including spaces
        ];
*/

    return (<MuiThemeProvider theme={theme}><span style={{position: 'relative'}} className='search-results'>

    <table onMouseMove={this.mouseHoverInAGGrid} onMouseDown={()=>{this.clicked=true;}}
            onMouseUp={()=>{this.clicked=false}}  style={{direction: 'rtl', zIndex:'0', position: 'relative', left: '30px'}} helpTopic="searchResults" >
        <tbody style={{verticalAlign:'top'}}>
            <td style={{width: '70px', lineHeight: '0px', direction: 'ltr'}} valign="top" height={`${resultsTableHeight}`} >
                                <div style={{height: '28px', direction: 'rtl'}}>
                                    <Button size="small" color="primary" className="dialog-resize-button" onClick={() => {this.props.handleCloseSearchResultsDialogOpen()}} title={close}>
                                      <CloseIcon style={{fontSize: '18px'}} />
                                    </Button>
                                    <Button className="dialog-resize-button" onClick={() => {this.handleCopyLink()}} title={copyLink}>
                                      <LinkIcon style={{fontSize: '18px'}} />
                                    </Button>
                                  </div>
                                <div style={{width: '70px', direction: 'ltr'}}>

                                    {increaseFontButton}
                                    {decreaseFontButton}
                                    <hr className='search-results-control-divider'/>
                                   <input
                                         accept=".json"
                                         id="search-results-upload"
                                         multiple
                                         type="file"
                                         style={{display: 'none'}}
                                         onChange={(e) => {this.handleLoadSearchResults(e)}}
                                   />
                                   <Button aria-label={loadSearchResults} title={loadSearchResults} style={{minWidth: 0, padding: '3px', lineHeight: '0'}} >
                                        <label htmlFor="search-results-upload" style={{margin: '0'}}  width="15" height="14">
                                            <img src={require("./images/icons8-opened-folder-500.png")} alt={loadSearchResults} width="15" height="14" />
                                        </label>
                                   </Button>
                                    <Button aria-label="saveResults" title={saveSearchResults} style={{minWidth: 0, padding: '3px'}} onClick={(e) => { this.handleOpenSaveResultsPopover(e) }}>
                                        <img src={require("./images/icons8-save-384.png")} alt={saveSearchResults} width="15" height="14" />
                                    </Button>
                                    <Button title={downloadSearchResults}  style={{minWidth: 0, maxWidth:'20px', maxHeight:'20px', padding: '3px'}}  onClick={fakeThis.onBtnExport}>
                                    <img src={require("./images/download.png")} alt={downloadSearchResults} width="15" height="14" />
                                    </Button>
                                    <Popover
                                        id={'save-results-popover'}
                                        open={this.state.saveResultsPopoverOpen}
                                        anchorEl={this.state.saveResultsPopoverAnchorEl}
                                        onClose={this.handleCloseSaveResultsPopover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <a href={(URL.createObjectURL(this.state.searchResultsBlob)) || ''} target="_blank" download='BHS_Search_Results.json' className='save-results-link'>
                                            <Button style={{maxWidth: '160px', minHeight: '50px'}}>
                                                <Typography>
                                                    {this.props.translate("rightClickToSaveResults.caption")}
                                                </Typography>
                                            </Button>
                                        </a>
                                    </Popover>
                                   <hr className='search-results-control-divider'/>
                                  <Button aria-label={deleteSelectedResults} title={deleteSelectedResults} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.deleteSelectedResults() }}>
                                       <img src={require("./images/icons8-trash-96.png")} alt={deleteSelectedResults} width="15" height="14" />
                                  </Button>
                                  <Button aria-label={clearAll} title={clearAll} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.unselectAllResults() }}>
                                       <img src={require("./images/icons8-unselect-all-500.png")} alt={clearAll} width="15" height="14" />
                                  </Button>
                                  <Button aria-label={selectAll} title={selectAll} style={{minWidth: 0, padding: '3px'}} onClick={() => { this.selectAllResults() }}>
                                       <img src={require("./images/icons8-select-all-500.png")} alt={selectAll} width="15" height="14" />
                                  </Button>
                                  <hr className='search-results-control-divider'/>
                                  {keepResultsOpenCheckbox}
                                  <hr className='search-results-control-divider'/>
                                  {searchWithinTheseResultsCheckbox}
                                  <hr className='search-results-control-divider'/>
                                  {closeAllOpenPesukimButton}
                                </div>
            </td>
            <td height={`${resultsTableHeight}`} style={{maxWidth: 'calc(100vw - 520px)', minWidth: '848px' }}>
                    <AppBar position="static" id='search-results-app-bar' >

                        <div style={{direction: directionStyling.direction}} className={"center"}>
                            <Typography className='result-statistics-select'>
                                <StatPicker2D pickerModel={statisticsPicker} title={resultStatistics} pasukTextPosition={this.state.pasukTextPosition} hoverSelect={this.state.hoverSelect}
                                            extSetValues={this.setPickerValues} extApplyValues={this.applyPickerValues} extHandleStatsPosition={this.shiftPasukTextColumn} extHandleHoverSelect={this.handleHoverSelect}
                                            selectStyle={"General"} extClassName={'statisticsPickerDropdownButton'} displayMultiple={false}
                                            setStatsToCopy={this.setStatsToCopy} setHowToPaste={this.setHowToPaste} 
                                            statsToCopy={this.state.statsToCopy} howToPaste={this.state.howToPaste} />
                                &nbsp;<div title={hoverSelectTooltipTitle} style={{  paddingTop: "2px"}}>
                                    <input type="checkbox" id="hover" name="hover" className={"hoverCheckbox"} onChange={this.changeHoverWithCheckbox} checked={this.state.hoverSelect!=="none"}/>
                                    &nbsp;<label for="hover" >{hoverPasukLabel}</label>
                                </div>
                                &nbsp;<div title={""} style={{  paddingTop: "2px"}}>
                                    <input type="checkbox" id="hoverWords" name="hoverWords" className={"hoverCheckbox"} onChange={this.changeHoverWordsWithCheckbox} checked={this.state.hoverWords!=="none"}/>
                                    &nbsp;<label for="hoverWords" >{hoverWordLabel}</label>
                                </div>
                            </Typography>
                        </div>

                      
                        
                        
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="saved results tabs"
                            style={{direction: 'rtl'}}
                            width='100%'
                            id='results-tabs'

                        >

                            {this.state.openResults.map(function(e, index){
                                return <Tab className='search-results-tab'  label={
                                    <table style={{position: 'relative'}}>
                                        <tbody>
                                            <tr>
                                                <td style={{position: 'relative', top: '1px', right: '5px', paddingLeft: '5px', textAlign: 'right', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                                    {fakeThis.state.openResults[index].terms}
                                                </td>
                                                <td style={{padding: '0', position: 'relative', left: '2.5px', top: '2.5px', }}>
                                                    <Button className='close-tab-button' onClick={() => {fakeThis.closeResultsTab(index)}} >
                                                        <CloseIcon style={{fontSize: '15px'}} className='close-tab-button-icon'/>
                                                    </Button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                } {...a11yProps(index+1)} style={{maxHeight: '20px', minHeight: '0px', padding: '0'}} height='20px' wrapped />
                                })}

                        </Tabs>
                      </AppBar>

                     <div style={{zIndex:'1', position: 'relative'}} >

                         {this.state.openResults.map(function(e, index){
                             if(fakeThis.state.tabValue === index){
                                     return <TabContainer className="">
                                         <LoadingOverlay
                                                         active={fakeThis.props.searchIsLoading || fakeThis.state.pageIsLoading}
                                                         spinner={<FadeLoader color={'#E0E0E0'} top={'80px'} />}
                                                           styles={{
                                                             wrapper: (base) => ({
                                                               width: '1400px',
                                                               maxWidth: 'calc(100vw - 520px)',
                                                               minWidth: '848px',
                                                               height: resultsTableHeight,
                                                             }),
                                                             overlay: (base) => ({
                                                               ...base,
                                                               width: '1400px',
                                                               maxWidth: 'calc(100vw - 520px)',
                                                               minWidth: '848px',
                                                               height: resultsTableHeight,
                                                               position: 'absolute',
                                                               left: '0px',
                                                               margin: 'auto',
                                                               top: {posTop},
                                                             })
                                                           }}
                                                         text={loadingText}
                                                     >
                      <MenuProvider className="context-menu" id='search-results-context-menu'>
                                                 <div
                                                   className="ag-theme-balham search-results-table"
                                                   style={{
                                                     height: resultsTableHeight,
                                                     width: '1400px',
                                                     maxWidth: 'calc(100vw - 520px)',
                                                     minWidth: '848px',
                                                   }}
                                                   >
                                                     <AgGridReact enableCellChangeFlash={true}
                                                         className='search-results-table'
                                                         onGridReady={fakeThis.resultsGridReady}
                                                         onColumnResized={fakeThis.headerHeightSetter}
                                                         defaultColDef={{resizable: true}}//, tooltipField: "location", tooltipComponent: 'customTooltip'}}
                                                         columnDefs={fakeThis.state.columnDefs}
                                                         rowData={fakeThis.state.openResults[index].rowData}
                                                         enableFilter={true}
                                                         enableBrowserTooltips={false}
                                                         enableColResize={true}
                                                         onCellContextMenu={(e) => {fakeThis.handleDoubleRightClick(e)}}
                                                         onCellClicked={(e) => {fakeThis.handleResultsCellClicked(e)}}
                                                         onCellDoubleClicked={(e) => {fakeThis.handleResultsCellDoubleClicked(e)}}
                                                         onCellMouseDown={(e) => {fakeThis.handleResultsCellMouseDown(e)}}
                                                         rowMultiSelectWithClick={true}
                                                         rowSelection='multiple'
                                                         frameworkComponents={fakeThis.state.frameworkComponents}
                                                         tooltipShowDelay={750}
                                                         overlayLoadingTemplate = "loading"
                                                         overlayNoRowsTemplate={fakeThis.state.pageIsLoading?`<div>${loadingText}</div>`:`<div style="color:dodgerblue;margin:20px 150px;direction:${isHebrew?'rtl':'ltr'}"><i><span style="font-size:28px; color: dodgerblue;">${fakeThis.props.translate("noResults")}</span><br/><span style="font-size:16px;">${fakeThis.props.translate("suggestion")}<br/>${fakeThis.props.translate("noResultsDetailsSearchResults")}</span></i></div>`}
                                                         suppressPaginationPanel={true}
                                                         deltaRowDataMode= {true}
                                                         getRowNodeId= {function (data) {
                                                             return data.pasukId;
                                                         }             }                                        
                                                         suppressScrollOnNewData={true}
                                                         enableRtl="true"
                                                         isFullWidthCell={(node)=>{return node.data.isWordRow}}
                                                         fullWidthCellRenderer={params=>{//fakeThis.state.fullWidthCellRenderer}
                                                            var cellContents = document.createElement('div');
                                                            var wordsTable = `<table class="word-stat-row word-statistic-table">`;
                                                            var headerRow = `<thead><tr class="">`+
                                                                            `<th class="header-row-word-stats cornerOfTable">#</th>` +
                                                                            `<th class="header-row-word-stats cornerOfTable">${params.data.body.wordTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.analysis}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.stam}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.ivri}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.inflectionTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.byAppearance}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.root}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.byAppearance}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.targumTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.taamSingular}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.wordTypesTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.misc}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.letterAppearance}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.unique}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.numLettersPerWord}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.nekudosTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.tenuosTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.gematriaTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.katanTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.atbashTitle}</th>` +
                                                                            `<th class="header-row-word-stats">${params.data.body.atbashKatanTitle}</th>` +
                                                                            '</tr></thead><tbody>';
                                                            wordsTable += headerRow;
                                                            for(let i=0; i<params.data.body.length; i++){
                                                                var currWord = params.data.body[i];
                                                                var wordRow =   `<tr wordId=${currWord.pasukSequenceNum} class="">`+
                                                                                `<td class="word-stat-id " location=${currWord.pasukSequenceNum} >${currWord.pasukSequenceNum}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.word}</td>` +
                                                                                `<td class="word-stat-text "><b class='theWord'>${currWord.colored}</b></td>` +
                                                                                `<td class="word-stat-text stamAshkenazCLM">${(currWord.onlyLetters)}</td>` +
                                                                                `<td class="word-stat-text hebrewPaleoGlezer">${currWord.onlyLetters}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.ln}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.appearancesLN}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.realShoresh}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.realAppearancesShoresh}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.fullTargum}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.fullTaam /*?currWord.taam:""} ${currWord.taamType?"&nbsp;<span class='smallerFont'>("+currWord.taamType+")</span>":""} ${currWord.mukafPhrase?"&nbsp;<b>"+currWord.mukafPhrase+"</b>":""*/}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.fullWordType}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.misc}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.sortedLetters}</td>` +
                                                                                `<td class="word-stat-text ">${String.prototype.concat(...new Set(currWord.sortedLetters))}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.letters}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.nekudos}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.tenuos}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.gematria}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.katan}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.atbash}</td>` +
                                                                                `<td class="word-stat-text ">${currWord.atbashKatan}</td>` +
                                                                                '</tr>';
                                                
                                                                wordsTable += wordRow;
                                                            }
                                                            wordsTable += '</tbody></table>';
                                                            cellContents.innerHTML = wordsTable;
                                                            cellContents.className = `word-stat-full-width-cell parsha-id-${params.data.pasukId}`;
                                                            cellContents.style.fontSize = `${fakeThis.state.resultsFontSize}px`;
                                                            cellContents.addEventListener("mousedown", function(e) {
                                                                fakeThis.handleDoubleRightClick(e)
                                                              });
                                                            return cellContents;
                                                        }}
                                                        >
                                                     </AgGridReact>
                                                 </div>

                      </MenuProvider>
                      <ContextMenu menuId='search-results-context-menu' menuType='searchResults' className='special-context-menu' resultsApi={resultsApi}
                        textToCopy={fakeThis.state.textToCopy} extCopySelectedToSearchbar={fakeThis.props.extCopySelectedToSearchbar}
                        extSearchWithSelected={fakeThis.props.extSearchWithSelected} noContextMenu={fakeThis.state.noContextMenu}
                        extToggleUseKinuim={fakeThis.toggleUseKinuim} useKinuim={fakeThis.state.useKinuim}
                        toggleIncludeStatistics={fakeThis.toggleIncludeStatistics}includeStatistics={fakeThis.state.includeStatistics}  
                        extToggleIncludeMekorot={fakeThis.toggleIncludeMekorot} includeMekorot={fakeThis.state.includeMekorot}
                        extOpenTaamParser={fakeThis.openTaamParser} hasRowData={hasRowData} fillTextComparator={fakeThis.props.fillTextComparator}
                        toggleTanachViewer={((boolean, pasukId, resultsApi,columnClicked) => {fakeThis.props.toggleTanachViewer(boolean, pasukId, resultsApi,columnClicked)})}
                        extToggleSearchAsQuote={fakeThis.toggleSearchAsQuote} searchAsQuote={fakeThis.state.searchAsQuote}
                        statsToCopy={fakeThis.state.statsToCopy} howToPaste={fakeThis.state.howToPaste} whichStatistics={fakeThis.state.statisticsPicker}
                        activeLanguage={fakeThis.props.activeLanguage} columnClicked={fakeThis.state.columnClicked}
                        withTargum={fakeThis.state.withTargum} contentDisplayed={fakeThis.state.contentDisplayed} extSetTextSource={val=>fakeThis.props.extSetTextSource(val)}
                        extOpenLexiconViewer={fakeThis.props.extOpenLexiconViewer} wordToCopy={fakeThis.state.wordToCopy}
                        searchTanach={fakeThis.searchTanach} toggleIncludeOtherStats={fakeThis.props.toggleIncludeOtherStats} includeOtherStats={fakeThis.props.includeOtherStats}/>
                                 </LoadingOverlay>
                                     </TabContainer>}
                             })}
                  </div>
                  <div elevation={4} className='results-status-bar' >

                  {fakeThis.props.rowDataForTotalTooltip && fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue]?<Tooltip style={{zIndex:'810'}} id="hoverTooltip" visible={this.state.tooltipOpen}
                            trigger={['click']} placement={'bottom'} onVisibleChange={()=>{this.setState({tooltipOpen:!this.state.tooltipOpen})}}
                            overlay={<div class="totalPesukimTooltip" style={{direction:'rtl'}}>
                                <table id="totalPesukimTooltip" class="totalPesukimTooltip">
                                    <thead class="totalPesukimTooltipHeader"><td title={sortByColumn} onClick={()=>this.sortTable(0)}>{place}</td><td title={sortByColumn} onClick={()=>this.sortTable(1)}>{pesukim}</td>{!noSeferMin?<td title={sortByColumn} onClick={()=>this.sortTable(2)}>{totalAppearances}</td>:null}</thead>
                            <tbody id="totalPesukimTooltipBody">
                                <tr><td className='bold'>תורה</td><td>{torahPesukim}</td>{!noSeferMin?<td>{torahAppearances}</td>:null}</tr>
                                <tr><td className='bold'>נביאים</td><td>{neviimPesukim}</td>{!noSeferMin?<td>{neviimAppearances}</td>:null}</tr>
                                <tr><td className='bold'>כתובים</td><td>{ksuvimPesukim}</td>{!noSeferMin?<td>{ksuvimAppearances}</td>:null}</tr>
                                <tr class="blank_row"><td></td><td></td><td></td></tr>
                                {!noSeferMin?
                                    fakeThis.state.seferArray.map(sefer=>{
                                        if(fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue] && fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferPesukim[sefer])
                                            return <tr><td className='bold'>{sefer}</td><td>{fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferPesukim[sefer]}</td><td>{fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferMin[sefer]}</td></tr>
                                  })
                                :noSeferMin && fakeThis.props.rowDataForTotalTooltip && fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue] && fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferPesukim?
                                    fakeThis.state.seferArray.map(sefer=>{
                                        if(fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferPesukim[sefer])
                                            return <tr><td className='bold'>{sefer}</td><td>{fakeThis.props.rowDataForTotalTooltip[fakeThis.props.tabValue].seferPesukim[sefer]}</td></tr>
                                    })
                                    :<></>}
                            </tbody></table></div>}>
                            <Typography title={fakeThis.props.translate("appearanceTooltip")} onClick={this.setTootlipOpenClose} className='results-status-bar-text' variant="subtitle2">
                            {this.state.tooltipOpen?<ArrowDropDownIcon className='arrowIcon'/>:<ArrowLeftIcon className='arrowIcon'/>}
                            {isHebrew?<span><span><b>{pesukim}: </b>{totalPesukimNum}</span><span>&nbsp;&nbsp;&nbsp;</span>{totalAppearancesNum>0?<span><b>{totalAppearances}</b>: {totalAppearancesNum}&nbsp;</span> :<></>}</span>
                            :<>{totalAppearancesNum>0?<span><b>{totalAppearances}</b>: {totalAppearancesNum}&nbsp;&nbsp;&nbsp;</span> :<></>}<span><b>{pesukim}: </b>{totalPesukimNum}</span></>}
                            </Typography>
                  </Tooltip>:<Typography className='results-status-bar-text noHover' variant="subtitle2" style={{top:'4px',right:'15px'}}>
                            &nbsp;<b>{pesukim}: </b>{totalPesukimNum}&nbsp;&nbsp;&nbsp;{totalAppearancesNum>0?<span><b>{totalAppearances}</b>: {totalAppearancesNum}&nbsp;</span> :<></>}
                            </Typography>}

        <DialogActions
                style={{
                  direction: isHebrew ? 'ltr' : 'rtl',
                }}
              >
                        <Typography variant='subtitle2'style={{top:'4px', lineHeight: '1',color: 'white',display:'inline', fontWeight: 'bold',position:'absolute',right:isHebrew ?fullScreen? '19%':'18%':fullScreen? '31%':'30%'}}>:<Translate id="selectContent" /></Typography>&nbsp;
                        <div className='tanach-viewer-select-text-display-type'style={{display:'inline',position:'absolute',right:isHebrew ? fullScreen?'22%':'22%':fullScreen?'22%':'18%',lineHeight: '1' }}>

                            {/* <div className={classes.toggleContainer} style={{direction: 'rtl', display:'inline'}} className="searchResultsToggleButtonContainer">
                            <ToggleButtonGroup className="tanachViewerToggleButtonGroup" value={contentDisplayed} onChange={(e, vals) => {this.handleToggleContentDisplayed(e, vals)}}>
                                <ToggleButton className="tanach-viewer-content-toggle-button" value="tanach">תנ"ך</ToggleButton>
                                <ToggleButton className="tanach-viewer-content-toggle-button" value="targum" disabled={this.state.rowData[0] && !hasTargum}>תרגום</ToggleButton>
                            </ToggleButtonGroup>
                            </div> */}
                            <div className={classes.toggleContainer} style={{direction: 'rtl'}} >
                             <ToggleButtonGroup className="toggleButtonGroup" value={contentDisplayed} onChange={(e, vals) => {this.clickContentDisplayed(e, vals)}} >
                                 <ToggleButton id='tanach' className="search-result-toggle-button" value="tanach"> תנ"ך</ToggleButton>
                                 <ToggleButton id='targum' className="search-result-toggle-button" value="targum">תרגום</ToggleButton>
                             </ToggleButtonGroup>
                         </div>

                        </div>

                  </DialogActions>

                        {/* <div style={{lineHeight:'1',display:'inline',position:'absolute',right:'600px',}}> */}
                            <Button title={firstPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.goToPage(e, 1, 'first')}} disabled={currentPage === 1 || isShowAll || this.state.pageIsLoading} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                            <LastPageIcon style={{fontSize: '15px'}} />
                        </Button>
                        <Button title={prevPage} variant="contained"  className= "search-results-page-button" onClick={(e) => {this.goToPage(e, currentPage - 1, 'prev')}} disabled={currentPage === 1 || isShowAll || this.state.pageIsLoading} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                            <NavigateNextIcon style={{fontSize: '15px'}} />
                        </Button>
                        <Select
                            value={isShowAll ? 1 : currentPage}
                            onChange={(e) => {this.goToPage(e, e.target.value)}}
                            name="pageSelect"
                            displayEmpty
                            autoWidth={true}
                            style={{
                                color: 'white'
                            }}
                        >
                            {pageMenuItems}
                        </Select>
                        <Button title={nextPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.goToPage(e, currentPage + 1, 'next')}} disabled={isLastPage || isShowAll || this.state.pageIsLoading} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                            <NavigateBeforeIcon style={{fontSize: '15px'}} />
                        </Button>
                        <Button title={lastPage} variant="contained" className= "search-results-page-button" onClick={(e) => {this.goToPage(e, lastResultsPageNum, 'last')}} disabled={isLastPage || isShowAll || this.state.pageIsLoading} style={{backgroundColor: 'RGB(224, 224, 224)'}}>
                            <FirstPageIcon style={{fontSize: '15px'}} />
                        </Button>
                        {/* </div> */}
                        <div style={{direction: directionStyling.direction}}>
                            <Typography className='results-per-page-select' variant="subtitle2">
                                {resultsPerPage}: &nbsp;
                            <Select
                                value={this.state.resultsPerPage}
                                onChange={(e) => {this.handleResultsPerPage(e)}}
                                name="sortOrder"
                                displayEmpty
                                autoWidth={true}
                                style={{
                                    color: 'white',
                                    fontWeight: 'normal',
                                    fontSize: '14px'
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                                <MenuItem value={200}>200</MenuItem>
                                <MenuItem value={"showAll"}>{showAll}</MenuItem>
                            </Select>
                            </Typography>
                        </div>

                  </div>
            </td>
        </tbody>
        {/* maybe put this into home so it'll be in the whole page. */}
        {/* <div style={{zIndex:"802",width:"100px", height:"100px", border:"1px black",position:"absolute", left:this.state.wordTooltipCoordinates.x,top:this.state.wordTooltipCoordinates.y}}></div> */}

    </table>
        </span>
                {/* <Dialog
                  open={this.state.taamParserOpen}
                  onClose={this.closeTaamParser}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  maxWidth="xl"
                >

                    <TaamParser data={this.state.taamParseData} open={this.state.taamParserOpen} closeTaamParser={this.closeTaamParser}
                            focusedCellRowLocation={focusedCellRowLocation} extOpenTaamParser={this.openTaamParser}
                            pasukId={this.state.taamParserPasukId} pasukLocation={this.state.taamParserLocation}
                            resultsApi={this.resultsApi} />

                </Dialog> */}
        </MuiThemeProvider>
    );
  }
}

SearchResults.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
//   export default (withLocalize(TanachViewer));
export default withStyles(styles)(withLocalize(SearchResults));


/*



                                <div style={{width: '20px', float: 'right', lineHeight: '0px', zIndex:'1', position: 'relative'}}>
                                    {increaseFontButton}
                                    {decreaseFontButton}
                                    <Button aria-label="saveResults" title={saveSearchResults} style={{minWidth: 0, padding: '3px'}} onClick={(e) => { this.handleOpenSaveResultsPopover(e) }}>
                                        <img src={require("./images/icons8-save-384.png")} alt={saveSearchResults} width="15" height="14" />
                                    </Button>
                                    <Popover
                                        id={'save-results-popover'}
                                        open={this.state.saveResultsPopoverOpen}
                                        anchorEl={this.state.saveResultsPopoverAnchorEl}
                                        onClose={this.handleCloseSaveResultsPopover}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <a href={(URL.createObjectURL(this.state.searchResultsBlob)) || ''} target="_blank" download='BHS_Search_Results.json' className='save-results-link'>
                                            <Button style={{maxWidth: '160px', minHeight: '50px'}}>
                                                <Typography>
                                                    {this.props.translate("rightClickToSaveResults.caption")}
                                                </Typography>
                                            </Button>
                                        </a>
                                    </Popover>
                                </div>




  <AppBar position="static" style={{width: '95%', maxHeight: '30px', minHeight: '0px', zIndex:'1', position: 'relative'}}>
    <Tabs value={this.state.tabValue} onChange={this.handleTabChange} aria-label="simple tabs example" style={{direction: 'rtl'}}>
      <Tab label="Item One" {...a11yProps(0)} style={{maxHeight: '30px', minHeight: '0px'}} />
      <Tab label="Item Two" {...a11yProps(1)} style={{maxHeight: '30px', minHeight: '0px'}} />
      <Tab label="Item Three" {...a11yProps(2)} style={{maxHeight: '30px', minHeight: '0px'}} />
    </Tabs>
  </AppBar>
 <div className="" style={{zIndex:'1', position: 'relative'}}>
    {this.state.tabValue === 0 &&
        <TabContainer className="">
            <LoadingOverlay
                            active={this.props.searchIsLoading}
                            spinner={<FadeLoader color={'#E0E0E0'} top={'80px'} />}
                              styles={{
                                wrapper: (base) => ({
                                  width: '1400px',
                                  height: `${fakeThis.props.searchResultsHeight}`,
                                  minHeight: '200px',
                                }),
                                overlay: (base) => ({
                                  ...base,
                                  width: '1400px',
                                  height: `${fakeThis.props.searchResultsHeight}`,
                                  minHeight: '200px',
                                  position: 'absolute',
                                  left: '70px',
                                  margin: 'auto',
                                  top: {posTop},
                                })
                              }}
                            text={loadingText}
                        >
                    <div
                      className="ag-theme-balham search-results-table"
                      style={{
                        height: `${parseInt(fakeThis.props.searchResultsHeight.substring(0, fakeThis.props.searchResultsHeight.length - 2))}px`,
                        minHeight: '175px',
                        width: '1400px'}}
                        >

                        <AgGridReact
                            className='search-results-table'
                            onGridReady={this.resultsGridReady}
                            columnDefs={this.state.columnDefs}
                            rowData={this.props.rowData}
                            enableFilter={true}
                            enableColResize={true}
                            frameworkComponents={this.state.frameworkComponents}
                            overlayNoRowsTemplate={'<span style="font-size:28px; color: dodgerblue;"><i>אין תוצאות</i></span>'}
                            enableRtl="true">
                        </AgGridReact>
                    </div>


    </LoadingOverlay>
        </TabContainer>}
    {this.state.tabValue === 1 &&
        <TabContainer className="">

    2
        </TabContainer>}
    {this.state.tabValue === 2 && <TabContainer className="">

    3
        </TabContainer>}
    {this.state.tabValue === 3 &&
        <TabContainer className="">
    4

        </TabContainer>}
</div>
*/