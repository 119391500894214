import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import './css/App.css';
import './css/FuzzyPicker.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import { Button, Paper, InputBase, IconButton, Fade, Typography, Popper, AppBar, Tabs, Tab, InputLabel, MenuItem, FormControl, Select, Switch, Popover, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
//import { Button } from 'reactstrap';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import translations from "./json/translations.json";
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {TriStateCheckbox} from 'primereact/tristatecheckbox';
import {Checkbox} from 'primereact/checkbox';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {Spinner} from 'primereact/spinner';
import PlacementRenderer from './PlacementRenderer';
import LetterPicker2D from './LetterPicker2D';
import NekudaPicker2D from './NekudaPicker2D';
import TaamPicker2D from './TaamPicker2D';
import DistancePicker from './DistancePicker';
import PartsOfSpeechSelector2D from './PartsOfSpeechSelector2D';
import PartsOfSpeechSlider from './PartsOfSpeechSlider';
//import AdvancedHelpDialog from './AdvancedHelpDialog';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as properties from './config/properties.js';
import FuzzyPicker from './FuzzyPicker'

function PickerModel() {
  this.list = [];
  this.values = [];
  this.renderValue = "";
}

function DualPickerModel() {
  this.masterList = [];
  this.pickerModel1 = new PickerModel();
  this.pickerModel2 = new PickerModel();

  this.getUnselected = function(selectedValues) {
      var result = [];
      this.masterList.map(function(e){
         var i = selectedValues.findIndex((v)=>{return v == e.id;});
         if (i == -1) //current master list element id not found in selected values of opposing list
             result.push(e);
      });
      return result;
  }
}

function namedSection(params) {
  return '<Spinner name="ns" id="ns" value={1} min={1} max={10} />';
}

function pasukRange(params) {
  return <Spinner name="pr" id="pr"  value={1} min={1} max={10} />;
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 6 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    align: 'left',
    textAlign: 'right',
    fontSize: '18px',
    fontWeight: 'bold',
    width: '1180px',
  },
  button: {
    margin: '0px',
    width: '0px',
    top: '0px',
    height: '0px',
    fontSize: '0px',
    padding: '0px',
    align: 'left',
    verticalAlign: 'top',
  },
});

const theme = createMuiTheme({ //can also use 2 toggleButton containers inside a Grid element to accomplish groups on same line as is done here https://material-ui.com/lab/toggle-button/
  overrides: {
    MuiToggleButtonGroup: {
      root: {
          display: 'inline',
      },
      selected: {
          display: 'inline',
      },
    },
    MuiTab: {
      selected: {
        textColor: 'white',
        textColorPrimary: 'white',
        backgroundColor: 'dodgerBlue'
      },
      primary: {
          color: 'white',
          textColor: 'white',
      },
    },
  },
  direction: 'rtl',
  typography: { useNextVariants: true },
});


const marksRosh = {100: "∞", 80:80, 60:60, 40:40, 20:20, 0: 0};
const marksSof = {0: {label: "0"}, 100: {label: "∞"}};


//const Range = createSliderWithTooltip(Slider.Range);

var checkBoxValue = null;

class AdvancedSearch extends Component {

//webServiceURL = properties.env.webServiceURL;

startTab = 4;

bituiColumns = [
    {headerName: "ביטויים", field: "term", width: 130, headerClass: "ag-header-cell-small", cellClass: "row-thin-small"}
];

constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
//      tabValue: this.startTab,
      bituiSelected: '',
      //bituiSettingsList: [new BituiSettings()], //will hold BituiSettings() objects
      searchParams: this.props.searchParams, //top level search parameters from Home.js
      bituiSettings: this.props.bituiSettingsList[0], //initialize to blank - will hold settings when a bitui gets selected
      termDistances: this.props.termDistances,
  //ckNegative: null,
  //ckCaseSensitive: null,
  //repetitions: 1,
      bituiColumnDefs: this.bituiColumns,
      //placementRowData: [{type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []}],
      inExRowData: [{title: "אותיות", include: "", exclude: ""}, {title: "נקודות", include: [], exclude: [], valList1: []}, {title: "טעמים", include: [], exclude: [], valList1: []}],
      isRefreshAdvancedSearch: true,
      bituiList: [],
      resetAdvancedDialogOpen: false,
      saveSettingsPopoverOpen: false,
      saveSettingsPopoverAnchorEl: null,
      searchSettingsBlob: new Blob([''], {type: "text/plain;charset=utf-8"}),
      bituiSelectedIndex: 0,
//      helpOpen: false,

  //nekudaPickers: [],
  //nekudaPicker: new PickerModel(),
  //taamPicker: new PickerModel(),
      //dualLetterPicker: new DualPickerModel(),
      //dualNekudaPicker: new DualPickerModel(),
      //dualTaamPicker: new DualPickerModel(),
//      letterNikkud: {value: ""},
//      generalNikkud: {value: ""},
//      generalHataama: {value: ""},

  //madreigos: [2, 2, 2, 2, 2, 2], //for sof and rosh of pasuk/parsha/sefer
  //distances: [[0,100], [0,100], [0,100], [0,100], [0,100], [0,100]], //for sof and rosh of pasuk/parsha/sefer

      //transformations: [{id: 1, name: "אנגרמה"}, {id: 2, name: "הפוך"}, {id: 3, name: "נוטריקון"}, {id: 4, name: "שורש"}, {id: 5, name: "Regex"}],
  //transformation: 0,

      defaultLanguage: '',
      binyanimList: [], //must be a different name from binyanim which is already in-state for bituiSettings object - otherwise setting state will get confused!!!
      zmanimList: [], // "" "" "" zmanim.......
      gufimList: [],
      misparimList: [],
      gendersList: [],
      affixTypesList: [],
      wordTypesList: [],
      languagesList: [],
      objectCategoriesList: [],
      numberCategoriesList: [],
      exDisabled: true,
      sliderValue: 0,
      rowData:[]
      };

    this.handleChange = this.handleChange.bind(this);
    this.getGridValue = this.getGridValue.bind(this);
    this.handleCellChanged = this.handleCellChanged.bind(this);
    this.handleCellFocused = this.handleCellFocused.bind(this);
    this.handleBiState = this.handleBiState.bind(this);
    this.placementGridReady = this.placementGridReady.bind(this);
    this.addPlacementRow = this.addPlacementRow.bind(this);
    this.extAddPlacementRow = this.extAddPlacementRow.bind(this);
    this.deletePlacementRow = this.deletePlacementRow.bind(this);
    this.extDeletePlacementRow = this.extDeletePlacementRow.bind(this);
    this.extModifyPlacementRow = this.extModifyPlacementRow.bind(this);
    this.modifyPlacementRow = this.modifyPlacementRow.bind(this);
    this.includeExcludeGridReady = this.includeExcludeGridReady.bind(this);
//    this.setLetterValues = this.setLetterValues.bind(this);
//    this.setNekudaValues = this.setNekudaValues.bind(this);
//    this.setTaamValues = this.setTaamValues.bind(this);
    this.setPickerList = this.setPickerList.bind(this);
    this.setPickerValues = this.setPickerValues.bind(this);
    //this.setDualPickerList = this.setDualPickerList.bind(this);
    this.setDualPickerValues = this.setDualPickerValues.bind(this);
    this.setContainerMadreiga = this.setContainerMadreiga.bind(this);
    this.setContainerDistance = this.setContainerDistance.bind(this);
    this.setTermPairDistance = this.setTermPairDistance.bind(this);
    this.handleSingleTabReset = this.handleSingleTabReset.bind(this);
    this.handleLoadSearchParams = this.handleLoadSearchParams.bind(this);
    this.handleResetAdvancedDialogOpen = this.handleResetAdvancedDialogOpen.bind(this);
    this.handleResetAdvancedDialogClose = this.handleResetAdvancedDialogClose.bind(this);
    this.handleConfirmFullReset = this.handleConfirmFullReset.bind(this);
    this.resetDistanceTab = this.resetDistanceTab.bind(this);
    this.resetNikudTaamimTab = this.resetNikudTaamimTab.bind(this);
    this.resetIncludeExcludeTab = this.resetIncludeExcludeTab.bind(this);
    this.resetPlacementTab = this.resetPlacementTab.bind(this);
    this.handlePOSsliderChange = this.handlePOSsliderChange.bind(this);
    this.handleBituiSettingChange = this.handleBituiSettingChange.bind(this);
    this.handleEditNikkudTaamim = this.handleEditNikkudTaamim.bind(this);
    this.setStateSynchronous = this.setStateSynchronous.bind(this);
//    this.renderLetterNikkud = this.renderLetterNikkud.bind(this);
//    this.renderGeneralNikkud = this.renderGeneralNikkud.bind(this);
//    this.renderGeneralHataama = this.renderGeneralHataama.bind(this);
    this.handleChangeBituiSelected = this.handleChangeBituiSelected.bind(this);
    this.handleBituiSelected = this.handleBituiSelected.bind(this);
    this.handleOpenSaveSettingsPopover = this.handleOpenSaveSettingsPopover.bind(this);
    this.handleCloseSaveSettingsPopover = this.handleCloseSaveSettingsPopover.bind(this);
//    this.toggleHelp = this.toggleHelp.bind(this);
    this.setSlilder = this.setSlilder.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.toggleAdvExactnessLevel = this.toggleAdvExactnessLevel.bind(this);
//    this.checkReset = this.checkReset.bind(this);
//    this.handleCheckedReset = this.handleCheckedReset.bind(this);

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}
  }

//as seen at https://stackoverflow.com/questions/42018342/is-there-a-synchronous-alternative-of-setstate-in-reactjs
    setStateSynchronous(stateUpdate) {
        return new Promise(resolve => {
            this.setState(stateUpdate, () => resolve());
        });
    }

//    toggleHelp(boolean){
//        this.setState({ helpOpen: boolean });
//    }

    handleOpenSaveSettingsPopover(e){
        this.setState({ saveSettingsPopoverOpen: true, saveSettingsPopoverAnchorEl: e.currentTarget, searchSettingsBlob: this.props.extGetSearchSettings() })
    }

    handleCloseSaveSettingsPopover(){
        this.setState({ saveSettingsPopoverOpen: false })
    }

    extAddPlacementRow = () => {
        this.addPlacementRow();
    }

    extDeletePlacementRow = (extState) => {
        this.deletePlacementRow(extState);
    }

    extModifyPlacementRow = (extState) => {
        this.modifyPlacementRow(extState);
    }

    addPlacementRow() {
        var newRow = {type: true, val1: 0, val2: 0, val3: 0, val4: 0, val5: 0, val6: 0, valList1: [], valList2: [], valList3: [], valList4: [], valList5: [], valList6: []};
        var newData = this.props.placementRowData;
        newData.push(newRow);
        this.setState( {rowData: []}, () => {
            this.placementApi.updateRowData({ add: [newRow] })
            this.setState({rowData: newData, rowDataLength:newData.length-1})
        });
    }

    deletePlacementRow(extState) {
        var selected = this.placementApi.getFocusedCell();
        var i = selected.rowIndex;
        if (i == this.placementApi.getLastDisplayedRow()) { //then just clear
            this.modifyPlacementRow(extState);
            //selected.setSelected(false);
        }
        else {
            var selectedData = this.placementApi.getSelectedRows();
            var newData = this.props.placementRowData;
            newData.splice(i,1);
            this.setState( {placementRowData: newData, rowDataLength:i}, () => {this.placementApi.updateRowData({ remove: selectedData }) });
        }
    }

    modifyPlacementRow(extState) {
        //alert(extState.type + "@@@" + extState.val1);
        var isAddRow = (extState.type && extState.val4 != this.props.placementRowData.val4 && extState.val4 != 0) || (!extState.type && extState.val3 != this.props.placementRowData.val3 && extState.val3 != 0 && extState.val6 != this.props.placementRowData.val6 && extState.val6 != 0);
        var selected = this.placementApi.getFocusedCell();

        var i = selected.rowIndex;
        var rowValues = {type: extState.type, val1: extState.val1, val2: extState.val2, val3: extState.val3, val4: extState.val4, val5: extState.val5, val6: extState.val6,
                                              valList1: extState.valList1, valList2: extState.valList2, valList3: extState.valList3, valList4: extState.valList4, valList5: extState.valList5, valList6: extState.valList6 };
        var newData = this.props.placementRowData;
        newData[i] = rowValues;


        this.setState( {placementRowData: newData}, () => {
            var res = this.gridApi.updateRowData({ update: [rowValues] });
            //alert(extState.type+"::"+extState.val1+"::"+extState.val4+"::"+extState.val5+":one:"+extState.valList1.length+":two:"+extState.valList2.length);
            if (i == this.placementApi.getLastDisplayedRow() && isAddRow) {
                //alert(selected.rowIndex+"::"+this.placementApi.getLastDisplayedRow());
                this.addPlacementRow();
            }else{
                this.setState({rowData:[]},()=>{
                  this.placementApi.updateRowData({ update: [rowValues] });
                  this.setState({ rowData: newData })
                })
            }
        });

/*
    var selected = $scope.grid.api.getFocusedCell();
    $scope.grid.rowData.splice(selected.rowIndex, 1);
    $scope.grid.api.setRowData($scope.grid.rowData)
*/
    }

  bituimGridReady = params => {
    this.props.extPassGridApiToHome(params)
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    //alert(this.props.bituiSettingsList.length);
    if (typeof this.props === undefined) {//sometimes get to this point and not ready yet
        return;
    }

    this.gridApi.setRowData(this.props.rowData);
    if (this.props.rowData.length > 0) {
        this.gridApi.setFocusedCell(0, "term", null);
        //var list = [];
        //this.setState( {bituiSettingsList: []} );
        //this.props.rowData.map(function(e){
        //    list[list.length] = new BituiSettings();
        //});
        //this.setState( {bituiSettingsList: list} );
    }
  };

    placementGridReady = params => {
      //if (!this.state.isRefreshAdvancedSearch)  //only refresh adv search grid info once per session
      //    return;

      this.placementApi = params.api;
      this.placementColumnApi = params.columnApi;
      this.placementApi.setRowData(this.props.placementRowData);

      //this.placementApi.setColumnDefs(this.state.placementColumnDefs);
      //alert(JSON.stringify(this.state.placementValues1));

      //giving placement row special class if activeLanguage.code === "en" to fix display issue
      const cellClass = this.props.activeLanguage && this.props.activeLanguage.code === "en" ? "row-thin-small ag-grid-placement-row" : "row-thin-small"

//following CSS classes seem to get overridden below in PlacementRenderer by placementGridLine and rangeGridLine
      this.placementApi.setColumnDefs(
           [{headerName: this.props.translate("addLines"), headerClass: 'row-thin-center', field: "namedSection", width: 1168, cellClass: cellClass, cellRendererFramework: PlacementRenderer, cellRendererParams:
               {
                placementRowData:this.props.placementRowData,
                extModifyPlacementRow: this.extModifyPlacementRow,
                extAddPlacementRow: this.extAddPlacementRow,
                extDeletePlacementRow: this.extDeletePlacementRow,
                translate: this.props.translate, //do I need to pass this here?
            },
      //cellClass: function(params) {return params.data.type ? 'placementGridLine' : 'rangeGridLine';},
            }]
      );

      if (this.props.rowData.length > 0)
          this.placementApi.setFocusedCell(0, "namedSection", null);

      var instances = null;
      if(this.refs.agGrid){
            var instances = this.refs.agGrid.gridOptions.api.getCellRendererInstances(params);
      }


      //if (instances.length > 0)
      //    alert("lenth:"+instances[0]);
      //this.state.isRefreshAdvancedSearch = false;
    };

    includeExcludeGridReady = params => {
      this.includeExcludeApi = params.api;
      this.includeExcludeColumnApi = params.columnApi;
      this.includeExcludeApi.setRowData(this.state.inExRowData);
//alert("1234567");
      this.includeExcludeApi.setColumnDefs(
           [    {headerName: "", //() => {return this.keyboardButton();}
                             field: "title", width: 65, cellClass: "row-header"},
                {headerName: "לכלול", field: "include", width: 250, cellClass: "row-thin", editable: function(params){return params.node.rowIndex==0;},
                           //cellRendererFramework: NekudaPicker,
                           cellRendererSelector:
                                function(params){
                                    //alert("ri:"+params.node.rowIndex+":"+params.data.include);

                                    if (params.node.rowIndex == 1)
                                        return {component: 'NekudaPicker2D', params: {val1: params.data.include, valList1: params.data.valList1}};
                                    else if (params.node.rowIndex == 2)
                                        return {component: 'TaamPicker2D', params: {val1: params.data.include, valList1: params.data.valList1}};
                                    else
                                        return null;
                                }},

//                           cellRendererParams: function(params){return {val1: params.node.rowIndex==1 && params.column.field=="include" ? params.data.include :
//                              (params.node.rowIndex==1 && params.column.field=="exclude" ? params.data.exclude : null)}}},
                {headerName: "להוציא", field: "exclude", width: 250, cellClass: "row-thin", editable: function(params){return params.node.rowIndex==0;}}
/*
 ,

{headerName: "הוסף שורות לפי הצורך", field: "namedSection", width: 1175, cellClass: "row-thin", cellRendererFramework: SectionRenderer, cellRendererParams:
               {
                extModifyPlacementRow: this.extModifyPlacementRow,
                extAddPlacementRow: this.extAddPlacementRow,
                extDeletePlacementRow: this.extDeletePlacementRow,
            }}
*/
            ]
      );

    };

    handleClick = event => {
      const { currentTarget } = event;
      this.setState(state => ({
        anchorEl: document.getElementById("terms"), //currentTarget,
        open: !state.open,
      }));
    };

    handleChange = (event, tabValue) => {
         const tabTopics = ['placement', 'pasukSettings', 'partsOfSpeech', 'distances', 'lnt'];
         if (tabValue != null && tabValue >= 0 && tabValue <= 4)
             localStorage.setItem("helpDialogStartupTopic", tabTopics[tabValue]);
         this.props.extSetAdvancedSearchTabValue(tabValue);
    };

      handleCellChanged = (params) => {
        //alert("handleCellChanged");

        const bitui = this.state.bituiSelected;
        if (bitui != null && bitui != "" && this.props.rowData.length > 0 && bitui.length == this.getGridValue(0).length &&
                (this.props.rowData.length > 1 || util.right(this.props.extBituimText, 1) == " "))
            return;

        const el = document.activeElement;
        if (params.rowIndex != null && params.rowIndex >= 0)
            this.gridApi.setFocusedCell(params.rowIndex, "term", null);
        else if (this.props.rowData.length > 0)
            this.gridApi.setFocusedCell(0, "term", null);
        el.focus(); //regain focus if lost it

      };

      handleCellFocused = (params) => {
//           alert("handlecellfocused")
           if (params.rowIndex != null && params.rowIndex >= 0) {
            if (this.getGridValue(0).trim() == "") {
               this.setState({ bituiSelected: "", bituiSelectedIndex: 0, bituiSettings: this.props.bituiSettingsList[0], termDistances: {} });

            }
            else {

          //alert("params.rowIndex:" + params.rowIndex + ":" + this.getGridValue(params.rowIndex) + ":bsl.len=" + this.props.bituiSettingsList[0].nekudaPicker.list.length);
        ///todo -WHEN ADD/UPDATE bituiSettingsList BY ADDING BituiSettings() OBJECT, NEED TO POPULATE THAT!!!
                this.setState({ bituiSelected: this.getGridValue(params.rowIndex), bituiSelectedIndex: params.rowIndex, bituiSettings: this.props.bituiSettingsList[params.rowIndex], termDistances:this.props.termDistances });
            }
            var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
            this.props.extRenderLetterNikkud(selectedTermIndex);
            this.props.extRenderGeneralNikkud(selectedTermIndex);
            this.props.extRenderGeneralHataama(selectedTermIndex);
//            this.renderLetterNikkud();
//            this.renderGeneralNikkud();
//            this.renderGeneralHataama();
            //alert(this.props.bituiSettingsList[selectedTermIndex].exactnessLevel);
        }

      };

      handleBituiSelected = (params) => {
//        alert("handleBituiSelected");

          if (this.gridApi != null && this.getGridValue(0).trim() == "") {
            //this.extUpdateBituim("");
            //this.setState({ bituiSelected: this.getGridValue(0), bituiSettings: this.props.bituiSettingsList[0] });
            this.setState({ bituiSelected: "", bituiSettings: this.props.bituiSettingsList[0] });
            var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
            this.props.extRenderLetterNikkud(selectedTermIndex);
            this.props.extRenderGeneralNikkud(selectedTermIndex);
            this.props.extRenderGeneralHataama(selectedTermIndex);
//            this.renderLetterNikkud();
//            this.renderGeneralNikkud();
//            this.renderGeneralHataama();
          }
          else if (this.gridApi != null && this.getGridValue(0).trim() != "") { //params.rowIndex will be null here
            this.handleCellChanged(params);
            //this.setState({ bituiSelected: this.getGridValue(0), bituiSettings: this.props.bituiSettingsList[0] });
            //this.gridApi.setFocusedCell(0, "term", null);
          }

      }

      handleBiState = (event, obj) => {
          let val = event.target.value;
          this.setState((prevState) => Object.assign(prevState.bituiSettings, {[obj]: val == false ? null : true}));
      };

      handleTriState(event, obj) {
          this.setState((prevState) => Object.assign(prevState.bituiSettings, {[obj]: event.target.value}));
      };

      handlePasukSettingsSliderChange(val, obj) {
          var range = this.state.searchParams.pasukSettings[obj+'Range'];
          this.setState((prevState) => Object.assign(prevState.searchParams.pasukSettings, {[obj]: val}));
          this.setState((prevState) => Object.assign(prevState.searchParams.pasukSettings, {[obj+'Display']: val[0] + " עד " + (val[1] < range[1] ? val[1] : "∞")}));
      }

      handlePOSsliderChange(val, obj) {
          var range = this.state.bituiSettings[obj+'Range'];
          this.setState((prevState) => Object.assign(prevState.bituiSettings, {[obj]: val}));
          this.setState((prevState) => Object.assign(prevState.bituiSettings, {[obj+'Display']: val[0] + " עד " + (val[1] < range[1] ? val[1] : "∞")}));
      }

      setSlilder = (num) => {
        this.handleBituiSettingChange(num, "exactnessLevel");
      }

//handling the checkbox to turn off and on the exactness level selector:
        handleChecked = (e) => {
        var val = e.target.value;
        if(val == null) {
           val = false
            }
        checkBoxValue = val == false ? null : true;
        this.setState({exDisabled: val == true ? false : true});
        this.handleBituiSettingChange(val, "exactnessLevelChecked");
        console.log(this.state.bituiSettings);
        };

       toggleAdvExactnessLevel = ()=>{};

//       checkReset = () => {
//        this.setState({checked: false, exDisabled: true});
//       };
//
//      handleCheckedReset = () => {
//         this.handleBituiSettingChange(false, "exactnessLevelChecked");
//         this.handleBituiSettingChange(0, "exactnessLevel");
//         this.setState({exDisabled: true, sliderValue: 0});
//         checkBoxValue = null;
//         console.log(this.state.bituiSettings);
//      }

      handleBituiSettingChange(event, obj) {
        //console.log(event.target.value);
        //console.log(event.target.attributes.value.value);
//          var val = event.target.attributes.value.value // e.target.value isn't working properly here
//        if(exDisabled==true){
//            var val = null;
//        } else {
            if(obj == "exactnessLevel" || obj == "exactnessLevelChecked"){
            var val = event;
            } else {
            var val = event.target.value;
            }
//        }
          var bituiProperty = this.state.bituiSettings[obj];
          if (!Array.isArray(bituiProperty)) {
              this.setState((prevState) => Object.assign(prevState.bituiSettings, {[obj]: val}));
              console.log(this.state.bituiSettings);
              return;
          }
//          if(val === 'clearAll'){
//            wordsToSkip = [];
//            this.setOtherWordsToSkip({target:{value:""}})
//          } else {
           if(bituiProperty.includes(val)){
           var valIndex = bituiProperty.indexOf(val);
           bituiProperty.splice(valIndex, 1);
           } else {
           bituiProperty.push(val);
           }
//          }
          bituiProperty = bituiProperty.sort();
          this.setState({ bituiProperty });
          console.log("bituiProperty = " + bituiProperty);
          console.log(this.state.bituiSettings);
          };



  getGridValue(row) {
      return this.gridApi.getValue("term", this.gridApi.getRowNode(row));
  }
//    async handleClear(event) {
//        document.getElementById("terms").value = '';
//        this.setState({ terms: '' });
//    }

//  setLetterValues(vals, sourceNum) {
//      const dualLetterPicker = this.state.dualLetterPicker;
//      const unselectedList = dualLetterPicker.getUnselected(vals);
//
//      if (sourceNum == 1)
//          this.setState(Object.assign(dualLetterPicker,{values1: vals, list2: unselectedList}));
//      else
//          this.setState(Object.assign(dualLetterPicker,{values2: vals, list1: unselectedList}));
//  }
//
//  setNekudaValues(vals, sourceNum) {
//      const dualNekudaPicker = this.state.dualNekudaPicker;
//      const unselectedList = dualNekudaPicker.getUnselected(vals);
//
//      if (sourceNum == 1)
//          this.setState(Object.assign(dualNekudaPicker,{values1: vals, list2: unselectedList}));
//      else
//          this.setState(Object.assign(dualNekudaPicker,{values2: vals, list1: unselectedList}));
//  }
//
//  setTaamValues(vals, sourceNum) {
//      const dualTaamPicker = this.state.dualTaamPicker;
//      const unselectedList = dualTaamPicker.getUnselected(vals);
//
//      if (sourceNum == 1)
//          this.setState(Object.assign(dualTaamPicker,{values1: vals, list2: unselectedList}));
//      else
//          this.setState(Object.assign(dualTaamPicker,{values2: vals, list1: unselectedList}));
//  }

   setPickerValues(pickerModel, newVals, renderVal) {
      //var model = sourceNum < 0 ? pickerModel : pickerModel[sourceNum];
      //await this.setStateSynchronous(Object.assign(pickerModel, {values: newVals}));
      this.setState(Object.assign(pickerModel, {values: newVals}));
      if (renderVal != null)
          this.setState(Object.assign(pickerModel, {renderValue: renderVal}));
      var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
      this.props.extRenderLetterNikkud(selectedTermIndex);
      this.props.extRenderGeneralNikkud(selectedTermIndex);
      this.props.extRenderGeneralHataama(selectedTermIndex);
  }

   setPickerList(pickerModel, newList) {
       //var model = sourceNum < 0 ? pickerModel : pickerModel[sourceNum];
       //await this.setStateSynchronous(Object.assign(pickerModel, {list: newList}));
       this.setState(Object.assign(pickerModel, {list: newList}));
   }

  setDualPickerValues(dualPickerModel, pickerModel, newVals) {
      const unselectedList = dualPickerModel.getUnselected(newVals);
      //this.setDualPickerList(dualPickerModel, sourceNum == 1 ? 2 : 1, unselectedList);
      var altPickerModel = pickerModel == dualPickerModel.pickerModel1 ? dualPickerModel.pickerModel2 : dualPickerModel.pickerModel1;
      this.setPickerList(altPickerModel, unselectedList);
      this.setPickerValues(pickerModel, newVals);

      //if (sourceNum == 1) {
          //await this.setStateSynchronous(Object.assign(dualPickerModel,{values: newVals}));
          //this.setState(Object.assign(pickerModel,{values: newVals}));
      //}
      //else {
      //    await this.setStateSynchronous(Object.assign(dualPickerModel,{values: newVals}));
      //}
  }

//  renderLetterNikkud() {
//      if (this.state.bituiSettings.letterNekudaPickers.length == 0)
//          return;
//
//      var result = "";
//      var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
//      var bituiSettings = this.props.bituiSettingsList[selectedTermIndex]
//      var len = this.state.bituiSelected.length;
//      var i;
//      for (i=len-1; i >=0; i--)
//          result += bituiSettings.letterNekudaPickers[i].renderValue;
//
//      this.setState(Object.assign(this.state.letterNikkud, {value: result}));
//  }

//  renderGeneralNikkud() {
//      if (this.state.bituiSettings.generalNekudaPickers.length == 0)
//          return;
//
//      var result = "";
//      var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
//      var bituiSettings = this.props.bituiSettingsList[selectedTermIndex]
//      var len = 13; //max length of 13 for now corresponding to longest stream of nikkud per word in Tanach
//      var i;
//      for (i=len-1; i >=0; i--)
//          result += bituiSettings.generalNekudaPickers[i].renderValue;
//
//      this.setState(Object.assign(this.state.generalNikkud, {value: result}));
//  }

//  renderGeneralHataama() {
//      if (this.state.bituiSettings.generalTaamPickers.length == 0)
//          return;
//
//      var result = "";
//      var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
//      var bituiSettings = this.props.bituiSettingsList[selectedTermIndex]
//      var len = 8; //max length of 8 for now
//      var i;
//      for (i=len-1; i >=0; i--)
//          result += bituiSettings.generalTaamPickers[i].renderValue;
//
//      this.setState(Object.assign(this.state.generalHataama, {value: result}));
//  }


  //setDualPickerList(dualPickerModel, newList) {
      //if (sourceNum == 1) {
      //    await this.setStateSynchronous(Object.assign(dualPickerModel,{list: newList}));
      //}
      //else {
          //await this.setStateSynchronous(Object.assign(dualPickerModel,{list: newList}));
   //       this.setState(Object.assign(dualPickerModel,{list: newList}));
      //}

  //}

    setContainerMadreiga = (val, sourceNum) => {
        var madreigos = this.state.bituiSettings.madreigos;
        madreigos[sourceNum] = val;

        //moving distance settings into Home so settings download/upload can work properly
        this.props.extSetContainerMadreigos(this.state.bituiSelectedIndex, madreigos)
        //ideally distance settings should only be saved in Home, but DistancePickers do not work unless settings are saved in AdvancedSearch as well
        this.setState(Object.assign(this.state.bituiSettings.madreigos, madreigos));
    }

    setTermPairMadreiga = (val, termPairKey) => {
        if(this.props.termDistances && this.props.termDistances[termPairKey]){
            var termDistances = this.props.termDistances;
            termDistances[termPairKey].madreiga = val;

            //moving distance settings into Home so settings download/upload can work properly
            this.props.extSetTermDistances(termDistances)
            //ideally distance settings should only be saved in Home, but DistancePickers do not work unless settings are saved in AdvancedSearch as well
            this.setState(Object.assign(this.props.termDistances, termDistances));
        }
    }

    setTermPairDirection = (val, termPairKey) => {
        if(this.props.termDistances && this.props.termDistances[termPairKey]){
            var termDistances = this.props.termDistances;
            termDistances[termPairKey].direction = val;

            //moving distance settings into Home so settings download/upload can work properly
            this.props.extSetTermDistances(termDistances)
            //ideally distance settings should only be saved in Home, but DistancePickers do not work unless settings are saved in AdvancedSearch as well
            this.setState(Object.assign(this.props.termDistances, termDistances));
        }
    }

    //todo - merge containerDistance/containerDist and termDistance/termDist functions/variables where possible
    setContainerDistance = (val, sourceNum) => {
        var containerDistances = this.state.bituiSettings.containerDistances;
        var containerDistDirections = this.state.bituiSettings.containerDistDirections;
        var containerDistDisplays = this.state.bituiSettings.containerDistDisplays;

        containerDistances[sourceNum] = val;
        var renderVal = containerDistDirections[sourceNum] == "rtl" ? [100-val[1], 100-val[0]] : val;
        containerDistDisplays[sourceNum] = renderVal[0] + " עד " + (renderVal[1] < 100 ? renderVal[1] : "∞");

        //moving distance settings into Home so settings download/upload can work properly
        this.props.extSetContainerDistances(this.state.bituiSelectedIndex, containerDistances, containerDistDisplays);
        //ideally distance settings should only be saved in Home, but DistancePickers do not work unless settings are saved in AdvancedSearch as well
        this.setState(Object.assign(this.state.bituiSettings.containerDistances, containerDistances));
        this.setState(Object.assign(this.state.bituiSettings.containerDistDisplays, containerDistDisplays));
    }

    setTermPairDistance = (val, termPairKey) => {
        if(this.props.termDistances && this.props.termDistances[termPairKey]){
            var termDistances = this.props.termDistances;
    //        var termDistDisplay = "0" + " עד " + "∞";
            termDistances[termPairKey].distance = val;
            var renderVal = [100-val[1], 100-val[0]];
            var termDistDisplay = renderVal[0] + " עד " + (renderVal[1] < 100 ? renderVal[1] : "∞");
            termDistances[termPairKey].distDisplay = termDistDisplay;

            //moving distance settings into Home so settings download/upload can work properly
            this.props.extSetTermDistances(termDistances)
            //ideally distance settings should only be saved in Home, but DistancePickers do not work unless settings are saved in AdvancedSearch as well
            this.setState(Object.assign(this.props.termDistances, termDistances));
        }
    }

    handleLoadSearchParams(e){
        this.props.extLoadSearchParams(e);
    }

    handleSingleTabReset(){
        if(this.props.tabValue === 0){
            this.resetPlacementTab()
        } else if (this.props.tabValue === 1){
            this.resetIncludeExcludeTab()
        } else if (this.props.tabValue === 2){
            this.resetPartOfSpeechTab()
        } else if (this.props.tabValue === 3){
            this.resetDistanceTab()
        } else if (this.props.tabValue === 4){
            this.resetNikudTaamimTab()
        }
    }

    handleFullReset(){
        this.handleCloseAdvancedDialogOpen()
    }

    handleResetAdvancedDialogOpen(){
        this.setState({ resetAdvancedDialogOpen: true })
    }

    handleResetAdvancedDialogClose(){
        this.setState({ resetAdvancedDialogOpen: false })
    }

    handleConfirmFullReset(){
        this.props.extResetAllAdvancedSearchSettings()
        this.gridApi.setFocusedCell(0, "term", null)
        this.handleResetAdvancedDialogClose();
    }

    resetDistanceTab(){
        var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
        this.props.extResetTermContainerDistances(selectedTermIndex)

        for (let i = 0; i < Object.keys(this.props.termDistances).length; i++){
            var currTermPairKey = Object.keys(this.props.termDistances)[i];
            var currTermPairSettings = this.props.termDistances[currTermPairKey];
            if(selectedTermIndex === currTermPairSettings.termPosition1 || selectedTermIndex === currTermPairSettings.termPosition2){
                if(!currTermPairSettings.termsInSameQuote){
                    this.props.extResetTermPairDistances(currTermPairKey)
                }
            }
        }
    }

    resetNikudTaamimTab() {
        var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
        this.props.extResetBituiNikudTaamimState(selectedTermIndex)
        this.props.extRenderLetterNikkud(selectedTermIndex);
        this.props.extRenderGeneralNikkud(selectedTermIndex);
        this.props.extRenderGeneralHataama(selectedTermIndex);
//        this.renderLetterNikkud();
//        this.renderGeneralNikkud();
//        this.renderGeneralHataama();
    }

    resetIncludeExcludeTab(){
        this.props.extResetIncludeExcludeState()
    }

    resetPlacementTab(){
        this.props.extResetPlacementState()
    }

    resetPartOfSpeechTab(){
        this.props.extResetPartOfSpeechState();
    }

    handleEditNikkudTaamim() {
        this.props.extSetAdvancedSearchTabValue(this.startTab)
//        this.setState({tabValue: this.startTab});
    }



    handleChangeBituiSelected() {
         if (this.state.bituiSelected == "") {
            this.gridApi.setFocusedCell(0, "term", null);
         }
    }

    handleBinyanChange(e){
        console.log(e)
        console.log(e.target.value)
    }

    async fetchLookups(){
        var response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getBinyanim?source=bhsWebApp');
        var body = await response.json();
        this.setState({binyanimList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getZmanim?source=bhsWebApp');
        body = await response.json();
        this.setState({zmanimList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getGufim?source=bhsWebApp');
        body = await response.json();
        this.setState({gufimList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getMisparim?source=bhsWebApp');
        body = await response.json();
        this.setState({misparimList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getGenders?source=bhsWebApp');
        body = await response.json();
        this.setState({gendersList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getAffixTypes?source=bhsWebApp');
        body = await response.json();
        this.setState({affixTypesList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getWordTypes?source=bhsWebApp');
        body = await response.json();
        this.setState({wordTypesList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getLanguages?source=bhsWebApp');
        body = await response.json();
        this.setState({languagesList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getObjectCategories?source=bhsWebApp');
        body = await response.json();
        this.setState({objectCategoriesList: body});

        response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getNumberCategories?source=bhsWebApp');
        body = await response.json();
        this.setState({numberCategoriesList: body});
    }

  async componentDidMount() {

    this.fetchLookups()

      document.getElementById('advanced-search-scroll-container').scrollLeft = 200;

      if (this.props.dualLetterPicker.masterList.length == 0) {
          var letterBody, nekudaBody, taamBody;
          var letterBody =  "אבגדהוזחטיכךלמםנןסעפףצץקרשת".split("").map(function(e){return {id: e, name: e};});
          await this.setStateSynchronous(Object.assign(this.props.dualLetterPicker,{masterList: letterBody}));
//master 27   |   letter body 0   |   dual picker 27, 27, 17, 17, picker 17   |   nekudabody 0   |   taambody 0   |   dualpicker 58, 58, picker 58
          //alert("masterList:" + this.state.dualLetterPicker.masterList.length); //@27

          //while (testLen == 0) {
          //new Promise(resolve => {

              //ReactDOM.unstable_batchedUpdates(() => {
                  this.setPickerList(this.props.dualLetterPicker.pickerModel1, letterBody);
                  this.setPickerList(this.props.dualLetterPicker.pickerModel2, letterBody);
              //});
          //});
          //}

          //alert("letterBody:" + this.state.dualLetterPicker.pickerModel1.list.length + ":" + this.state.dualLetterPicker.pickerModel2.list.length); //@0
          var nekudaResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getNekudos?source=bhsWebApp');
          nekudaBody = await nekudaResponse.json();
          await this.setStateSynchronous( {nekudaList: nekudaBody} );
          await this.setStateSynchronous(Object.assign(this.props.dualNekudaPicker,{masterList: nekudaBody}));
          this.setPickerList(this.props.dualNekudaPicker.pickerModel1, nekudaBody); //27, 17
          this.setPickerList(this.props.dualNekudaPicker.pickerModel2, nekudaBody); //27, 17
          //this.setPickerList(this.state.bituiSettings.nekudaPicker, nekudaBody); //@17   //sourceNum doesn't matter here @@@@@@instantiatr array with new nekudaPickerModel object and set its list
            var i;
            var newNekudaPicker;
            var pickers = [];
            //for (i=0; i < this.state.bituiSelected.length; i++) {
            for (i=0; i < 20; i++) {
                newNekudaPicker = new PickerModel();
                newNekudaPicker.list = nekudaBody;
                newNekudaPicker.values = [];
                pickers[i] = newNekudaPicker;
            }
            await this.setStateSynchronous(Object.assign(this.state.bituiSettings.letterNekudaPickers, pickers));
            await this.setStateSynchronous(Object.assign(this.state.bituiSettings.generalNekudaPickers, pickers.slice(0,13)));

          //alert("nekudaBodyDual:" + this.state.dualNekudaPicker.pickerModel1.list.length); //@0
          //alert("nekudaBody:" + this.state.bituiSettings.nekudaPicker.list.length); //@0
          //alert("nekudaBody:" + nekudaBody.length); //@0


          var taamResponse = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getTaamim?source=bhsWebApp');
          taamBody = await taamResponse.json();
          var emetIndex = taamBody.findIndex((e)=>{return e.seferEmetFlag;});
          taamBody.splice(emetIndex, 0, {seferEmetFlag: true, taamType: '', name: 'טעמי אמ"ת', icon: ''});
          await this.setStateSynchronous(Object.assign(this.props.dualTaamPicker,{masterList: taamBody}));
          this.setPickerList(this.props.dualTaamPicker.pickerModel1, taamBody); //@58
          this.setPickerList(this.props.dualTaamPicker.pickerModel2, taamBody);  //@58
          //this.setPickerList(this.state.bituiSettings.taamPicker, taamBody); //@58   //sourceNum doesn't matter here

            var newTaamPicker;
            pickers = [];
            for (i=0; i < 8; i++) {
                newTaamPicker = new PickerModel();
                newTaamPicker.list = taamBody;
                newTaamPicker.values = [];
                pickers[i] = newTaamPicker;
            }
            await this.setStateSynchronous(Object.assign(this.state.bituiSettings.generalTaamPickers, pickers));

          //alert("taamBodyDual:" + this.state.dualTaamPicker.pickerModel1.list.length); //@0
          //alert("taamBody:" + this.state.bituiSettings.taamPicker.list.length); //@0
          //alert("taamBody:" + taamBody.length); //@0

      }

    //console.log('about to fetch stuff upon screen load:');
      //const response = await fetch(properties.env.webServiceURL + '/BaseHaSefer/getPlacementOccasions?source=bhsWebApp');
      //const body = await response.json();
//      const colDefs = [
//                       {headerName: "הוסף קטעים לפי הצורך", field: "namedSection", width: 600, cellClass: "row-thin", cellRendererFramework: SectionRenderer, cellRendererParams:
//                           {valueList1: [{value: 1, text: 'xxx'}, {value: 2, text: 'yyy'}],
//                            valueList2: [],
//                            valueList3: [],
//                            valueList4: [],
//                        }},
//                   ];
//      let placementColumnDefs = this.state.placementColumnDefs;
//      placementColumnDefs.cellRendererParams.valueList1 = body;
      //alert(JSON.stringify(body));
      //this.setState({ placementValues1: body, isLoading: false });
  }

  componentDidUpdate(prevProps, prevState){
        //force out of placement tab then back in on language change to force the table to rerender in new language
       if(prevProps.activeLanguage && this.props.activeLanguage.code !== prevProps.activeLanguage.code && this.props.tabValue === 0){
           this.props.extSetAdvancedSearchTabValue(1, () => {
              this.props.extSetAdvancedSearchTabValue(0);
           });
       }
       for (let i = 0; i < Object.keys(this.props.termDistances).length; i++){
            //updating distance settings in case quotes were added in the search bar
            if(this.state.termDistances[Object.keys(this.state.termDistances)[i]]){
                var propsDistDisplay = this.props.termDistances[Object.keys(this.props.termDistances)[i]].distDisplay;
                var stateDistDisplay = this.state.termDistances[Object.keys(this.state.termDistances)[i]].distDisplay;
                if( propsDistDisplay !== stateDistDisplay ){
                    this.setState({termDistances: this.props.termDistances});
                }
            }
       }
       if(this.state.rowDataLength !== prevState.rowDataLength){
        this.placementApi.gridOptionsWrapper.gridOptions.api.ensureIndexVisible(this.state.rowDataLength,'bottom')
       }
  }

//  fuzzyPropsSetAdv(num){
//     this.handleBituiSettingChange(num,'exactness');
//     }


  render() {
    const { classes } = this.props;
    const { tabValue } = this.state;
    const clear = this.props.translate("clear.caption");
    //const help = this.props.translate("help.caption");
    const clearTab = this.props.translate("clearTab.caption");
    const saveSearchParams = this.props.translate("saveSearchParams.caption");
    const loadSearchParams = this.props.translate("loadSearchParams.caption");
    const clearAllSettings = this.props.translate("clearAllSettings.caption");
    const distanceSettingsDisabled = this.props.translate("distanceSettingsDisabled");

    var i=0, j=0, k=0;
    var setPickerValues = this.setPickerValues;
//    var renderLetterNikkud = this.renderLetterNikkud;
//    var renderGeneralNikkud = this.renderGeneralNikkud;
//    var renderGeneralHataama = this.renderGeneralHataama;
    const transformations = [{id: 1, name: this.props.translate("anagram")}, {id: 2, name: this.props.translate("reverse")}, {id: 3, name: this.props.translate("root")}, {id: 4, name: "Regex"}];


//    const { setLetterValues, setNekudaValues, setTaamValues } = this.state;
    //const { dualLetterPicker, dualNekudaPicker, dualTaamPicker, nekudaPickers, nekudaPicker, taamPicker, nekudaList, letterNikkud } = this.state;
    const { nekudaList, searchParams, bituiSettings } = this.state;
    var letterNikkud = {value: ""}
    var generalNikkud = {value: ""}
    var generalHataama = {value: ""}
    if(this.gridApi){
        var selectedTermIndex = this.gridApi.getFocusedCell().rowIndex
        if(this.props.bituiSettingsList[selectedTermIndex]){ // --> prevents error when entering 2+ words in search bar, selecting a word other than the first in agGrid, then deleting from search bar
            letterNikkud = this.props.bituiSettingsList[selectedTermIndex].letterNikkud
            generalNikkud = this.props.bituiSettingsList[selectedTermIndex].generalNikkud
            generalHataama = this.props.bituiSettingsList[selectedTermIndex].generalHataama
        }
    }
    const { dualLetterPicker, dualNekudaPicker, dualTaamPicker, placementRowData } = this.props;

    const wordsPerPasuk = searchParams.pasukSettings.wordsPerPasuk;
    const lettersPerPasuk = searchParams.pasukSettings.lettersPerPasuk;
    const lettersPerWord = searchParams.pasukSettings.lettersPerWord;
    const uniqueLetter = searchParams.pasukSettings.uniqueLetter;
    const uniqueLetterCS = searchParams.pasukSettings.uniqueLetterCS;
    const tenuosPerPasuk = searchParams.pasukSettings.tenuosPerPasuk;
    const tenuosPerWord = searchParams.pasukSettings.tenuosPerWord;
    const tenuosPerLetter = searchParams.pasukSettings.tenuosPerLetter;
    const taamimPerPasuk = searchParams.pasukSettings.taamimPerPasuk;
    const taamimPerWord = searchParams.pasukSettings.taamimPerWord;
    const taamimPerTenua = searchParams.pasukSettings.taamimPerTenua;
    const gematriaPerPasuk = searchParams.pasukSettings.gematriaPerPasuk;
    const gematriaPerWord = searchParams.pasukSettings.gematriaPerWord;
    const gematriaPerLetter = searchParams.pasukSettings.gematriaPerLetter;
    const atbashPerPasuk = searchParams.pasukSettings.atbashPerPasuk;
    const atbashPerWord = searchParams.pasukSettings.atbashPerWord;
    const atbashPerLetter = searchParams.pasukSettings.atbashPerLetter;

    const wordsPerPasukDisplay = searchParams.pasukSettings.wordsPerPasukDisplay;
    const lettersPerPasukDisplay = searchParams.pasukSettings.lettersPerPasukDisplay;
    const lettersPerWordDisplay = searchParams.pasukSettings.lettersPerWordDisplay;
    const uniqueLetterDisplay = searchParams.pasukSettings.uniqueLetterDisplay;
    const uniqueLetterCSDisplay = searchParams.pasukSettings.uniqueLetterCSDisplay;
    const tenuosPerPasukDisplay = searchParams.pasukSettings.tenuosPerPasukDisplay;
    const tenuosPerWordDisplay = searchParams.pasukSettings.tenuosPerWordDisplay;
    const tenuosPerLetterDisplay = searchParams.pasukSettings.tenuosPerLetterDisplay;
    const taamimPerPasukDisplay = searchParams.pasukSettings.taamimPerPasukDisplay;
    const taamimPerWordDisplay = searchParams.pasukSettings.taamimPerWordDisplay;
    const taamimPerTenuaDisplay = searchParams.pasukSettings.taamimPerTenuaDisplay;
    const gematriaPerPasukDisplay = searchParams.pasukSettings.gematriaPerPasukDisplay;
    const gematriaPerWordDisplay = searchParams.pasukSettings.gematriaPerWordDisplay;
    const gematriaPerLetterDisplay = searchParams.pasukSettings.gematriaPerLetterDisplay;
    const atbashPerPasukDisplay = searchParams.pasukSettings.atbashPerPasukDisplay;
    const atbashPerWordDisplay = searchParams.pasukSettings.atbashPerWordDisplay;
    const atbashPerLetterDisplay = searchParams.pasukSettings.atbashPerLetterDisplay;

    const wordsPerPasukRange = searchParams.pasukSettings.wordsPerPasukRange;
    const lettersPerPasukRange = searchParams.pasukSettings.lettersPerPasukRange;
    const lettersPerWordRange = searchParams.pasukSettings.lettersPerWordRange;
    const uniqueLetterRange = searchParams.pasukSettings.uniqueLetterRange;
    const uniqueLetterCSRange = searchParams.pasukSettings.uniqueLetterCSRange;
    const tenuosPerPasukRange = searchParams.pasukSettings.tenuosPerPasukRange;
    const tenuosPerWordRange = searchParams.pasukSettings.tenuosPerWordRange;
    const tenuosPerLetterRange = searchParams.pasukSettings.tenuosPerLetterRange;
    const taamimPerPasukRange = searchParams.pasukSettings.taamimPerPasukRange;
    const taamimPerWordRange = searchParams.pasukSettings.taamimPerWordRange;
    const taamimPerTenuaRange = searchParams.pasukSettings.taamimPerTenuaRange;
    const gematriaPerPasukRange = searchParams.pasukSettings.gematriaPerPasukRange;
    const gematriaPerWordRange = searchParams.pasukSettings.gematriaPerWordRange;
    const gematriaPerLetterRange = searchParams.pasukSettings.gematriaPerLetterRange;
    const atbashPerPasukRange = searchParams.pasukSettings.atbashPerPasukRange;
    const atbashPerWordRange = searchParams.pasukSettings.atbashPerWordRange;
    const atbashPerLetterRange = searchParams.pasukSettings.atbashPerLetterRange;

    //const { isNegative, isCaseSensitive, repetitions, transformation, placementRowData, letterNekudaPickers, nekudaPicker, taamPicker, madreigos, distances } = bituiSettings;
    const isNegative = bituiSettings.isNegative;
    const isCaseSensitive = bituiSettings.isCaseSensitive;
    const repetitions = bituiSettings.repetitions;
    const transformation = bituiSettings.transformation;
    const letterNekudaPickers = bituiSettings.letterNekudaPickers;
    const generalNekudaPickers = bituiSettings.generalNekudaPickers;
    const generalTaamPickers = bituiSettings.generalTaamPickers;
    const madreigos = bituiSettings.madreigos;
    const containerDistances = bituiSettings.containerDistances;
    const containerDistDirections = bituiSettings.containerDistDirections;
    const containerDistDisplays = bituiSettings.containerDistDisplays;
    const binyanim = bituiSettings.binyanim;
    const zmanim = bituiSettings.zmanim;
    const gufim = bituiSettings.gufim;
    const misparim = bituiSettings.misparim;
    const genders = bituiSettings.genders;
    const gufimSof = bituiSettings.gufimSof;
    const misparimSof = bituiSettings.misparimSof;
    const gendersSof = bituiSettings.gendersSof;
    const prefixes = bituiSettings.prefixes;
    const languages = bituiSettings.languages;
    const wordTypes = bituiSettings.wordTypes;
    const objectCategories = bituiSettings.objectCategories;
    const numberCategories = bituiSettings.numberCategories;
    const exactnessLevel = bituiSettings.exactnessLevel;
    const advExactnessLevel = bituiSettings.advExactnessLevel;
    const exactnessLevelChecked = bituiSettings.exactnessLevelChecked;
    const hasSuffix = bituiSettings.hasSuffix;
    const hasInfix = bituiSettings.hasInfix;

    const frequency = bituiSettings.frequency;
    const gematria = bituiSettings.gematria;
    const atbash = bituiSettings.atbash;
    const lettersQty = bituiSettings.lettersQty;
    const vowelsQty = bituiSettings.vowelsQty;
    const tenuosQty = bituiSettings.tenuosQty;
    const frequencyDisplay = bituiSettings.frequencyDisplay;
    const gematriaDisplay = bituiSettings.gematriaDisplay;
    const atbashDisplay = bituiSettings.atbashDisplay;
    const lettersQtyDisplay = bituiSettings.lettersQtyDisplay;
    const vowelsQtyDisplay = bituiSettings.vowelsQtyDisplay;
    const tenuosQtyDisplay = bituiSettings.tenuosQtyDisplay;
    const frequencyRange = bituiSettings.frequencyRange;
    const gematriaRange = bituiSettings.gematriaRange;
    const atbashRange = bituiSettings.atbashRange;
    const lettersQtyRange = bituiSettings.lettersQtyRange;
    const vowelsQtyRange = bituiSettings.vowelsQtyRange;
    const tenuosQtyRange = bituiSettings.tenuosQtyRange;

    const nArray = generalNekudaPickers.length > 0 ? [0,0,0,0,0,0,0,0,0,0,0,0,0] : []; //can also do new Array(13)?
    const tArray = generalTaamPickers.length > 0 ? [0,0,0,0,0,0,0,0] : []; //can also do new Array(8)?

    const localProps = this.props;
    const agTranslate = function(key, defaultValue) {
        var gridKey = 'placementGrid.' + key;
        //var value = alert(localProps.translate(gridKey));
        return ""; //value === gridKey ? defaultValue : value;
    }
    var directionStyling = {}
    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
        directionStyling = {direction: 'rtl', textAlign: 'right'}
    }

//    const hebrewKeyboard = this.props.translate("hebrewKeyboard");
//    const { anchorEl, open } = this.state;
//    const id = open ? 'simple-popper' : null;
    var termDistanceRows = [];
    var quotedWordDisplayed = false;

    for (let i = 0; i < this.props.rowData.length; i++){
        var selectedRowIndex = null;
        if(this.gridApi != null){
            selectedRowIndex = this.gridApi.getFocusedCell().rowIndex
        }
        if(i !== selectedRowIndex){
            var termPairKey = `${i}-${selectedRowIndex}`;
            if(selectedRowIndex > i){
                termPairKey = `${selectedRowIndex}-${i}`;
            }
            var pairDistance = [100,0];
            var distDisplay =  "0" + " עד " + "∞";
            var madreiga = 2;
            var distDirection = 0;

            //if this refers directly to this.props.termDistances, DistancePickers do not work
            if(this.state.termDistances && this.state.termDistances[termPairKey] && this.state.termDistances[termPairKey].distance){
                pairDistance = this.state.termDistances[termPairKey].distance;
                distDisplay = this.state.termDistances[termPairKey].distDisplay;
                madreiga = this.state.termDistances[termPairKey].madreiga;
                distDirection = this.state.termDistances[termPairKey].direction;
                if(this.state.termDistances[termPairKey].termsInSameQuote){
                    quotedWordDisplayed = true;
                }
            }

            termDistanceRows.push(
                <tr>
                    <DistancePicker caption={this.props.rowData[i].term} selectedTermNum={selectedRowIndex} compareTermNum={i} direction="rtl"
                                    numMadreigos={4} madreiga={madreiga} extSetMadreiga={this.setTermPairMadreiga} extSetDirection={this.setTermPairDirection}
                                    distance={pairDistance} display={distDisplay} distDirection={distDirection} extSetTermPairDistance={this.setTermPairDistance}
                                    isBituiToBitui={true} termDistances={this.state.termDistances}/>
                </tr>
            )
        }
    }
    var termDistanceDiv = <></>;
    if(this.props.rowData.length > 1){
        termDistanceDiv = <div style={{height: '70px', width: '780px', display: 'inline', align: 'center', float:'right', overflow: 'auto', direction:'rtl', borderColor: 'black', borderWidth: '1px', borderStyle: 'solid', margin: '10px', paddingTop: '10px' }}>
                              <table style={{direction:'ltr'}}>
                                  <tbody>
                                      {termDistanceRows}
                                  </tbody>
                              </table>
                          </div>
    }

    var tabContentHelpTopic = "";
    switch(this.props.tabValue) {
        case 4:
          tabContentHelpTopic = "lnt";
          break;
        case 3:
          tabContentHelpTopic = "distances";
          break;
        case 2:
          tabContentHelpTopic = "partsOfSpeech";
          break;
        case 1:
          tabContentHelpTopic = "pasukSettings";
          break;
        case 0:
          tabContentHelpTopic = "placement";
          break;
        default:
          tabContentHelpTopic = "lnt"
      }

    var exactnessLevelSliderDisabled = exactnessLevelChecked == true ? false : true;
    var exactnessLevelSliderCheck = exactnessLevelChecked == true? true : null;

    return <MuiThemeProvider theme={theme}><form>
                        <Dialog
                          open={this.state.resetAdvancedDialogOpen}
                          onClose={this.handleResetAdvancedDialogClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          style={directionStyling}
                          helpTopic="advancedLayout"
                        >
                          <DialogTitle id="alert-dialog-title">{<Translate id="clearAdvancedSearchPopupTitle" />}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                              <Translate id="continueToClear" />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.handleResetAdvancedDialogClose} color="primary">
                              <Translate id="cancel.caption" />
                            </Button>
                            <Button onClick={this.handleConfirmFullReset} color="primary">
                              <Translate id="continue.caption" />
                            </Button>
                          </DialogActions>
                        </Dialog>

                        <Dialog
                          open={this.props.invalidFileDialogOpen}
                          onClose={this.props.extHandleCloseInvalidFileDialog}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                          style={directionStyling}
                          helpTopic="advancedLayout"
                        >
                          <DialogTitle id="alert-dialog-title">{<Translate id="invalidFileTitle.caption" />}</DialogTitle>
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                <Translate id="invalidFileContent.caption" />
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={this.props.extHandleCloseInvalidFileDialog} color="primary">
                              <Translate id="close" />
                            </Button>
                          </DialogActions>
                        </Dialog>
           <table align="center" id='advanced-search-table' helpTopic="advancedLayout">
             <tbody className="main-body">
                 <tr className="searchLine">
                     <td width="1180px" align="center" style={{backgroundColor: '#d3d3d3', minHeight: '100%', borderColor: 'black', padding: '0', maxWidth: 'calc(100vw - 738px)', minWidth: '629px'}}>
                             <div className={classes.root}>

        <AppBar position="static" color="default" id='advaced-search-appbar' style={{direction: 'rtl', maxWidth: 'calc(100vw - 738px)', minWidth: '629px', overflow: 'hidden'}}>
          <Tabs
            value={this.props.tabValue}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="auto"
            id='advaced-search-tabs'
            helpTopic={tabContentHelpTopic}
          >
              <Tab value={4} className="tabs" id='nikud-taam' helpTopic="lnt" style={{textTransform: 'capitalize'}} label={<Translate id="advTabVowelizeCantillate" />} />
              <Tab value={3} className="tabs" id='distance' helpTopic="distances" style={{textTransform: 'capitalize'}} label={<Translate id="advTabDistances" />} />
              <Tab value={2} className="tabs" id='parts-of-speech' style={{textTransform: 'capitalize'}} label={<Translate id="advTabPOS" />} />
              <Tab value={1} className="tabs" id='inclusion-exclusion' helpTopic="pasukSettings" style={{textTransform: 'capitalize'}} label={<Translate id="advTabPasukSettings" />} />
              <Tab value={0} className="tabs" id='placement' helpTopic="placement" style={{textTransform: 'capitalize'}} label={<Translate id="advTabPlacement" />} />
          </Tabs>
        </AppBar>
        <div style={{maxWidth: 'calc(100vw - 738px)', minWidth: '629px', direction: 'rtl'}} id='advanced-search-scroll-container'><div style={{direction: 'rtl', overflow: 'auto'}}><div >

      <div className="tabContent" style={{direction: 'ltr'}} helpTopic={tabContentHelpTopic}>
        {this.props.tabValue === 0 &&
            <TabContainer className="tabContent">
                <div
                  className="ag-theme-balham"
                  style={{
                    height: '205px',
                    width: '1170px'}}
                    >
                    <AgGridReact id="placement"
                        ref="agGrid"
                        /* columnDefs={this.state.placementColumnDefs} */
                        rowData={this.state.rowData} //this.state.rowData
                        onGridReady={this.placementGridReady}
                        /* localeTextFunc = {(key, defaultValue) => agTranslate(key, defaultValue)} */
                        enableFilter={false}
                        frameworkComponents={this.state.frameworkComponents}
                        headerHeight={28}
                        rowHeight={40}
                        overlayNoRowsTemplate={'<span></span>'}
                        enableRtl="true">
                    </AgGridReact>
                </div>
            </TabContainer>}
        {this.props.tabValue === 1 &&
            <TabContainer className="tabContent">
                <div style={{height: '0px', width: '1000px', float: 'right', backgroundColor: 'lightgray'}}>&nbsp;</div>
                <div style={{height: '160px', width: '1075px', display: 'inline', float: 'right'}}>
                    <table width="900">
                        <tbody>
                            <tr>
                                <td align="center" width="400" className="row-header-center-compact"><Translate id="exclude" /></td>
                                <td align="center" width="400" className="row-header-center-compact"><Translate id="include" /></td>
                                <td width="100"></td>
                            </tr>
                            <tr>
                                <td><LetterPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualLetterPicker.pickerModel2} extSetValues={(newVals) => this.setDualPickerValues(dualLetterPicker, dualLetterPicker.pickerModel2, newVals)} /></td>
                                <td><LetterPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualLetterPicker.pickerModel1} extSetValues={(newVals) => this.setDualPickerValues(dualLetterPicker, dualLetterPicker.pickerModel1, newVals)} /></td>
                                <td className="row-header-left">&nbsp;{this.props.translate("letters")}</td>
                            </tr>
                            <tr>
                                <td><NekudaPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualNekudaPicker.pickerModel2} extSetValues={(newVals, renderVal) => this.setDualPickerValues(dualNekudaPicker, dualNekudaPicker.pickerModel2, newVals, renderVal)}/></td>
                                <td><NekudaPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualNekudaPicker.pickerModel1} extSetValues={(newVals, renderVal) => this.setDualPickerValues(dualNekudaPicker, dualNekudaPicker.pickerModel1, newVals, renderVal)}/></td>
                                <td className="row-header-left">&nbsp;{this.props.translate("nekudos")}</td>
                            </tr>
                            <tr>
                                <td><TaamPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualTaamPicker.pickerModel2} extSetValues={(newVals, renderVal) => this.setDualPickerValues(dualTaamPicker, dualTaamPicker.pickerModel2, newVals, renderVal)} /></td>
                                <td><TaamPicker2D extClassName={'pasukSettingsDropdownButton longNikudTaamDropdownButton'} pickerModel={dualTaamPicker.pickerModel1} extSetValues={(newVals, renderVal) => this.setDualPickerValues(dualTaamPicker, dualTaamPicker.pickerModel1, newVals, renderVal)} /></td>
                                <td className="row-header-left">&nbsp;{this.props.translate("taamim")}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{height: '99px', width: '1158px', display: 'inline', align: 'center', float:'right', overflow: 'auto', direction:'rtl', borderColor: 'black', borderWidth: '1px', borderStyle: 'solid', marginTop: '5px', marginRight: '5px', marginLeft: '5px'}}>
                    <table width="1050">
                        <tbody>
                            <tr>
                                <td align="center" style={{verticalAlign: "top"}}>
                                    <div style={{direction: 'rtl'}}>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("wordsPerPasuk")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={wordsPerPasuk} display={wordsPerPasukDisplay} range={wordsPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'wordsPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("lettersPerPasuk")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={lettersPerPasuk} display={lettersPerPasukDisplay} range={lettersPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'lettersPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("letters") + " " + this.props.translate("perWord")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={lettersPerWord} display={lettersPerWordDisplay} range={lettersPerWordRange} sliderWidth={140} step={0.01} stepDecimals={2} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'lettersPerWord')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("byUniqueLetters")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={uniqueLetter} display={uniqueLetterDisplay} range={uniqueLetterRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'uniqueLetter')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("byUniqueLettersCS")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={uniqueLetterCS} display={uniqueLetterCSDisplay} range={uniqueLetterCSRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'uniqueLetterCS')} />
                                        </div>
                                    </div>
                                </td>
                                <td align="center" style={{verticalAlign: "top"}}>
                                    <div style={{direction: 'rtl'}}>
                                        <div className="pasuk-settings-slider-parent" title={this.props.translate("excludingChatafim")}>
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("tenuosPerPasuk")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={tenuosPerPasuk} display={tenuosPerPasukDisplay} range={tenuosPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'tenuosPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent" title={this.props.translate("excludingChatafim")}>
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("tenuos") + " " + this.props.translate("perWord")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={tenuosPerWord} display={tenuosPerWordDisplay} range={tenuosPerWordRange} sliderWidth={140} step={0.01} stepDecimals={2} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'tenuosPerWord')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent" title={this.props.translate("excludingChatafim")}>
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("tenuos") + " " + this.props.translate("perLetter")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={tenuosPerLetter} display={tenuosPerLetterDisplay} range={tenuosPerLetterRange} sliderWidth={140} step={0.01} stepDecimals={2} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'tenuosPerLetter')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("taamimPerPasuk")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={taamimPerPasuk} display={taamimPerPasukDisplay} range={taamimPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'taamimPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("taamim") + " " + this.props.translate("perWord")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={taamimPerWord} display={taamimPerWordDisplay} range={taamimPerWordRange} sliderWidth={140} step={0.01} stepDecimals={2} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'taamimPerWord')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("taamim") + " " + this.props.translate("perTenua")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={taamimPerTenua} display={taamimPerTenuaDisplay} range={taamimPerTenuaRange} sliderWidth={140} step={0.01} stepDecimals={2} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'taamimPerTenua')} />
                                        </div>
                                    </div>
                                </td>
                                <td align="center" style={{verticalAlign: "top"}}>
                                    <div style={{direction: 'rtl'}}>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("gematria")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={gematriaPerPasuk} display={gematriaPerPasukDisplay} range={gematriaPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'gematriaPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("gematria") + " " + this.props.translate("perWord")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={gematriaPerWord} display={gematriaPerWordDisplay} range={gematriaPerWordRange} sliderWidth={140} step={0.1} stepDecimals={1} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'gematriaPerWord')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("gematria") + " " + this.props.translate("perLetter")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={gematriaPerLetter} display={gematriaPerLetterDisplay} range={gematriaPerLetterRange} sliderWidth={140} step={0.1} stepDecimals={1} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'gematriaPerLetter')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("atbash")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={atbashPerPasuk} display={atbashPerPasukDisplay} range={atbashPerPasukRange} sliderWidth={140} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'atbashPerPasuk')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("atbash") + " " + this.props.translate("perWord")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={atbashPerWord} display={atbashPerWordDisplay} range={atbashPerWordRange} sliderWidth={140} step={0.1} stepDecimals={1} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'atbashPerWord')} />
                                        </div>
                                        <div className="pasuk-settings-slider-parent">
                                            <div className="parts-of-speech-slider-label row-header-small">
                                                {this.props.translate("atbash") + " " + this.props.translate("perLetter")}
                                            </div>
                                            <PartsOfSpeechSlider
                                                distance={atbashPerLetter} display={atbashPerLetterDisplay} range={atbashPerLetterRange} sliderWidth={140} step={0.1} stepDecimals={1} setRange={(e) => this.handlePasukSettingsSliderChange(e, 'atbashPerLetter')} />
                                        </div>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </TabContainer>}

            {this.props.tabValue === 2 &&
            <TabContainer className="tabContent">
                <div style={{height: '160px', width: '1075px', display: 'inline', float: 'right', backgroundColor: 'lightgray', direction: 'rtl'}}>
                    <table className="parts-of-speech-settings-table" width="1050" style={{direction:'rtl', margin: 'auto'}}>
                        <tbody>
                            <tr>
                                <td align="right" className="parts-of-speech-settings-table-cell" width='345px'>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <span className="parts-of-speech-separator" height='10px'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.props.translate("kluj_wordType")}</b></span>
                                        <span className="parts-of-speech-separator" height='10px'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.props.translate("kluj_language")}</b></span>
                                        <div className="parts-of-speech-dropdown-label">&nbsp;</div>
                                        <PartsOfSpeechSelector2D value={wordTypes} onChange={(e) => {this.handleBituiSettingChange(e, 'wordTypes')}}
                                            menuItems={this.state.wordTypesList}
                                        />
                                        <PartsOfSpeechSelector2D value={languages} onChange={(e) => {this.handleBituiSettingChange(e, 'languages')}}
                                            menuItems={this.state.languagesList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-separator" height='10px'>&nbsp;</div>
                                    <span className="parts-of-speech-separator" height='10px'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.props.translate("wordBody")}</b></span>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("binyan")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={binyanim} onChange={(e) => this.handleBituiSettingChange(e, 'binyanim')}
                                            menuItems={this.state.binyanimList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("zman")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={zmanim} onChange={(e) => {this.handleBituiSettingChange(e, 'zmanim')}}
                                            menuItems={this.state.zmanimList}
                                        />
                                        <span className="parts-of-speech-separator" height='10px'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.props.translate("suffixPOS")}</b></span>

                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("mispar")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={misparim} onChange={(e) => {this.handleBituiSettingChange(e, 'misparim')}}
                                            menuItems={this.state.misparimList}
                                        />
                                        <PartsOfSpeechSelector2D value={misparimSof} onChange={(e) => {this.handleBituiSettingChange(e, 'misparimSof')}}
                                            menuItems={this.state.misparimList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("guf")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={gufim} onChange={(e) => {this.handleBituiSettingChange(e, 'gufim')}}
                                            menuItems={this.state.gufimList}
                                        />
                                        <PartsOfSpeechSelector2D value={gufimSof} onChange={(e) => {this.handleBituiSettingChange(e, 'gufimSof')}}
                                            menuItems={this.state.gufimList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("gender")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={genders} onChange={(e) => {this.handleBituiSettingChange(e, 'genders')}}
                                            menuItems={this.state.gendersList}
                                        />
                                        <PartsOfSpeechSelector2D value={gendersSof} onChange={(e) => {this.handleBituiSettingChange(e, 'gendersSof')}}
                                            menuItems={this.state.gendersList}
                                        />
                                    </div>
                                </td>
                                <td align="right" className="parts-of-speech-settings-table-cell"  width='40px'>&nbsp;</td>

                                <td align="right" className="parts-of-speech-settings-table-cell"  width='205px'>
                                    <span className="parts-of-speech-separator" height='10px'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{this.props.translate("affixes")}</b></span>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("prefix")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={prefixes} onChange={(e) => {this.handleBituiSettingChange(e, 'prefixes')}}
                                            menuItems={this.state.affixTypesList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("suffix")}
                                        </div>
                                        &nbsp;<TriStateCheckbox value={hasSuffix} onChange={(e) => this.handleTriState(e, 'hasSuffix')} />
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("infix")}
                                        </div>
                                        &nbsp;<TriStateCheckbox value={hasInfix} onChange={(e) => this.handleTriState(e, 'hasInfix')} />
                                    </div>
                                    <div className="parts-of-speech-separator" height='10px'>&nbsp;</div>
                                    <div className="parts-of-speech-separator" height='10px'>&nbsp;</div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("objectCategory")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={objectCategories} onChange={(e) => {this.handleBituiSettingChange(e, 'objectCategories')}}
                                            menuItems={this.state.objectCategoriesList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-dropdown-label">
                                            {this.props.translate("numberCategories")}
                                        </div>
                                        <PartsOfSpeechSelector2D value={numberCategories} onChange={(e) => {this.handleBituiSettingChange(e, 'numberCategories')}}
                                            menuItems={this.state.numberCategoriesList}
                                        />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent exactnessLevel-slider-wrapper-div">
                                       <div className = "advExactnessLevel-checkbox">
                                            <TriStateCheckbox id="advExactnessLevel" value={exactnessLevelSliderCheck} onChange={this.handleChecked} tooltip={this.props.translate("exactnessLevelCheckbox")} />
                                       </div>
                                       <div className="advExactnessLevel-slider">
                                            <FuzzyPicker sliderValue={exactnessLevel}  disabled={exactnessLevelSliderDisabled}
                                                setSlider={this.setSlilder} onChange={(e)=>{this.handleBituiSettingChange(e,'exactnessLevel')}} />
                                       </div>
                                    </div>
                                </td>
                                <td align="left" className="parts-of-speech-settings-table-cell"  width='425px'>
                                    <span className="parts-of-speech-separator" height='10px'><b>{this.props.translate("otherSettings")}</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-slider-label">
                                            {this.props.translate("frequencyTanach")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={frequency} display={frequencyDisplay} range={frequencyRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'frequency')} />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                       <div className="parts-of-speech-slider-label">
                                            {this.props.translate("lettersPerWord")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={lettersQty} display={lettersQtyDisplay} range={lettersQtyRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'lettersQty')} />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent" title={this.props.translate("includingChatafim")}>
                                       <div className="parts-of-speech-slider-label">
                                            {this.props.translate("vowelsPerWord")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={vowelsQty} display={vowelsQtyDisplay} range={vowelsQtyRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'vowelsQty')} />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent" title={this.props.translate("excludingChatafim")}>
                                       <div className="parts-of-speech-slider-label">
                                            {this.props.translate("tenuosPerWord")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={tenuosQty} display={tenuosQtyDisplay} range={tenuosQtyRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'tenuosQty')} />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-slider-label">
                                            {this.props.translate("gematria")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={gematria} display={gematriaDisplay} range={gematriaRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'gematria')} />
                                    </div>
                                    <div className="parts-of-speech-dropdown-parent">
                                        <div className="parts-of-speech-slider-label">
                                            {this.props.translate("atbash")}
                                        </div>
                                        <PartsOfSpeechSlider
                                            distance={atbash} display={atbashDisplay} range={atbashRange} sliderWidth={185} setRange={(e) => this.handlePOSsliderChange(e, 'atbash')} />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabContainer>}

        {this.props.tabValue === 3 && <TabContainer className="tabContent">
                <div style={{height: '20px', width: '1000px', float: 'right', backgroundColor: 'lightgray'}}>&nbsp;</div>
                <div style={{height: '100px', width: '1165px', display: 'inline', align: 'center', float:'right'}}>
                    <table width="1146" className="fixed_header" style={{margin: 'auto'}}>
                        <tbody>
                            <tr>
                                <td width="130" align="right" style={{minWidth: '130px'}}><i>{this.props.translate("fromTheTerm")}</i></td>
                                <td width="140" className="row-header-center">{this.props.translate("unit")}</td>
                                <td width="80" className="row-header-center">{this.props.translate("distance")}</td>
                                <td width="2.5">&nbsp;</td>
                                <td width="182" className="row-header-center-regular" style={{minWidth: '182px'}}>
                                    0&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    40&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    60&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    ∞
                                </td>
                                <td width="10">&nbsp;</td>
                                <td width="10">&nbsp;</td>
                                <td width="10">&nbsp;</td>
                                <td width="182" className="row-header-center-regular" style={{minWidth: '182px'}}>
                                    ∞&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    80&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    60&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    40&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    20&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    0
                                </td>
                                <td width="2.5">&nbsp;</td>
                                <td width="80" className="row-header-center">{this.props.translate("distance")}</td>
                                <td width="140" className="row-header-center">{this.props.translate("unit")}</td>
                                <td width="130" align="left" style={{minWidth: '130px'}}><i>{this.props.translate("fromTheTerm")}</i></td>
                            </tr>
                            <tr>
                                <DistancePicker caption={this.props.translate("toPasukEnd")} sourceNum={0} direction={containerDistDirections[0]}
                                                numMadreigos={2} madreiga={madreigos[0]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[0]} display={containerDistDisplays[0]} extSetContainerDistance={this.setContainerDistance} />
                                <td>&nbsp;</td>
                                <DistancePicker caption={this.props.translate("toPasukStart")} sourceNum={1} direction={containerDistDirections[1]}
                                                numMadreigos={2} madreiga={madreigos[1]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[1]} display={containerDistDisplays[1]} extSetContainerDistance={this.setContainerDistance} />
                            </tr>
                            <tr>
                                <DistancePicker caption={this.props.translate("toParshaEnd")} sourceNum={2} direction={containerDistDirections[2]}
                                                numMadreigos={3} madreiga={madreigos[2]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[2]} display={containerDistDisplays[2]} extSetContainerDistance={this.setContainerDistance} />
                                <td>&nbsp;</td>
                                <DistancePicker caption={this.props.translate("toParshaStart")} sourceNum={3} direction={containerDistDirections[3]}
                                                numMadreigos={3} madreiga={madreigos[3]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[3]} display={containerDistDisplays[3]} extSetContainerDistance={this.setContainerDistance} />
                            </tr>
                            <tr>
                                <DistancePicker caption={this.props.translate("toSeferEnd")} sourceNum={4} direction={containerDistDirections[4]}
                                                numMadreigos={4} madreiga={madreigos[4]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[4]} display={containerDistDisplays[4]} extSetContainerDistance={this.setContainerDistance} />
                                <td>&nbsp;</td>
                                <DistancePicker caption={this.props.translate("toSeferStart")} sourceNum={5} direction={containerDistDirections[5]}
                                                numMadreigos={4} madreiga={madreigos[5]} extSetMadreiga={this.setContainerMadreiga}
                                                distance={containerDistances[5]} display={containerDistDisplays[5]} extSetContainerDistance={this.setContainerDistance} />
                            </tr>
                        </tbody>
                    </table>
                </div>
                {quotedWordDisplayed &&
                    <div id='distance-settings-disabled-message-parent'>
                        <div id='distance-settings-disabled-message' style={directionStyling}>
                            {distanceSettingsDisabled}
                        </div>
                    </div>
                }
                {termDistanceDiv}
            </TabContainer>}
        {this.props.tabValue === 4 &&
            <TabContainer className="tabContent">
                <div style={{height: '42px', width: '1000px', float: 'right', backgroundColor: 'lightgray'}}>&nbsp;</div>
                <div style={{height: '160px', width: '1075px', display: 'inline', float: 'right', backgroundColor: 'lightgray'}}>
                    <table width="900">
                        <tbody>
                            <tr>
                                <td align="right" width="790" >
                                   <span>{this.state.bituiSelected.split("").reverse().map(function(e){ //reverse is for RTL issues
                                       var lnPicker = letterNekudaPickers[i++];
                                       return <NekudaPicker2D pickerModel={lnPicker}
                                                            extSetValues={(newVals, renderVal) => {
                                                                setPickerValues(lnPicker, newVals, renderVal);
//                                                              renderLetterNikkud(); -- moved this into setPickerValues
                                                            }}
                                                            selectStyle={"Letter"} extClassName={'nikudTaamDropdownButton'} icon={e} displayMultiple={false} />;
                                   })}</span>
                                </td>
                                <td align="left" width="120" className="row-header-left">&nbsp;{this.props.translate("nikudLetter")}</td>
                            </tr>
                            <tr>
                                <td>
                                   <span>{
                                       nArray.map(function(e){ //reverse is for RTL issues
                                           var gnPicker = generalNekudaPickers[j++];
                                           return <NekudaPicker2D pickerModel={gnPicker}
                                                                extSetValues={(newVals, renderVal) => {
                                                                    setPickerValues(gnPicker, newVals, renderVal);
//                                                                    renderGeneralNikkud(); -- moved this into setPickerValues
                                                                }}
                                                                selectStyle={"General"} extClassName={'nikudTaamDropdownButton'} displayMultiple={false} />;
                                       })
                                   }</span>
                                </td>
                                <td className="row-header-left">&nbsp;{this.props.translate("nikudGeneral")}</td>
                            </tr>
                            <tr>
                                <td>
                                   <span>{
                                       tArray.map(function(e){ //reverse is for RTL issues
                                           var gntPicker = generalTaamPickers[k++];
                                           return <TaamPicker2D pickerModel={gntPicker}
                                                                extSetValues={(newVals, renderVal) => {
                                                                    setPickerValues(gntPicker, newVals, renderVal);
//                                                                    renderGeneralHataama(); -- moved this into setPickerValues
                                                                }}
                                                                selectStyle={"General"} extClassName={'nikudTaamDropdownButton'} displayMultiple={false} />;
                                       })
                                   }</span>

                                </td>
                                <td className="row-header-left">&nbsp;{this.props.translate("hataama")}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabContainer>}
      </div>

      </div>
       </div></div></div>

                     </td>

                     <td width="40px" height='289px' style={{backgroundColor: '#919191', borderColor: 'black'}}>
                        <Button className={classes.margin} aria-label="resetAllTabs" title={clearAllSettings} onClick={() => { this.handleResetAdvancedDialogOpen() }} style={{minWidth: 0}} helpTopic="advancedLayout">
                            <img src={require("./images/closeAllTabs_gray.jpg")} alt="Clear all settings" width="30" height="28.7" />
                        </Button>
                        <Button className={classes.margin} aria-label="resetAllTabs" title={clearTab} onClick={() => { this.handleSingleTabReset() }} style={{minWidth: 0}} helpTopic="advancedLayout">
                            <img src={require("./images/closeTab_gray.jpg")} alt="Clear this tab" width="30" height="28.7" />
                        </Button>
                        <Button className={classes.margin} aria-label="saveSearchParams" title={saveSearchParams} style={{minWidth: 0}} onClick={(e) => { this.handleOpenSaveSettingsPopover(e) }} helpTopic="loadSaveSettings">
                            <img src={require("./images/icons8-save-384.png")} alt={saveSearchParams} width="30" height="28.7" />
                        </Button>
                        <Popover
                            id={'save-settings-popover'}
                            helpTopic="loadSaveSettings"
                            open={this.state.saveSettingsPopoverOpen}
                            anchorEl={this.state.saveSettingsPopoverAnchorEl}
                            onClose={this.handleCloseSaveSettingsPopover}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <a href={(URL.createObjectURL(this.state.searchSettingsBlob)) || ''} target="_blank" download='BHS_Settings.json' className='save-settings-link'>
                                <Button style={{maxWidth: '160px', minHeight: '50px'}}>
                                    <Typography className={classes.typography}>
                                        {this.props.translate("rightClickToSaveSettings.caption")}
                                    </Typography>
                                </Button>
                            </a>
                        </Popover>
                        <input
                            accept=".json"
                            className={classes.input}
                            id="search-params-upload"
                            helpTopic="loadSaveSettings"
                            multiple
                            type="file"
                            style={{display: 'none'}}
                            onChange={(e) => {this.handleLoadSearchParams(e)}}
                        />
                        <label htmlFor="search-params-upload" helpTopic="loadSaveSettings">
                            <Button component="span" className={classes.margin} style={{minWidth: 0}} aria-label="loadSearchParams" title={loadSearchParams}>
                                <img src={require("./images/icons8-opened-folder-500.png")} alt={loadSearchParams} width="30" height="28.7" />
                            </Button>
                        </label>
                     </td>
                     <td align="right" helpTopic="advancedSettings">
                         <div align="right">
                         <Paper name="term" id="box1" className="termBox1" elevation={1} >
                          <InputBase name="bituiSelected" id="bituiSelected" className="termBox1a" value={this.state.bituiSelected} onChange={this.handleChangeBituiSelected} />&nbsp;
                          <InputBase  className="termBox1b" value={this.props.translate("selectedTerm")} />
                          </Paper>
                          <div className="termBox-div" />
                         <Paper name="termBox2" id="box1" className="termBox1"  elevation={1}>
                          <InputBase name="term2" id="term2" className="termBox2a" value={letterNikkud.value} />&nbsp;
                          <IconButton type="button" className={classes.button} aria-label="Edit" color="primary" onClick={this.handleEditNikkudTaamim} style={{marginLeft: '9px'}}><EditIcon /></IconButton>
                          <InputBase className="termBox2b" value={this.props.translate("nikudLetter")} style={{marginLeft: '15px'}} />
                         </Paper>
                          <div className="termBox-div" />
                         <Paper name="termBox2" id="box1" className="termBox1"  elevation={1}>
                          <InputBase name="term2" id="term2" className="termBox2a" value={generalNikkud.value} />&nbsp;
                          <IconButton type="button" className={classes.button} aria-label="Edit" color="primary" onClick={this.handleEditNikkudTaamim} style={{marginLeft: '9px'}}><EditIcon /></IconButton>
                          <InputBase className="termBox2b" value={this.props.translate("nikudGeneral")} style={{marginLeft: '15px'}}/>
                         </Paper>
                         <div className="termBox-div" />
                         <Paper name="termBox3" id="box1" className="termBox1"  elevation={1}>
                          <InputBase name="term3" id="term3" className="termBox2a" value={generalHataama.value} />&nbsp;
                          <IconButton type="button" className={classes.button} aria-label="Edit" color="primary" onClick={this.handleEditNikkudTaamim} style={{marginLeft: '9px'}}><EditIcon /></IconButton>
                          <InputBase className="termBox2b" value={this.props.translate("hataama")} style={{marginLeft: '15px'}}/>
                          </Paper>
<div className="termBox-div" /><div className="termBox-div" /><div className="termBox-div" />
<div style={{width:'150px', display: 'inline', float:'right'}}>
    <span style={{fontSize:'12px', fontWeight:'bold'}}>{this.props.translate("termsEnteredAbove")}</span>
    <div className="termBox-div" /><div className="termBox-div" />
                <div id="agGridBituim"
                  className="ag-theme-balham"
                  style={{
                    height: '115px',
                    width: '132px'}}
                    >
                    <AgGridReact id="bituim"
                        columnDefs={this.state.bituiColumnDefs}
                        rowData={this.props.rowData}
                        onModelUpdated={this.handleBituiSelected}
                        /* onViewportChanged={this.handleBituiSelected}*/
                        onCellChanged={this.handleCellChanged}
                        onCellFocused={this.handleCellFocused}
                        onGridReady={this.bituimGridReady}
                        enableFilter={false}
                        frameworkComponents={this.state.frameworkComponents}
                        headerHeight={0}
                        rowHeight={22}
                        overlayNoRowsTemplate={'<span></span>'}
                        enableRtl="true">
                    </AgGridReact>
                </div>
</div>
<div style={{width:'142px', display: 'inline', float:'right', fontSize:'13px', fontWeight:'regular'}}>
                    &nbsp;{this.props.translate("negate")}&nbsp;<TriStateCheckbox id="ckNegative" value={isNegative} onChange={(e) => this.handleBiState(e, 'isNegative')} />
                    <div className="termBox-div" />
                    &nbsp;{this.props.translate("sofiot")}&nbsp;<TriStateCheckbox id="ckCaseSensitive" value={isCaseSensitive} onChange={(e) => this.handleBiState(e, 'isCaseSensitive')} />
                    <div className="termBox-div" />
                      &nbsp;{this.props.translate("repetitions")}&nbsp;<Spinner name="repetitions" id="repetitions" className="content-section implementation" value={repetitions} onChange={(e) => this.handleBituiSettingChange(e, 'repetitions')} min={1} max={50} />
                    <div className="termBox-div" />
                    <div className="termBox-div" />
                    <div className="termBox-div" />
                      &nbsp;<span style={{fontSize:'14px'}}>{this.props.translate("transformations")}</span>
                    <div className="termBox-div" />
                    <div className="termBox-div" />
                    <div className="termBox-div" />
                       <Paper name="termBox2" id="box1" className="boxTransformations"  elevation={1} align="center">
                           <Select className="selectTransformation" disableUnderline={true} value={transformation} onChange={(e) => this.handleBituiSettingChange(e, 'transformation')}>
            <MenuItem className="menuItem" value={0}>
              <em>בחר</em>
            </MenuItem>
                               {transformations.map(function(e){
                                   const levelStyle = {height: '16px'};
                                   return <MenuItem className="menuItem" style={levelStyle} value={e.id}>
                                   <span className="lntPickerSelectName">{e.name}</span>
                                   </MenuItem>;
                               })}
                           </Select>
                       </Paper>
</div>
                     </div>
                     </td>

                 </tr>

             </tbody>
           </table>
      </form></MuiThemeProvider>;
  }
}

AdvancedSearch.propTypes = {
  classes: PropTypes.object.isRequired, //onClick={this.closeSearchResults}
};

export default withStyles(styles)(withLocalize(AdvancedSearch));

/*
    getGridColumns(language) {
        return [ //, type: "numericColumn" merely used as trick for getting align-right until learn a better solution - align: "right"
            {id: -2, headerName: "language", field: "language", hide: true, cellRenderer: function(params) {return language;} },
            {id: -1, headerName: "#", autoHeight: true, field: "rowNum", width: 60, headerClass: "ag-header-cell-center", cellClass: "row-header-center search-results-row", filter: 'agNumberColumnFilter', pinned: "right" },
            {id: 0, headerName: "פסוק", field: "location", width: this.getPasukColumWidth(), cellClass: "row-header search-results-row", pinned: "right" },
            {id: 1, headerName: "תיבות", field: "teiva", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 2, headerName: "אותיות", field: "letter", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 3, headerName: "אותיות לתיבה", field: "lettersPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.lettersPerWord).toFixed(2);} },
            {id: 4, headerName: "אותיות מיוחדות", field: "uniqueLetter", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 5, headerName: "בהבחנת סופיות", field: "uniqueLetterCS", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 6, headerName: "הופעת אותיות", field: "letterCoverage", width: 240, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return util.showLetterCoverage(params.data.text);} },
            {id: 7, headerName: "תנועות", field: "tenua", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 8, headerName: "תנועות לתיבה", field: "tenuosPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenuosPerWord).toFixed(2);} },
            {id: 9, headerName: "תנועות לאות", field: "tenuosPerLetter", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.tenuosPerLetter).toFixed(2);} },
            {id: 10, headerName: "טעמים", field: "taam", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 11, headerName: "טעמים לתיבה", field: "taamimPerWord", width: 60, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taamimPerWord).toFixed(2);} },
            {id: 12, headerName: "טעמים לתנועה", field: "taamimPerTenua", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.taamimPerTenua).toFixed(2);} },
            {id: 13, headerName: "גמטריא", field: "standard", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 14, headerName: "גמטריא לתיבה", field: "gematriaPerWord", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.gematriaPerWord).toFixed(1);} },
            {id: 15, headerName: "גמטריא לאות", field: "gematriaPerLetter", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.gematriaPerLetter).toFixed(1);} },
            {id: 16, headerName: "אתבש", field: "atbash", width: 65, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-row" },
            {id: 17, headerName: "אתבש לתיבה", field: "atbashPerWord", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbashPerWord).toFixed(1);} },
            {id: 18, headerName: "אתבש לאות", field: "atbashPerLetter", width: 67, hide: true, headerClass: "ag-header-cell-small-font", cellClass: "row-thin-sm search-results-calc", cellRenderer: function(params) {return (params.data.atbashPerLetter).toFixed(1);} },
            {id: 19, headerName: "טקסט", field: "text", width: 1500, cellClass: "row-thin search-results-row", cellRenderer: function(params) {return util.decorateText(params.data.text, params.data.matchIndex);}}
            //this width can even handle אסתר ח:ט which has 235 chars including spaces
        ];



                                                    <b>קובע הכל כהגדרה כללית לכל הביטויים? &nbsp;&nbsp;</b>
                                                        <TriStateCheckbox id="wordForm" className="parts-of-speech-checkbox" style={{marginTop: '-3px'}} value={null} onChange={(e) => {return null}} />
                                                    <Divider />
                                                    <div className="parts-of-speech-hagdara-klalit-label">
                                                        <small>
                                                            הגדרה כללית?
                                                        </small>
                                                    </div>&nbsp;&nbsp;&nbsp;&nbsp;

                                                        <TriStateCheckbox id="wordForm" className="parts-of-speech-checkbox" value={null} onChange={(e) => {return null}} />



                        <AdvancedHelpDialog
                            isOpen={this.state.helpOpen}
                            toggleOpen={(boolean) => {this.toggleHelp(boolean)}}
                            title={this.props.translate("advancedSearch.caption")}
                        />


                        <Button className={classes.margin} aria-label="saveSearchParams" title={help} style={{minWidth: 0}} onClick={() => { this.toggleHelp(true) }}>
                            <img src={require("./images/black-help.svg")} alt={help} width="30" height="28.7" />
                        </Button>


                          <InputBase name="term2" id="term2" className="termBox2a" value={nekudaPicker.renderValue} />&nbsp;

                                    <span><NekudaPicker pickerModel={nekudaPicker} extSetValues={(newVals, renderVal) => this.setPickerValues(nekudaPicker, newVals, renderVal)} /></span>
                                <span><TaamPicker pickerModel={taamPicker} extSetValues={(newVals, renderVal) => this.setPickerValues(taamPicker, newVals, renderVal)} /></span>


                                   <span>{this.state.bituiSelected.split("").reverse().map(function(e){ //reverse is for RTL issues
                                       var picker = letterNekudaPickers[i++];
                                       return <NekudaPicker pickerModel={picker}
                                                            extSetValues={(newVals, renderVal) => {
                                                                setPickerValues(picker, newVals, renderVal);
                                                                renderLetterNikkud();
                                                            }}
                                                            selectStyle={"Letter"} icon={e} />;
                                   })}</span>




                                   <span>{this.state.bituiSelected.split("").reverse().map(function(e){ //reverse is for RTL issues
                                       return <NekudaPicker pickerModel={nekudaPickers[i]} extSetValues={setPickerValues} sourceNum={i++} selectStyle={"Letter"} icon={e} />;
                                   })}</span>
                            <tr>
                                <td><LetterPicker values={dualLetterPicker.values2} list={dualLetterPicker.list2} extSetLetterValues={this.setLetterValues} sourceNum={2}/></td>
                                <td><LetterPicker values={dualLetterPicker.values1} list={dualLetterPicker.list1} extSetLetterValues={this.setLetterValues} sourceNum={1}/></td>
                                <td align="left" className="row-header-center">&nbsp;אותיות</td>
                            </tr>
                            <tr>
                                <td><NekudaPicker values={dualNekudaPicker.values2} list={dualNekudaPicker.list2} extSetNekudaValues={this.setNekudaValues} sourceNum={2} /></td>
                                <td><NekudaPicker values={dualNekudaPicker.values1} list={dualNekudaPicker.list1} extSetNekudaValues={this.setNekudaValues} sourceNum={1} /></td>
                                <td align="left" className="row-header-center">&nbsp;נקודות</td>
                            </tr>
                            <tr>
                                <td><TaamPicker values={dualTaamPicker.values2} list={dualTaamPicker.list2} extSetTaamValues={this.setTaamValues} sourceNum={2} /></td>
                                <td><TaamPicker values={dualTaamPicker.values1} list={dualTaamPicker.list1} extSetTaamValues={this.setTaamValues} sourceNum={1} /></td>
                                <td align="left" className="row-header-center">&nbsp;טעמים</td>
                            </tr>

<IconButton className={classes.iconButton} aria-label="Keyboard" title={hebrewKeyboard} onClick={this.handleClick} style={{display: 'inline', float: 'right', backgroundColor: 'lightgray', height: '150px'}}><KeyboardIcon /></IconButton>

                    <AgGridReact id="placement"
                        ref="agGrid"
                        /* columnDefs={this.state.placementColumnDefs} ------------
                        rowData={this.state.inExRowData} //this.props.rowData
                        onGridReady={this.includeExcludeGridReady}
                        enableFilter={false}
                        frameworkComponents={this.state.frameworkComponents}
                        headerHeight={28}
                        rowHeight={40}
                        overlayNoRowsTemplate={'<span></span>'}
                        enableRtl="true">
                    </AgGridReact>
grid control
start off with single row containing empty selections - entry of picklist info on last line causes new empty line at end
trash button to clear/delete ranges - deleting when before last line and > 1 rows will cause row to be deleted
cell 1 = named section picklist (choosing here wipes out/disables cell 2 selections - make this clear to user somehow that it's either/or)
cwll 2 - picklists for sefer1, perek1, pasuk1, sefer2, perek2, pasuk2 (choosing here wipes out/disables cell 1 selections)
PHS/Kria/Aliya.... named section, aliya picker to set range
map on server between ids and sefer/perek/pasuk text or named section
later can get more fine grained eg. aliyos and sidros

                     <Paper name="advSearchBox" id="box1" className="advSearchBox"  elevation={1}>
                      <Spinner name="repetitions" id="repetitions" className="content-section implementation" value={this.state.repetitions} onChange={this.handleChangeRep} min={1} max={10} />
                      <InputBase className="advSearchCaption" value={' חזרות'} />
                     </Paper>
*/





