import React, { Component, useState } from 'react';
import * as properties from './config/properties.js';
import './css/App.css';
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import {Checkbox} from 'primereact/checkbox';
import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { renderToStaticMarkup } from "react-dom/server";
import MoreVert from '@material-ui/icons/MoreVert';
import OpenInNew from '@material-ui/icons/OpenInNew';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SubjectIcon from '@material-ui/icons/Subject';
import StatPicker2D from './StatPicker2D';

function PickerModel() {
    this.list = [];
    this.values = [];
  }

const letterCoverageGridPos = 9, textGridPos = 23, targumGridPos = 22;

class ContextMenu extends Component {

 constructor(props) {
    super(props);
    this.state = {
       zIndex: 0,
       statisticsArray: [],
       whichStatistics: this.props.whichStatistics,
       statisticsPicker: {list:this.getPickerList(), values:[]},
       openStatPicker:false,
       values:[],
    };

    this.props.initialize({
      languages: [
        { name: "עברית", code: "he" },
        { name: "English", code: "en" }
      ],
      translation: translations,
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.props.activeLanguage ? this.props.activeLanguage.code : 'en' }
    });

    this.props.addTranslation(translations);
    this.setLanguage = (code) => {props.setActiveLanguage(code)}

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.searchSelected = this.searchSelected.bind(this);
    this.toggleUseKinuim = this.toggleUseKinuim.bind(this);
    this.toggleIncludeStatistics = this.toggleIncludeStatistics.bind(this);
    this.toggleIncludeMekorot = this.toggleIncludeMekorot.bind(this);
    this.toggleSearchAsQuote = this.toggleSearchAsQuote.bind(this);
    this.addMakor = this.addMakor.bind(this);
    this.handleParseTaamim = this.handleParseTaamim.bind(this);
    this.getPasukIdOrMakor = this.getPasukIdOrMakor.bind(this);
    this.openTanachViewer = this.openTanachViewer.bind(this);
    this.copyStatsOfPasuk = this.copyStatsOfPasuk.bind(this);
    this.formatLNT = this.formatLNT.bind(this);
    this.formatCopiedStats = this.formatCopiedStats.bind(this);
    this.applyPickerValues = this.applyPickerValues.bind(this);
    this.setPickerValues = this.setPickerValues.bind(this);
  }

  async openTextComparator(contextType){
      //get the pasuk data
    var pasuk1Data = null;
    var pasuk2Data = null;
    if(this.props.resultsApi){
        var selectedKeys = Object.keys(this.props.resultsApi.selectionController.selectedNodes);
        for(let i = 0; i < selectedKeys.length; i++){
            var currKey = Object.keys(this.props.resultsApi.selectionController.selectedNodes)[i];
            var currNode = this.props.resultsApi.selectionController.selectedNodes[currKey]
            if(currNode){
              if(!pasuk1Data){
                pasuk1Data = currNode
              } else if(!pasuk2Data){
                  pasuk2Data = currNode
              }
            }
        }
    }

    this.props.fillTextComparator(contextType, pasuk1Data, pasuk2Data)
  }

    openTanachViewer() {
        var pasukId = this.getPasukIdOrMakor();
        this.props.toggleTanachViewer(true, pasukId, this.props.resultsApi,this.props.columnClicked);
    }

    handleParseTaamim(){
        var pasukIdOrMakor = this.getPasukIdOrMakor();
        if(['searchResults', 'tanachViewer'].includes(this.props.menuType)){
            this.props.extOpenTaamParser(pasukIdOrMakor);
        } else if (this.props.menuType === 'frequencyViewer') {
            if(typeof(pasukIdOrMakor) === 'number'){
                this.props.extOpenTaamParser(pasukIdOrMakor);
            }
        } else if (this.props.menuType === 'parshaViewer'){
            if(this.props.isStatsContextMenu){
                //todo - what should happen here?
            } else {
                this.props.extOpenTaamParser(pasukIdOrMakor);
            }
        } else if (this.props.menuType === 'diff'){
            this.props.extOpenTaamParser(this.props.makor);
        }    
    }
    handleOpenLexicon(word1,word2,word3,word4){//3 diff options of what the word could be.
        var highlightedWord = this.props.menuType==="tanachViewer" && !word4 ? this.props.textCellRowText : window.getSelection().toString()
        if(highlightedWord===""){
            highlightedWord = word4 ? word4 : word1 ? word1 : word2 ? word2 : word3 
            // var focusedCellRowIndex = this.props.resultsApi?this.props.resultsApi.getFocusedCell()?this.props.resultsApi.getFocusedCell().rowIndex:null:null;
            // highlightedWord = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.term? this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.term:
            // // this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.term.split(" ").length===1) ||
            // this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data[this.props.resultsApi.getFocusedCell().column.colId.split(".")[0]][this.props.resultsApi.getFocusedCell().column.colId.split(".")[1]]//.split(" ").length===1
        }
        if(this.props.columnClicked==="תרגום"){
            this.props.extOpenLexiconViewer("Targum", this.formatLNT("L", highlightedWord))
        }else if(this.props.columnClicked===undefined || this.props.columnClicked==="טקסט" || this.props.columnClicked==="תנך" || this.props.columnClicked==="סתם" || this.props.columnClicked==="עברי" ){
            this.props.extOpenLexiconViewer("Teiva", this.formatLNT("L", highlightedWord))
        }else if(this.props.columnClicked==="גמטריא"){
            this.props.extOpenLexiconViewer("Gematria", this.formatLNT("L", highlightedWord))
        }else if(this.props.columnClicked==="אתבש"){
            this.props.extOpenLexiconViewer("Atbash", this.formatLNT("L", highlightedWord))
        }
    }

        getPasukIdOrMakor(){
            var pasukId = null;
            if(this.props.menuType === 'searchResults'){
                var focusedCellRowIndex = null ;
                if(this.props.resultsApi.getFocusedCell()){
                    var focusedCellRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
                }
                var focusedCellRowPasukId = null;
                if((typeof(focusedCellRowIndex) === 'number') && this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
                    focusedCellRowPasukId = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data.pasukId;
                }
                pasukId = focusedCellRowPasukId;
            } else if (this.props.menuType === 'frequencyViewer') {
                var focusedCellRowIndex = null ;
                if(this.props.resultsApi.getFocusedCell()){
                    var focusedCellRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
                }
                var pasukId = null;
                var focusedCellRowData = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data
                var focusedCellColumnId = this.props.resultsApi.getFocusedCell().column.colId
                if(['location', 'pasukText'].includes(focusedCellColumnId)){
                    pasukId = focusedCellRowData.pasukId;
                } else if(['location1', 'pasukText1'].includes(focusedCellColumnId)){
                    pasukId = focusedCellRowData.pasukId1;
                } else if (['location2', 'pasukText2'].includes(focusedCellColumnId)) {
                    pasukId = focusedCellRowData.pasukId2;
                } else if (this.props.currentFreq === '1') {
                    pasukId = focusedCellRowData.pasukId;
                }
//                if(typeof(pasukId) === 'number'){
//                    this.props.extOpenTaamParser(pasukId);
//                }
            } else if (this.props.menuType === 'parshaViewer'){
                if(this.props.isStatsContextMenu){
                    //todo - what should happen here?
                } else {
                    pasukId = this.props.textCellRowMakor;
                }
            } else if (this.props.menuType === 'tanachViewer'){
                pasukId = this.props.pasukLocation;
            } else if (this.props.menuType === 'diff'){
                pasukId = this.props.makor;
            }
            return pasukId;
        }

    toggleUseKinuim(){
        this.props.extToggleUseKinuim();
    }

    toggleIncludeStatistics(){
        this.props.toggleIncludeStatistics();
    }

    toggleIncludeMekorot(){
        this.props.extToggleIncludeMekorot();
    }

    toggleSearchAsQuote(){
        this.props.extToggleSearchAsQuote();
    }

    addMakor(text, makor){
        //changing colon between sefer and perek to a space
        var makorArray = makor.split(':');
        makor = `${makorArray[0]} ${makorArray[1]}:${makorArray[2]}`

        if(this.props.includeMekorot){
            text = `(${makor}) ${text}`
        }
        if(this.props.includeStatistics){
            return makor;
        }
        return text
    }

    copyToClipboard(LNT,word){
        //get selection
        var selection = '';
        var fakeStatisticsArray=[];
        var focusedCellRowIndex = null ;
        if(this.props.resultsApi && this.props.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        var focusedCellRowMakor = null;

        if((typeof(focusedCellRowIndex) === 'number') && this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            var focusedCellRowData = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data;
            var focusedCellColumnId = this.props.resultsApi.getFocusedCell().column.colId;
            if(this.props.menuType === 'searchResults') {
                focusedCellRowText = this.props.columnClicked==='תרגום'? focusedCellRowData.targumText:focusedCellRowData.text;
                focusedCellRowMakor = focusedCellRowData.location;
                // if(word){
                //     focusedCellRowText = this.props.wordToCopy;
                // }
            } else if (this.props.menuType === 'frequencyViewer'){
                if(this.props.currentFreq === '3+' || (this.props.currentFreq === '1' && ['rowNum_1', 'testField_1'].includes(focusedCellColumnId))){
                    focusedCellRowText = focusedCellRowData.testField;
                } else if(['location', 'pasukText'].includes(focusedCellColumnId)){
                    focusedCellRowText = focusedCellRowData.pasukText;
                    focusedCellRowMakor = focusedCellRowData.location;
                } else if(['location1', 'pasukText1'].includes(focusedCellColumnId)){
                    focusedCellRowText = focusedCellRowData.pasukText1;
                    focusedCellRowMakor = focusedCellRowData.location1;
                } else if (['location2', 'pasukText2'].includes(focusedCellColumnId)) {
                    focusedCellRowText = focusedCellRowData.pasukText2;
                    focusedCellRowMakor = focusedCellRowData.location2;
                } else if (['rowNum', 'testField'].includes(focusedCellColumnId)) {
                    focusedCellRowText = focusedCellRowData.testField;
                    focusedCellRowMakor = `${focusedCellRowData.location1}, ${focusedCellRowData.location2}`;
                }
            } else if (this.props.menuType === 'textComparator'){
                if(focusedCellRowData.term){
                    focusedCellRowText = focusedCellRowData.term;
                }else if(focusedCellColumnId){
                    var term = focusedCellColumnId.split(".")
                    if(term[1]==='text' || term[1]==='pasukText'){
                        focusedCellRowText = focusedCellRowData[term[0]][term[1]]
                    }else return false
                }
            } else if (this.props.menuType === 'parshaViewer'){
                if(this.props.isStatsContextMenu){
                    //todo - what should happen here?
                    focusedCellRowText = focusedCellRowData.pasukRange;
                    focusedCellRowMakor = 'dummySefer:dummyPerek:dummyPasuk';
                } else {
                    focusedCellRowText = this.props.textCellRowText;
                    focusedCellRowMakor = this.props.textCellRowMakor;
                }
            } else if (this.props.menuType === 'tanachViewer'){
                focusedCellRowText = this.props.textCellRowText;
                focusedCellRowMakor = this.props.pasukLocation;
            }
        } else if (this.props.menuType === 'tanachViewer'){
            focusedCellRowText = this.props.textCellRowText;
            focusedCellRowMakor = this.props.pasukLocation;
            // if(word){
            //     focusedCellRowText = this.props.wordToCopy
            // }
        } else if (this.props.menuType === 'diff'){
            focusedCellRowText = this.props.text;
            focusedCellRowMakor = this.props.makor;
        }
        if(word){
            focusedCellRowText = this.props.wordToCopy
        }


        if(['focused', 'pasuk'].includes(this.props.textToCopy)){
            selection = this.addMakor(this.addKinuim(focusedCellRowText), focusedCellRowMakor);
        } else if(this.props.textToCopy === 'highlighted'){
            var highlightedText = window.getSelection().toString();
            if(this.props.menuType === 'tanachViewer'){
                highlightedText = focusedCellRowText;
            }
            if(this.props.menuType === 'parshaViewer' && this.props.textDisplayType === 'stream'){
                selection = this.addMakor(this.addKinuim(this.removeParshaViewerStreamViewMekorot(highlightedText, 'copyToClipboard')), focusedCellRowMakor);
            } else {
                selection = this.addMakor(this.addKinuim(highlightedText), focusedCellRowMakor);
            }
        } else if (this.props.textToCopy === 'selected') {
            var selectedRows = this.props.resultsApi.getSelectedRows();
            for(let i = 0; i < selectedRows.length; i++){
                var selectedRow = selectedRows[i];
                var text = this.props.columnClicked==='תרגום'?selectedRow.targumText:selectedRow.text;
                var selectedRowWithMakor = this.addMakor(this.addKinuim(text), selectedRow.location);
                selection = selection.concat(selectedRowWithMakor + "\n")
                if((this.props.menuType === 'searchResults' && this.props.includeStatistics) || (this.props.menuType === 'searchResults' && this.props.contentDisplayed && this.props.contentDisplayed.length>1)){
                    fakeStatisticsArray.push( this.copyStatsOfPasuk(selectedRow, LNT));
                }
            }
            selection = selection.slice(0, selection.length-1);
        } else if (this.props.textToCopy === 'bitui'){
            selection = this.addKinuim(focusedCellRowText);
        }
        if(this.props.textToCopy === 'focused' && this.props.menuType === 'searchResults' && this.props.includeStatistics){//if the user wants to copy the stats
            fakeStatisticsArray.push( this.copyStatsOfPasuk(focusedCellRowData, LNT));
        }

        if(this.props.includeStatistics || (this.props.contentDisplayed && this.props.contentDisplayed.length > 1 && this.props.textToCopy==='selected')){
            this.setState({ statisticsArray: fakeStatisticsArray}, ()=> this.formatCopiedStats());
        }
        // var textToCopy = this.formatLNT(LNT, selection)
        // this.copyToClipboard2(textToCopy)
        //         .then(() => console.log('text copied !'))
        //         .catch(() => console.log('error'));
    
        navigator.clipboard.writeText(this.formatLNT(LNT, selection));
        
    }

    copyToClipboard2(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }
    

    formatLNT(LNT, selection){
        //LNT formatting
        if (LNT === "LN"){
            selection = this.removeTaamim(selection);
        } else if (LNT === "L"){
            selection = this.removeTaamim(this.removeNikud(selection));
        }
        return selection;
    }

    copyStatsOfPasuk(pasuk, LNT){
        var toPush = {};//{title: null, stat: null};
        var statArray = []//this.state.statisticsArray;
        if(this.props.includeMekorot){
            toPush.title = this.props.activeLanguage.code === "en" ? "Location" : "פסוק";
            toPush.stat = this.addMakor("", pasuk.location);
            statArray.push(toPush);
            toPush = {};
        }
        if(this.props.includeStatistics){
            if(this.props.statsToCopy === "all"){
                for(let i=0; i<this.props.whichStatistics.list.length; i++){
                    if((this.props.whichStatistics.list[i].id!==textGridPos) && (this.props.whichStatistics.list[i].id!==targumGridPos)){
                        toPush.title = this.props.whichStatistics.list[i].name ;//the name is based on the language
                        if(this.props.whichStatistics.list[i].id === letterCoverageGridPos){//if it is letter coverage
                            toPush.stat = util.showLetterCoverage(pasuk.text, false);
                        }
                        else{
                            toPush.stat = pasuk[this.props.whichStatistics.list[i].colName];
                        }
                        statArray.push(toPush);
                        toPush = {}
                    }
                }
            }else{
                for(let i=0; i<this.props.whichStatistics.values.length; i++){
                    if((this.props.whichStatistics.list[this.props.whichStatistics.values[i]-1].id!==textGridPos) && (this.props.whichStatistics.list[this.props.whichStatistics.values[i]-1].id!==targumGridPos)){
                        toPush.title = this.props.whichStatistics.list[this.props.whichStatistics.values[i]-1].name;
                        if(this.props.whichStatistics.list[this.props.whichStatistics.values[i]-1].id === letterCoverageGridPos){
                            toPush.stat = util.showLetterCoverage(pasuk.text, false);
                        }else{
                            toPush.stat = pasuk[this.props.whichStatistics.list[this.props.whichStatistics.values[i]-1].colName];
                        }
                        statArray.push(toPush)
                        toPush = {}
                    }
                }
            }
        }
        if(this.props.contentDisplayed && this.props.contentDisplayed.includes('targum')){
            toPush.title = this.props.activeLanguage.code === "en" ? "Targum" : "תרגום";
            toPush.stat = this.formatLNT(LNT, this.addKinuim(pasuk["targumText"]));
            statArray.push(toPush);
            toPush={}
        }
        if(this.props.contentDisplayed && this.props.contentDisplayed.includes('tanach')){
            toPush.title = this.props.activeLanguage.code === "en" ? "Text" : "טקסט";
            toPush.stat = this.formatLNT(LNT, this.addKinuim(pasuk["text"]));//toDo: put the focused word here instead?
            statArray.push(toPush);
        }
        return statArray;

        // this.setState(prevState => ({
        //     statisticsArray: [...prevState.statisticsArray, statArray] 
        //   }))


        // this.setState({ statisticsArray: statArray });
    }

    formatCopiedStats(){
        var selection = "";
        var header = "";
        if(this.props.howToPaste === "header row"){
            if(this.state.statisticsArray.length>0){
                for(let i=this.state.statisticsArray[0].length-1; i>=0; i--){
                    selection = selection.concat(this.state.statisticsArray[0][i].title+"\t")//set up header row
                }
                header = selection +"\n";
                for(let j=0; j<this.state.statisticsArray.length; j++){
                    selection = "";
                    for(let i=this.state.statisticsArray[j].length-1; i>=0; i--){
                        selection = selection.concat((isNaN(this.state.statisticsArray[j][i].stat) || Number.isInteger(this.state.statisticsArray[j][i].stat)) ?
                                this.state.statisticsArray[j][i].stat+"\t" : this.state.statisticsArray[j][i].stat.toFixed(2)+"\t")
                    }
                    header += selection +"\n";
                }
            }
        }else{
            for(let i=0; i<this.state.statisticsArray.length; i++){
                for(let j=0; j<this.state.statisticsArray[i].length; j++){
                    selection = selection.concat(this.state.statisticsArray[i][j].title +":" 
                                    + ((isNaN(this.state.statisticsArray[i][j].stat) || Number.isInteger(this.state.statisticsArray[i][j].stat)) ? this.state.statisticsArray[i][j].stat :
                                                    this.state.statisticsArray[i][j].stat.toFixed(2))+" ")
                }
                header = header.concat(selection  + "\n");
                selection = "";
            }
        }
        // this.copyToClipboard2(header)
        //     .then(() => console.log('text copied !'))
        //     .catch(() => console.log('error'));
    
        navigator.clipboard.writeText(header);
        // this.setState({statisticsArray:[]});//reset the array at the end
    }

    searchSelected(LNT, copyOrSearch, word){
        //get selection
        var selection = '';

        var focusedCellRowIndex = null ;
        if(this.props.resultsApi && this.props.resultsApi.getFocusedCell()){
            var focusedCellRowIndex = this.props.resultsApi.getFocusedCell().rowIndex;
        }
        var focusedCellRowText = null;
        if(word){
            focusedCellRowText = this.props.wordToCopy;
        } else if((typeof(focusedCellRowIndex) === 'number') && this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]){
            var focusedCellRowData = this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex].data;
            var focusedCellColumnId = this.props.resultsApi.getFocusedCell().column.colId;
            if(this.props.menuType === 'searchResults') {
                focusedCellRowText = this.props.columnClicked==="תרגום" ? focusedCellRowData.targumText: focusedCellRowData.text;
            } else if (this.props.menuType === 'frequencyViewer'){
                if(this.props.currentFreq === '3+' || (this.props.currentFreq === '1' && ['rowNum_1', 'testField_1'].includes(focusedCellColumnId))){
                    focusedCellRowText = focusedCellRowData.testField;
                } else if(['location', 'pasukText'].includes(focusedCellColumnId)){
                    focusedCellRowText = focusedCellRowData.pasukText;
                } else if(['location1', 'pasukText1'].includes(focusedCellColumnId)){
                    focusedCellRowText = focusedCellRowData.pasukText1;
                } else if (['location2', 'pasukText2'].includes(focusedCellColumnId)) {
                    focusedCellRowText = focusedCellRowData.pasukText2;
                } else if (['rowNum', 'testField'].includes(focusedCellColumnId)) {
                    focusedCellRowText = focusedCellRowData.testField;
                }
            } else if (this.props.menuType === 'textComparator'){
                if(focusedCellRowData.term){
                    focusedCellRowText = focusedCellRowData.term;
                }else if(focusedCellColumnId){
                    var term = focusedCellColumnId.split(".")
                    if(term[1]==='text' || term[1]==='pasukText'){
                        focusedCellRowText = focusedCellRowData[term[0]][term[1]]
                    }else return false
                }

            } else if (this.props.menuType === 'parshaViewer') {
                if(this.props.isStatsContextMenu){
                    focusedCellRowText = focusedCellRowData.pasukRange;
                } else {
                    focusedCellRowText = this.props.textCellRowText;
                }
            } else if (this.props.menuType === 'tanachViewer') {
                focusedCellRowText = this.props.textCellRowText;
            }
        } else if (this.props.menuType === 'tanachViewer'){
            focusedCellRowText = this.props.textCellRowText;
        } else if (this.props.menuType === 'diff'){
            focusedCellRowText = this.props.text;
        }

        if(['focused', 'pasuk'].includes(this.props.textToCopy)){
            selection = focusedCellRowText;
        } else if(this.props.textToCopy === 'highlighted'){
            var highlightedText = window.getSelection().toString();
            if(this.props.menuType === 'tanachViewer'){
                highlightedText = focusedCellRowText;
            }
            if(this.props.menuType === 'parshaViewer' && this.props.textDisplayType === 'stream'){
                selection = this.removeParshaViewerStreamViewMekorot(highlightedText, 'searchSelected');
            } else {
                selection = highlightedText;
            }
        } else if (this.props.textToCopy === 'selected') {
            var selectedRows = this.props.resultsApi.getSelectedRows();
            for(let i = 0; i < selectedRows.length; i++){
                var selectedRow = selectedRows[i];
                var selectedRowText = this.props.columnClicked==='תרגום'?selectedRow.targumText:selectedRow.text;
                selection = selection.concat(selectedRowText + " ");
            }
            selection = selection.slice(0, selection.length-1)
        } else if (this.props.textToCopy === 'bitui'){
            selection = this.addKinuim(focusedCellRowText);
        }

        //LNT formatting
        if (LNT === "LN"){
            selection = this.removeTaamim(selection);
        } else if (LNT === "L"){
            selection = this.removeTaamim(this.removeNikud(selection));
        }

        //quote formatting
        if(this.props.searchAsQuote){
            selection = `\"${selection}\"`;
        }
        if(copyOrSearch === 'copy'){
            this.props.extSetTextSource((this.props.columnClicked==="תרגום" )?'targum':'tanach')
            this.props.extCopySelectedToSearchbar(selection);
        } else if (copyOrSearch === 'search'){
            this.props.extSetTextSource((this.props.columnClicked==="תרגום" )?'targum':'tanach')
            this.props.extSearchWithSelected(selection);
        }
    }

    searchPersonObject(){
        this.props.searchPersonInTanach();
    }

    removeNikud(str){
        if(!str) return null;
        for (let i = str.length-1; i >= 0; i--){
            if('ְֱֲֳִֵֶַָֹֺֻּֽ־ׇֿׁׂׅׄ'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    removeTaamim(str){
        if(!str) return null;
        for (let i = str.length-1; i >= 0; i--){
            if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(str[i])){
                str = str.slice(0,i).concat(str.slice(i+1,str.length))
            }
        }
        return str;
    }

    removeParshaViewerStreamViewMekorot(text, menuOption){
        var reformattedText = '';
        var isMakorText = false;
        for(let i = text.length-1; i >= 0; i--){
            if(text[i] === ')'){
                isMakorText = true;
                if(menuOption === 'copyToClipboard'){
                    reformattedText = ':'.concat(reformattedText);
                }
            }
            if(!isMakorText){
                reformattedText = text[i].concat(reformattedText);
            }
            if(isMakorText && text[i] === ' '){
                isMakorText = false;
            }
        }
        //remove extra spaces, colons at the beginning
        while([' ',':'].includes(reformattedText[0])){
            reformattedText = reformattedText.slice(1, reformattedText.length);
        }

        return reformattedText;
    }

    addKinuim(str){
        if(this.props.useKinuim){
            let words = str.split(' ')
            var trop = ''
            var kinui = 'ה'
            for(let i = 0; i < words.length; i ++){
                var word = words[i]
                var noNikudTaamimWord = this.removeTaamim(this.removeNikud(word))
                var lastFourLetters = noNikudTaamimWord? noNikudTaamimWord.slice(noNikudTaamimWord.length-4, noNikudTaamimWord.length):""
                if(lastFourLetters === 'יהוה'){
                    var yudIndex = null;
                    for (let j = word.length-1; j >=0; j--){
                        if (word[j] === 'י'){
                            yudIndex = j;
                            break;
                        }
                    }
                    var wordWithoutPrefix = word.slice(yudIndex, word.length)
                    for(let k = 0; k < wordWithoutPrefix.length; k++){
                        if('ֽ֑֖֛֢֣֤֥֦֧֪֚֭֮֒֓֔֕֗֘֙֜֝֞֟֠֡֨֩֫֬֯׀'.includes(wordWithoutPrefix[k])){
                            trop = trop.concat(wordWithoutPrefix[k])
                        }
                    }
                    kinui = kinui.concat(trop).concat('\'')

                    var prefixes = word.slice(0, yudIndex);

                    var wordWithKinui = prefixes.concat(kinui);

                    words[i] = wordWithKinui;

                    var trop = ''
                    var kinui = 'ה'
                }
            }
            str = words.join(" ");
        }
        return str;
    }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.isOpen && !prevProps.isOpen){
      this.showOnTop();
    }

  }

  findHighestZIndex(elem) {
    var elems = document.getElementsByTagName("*");
    var highest = 0;
    for (var i = 0; i < elems.length; i++)
    {
      var zindex=document.defaultView.getComputedStyle(elems[i],null).getPropertyValue("z-index");
      if ((zindex > highest) && (zindex != 'auto') && (zindex != 9998))// && (zindex != 100000090))
      {
        highest = zindex;
      }
    }
    return 100000090 //this is here to make the context menu open on top of the wordTooltips.
    return parseFloat(highest);
  }

  showOnTop(){
      this.setState({
        zIndex: this.findHighestZIndex() + 1
      });
  }
    setPickerValues(newVals) {
        if (this.state.statisticsPicker.list == null || this.state.statisticsPicker.list.length == 0) {
            this.setState(Object.assign(this.state.statisticsPicker, {list: this.getPickerList(), values: newVals})); //force lazy initialization of item list here
        }
        else {
            this.setState(Object.assign(this.state.statisticsPicker, {values: newVals}));
        }
    }

    applyPickerValues() {
        if(this.state.values.length>0){
            this.props.searchTanach(this.state.statisticsPicker,this.props.resultsApi.getModel().rowsToDisplay[this.props.resultsApi.getFocusedCell().rowIndex].data)
        }
    }

  getPickerList(){
    const parshaName = this.props.translate("parshaName");
    const sidraName = this.props.translate("sidraName");
    const otherKriosName = this.props.translate("otherKriosName");
    const words = this.props.translate("words");
    const letters = this.props.translate("letters");
    const uniqueLetters = this.props.translate("byUniqueLetters");
    const uniqueLettersCS = this.props.translate("byUniqueLettersCS");
    const tenua = this.props.translate("tenuos");
    const taam = this.props.translate("taamim");
    const gematria = this.props.translate("gematria");
    const atbash = this.props.translate("atbash");
    const letterCoverage = this.props.translate("letterCoverage");
    const perWord = this.props.translate("perWord");
    const perLetter = this.props.translate("perLetter");
    const perTenua = this.props.translate("perTenua");
    const targumText = this.props.translate("targum");
    const text = this.props.translate("text");
    const lettersPerWord = letters + " " + perWord;
    const tenuosPerWord = tenua + " " + perWord;
    const tenuosPerLetter = tenua + " " + perLetter;
    const taamimPerWord = taam + " " + perWord;
    const taamimPerTenua = taam + " " + perTenua;
    const gematriaPerWord = gematria + " " + perWord;
    const gematriaPerLetter = gematria + " " + perLetter;
    const atbashPerWord = atbash + " " + perWord;
    const atbashPerLetter = atbash + " " + perLetter;
    return[
        {id: 1, name: parshaName, colName: "parshaName", icon: ""},
        {id: 2, name: sidraName, colName: "sidraName", icon: ""},
        {id: 3, name: otherKriosName, colName: "otherKriosName", icon: ""},
        {id: 4, name: words, colName: "teiva", icon: ""},
        {id: 5, name: letters, colName: "letter", icon: ""},
        {id: 6, name: lettersPerWord, colName: "lettersPerWord", icon: ""},
        {id: 7, name: uniqueLetters, colName: "uniqueLetter", icon: ""},
        {id: 8, name: uniqueLettersCS, colName: "uniqueLetterCS", icon: ""},
        {id: letterCoverageGridPos, name: letterCoverage, colName: "letterCoverage", icon: ""},
        {id: 10, name: tenua, colName: "tenua", icon: ""},
        {id: 11, name: tenuosPerWord, colName: "tenuosPerWord", icon: ""},
        {id: 12, name: tenuosPerLetter, colName: "tenuosPerLetter", icon: ""},
        {id: 13, name: taam, colName: "taam", icon: ""},
        {id: 14, name: taamimPerWord, colName: "taamimPerWord", icon: ""},
        {id: 15, name: taamimPerTenua, colName: "taamimPerTenua", icon: ""},
        {id: 16, name: gematria, colName: "standard", icon: ""},
        {id: 17, name: gematriaPerWord, colName: "gematriaPerWord", icon: ""},
        {id: 18, name: gematriaPerLetter, colName: "gematriaPerLetter", icon: ""},
        {id: 19, name: atbash, colName: "atbash", icon: ""},
        {id: 20, name: atbashPerWord, colName: "atbashPerWord", icon: ""},
        {id: 21, name: atbashPerLetter, colName: "atbashPerLetter", icon: ""}
    ];
  }

  render() {
       var isYuchsinTree = this.props.menuType === "yuchsin-tree";

       var copyToClipboard = this.props.translate('copySelectedToClipboard');
       var copyToSearch = this.props.translate('copySelectedToSearch');
       var searchWith = isYuchsinTree ? this.props.translate('searchTanachWithSelected') : this.props.translate('searchWithSelected');
       var searchWithWord,copyWordToSearch,copyWord
       var searchSimilarPesukimWords = this.props.translate("searchSimilarPesukimWords");
        if(this.props.textToCopy === 'focused'){
             copyToClipboard = this.props.translate('copyFocusedToClipboard');
             copyToSearch = this.props.translate('copyFocusedToSearch');
             searchWith = this.props.translate('searchWithFocused');
             searchWithWord = this.props.translate('searchWithFocusedWord');
             copyWordToSearch = this.props.translate('copyFocusedWordToSearch');
             copyWord = this.props.translate('copyFocusedWordToClipboard');
        } else if (this.props.textToCopy === 'highlighted'){
             copyToClipboard = this.props.translate('copyHighlightedToClipboard');
             copyToSearch = this.props.translate('copyHighlightedToSearch');
             searchWith = this.props.translate('searchWithHighlighted');
        } else if (this.props.textToCopy === 'selected'){
             searchWithWord = this.props.translate('searchWithFocusedWord');
             copyWordToSearch = this.props.translate('copyFocusedWordToSearch');
             copyWord = this.props.translate('copyFocusedWordToClipboard');
             copyToClipboard = this.props.translate('copySelectedToClipboard');
             copyToSearch = this.props.translate('copySelectedToSearch');
             searchWith = this.props.translate('searchWithSelected');
        } else if (this.props.textToCopy === 'bitui'){
             copyToClipboard = this.props.translate('copyBituiToClipboard');
             copyToSearch = this.props.translate('copyBituiToSearch');
             searchWith = this.props.translate('searchWithBitui');
             searchWithWord = this.props.translate('searchWithFocusedWord');
             copyWordToSearch = this.props.translate('copyFocusedWordToSearch');
             copyWord = this.props.translate('copyFocusedWordToClipboard');
        } else if (this.props.textToCopy === 'pasuk'){
             copyToClipboard = this.props.translate('copyPasukToClipboard');
             copyToSearch = this.props.translate('copyPasukToSearch');
             searchWith = this.props.translate('searchWithPasuk');
             searchWithWord = this.props.translate('searchWithFocusedWord');
             copyWordToSearch = this.props.translate('copyFocusedWordToSearch');
             copyWord = this.props.translate('copyFocusedWordToClipboard');
        }

     const letters = this.props.translate('letters');
     const lettersNekudot = this.props.translate('letters').concat(', ').concat(this.props.translate('nekudos'));
     const lettersNekudotTaamim = this.props.translate('letters').concat(', ').concat(this.props.translate('nekudos')).concat(', ').concat(this.props.translate('taamim'));
     const person = this.props.translate('person');
     const includeMekorot = this.props.translate('includeMekorot');
     const useKinuim = this.props.translate('useKinuim');
     const parseTaamim = this.props.translate('parseTaamim');
     const readContext = this.props.translate('readContext');
     const moreDetails = this.props.translate('moreDetails');
     const copyAsQuote = this.props.translate('copyAsQuote');
     const searchAsQuote = this.props.translate('searchAsQuote');
     const taamParser = this.props.translate('taamParser');
     const lexiconViewer = this.props.translate('lexicon');//searchInLexicon');
     const tanachViewer = this.props.translate('tanachViewer');
     const openPasukIn = isYuchsinTree ? this.props.translate('openFirstOccurrenceIn') : this.props.translate('openPasukIn');
     const openFocusedWordIn = this.props.translate('openFocusedWordIn');
     const parsha = this.props.translate('parsha');
     const twentyOnePesukim = this.props.translate('twentyOnePesukim');
     const textComparator = this.props.translate('textComparator.caption');
     const includeStatistics = this.props.translate('includeStatistics');
     const resultStatistics = this.props.translate("resultStatistics");

     const isHebrew = this.props.activeLanguage && this.props.activeLanguage.code === "he";

     const frequencyViewerNotPasuk = this.props.resultsApi && this.props.menuId === 'frequency-viewer-context-menu' && this.props.currentFreq === '2' && this.props.resultsApi.getFocusedCell() && ['rowNum', 'testField'].includes(this.props.resultsApi.getFocusedCell().column.colId)
     const isTextComparator = this.props.menuId === 'text-comparator-viewer-context-menu';
     var isNotAPasuk = (frequencyViewerNotPasuk || isTextComparator);
     var allDisabled = (this.props.menuType === "diff" && !this.props.isAPasuk) || this.props.menuType==="textComparatorObjectCloud";

     if(this.props.currentFreq === '3+'){
        isNotAPasuk = true;
     } else if (this.props.currentFreq == '1'){
        if(this.props.resultsApi.getFocusedCell() && (['rowNum_1', 'testField_1'].includes(this.props.resultsApi.getFocusedCell().column.colId) || ['rowNum', 'testField','location'].includes(this.props.resultsApi.getFocusedCell().column.colId))){
            isNotAPasuk = true;
        }
     }

     var statisticsPicker = new PickerModel();
     statisticsPicker.list = this.getPickerList()
     statisticsPicker.values = this.state.statisticsPicker.values;

     const fakeThis = this;

    var menuStyling = {minWidth: '310px', zIndex: this.state.zIndex}
    if(isHebrew){
        menuStyling = {direction: 'rtl', minWidth: '315px', zIndex: this.state.zIndex}
    }

      const copyLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-copy-96.png")} alt='copy' width="19" height="19" /></div>
            {copyToClipboard}
        </>;
      const copyToSearchLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-google-web-search-96.png")} alt='copy' width="19" height="19" /></div>
            {copyToSearch}
        </>;
      const searchWithLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-search-100.png")} alt='copy' width="19" height="19" /></div>
            {searchWith}
        </>;
      const searchWithWordLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-search-100.png")} alt='copy' width="19" height="19" /></div>
            {searchWithWord}
        </>;
      const copyWordToSearchLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-google-web-search-96.png")} alt='copy' width="19" height="19" /></div>
            {copyWordToSearch}
        </>;
      const copyWordLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-copy-96.png")} alt='copy' width="19" height="19" /></div>
            {copyWord}
        </>;
      const searchSimilarPesukimLabel = <>
            <div className='context-menu-LNT-icon'><img src={require("./images/icons8-search-100.png")} alt='search' width="19" height="19" /></div>
            {searchSimilarPesukimWords}</>
      const openInLabel = <>
            <div className='context-menu-LNT-icon'><OpenInNew style={{padding: '0', width: '19px',height:"19px"}} /></div>
            {openPasukIn}
        </>;
      const lexiconLabel = <>
      <div className='context-menu-LNT-icon'><OpenInNew style={{padding: '0', width: '19px',height:"19px"}} /></div>
      {openFocusedWordIn}
        </>;

      const LNTicon = <div className='context-menu-LNT-icon'><b>אָ֞</b></div>
      const LNicon = <div className='context-menu-LNT-icon'><b>אָ</b></div>
      const Licon = <div className='context-menu-LNT-icon'><b>א</b></div>
      const personIcon = <div><img src={require("./images/person.png")} width="21" height="21" />&nbsp;</div>

      const parseIcon = <div className='context-menu-LNT-icon'><img src={require("./images/icons8-hierarchy-96.png")} alt='copy' width="16" height="16" /></div>
      const lexiconIcon = <div className='context-menu-LNT-icon'><img src={require("./images/lexicon.jpg")} alt='copy' width="16" height="16" /></div>
      const contextIcon = <div className='context-menu-LNT-icon'><img src={require("./images/menu_book-24px.svg")} alt='copy' width="16" height="16" /></div>
      const comparatorIcon = <CompareArrowsIcon width="16" height="16"  />

      const rightArrowIcon = <div className='context-menu-LNT-icon'><img src={require("./images/icons8-sort-right-100.png")} alt='copy' width="13" height="16" /></div>
      const leftArrowIcon = <div className='context-menu-LNT-icon' style={{transform: 'rotate(180deg)', marginRight: isHebrew ?'275px' : '278px', marginTop: '4px'}}><img src={require("./images/icons8-sort-right-100.png")} alt='copy' width="13" height="16" /></div>

      const textComparatorLeftArrowIcon = <div className='context-menu-LNT-icon' style={{transform: 'rotate(180deg)', marginRight: '25px', marginTop: '4px'}}><img src={require("./images/icons8-sort-right-100.png")} alt='copy' width="13" height="16" /></div>
      const textComparatorRightArrowIcon = <div className='context-menu-LNT-icon' style={{ marginLeft: '15px'}}><img src={require("./images/icons8-sort-right-100.png")} alt='copy' width="13" height="16" /></div>
      var arrowIcon = null;
      var textComparatorArrowIcon = null;
      if(this.props.activeLanguage){
        arrowIcon = this.props.activeLanguage.code === 'en' ? rightArrowIcon : leftArrowIcon;
        textComparatorArrowIcon = this.props.activeLanguage.code === 'en' ? textComparatorRightArrowIcon : textComparatorLeftArrowIcon;
      }

      var nothingSelected = this.props.menuType === 'parshaViewer' && this.props.textCellRowText === null;
      if (this.props.resultsApi){
          var nothingSelected = (this.props.menuType === 'searchResults' && (!this.props.resultsApi.getFocusedCell() && !this.props.wordToCopy))
                                || (this.props.menuType === 'frequencyViewer' && !this.props.resultsApi.getFocusedCell())
                                || (this.props.menuType === 'parshaViewer' && !(this.props.resultsApi.getFocusedCell() || this.props.textCellRowText));
      }

      var isTargum = this.props.menuId === 'tanach-viewer-targum-stream-context-menu';

      var searchWithDisabled = (!this.props.hasRowData || nothingSelected /*|| isTargum */|| allDisabled);
      if(isYuchsinTree && this.props.nodeToSearch){
        searchWithDisabled = false;
      }
      var copyToSearchDisabled = (!this.props.hasRowData || nothingSelected /*|| isTargum */|| allDisabled);
      var copyDisabled = (!this.props.hasRowData || nothingSelected || allDisabled);
      var taamParserDisabled = (nothingSelected || (isNotAPasuk && this.props.currentFreq !== '1') || /*isTargum ||*/ allDisabled || isYuchsinTree);
      var readContextDisabled = (nothingSelected || (isNotAPasuk && this.props.currentFreq !== '1') || ["parshaViewer", "tanachViewer"].includes(this.props.menuType) || allDisabled);

      var twoResultsSelected = false;
      if(this.props.resultsApi){
          var selectedCount = 0;
          var selectedKeys = Object.keys(this.props.resultsApi.selectionController.selectedNodes);
          for(let i = 0; i < selectedKeys.length; i++){
              var currKey = Object.keys(this.props.resultsApi.selectionController.selectedNodes)[i];
              var currNode = this.props.resultsApi.selectionController.selectedNodes[currKey]
              if(currNode){
                selectedCount++
              }
          }
          twoResultsSelected = selectedCount===2;
      }
      var textComparatorDisabled = (this.props.menuType!=="searchResults") ||
        (this.props.menuType==="searchResults" && this.props.resultsApi && !twoResultsSelected);
      var pasukTextInComparator = this.props.resultsApi && this.props.resultsApi.getFocusedCell() && ['value1.pasukText', 'value2.pasukText'].includes(this.props.resultsApi.getFocusedCell().column.colId)
      var lettersIsSelected = this.props.morphInflections ? (this.props.morphInflections.indexOf("L") > -1 || this.props.morphInflections.indexOf("S") > -1 || this.props.morphInflections.indexOf("G") > -1 || this.props.morphInflections.indexOf("A") > -1):false;
      var nekudosIsSelected = this.props.morphInflections ? this.props.morphInflections.indexOf("N") > -1:false;
      var taamimIsSelected = this.props.morphInflections ? this.props.morphInflections.indexOf("T") > -1:false;
      var targumDisableLNT = (this.props.menuType==="tanachViewer" && this.props.columnClicked==='תרגום') || (this.props.menuType==="parshaViewer" && this.props.columnClicked==='תרגום') ||
                            (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום' && this.props.textToCopy !== 'selected')||
                            (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום' && this.props.contentDisplayed && this.props.contentDisplayed.length===1)
      var LDisabled = (isNotAPasuk && !((this.props.menuType==='textComparator' || this.props.menuType === 'frequencyViewer') && lettersIsSelected)) || isYuchsinTree;
      var LNDisabled = ((isNotAPasuk && !pasukTextInComparator) && !((this.props.menuType==='textComparator' || this.props.menuType === 'frequencyViewer') && nekudosIsSelected)) || isYuchsinTree  || (this.props.menuType==='diff' && this.props.diffData.removeNikud);
      var LNTDisabled = ((isNotAPasuk && !pasukTextInComparator) && !((this.props.menuType==='textComparator' || this.props.menuType === 'frequencyViewer') && taamimIsSelected)) || isYuchsinTree || targumDisableLNT  || (this.props.menuType==='diff' && this.props.diffData.removeTaameiMikra);
      var searchSimilarPesukimDisabled = this.props.menuType!=="searchResults" || (this.props.columnClicked==='תרגום' && this.props.contentDisplayed && this.props.contentDisplayed.length===1) || (this.props.textToCopy === 'selected' && this.props.resultsApi && this.props.resultsApi.getSelectedRows().length > 1)
      var searchWithDisplay = this.props.isStatsContextMenu ? 'none' : 'block';
      var copyToSearchDisplay = this.props.isStatsContextMenu ? 'none' : 'block';
      var copyDisplay = this.props.isStatsContextMenu ? 'none' : 'block';
      var taamParserDisplay = this.props.isStatsContextMenu ? 'none' : 'block';
      var readContextDisplay = this.props.isStatsContextMenu ? 'none' : 'block';
      var moreDetailsDisplay = this.props.isStatsContextMenu ? 'block' : 'none';
      var focusedCellRowIndex = this.props.resultsApi?this.props.resultsApi.getFocusedCell()?this.props.resultsApi.getFocusedCell().rowIndex:-1:-1;
      var focusedCellRow = this.props.resultsApi?this.props.resultsApi.getModel().rowsToDisplay[focusedCellRowIndex]:null;
      var focusedCellColumnId = this.props.resultsApi?this.props.resultsApi.getFocusedCell()?this.props.resultsApi.getFocusedCell().column.colId:null:null;
      var data = focusedCellRowIndex>=0  && focusedCellColumnId && focusedCellRow? 
                        focusedCellRow.data[focusedCellColumnId.split(".")[0]]?focusedCellRow.data[focusedCellColumnId.split(".")[0]][focusedCellColumnId.split(".")[1]]:null:null;
      var focusedCellRowText = (this.props.menuType === 'frequencyViewer' && focusedCellRow)?
                            (this.props.currentFreq === '3+' || (this.props.currentFreq === '1' && ['rowNum_1', 'testField_1'].includes(focusedCellColumnId)))?
                                 focusedCellRow.data.testField :
                            // (['location', 'pasukText'].includes(focusedCellColumnId)) ?
                            //     focusedCellRowText = focusedCellRow.data.pasukText :
                            // (['location1', 'pasukText1'].includes(focusedCellColumnId)) ?
                            //     focusedCellRowText = focusedCellRow.data.pasukText1 :
                            // (['location2', 'pasukText2'].includes(focusedCellColumnId)) ?
                            //     focusedCellRowText = focusedCellRow.data.pasukText2 :
                            (['rowNum', 'testField'].includes(focusedCellColumnId)) ?
                                focusedCellRowText = focusedCellRow.data.testField:null:null;
                            
      var openInLexicon = ((focusedCellRow && focusedCellRow.data.term && focusedCellRow.data.term.split(" ").length===1) ||
                                (data && (focusedCellColumnId.split(".")[1]==='text') && data.split(" ").length===1) || (focusedCellRowText && focusedCellRowText.split(" ").length===1)
                                /*|| (this.props.wordToCopy && this.props.textToCopy !== 'highlighted')*/)
            
      var focusedWordOptions = this.props.wordToCopy && this.props.textToCopy !== 'highlighted' && !openInLexicon && !['location1', 'location2', 'location', 'value1.location', 'value2.location'].includes(focusedCellColumnId)
      var copyToSearchSubmenu = <><div style={{display: copyToSearchDisplay}}>
                                <Submenu label={copyToSearchLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled={copyToSearchDisabled}>
                                    <Item disabled className='context-menu-checkbox'>
                                        <div onClick={() => {fakeThis.toggleSearchAsQuote()}}>
                                            <Checkbox checked={fakeThis.props.searchAsQuote} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                            <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                                {copyAsQuote}
                                            </label>
                                        </div>
                                    </Item>
                                    <Separator />
                                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('L', 'copy')}} disabled={LDisabled}>{Licon} {letters}</Item></div>
                                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LN', 'copy')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item></div>
                                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LNT', 'copy')}} disabled={LNTDisabled || (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום')}>{LNTicon} {lettersNekudotTaamim}</Item></div>
                                </Submenu><Separator/></div></>
      if(this.props.textToCopy === 'selected' && this.props.resultsApi && this.props.resultsApi.getSelectedRows().length > 1){
        copyToSearchSubmenu = <>
                                <Submenu label={copyToSearchLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled>
                                </Submenu><Separator/></>
      }
      var searchWithSubmenu = <><div style={{display: searchWithDisplay}}>
                {this.props.wordToCopy  && this.props.textToCopy !== 'highlighted'}<Submenu label={searchWithLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled={searchWithDisabled}>
                    {!isYuchsinTree &&
                        <><Item disabled className='context-menu-checkbox'>
                            <div onClick={() => {fakeThis.toggleSearchAsQuote()}}>
                                <Checkbox checked={fakeThis.props.searchAsQuote} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                    {searchAsQuote}
                                </label>
                            </div>
                        </Item><Separator /></>}
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('L', 'search')}} disabled={LDisabled}>{Licon} {letters}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LN', 'search')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LNT', 'search')}} disabled={LNTDisabled || (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום')}>{LNTicon} {lettersNekudotTaamim}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchPersonObject()}} disabled={!(isYuchsinTree && this.props.nodeToSearch)}>
                            {personIcon} {person}</Item></div>
                </Submenu><Separator/>
        </div></>
      if(this.props.textToCopy === 'selected' && this.props.resultsApi && this.props.resultsApi.getSelectedRows().length > 1){
        searchWithSubmenu = <><Submenu label={searchWithLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled>
                                </Submenu><Separator/></>
      }
      var searchWithFocusedWord=focusedWordOptions?<><div style={{display: copyToSearchDisplay}}>
                <Submenu label={searchWithWordLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled={copyToSearchDisabled}>
                    <Item disabled className='context-menu-checkbox'>
                        <div onClick={() => {fakeThis.toggleSearchAsQuote()}}>
                            <Checkbox checked={fakeThis.props.searchAsQuote} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                            <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                {copyAsQuote}
                            </label>
                        </div>
                    </Item>
                    <Separator />
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('L', 'search', 'word')}} disabled={LDisabled}>{Licon} {letters}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LN', 'search', 'word')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LNT', 'search', 'word')}} disabled={LNTDisabled || (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום')}>{LNTicon} {lettersNekudotTaamim}</Item></div>
                </Submenu><Separator/></div></>:<></>
      var copyFocusedWordToSearchBar = focusedWordOptions ? <><div style={{display: copyToSearchDisplay}}>
                <Submenu label={copyWordToSearchLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled={copyToSearchDisabled}>
                    <Item disabled className='context-menu-checkbox'>
                        <div onClick={() => {fakeThis.toggleSearchAsQuote()}}>
                            <Checkbox checked={fakeThis.props.searchAsQuote} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                            <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                {copyAsQuote}
                            </label>
                        </div>
                    </Item>
                    <Separator />
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('L', 'copy', 'word')}} disabled={LDisabled}>{Licon} {letters}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LN', 'copy', 'word')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.searchSelected('LNT', 'copy', 'word')}} disabled={LNTDisabled || (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום')}>{LNTicon} {lettersNekudotTaamim}</Item></div>
                </Submenu><Separator/></div></>:<></>
        var includeMekorotOption = <div onClick={() => {fakeThis.toggleIncludeMekorot()}}>
                    <Checkbox checked={fakeThis.props.includeMekorot} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                    <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                        {includeMekorot}
                    </label>
                </div>
        if(isNotAPasuk){
                includeMekorotOption = <div style={{color: '#afafaf', pointerEvents: 'none'}} >
                    <Checkbox checked={false} id='include-mekorot-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '0', position: 'relative'}} />
                    <label htmlFor="include-mekorot-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                        {includeMekorot}
                    </label>
                </div>
        }
var copyFocusedWord = focusedWordOptions ? <><div style={{display: copyToSearchDisplay}}>
                <Submenu label={copyWordLabel} arrow={arrowIcon} style={{minWidth: '270px'}} disabled={copyToSearchDisabled}>
                <Item disabled className='context-menu-checkbox'>
                               {includeMekorotOption}
                          </Item>
                          {/* {this.props.textToCopy === 'highlighted' || this.props.menuType !== 'searchResults' ? null : <Item disabled className='context-menu-checkbox'>
                                <div onClick={() => {fakeThis.toggleIncludeStatistics()}}>
                                   <Checkbox checked={fakeThis.props.includeStatistics} id='includeStatistics' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                   <label htmlFor="includeStatistics" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                       {includeStatistics}
                                   </label>
                               </div>
                          </Item>} //took this out because right now there is no word statistics that are relevent*/}
                          <Item disabled className='context-menu-checkbox'>
                                <div onClick={() => {fakeThis.toggleUseKinuim()}}>
                                   <Checkbox checked={fakeThis.props.useKinuim} id='use-kinuim-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                   <label htmlFor="use-kinuim-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                       {useKinuim}
                                   </label>
                               </div>
                          </Item>
                    <Separator />
                    <div helpTopic="searchResults"><Item onClick={() => {this.copyToClipboard('L','word')}} disabled={LDisabled}>{Licon} {letters}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.copyToClipboard('LN','word')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item></div>
                    <div helpTopic="searchResults"><Item onClick={() => {this.copyToClipboard('LNT','word')}} disabled={LNTDisabled || (this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום')}>{LNTicon} {lettersNekudotTaamim}</Item></div>
                </Submenu><Separator/></div></>:<></>
    var openWordInLexicon = (openInLexicon || (focusedWordOptions) || (this.props.textToCopy==="highlighted"
                && (this.props.menuType==="tanachViewer"? this.props.textCellRowText.split(" ").length===1: window.getSelection().toString().split(" ").length===1 )))
                ? <div helpTopic="lexicon">
              <Submenu label={lexiconLabel} arrow={arrowIcon} style={{minWidth: '270px'}} disabled={false}>
                  <Item onClick={() => {this.handleOpenLexicon(focusedCellRow?focusedCellRow.data.term:null, data, focusedCellRowText,this.props.wordToCopy)}}>{lexiconIcon} {lexiconViewer}</Item>
              </Submenu><Separator/>
                  </div>:<></>

    var searchSimilarPesukim=searchSimilarPesukimDisabled?<></>:<><div style={{display: copyToSearchDisplay}}>
    <Submenu id={"statPickerAnchor"} label={searchSimilarPesukimLabel} arrow={arrowIcon} style={{minWidth: isHebrew? '294px':'372px'}} disabled={searchSimilarPesukimDisabled} onClick={()=>{this.setState({openStatPicker:!this.state.openStatPicker})}}>
              <Item disabled className='context-menu-checkbox'>
              <StatPicker2D pickerModel={statisticsPicker} title={resultStatistics} extSetValues={this.setPickerValues} 
                                            extApplyValues={this.applyPickerValues} toggleIncludeOtherStats={this.props.toggleIncludeOtherStats}
                                            keepOpen={true} selectStyle={"General"} values={this.state.values} includeOtherStats={this.props.includeOtherStats}
                                            extClassName={'statisticsPickerDropdownButton'} displayMultiple={false} />
              </Item>
    </Submenu><Separator/></div></>

       var textComparatorSubmenuLabel = null;
       if(isHebrew){
           textComparatorSubmenuLabel = <div style={{ display: "flex", minWidth: '203px', maxWidth: '203px', width: '203px'}}>
                <div style={{width: 'fit-content'}}>{comparatorIcon} {textComparator}</div>
                <div style={{ justifyContent: "flex-end"}}>{textComparatorArrowIcon}</div>
            </div>
       } else {
           textComparatorSubmenuLabel = <div style={{ display: "flex", minWidth: '203px', maxWidth: '203px', width: '203px'}}>
                <div style={{width: 'fit-content'}}>{comparatorIcon} {textComparator}</div>
                <div style={{ justifyContent: "flex-end"}}>{textComparatorArrowIcon}</div>
            </div>
       }
        if(this.props.noContextMenu)  {
            return (<div></div>)
        }      
        else{return (<Menu className='context-menu' id={this.props.menuId} style={menuStyling} onShown={() => { this.showOnTop(); }} >

                  {searchWithFocusedWord}
                  {copyFocusedWordToSearchBar}
                  {copyFocusedWord}
                  {focusedWordOptions?<>{openWordInLexicon}</>:<></>}
                  {searchSimilarPesukim}
                  {searchWithSubmenu}
                  {copyToSearchSubmenu}
                  <div style={{display: copyDisplay}}>
                      <Submenu label={copyLabel} arrow={arrowIcon} style={{minWidth: '270px'}} disabled={copyDisabled}>
                          <Item disabled className='context-menu-checkbox'>
                               {includeMekorotOption}
                          </Item>
                          {this.props.textToCopy === 'highlighted' || this.props.menuType !== 'searchResults' ? null : <Item disabled className='context-menu-checkbox'>
                                <div onClick={() => {fakeThis.toggleIncludeStatistics()}}>
                                   <Checkbox checked={fakeThis.props.includeStatistics} id='includeStatistics' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                   <label htmlFor="includeStatistics" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                       {includeStatistics}
                                   </label>
                               </div>
                          </Item>}
                          <Item disabled className='context-menu-checkbox'>
                                <div onClick={() => {fakeThis.toggleUseKinuim()}}>
                                   <Checkbox checked={fakeThis.props.useKinuim} id='use-kinuim-checkbox' style={{display: 'inline-block', margin: '0 6px', zIndex: '2', position: 'relative'}} />
                                   <label htmlFor="use-kinuim-checkbox" style={{ whiteSpace: 'normal', lineHeight: '1', display: 'inline'}} >
                                       {useKinuim}
                                   </label>
                               </div>
                          </Item>
                          <Separator />
                          <Item onClick={() => {this.copyToClipboard('L')}} disabled={LDisabled}>{Licon} {letters}</Item>
                          <Item onClick={() => {this.copyToClipboard('LN')}} disabled={LNDisabled}>{LNicon} {lettersNekudot}</Item>
                          <Item onClick={() => {this.copyToClipboard('LNT')}} disabled={LNTDisabled}>{LNTicon} {lettersNekudotTaamim}</Item>
                      </Submenu>
                      <Separator />
                  </div>
                  {focusedWordOptions?<></>:<>{openWordInLexicon}</>}

                  <div style={{display: taamParserDisplay}}>
                  {/* {this.props.wordToCopy?<></>:(openInLexicon || (this.props.textToCopy==="highlighted"
                          && (this.props.menuType==="tanachViewer"? this.props.textCellRowText.split(" ").length===1: window.getSelection().toString().split(" ").length===1 )))
                          && <span><div helpTopic="lexicon">
                        <Submenu label={lexiconLabel} arrow={arrowIcon} style={{minWidth: '270px'}} disabled={false}>
                            <Item onClick={() => {this.handleOpenLexicon(focusedCellRow?focusedCellRow.data.term:null, data, focusedCellRowText,this.props.wordToCopy)}}
                            style={{display: taamParserDisplay}} >{lexiconIcon} {lexiconViewer}</Item>
                        </Submenu>
                            </div>
                        <Separator /></span>} */}
                    {/* adding a submenu here for taam parser and tanach viewer becasue disabled option on menu items doesn't seem to update properly */}
                    {this.props.columnClicked==='תרגום' && (this.props.menuType==="tanachViewer" || this.props.menuType==="parshaViewer") ? <Submenu label={openInLabel} arrow={arrowIcon} style={{minWidth: '203px'}} disabled>
                                </Submenu>:
                    <Submenu label={openInLabel} arrow={arrowIcon} style={{minWidth: '203px',  maxWidth: '203px'}} disabled={taamParserDisabled}>
                        <div helpTopic="tanachViewer">
                            <Item onClick={() => {this.openTanachViewer()}} disabled={false} style={{display: readContextDisplay}} disabled={readContextDisabled}>{contextIcon} {tanachViewer}</Item>
                        </div>
                        <Separator />
                        <div helpTopic="taamParser"><Item onClick={() => {this.handleParseTaamim()}} disabled={this.props.menuType==="searchResults" && this.props.columnClicked==='תרגום'} style={{display: taamParserDisplay}} >{parseIcon} {taamParser}</Item></div>
                        <Separator />
                        {/* {this.props.textToCopy==="highlighted"
                          && (this.props.menuType==="tanachViewer"? this.props.textCellRowText.split(" ").length===1: window.getSelection().toString().split(" ").length===1 ) 
                          && <span><div helpTopic="lexicon">
                            <Item onClick={() => {this.handleOpenLexicon()}}
                            style={{display: taamParserDisplay}} >{lexiconIcon} {lexiconViewer}</Item></div>
                        <Separator /></span>} */}
                        <Submenu style={{minWidth: '203px', maxWidth: '203px', width: '203px'}} disabled={textComparatorDisabled} arrow={""}
                            label={textComparatorSubmenuLabel} 
                        >
                            <div helpTopic="textComparatorOptions">
                                <Item onClick={() => {this.openTextComparator("parsha")}} style={{display: readContextDisplay}}>
                                    <SubjectIcon width="13" height="13"
                                        style={{
                                            transform: 'rotateY(180deg)',
                                            marginRight: `${!isHebrew ? '2px' : 0}`,
                                            marginLeft: `${isHebrew ? '2px' : 0}`,
                                        }}
                                    />
                                    {parsha}
                                </Item>
                            </div>
                            <Separator />
                                <div helpTopic="textComparatorOptions"><Item onClick={() => {this.openTextComparator("20 pesukim")}} style={{display: taamParserDisplay}} >
                                    <MoreVert style={{padding: '0 6px', width: '28px'}} />
                                    {twentyOnePesukim}
                                </Item></div>
                        </Submenu>
                    </Submenu>}
                    {/* {this.props.textToCopy==="highlighted" && window.getSelection().toString().split(" ").length===1 && <span><div helpTopic="lexicon">
                            <Item onClick={() => {this.handleOpenLexicon()}}
                            style={{display: taamParserDisplay}} >{lexiconIcon} {lexiconViewer}</Item></div>
                        <Separator /></span>} */}

                  </div>
                  {/* <div style={{display: taamParserDisplay}}>
                      <Separator />
                      <Item onClick={() => {this.handleParseTaamim()}} disabled={taamParserDisabled} style={{display: taamParserDisplay}} >{parseIcon} {parseTaamim}</Item>
                  </div>
                  <div style={{display: readContextDisplay}}>
                      <Separator />
                      <Item onClick={() => {this.openTanachViewer()}} disabled={readContextDisabled} style={{display: readContextDisplay}} >{contextIcon} {readContext}</Item>
                  </div> */}
                  <div style={{display: moreDetailsDisplay}}>
                      <Item disabled={true} ><MoreVert style={{padding: '0 6px', width: '28px'}} /> {moreDetails}</Item>
                  </div>
            </Menu>
    );
  
    }
  }
}

export default withLocalize(ContextMenu);