import React, { Component } from 'react';

class VersionInformation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  getTranslatedContent(langCode){
    if(!["he", "en"].includes(langCode)){ // setting langCode to "en" if invalid code given
      langCode = "en";
    }
    var retVal;
    if(langCode === "he"){
      retVal = <>
      <b>Version History</b><br /><br />
      <table width="100%">
        <tr>
          <td style={{border: '0.5px solid dodgerblue'}} width="15%"><b>Version #</b></td>
          <td style={{border: '0.5px solid dodgerblue'}} width="20%"><b>Date</b></td>
          <td style={{border: '0.5px solid dodgerblue'}} width="65%"><b>Changes</b></td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.4.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>February 20, 2024</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>New search result columns available from column picker which display named-sections of Tanach related to each pasuk: a) Parshas Hashavua (krias haTorah & haftara) - also can sort searches by this b) Triennial Sidra c) Other related krios - also can sort by this</li>
                  <li>Various bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.3.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>December 26, 2022</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Now can load all site features and pages via direct URLs (in addition to Parsha Viewer, Frequency Viewer and Text Comparator which were already accessible in the past)</li>
                  <li>Now can pass URL parameters to directly navigate to pesukim in the Tanach Viewer and Taam Parser and even to instantly load full word by word pasuk details in the main search</li>
                  <li>Now can pass URL parameters to directly focus/zoom on individuals in the Tanach Family Tree and even to display and animate relation paths</li>
                  <li>Now can pass URL parameters to directly launch a Lexicon search</li>
                  <li>A new icon button is available on the upper corner of each page for copying their direct URL links along with parameters for specifying page settings</li>
                  <li>Added a Site Map page</li>
                  <li>In the search results window, the "Search within these results" option now works again</li>
                  <li>Enabled the Help dialog to be more context-sensitive so will automatically open to a topic that's pertinent to a recently used screen component</li>
                  <li>Expanded pasuk limit on search results from 2000 to 6000</li>
                  <li>Various bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.2.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 7, 2022</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Now the Targum Onkelos source selection will be reflected by relevant content in the Frequency Viewer, Object Clouds and Text Comparator</li>
              <li>Grids of information can now be sorted in various ways for many columns by clicking on columns headers</li>
              <li>General bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.1.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 27, 2022</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Now voice input can be done from search bars by clicking the microphone icon (works for Chrome browser)</li>
              <li>Internal changes to allow for HTTPS access, a new content server, and serving content over a content delivery network (CDN)</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.0.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 15, 2022</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>New feature: Search Targum Onkelos on the Chumash</li>
              <li>New Lexicon feature</li>
              <li>Parallel Hebrew-Targum columns now available in search results</li>
              <li>Now can search for pesukim with similar stats from pasuk-level context menus upon right-click</li>
              <li>Now can load selected sections in the Parsha Viewer to an Object Cloud</li>
              <li>Added button to search results window for downloading to CSV files</li>
              <li>Expanded search summary statistics with a popup containing sortable breakdown of sefer locations</li>
              <li>New convenience dropdown in Advanced Search's Placement tab for choosing recent kria and pasuk range selections</li>
              <li>Speeded up loading of the parshas hashavua selection dropdown for the Placement tab</li>
              <li>Made Taam Parser window movable and resizable</li>
              <li>Improved regular expression capabilities in advanced search</li>
              <li>General bug fixes and performance improvements</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.1.1</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 6, 2021</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Tweaks to the Object Cloud UI</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.1.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 1, 2021</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Introduced the capability to launch animations from the Object Cloud popup</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.0.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>September 19, 2021</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Introduced descriptive image panels on the home page for each site feature page.  Clicking them will navigate to those pages</li>
              <li>Clicking the plus sign near search result rows now open up an extensive list of word-by-word information for that entire pasuk</li>
              <li>Word-by-word information is also available as a tooltip by hovering over each word in search result pesukim.  This can be enabled via a checkbox at the left of the search results header bar</li>
              <li>Search summary information gets updated within an expandable panel on the upper left of the screen each time a search is launched</li>
              <li>Right-clicking on pasuk rows will now show an option in the context menu that lets one copy/paste pasuk statistics from the search results grid</li>
              <li>Search settings controlled by sliders now enable direct editing of setting values by clicking on the numeric text box adjacent to each slider</li>
              <li>Choosing to sort search results by a pasuk statistic (via dropdown in search settings) will force that statistic to be displayed in the results grid if not already selected for display</li>
              <li>Various bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.6.1</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>June 10, 2021</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Hovering the mouse over search results rows now reveals a wide selection of statistics for the hovered pasuk</li>
              <li>A new button in the search results status bar lets one select many different statistical columns to display near the pasuk text</li>
              <li>Added a new control to Search Settings panel which lets one sort search results by a selection of pasuk statistics</li>
              <li>Added additional controls on the Pasuk Settings tab to enable filtering search results by many additional criteria</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.5.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 25, 2021</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Renamed the <b>Include\Exclude</b> tab of the Advanced Search to <b>Pasuk Settings</b></li>
              <li>Added controls on the Pasuk Settings tab to enable filtering search results by total words, letters, vowels, taamim, gematria, atbash per pasuk</li>
              <li>Added controls on the Parts of Speech++ tab to enable filtering search results by total nekudos and vowels per word</li>
              <li>Added a new tab to the Help dialogue popup box: <b>Tutorials</b> that display any tutorial videos available for the selected help topic</li>
              <li>Added a new tab to the Help dialogue popup box: <b>All Tutorials</b> which lists tutorial videos available for all help topics</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.3.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>November 17, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Added buttons to the control bar of the 3D Family Tree which enable the user to highlight ancestors and descendants of a selected person</li>
              <li>Added a Settings button to the control bar of the 3D Family Tree which opens a popup which lets the user control and animate the highlighting of ancestors and descendants</li>
              <li>Added the capability to the 3D Family Tree to visually animate person to person relation paths in the popup that lets one search for person to person relationships</li>
              <li>Added a checkbox to the display settings of the 3D Family Tree which lets one switch the diagram from Dark Mode to Light Mode</li>
              <li>Fixed bug in name selection dropdown in the Find Relation popup where the type-ahead name filter now works</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.2.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>November 9, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Added popup window in 3D Family Tree to search and highlight person to person relations in Tanach</li>
              <li>Fixed bug in data grids so that now can resize column width by dragging column separators</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.1.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 18, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Added ability in 3D Family Tree to search for mentions of a selected person in Tanach</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.0.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>August 31, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Initial release of the new 3D Tanach Family Tree feature</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.2.1.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>August 27, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Test version of the new 3D Tanach Family Tree feature (to be updated shortly with its initial release)</li>
              <li>The ability to select a range of exactness/fuzziness in search terms.  This appears as general setting in simple search and as a per-term setting in advanced search</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.2.0.1</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>June 22, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Integrated Sefer Ameilim BaTorah grammatical information on verbs into the database model</li>
              <li>A new advanced search tab was added that lets one specify grammatical part-of-speech details and more when defining search criteria</li>
              <li>Tag clouds for words, shorashim, gematrios and more can now be generated by a separate Cloud Viewer, the Tanach Viewer and can even be compared in the Text Comparator</li>
              <li>Added new display panel options for both the Parsha and Tanach viewers so that one can now view gematria values in parallel to the main Hebrew text</li>
              <li>One can now enter ''ה into the search bar and have it understood as the four letter שם השם</li>
              <li>Fixed Text Comparator so that it can not only compare word forms but even shorashim and gematria</li>
              <li>Fixed a textual error in יחזקאל ג:טז</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.4.0</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 7, 2020</td>
          <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
              <li>Improved context-sensitive help button so that the topic loaded reacts to recent user on-screen activity</li>
              <li>Added paging to the Parsha Viewer so that the apllication loads faster</li>
              <li>By selecting two search result lines and then right-clicking, one can now launch the Text Comparator screen preloaded with sections of Tanach to compare</li>
              <li>New Gematria view added to Tanach Viewer</li>
              <li>Various bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>1.1.3.0</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>March 29, 2020</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue', paddingLeft: '5px',}}>
              <li>Added paging to Parsha Viewer grid</li>
          </td>
        </tr>
        <tr style={{verticalAlign: "text-top"}}>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>1.1.2.0</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>March 22, 2020</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue', paddingLeft: '5px',}}>
              <li>Completed Hebrew translations for Help, About and Premium Services dialogues and also for the User Manual</li>
              <li>Created maintenance mode for displaying a special screen during system maintenance</li>
          </td>
        </tr>
        <tr style={{verticalAlign: 'text-top'}}>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>1.1.1.0</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>March 15, 2020</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue', paddingLeft: '5px'}}>
              <li>Help topic dropdown selector now clears after a selection is made</li>
              <li>Fixed bug in search results re: navigation to the end of a large list</li>
              <li>Fixed full-screen and split-screen view for Tanach Viewer</li>
              <li>Added full-screen view to About and Help dialogues</li>
              <li>Added more Hebrew content to About dialogue box</li>
              <li>Other assorted bug fixes</li>
          </td>
        </tr>
        <tr style={{verticalAlign: 'text-top'}}>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>1.1.0.0</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue'}}>March 4, 2020</td>
          <td style={{fontSize: '12px', border: '0.5px solid dodgerblue', paddingLeft: '5px'}}>
              <li>Release of initial Beta Test version of Base HaSefer</li>
              <li>Created documentation for About and Help dialogue buttons</li>
              <li>Added LCS and Diff features to the Text Comparator</li>
              <li>Added enhancements to the Tanach Viewer</li>
          </td>
        </tr>
      </table>

      </>
    } else if (langCode === "en") {
      retVal = <>

        <b>Version History</b><br /><br />
        <table width="100%">
          <tbody><tr>
              <td style={{border: '0.5px solid dodgerblue'}} width="15%"><b>Version #</b></td>
              <td style={{border: '0.5px solid dodgerblue'}} width="20%"><b>Date</b></td>
              <td style={{border: '0.5px solid dodgerblue'}} width="65%"><b>Changes</b></td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.4.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>February 20, 2024</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>New search result columns available from column picker which display named-sections of Tanach related to each pasuk: a) Parshas Hashavua (krias haTorah & haftara) - also can sort searches by this b) Triennial Sidra c) Other related krios - also can sort by this</li>
                  <li>Various bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.3.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>December 26, 2022</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Now can load all site features and pages via direct URLs (in addition to Parsha Viewer, Frequency Viewer and Text Comparator which were already accessible in the past)</li>
                  <li>Now can pass URL parameters to directly navigate to pesukim in the Tanach Viewer and Taam Parser and even to instantly load full word by word pasuk details in the main search</li>
                  <li>Now can pass URL parameters to directly focus/zoom on individuals in the Tanach Family Tree and even to display and animate relation paths</li>
                  <li>Now can pass URL parameters to directly launch a Lexicon search</li>
                  <li>A new icon button is available on the upper corner of each page for copying their direct URL links along with parameters for specifying page settings</li>
                  <li>Added a Site Map page</li>
                  <li>In the search results window, the "Search within these results" option now works again</li>
                  <li>Enabled the Help dialog to be more context-sensitive so will automatically open to a topic that's pertinent to a recently used screen component</li>
                  <li>Expanded pasuk limit on search results from 2000 to 6000</li>
                  <li>Various bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.2.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 7, 2022</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Now the Targum Onkelos source selection will be reflected by relevant content in the Frequency Viewer, Object Clouds and Text Comparator</li>
                  <li>Grids of information can now be sorted in various ways for many columns by clicking on columns headers</li>
                  <li>General bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.1.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 27, 2022</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Now voice input can be done from search bars by clicking the microphone icon (works for Chrome browser)</li>
                  <li>Internal changes to allow for HTTPS access, a new content server, and serving content over a content delivery network (CDN)</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.5.0.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 15, 2022</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>New feature: Search Targum Onkelos on the Chumash</li>
                  <li>New Lexicon feature</li>
                  <li>Parallel Hebrew-Targum columns now available in search results</li>
                  <li>Now can search for pesukim with similar stats from pasuk-level context menus upon right-click</li>
                  <li>Now can load selected sections in the Parsha Viewer to an Object Cloud</li>
                  <li>Added button to search results window for downloading to CSV files</li>
                  <li>Expanded search summary statistics with a popup containing sortable breakdown of sefer locations</li>
                  <li>New convenience dropdown in Advanced Search's Placement tab for choosing recent kria and pasuk range selections</li>
                  <li>Speeded up loading of the parshas hashavua selection dropdown for the Placement tab</li>
                  <li>Made Taam Parser window movable and resizable</li>
                  <li>Improved regular expression capabilities in advanced search</li>
                  <li>General bug fixes and performance improvements</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.1.1</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 6, 2021</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Tweaks to the Object Cloud UI</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.1.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 1, 2021</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Introduced the capability to launch animations from the Object Cloud popup</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.4.0.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>September 19, 2021</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Introduced descriptive image panels on the home page for each site feature page.  Clicking them will navigate to those pages</li>
                  <li>Clicking the plus sign near search result rows now open up an extensive list of word-by-word information for that entire pasuk</li>
                  <li>Word-by-word information is also available as a tooltip by hovering over each word in search result pesukim.  This can be enabled via a checkbox at the left of the search results header bar</li>
                  <li>Search summary information gets updated within an expandable panel on the upper left of the screen each time a search is launched</li>
                  <li>Right-clicking on pasuk rows will now show an option in the context menu that lets one copy/paste pasuk statistics from the search results grid</li>
                  <li>Search settings controlled by sliders now enable direct editing of setting values by clicking on the numeric text box adjacent to each slider</li>
                  <li>Choosing to sort search results by a pasuk statistic (via dropdown in search settings) will force that statistic to be displayed in the results grid if not already selected for display</li>
                  <li>Various bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.6.1</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>June 10, 2021</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Hovering the mouse over search results rows now reveals a wide selection of statistics for the hovered pasuk</li>
                  <li>A new button in the search results status bar lets one select many different statistical columns to display near the pasuk text</li>
                  <li>Added a new control to Search Settings panel which lets one sort search results by a selection of pasuk statistics</li>
                  <li>Added additional controls on the Pasuk Settings tab to enable filtering search results by many additional criteria</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.5.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 25, 2021</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Renamed the <b>Include\Exclude</b> tab of the Advanced Search to <b>Pasuk Settings</b></li>
                  <li>Added controls on the Pasuk Settings tab to enable filtering search results by total words, letters, vowels, taamim, gematria, atbash per pasuk</li>
                  <li>Added controls on the Parts of Speech++ tab to enable filtering search results by total nekudos and vowels per word</li>
                  <li>Added a new tab to the Help dialogue popup box: <b>Tutorials</b> that display any tutorial videos available for the selected help topic</li>
                  <li>Added a new tab to the Help dialogue popup box: <b>All Tutorials</b> which lists tutorial videos available for all help topics</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.3.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>November 17, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Added buttons to the control bar of the 3D Family Tree which enable the user to highlight ancestors and descendants of a selected person</li>
                  <li>Added a Settings button to the control bar of the 3D Family Tree which opens a popup which lets the user control and animate the highlighting of ancestors and descendants</li>
                  <li>Added the capability to the 3D Family Tree to visually animate person to person relation paths in the popup that lets one search for person to person relationships</li>
                  <li>Added a checkbox to the display settings of the 3D Family Tree which lets one switch the diagram from Dark Mode to Light Mode</li>
                  <li>Fixed bug in name selection dropdown in the Find Relation popup where the type-ahead name filter now works</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.2.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>November 9, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Added popup window in 3D Family Tree to search and highlight person to person relations in Tanach</li>
                  <li>Fixed bug in data grids so that now can resize column width by dragging column separators</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.1.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>October 18, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Added ability in 3D Family Tree to search for mentions of a selected person in Tanach</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.3.0.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>August 31, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Initial release of the new 3D Tanach Family Tree feature</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.2.1.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>August 27, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Test version of the new 3D Tanach Family Tree feature (to be updated shortly with its initial release)</li>
                  <li>The ability to select a range of exactness/fuzziness in search terms.  This appears as general setting in simple search and as a per-term setting in advanced search</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.2.0.1</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>June 22, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Integrated Sefer Ameilim BaTorah grammatical information on verbs into the database model</li>
                  <li>A new advanced search tab was added that lets one specify grammatical part-of-speech details and more when defining search criteria</li>
                  <li>Tag clouds for words, shorashim, gematrios and more can now be generated by a separate Cloud Viewer, the Tanach Viewer and can even be compared in the Text Comparator</li>
                  <li>Added new display panel options for both the Parsha and Tanach viewers so that one can now view gematria values in parallel to the main Hebrew text</li>
                  <li>One can now enter ''ה into the search bar and have it understood as the four letter שם השם</li>
                  <li>Fixed Text Comparator so that it can not only compare word forms but even shorashim and gematria</li>
                  <li>Fixed a textual error in יחזקאל ג:טז</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.4.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>April 7, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Improved context-sensitive help button so that the topic loaded reacts to recent user on-screen activity</li>
                  <li>Added paging to the Parsha Viewer so that the apllication loads faster</li>
                  <li>By selecting two search result lines and then right-clicking, one can now launch the Text Comparator screen preloaded with sections of Tanach to compare</li>
                  <li>New Gematria view added to Tanach Viewer</li>
                  <li>Various bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: "text-top"}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.3.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 29, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5,}}>
                  <li>Added paging to Parsha Viewer grid</li>
              </td>
            </tr>
            <tr style={{verticalAlign: 'text-top'}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.2.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 22, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5}}>
                <li>Completed Hebrew translations for Help, About and Premium Services dialogues and also for the User Manual</li>
                <li>Created maintenance mode for displaying a special screen during system maintenance</li>
              </td>
            </tr>
            <tr style={{verticalAlign: 'text-top'}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.1.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 15, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5}}>
                <li>Help topic dropdown selector now clears after a selection is made</li>
                <li>Fixed bug in search results re: navigation to the end of a large list</li>
                <li>Fixed full-screen and split-screen view for Tanach Viewer</li>
                <li>Added full-screen view to About and Help dialogues</li>
                <li>Added more Hebrew content to About dialogue box</li>
                <li>Other assorted bug fixes</li>
              </td>
            </tr>
            <tr style={{verticalAlign: 'text-top'}}>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>1.1.0.0</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue'}}>March 4, 2020</td>
              <td style={{fontSize: 12, border: '0.5px solid dodgerblue', paddingLeft: 5}}>
                <li>Release of initial Beta Test version of Base HaSefer</li>
                <li>Created documentation for About and Help dialogue buttons</li>
                <li>Added LCS and Diff features to the Text Comparator</li>
                <li>Added enhancements to the Tanach Viewer</li>
              </td>
            </tr>
          </tbody>
        </table>

      </>
    }
    return retVal;
  }
  
  render() {
    return <>
      {this.getTranslatedContent(this.props.langCode)}
    </>
  }
}

export default VersionInformation;