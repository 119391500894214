import React, { Component, useState } from 'react';
import { renderToStaticMarkup } from "react-dom/server";
import { withLocalize, Translate } from "react-localize-redux";
import translations from "./json/translations.json";
import * as util from './newUtil.js'; //util.js is old style JS and doesn't declare vars - slowly porting functions into newUtil
import * as properties from './config/properties.js';

var isBlank = false;
var tooltipClassName;

class PasukStatsTooltip extends Component {
  getReactContainerClasses() {
    return [tooltipClassName];
  }

//todo - use a better rendering method instead of dangerouslySetInnerHTML
  render() {
  //direction setting doesn't seem to work nicely here
//    var directionStyling = {direction: 'ltr', textAlign: 'left'}
//    if(this.props.activeLanguage && this.props.activeLanguage.code === "he"){
//        directionStyling = {direction: 'rtl', textAlign: 'right'}
//    }
    var language = localStorage.getItem('language');
    var isHebrew = language == "he";

    var numSelectedStatColumns = properties.statisticsPicker && properties.statisticsPicker.values ? properties.statisticsPicker.values.length : 0;
    isBlank = (properties.hoverSelect == "none" || (properties.hoverSelect == "selected" && (numSelectedStatColumns == null || numSelectedStatColumns == 0))) //need to use properties global var instead of props due to a hack since can't pass props to ag-grid custome tooltip

    tooltipClassName = isBlank ? "pasukStatsTooltipBlank" : (isHebrew ? "pasukStatsTooltip_he" : "pasukStatsTooltip_en");

    const words = <Translate id="words"/>;
    const letters = <Translate id="letters"/>;
    const uniqueLetter = <Translate id="byUniqueLetters"/>;
    const uniqueLetterCS = <Translate id="byUniqueLettersCS"/>;
    const tenua = <Translate id="tenuos"/>;
    const taam = <Translate id="taamim"/>;
    const gematria = <Translate id="gematria"/>;
    const atbash = <Translate id="atbash"/>;
    const letterCoverage = <Translate id="letterCoverage"/>;
    const perWord = <Translate id="perWord"/>;
    const perLetter = <Translate id="perLetter"/>;
    const perTenua = <Translate id="perTenua"/>;

    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex).data;

//    const lettersPerWord = (data.letter/data.teiva).toFixed(2);
//    const tenuosPerWord = (data.tenua/data.teiva).toFixed(2);
//    const tenuosPerLetter = (data.tenua/data.letter).toFixed(2);
//    const taamimPerWord = (data.taam/data.teiva).toFixed(2);
//    const taamimPerTenua = (data.taam/data.tenua).toFixed(2);
//    const gematriaPerWord = (data.standard/data.teiva).toFixed(1);
//    const gematriaPerLetter = (data.standard/data.letter).toFixed(1);
//    const atbashPerWord = (data.atbash/data.teiva).toFixed(1);
//    const atbashPerLetter = (data.atbash/data.letter).toFixed(1);

    var isHoverByField = properties.hoverSelect == "selected";

    var list = properties.statisticsPicker.list;
    var vals = properties.statisticsPicker.values;
    var i , selectedFields = [];

    for (i = 0; i < vals.length; i++)
        selectedFields.push(list[vals[i]-1].colName);

    var isTeivaContent = !isHoverByField || selectedFields.includes("teiva");
    var isLetterCoverageContent = !isHoverByField || selectedFields.includes("letterCoverage");
    var isLetterContent = !isHoverByField || selectedFields.includes("letter");
    var isLettersPerWordContent = !isHoverByField || selectedFields.includes("lettersPerWord");
    var isUniqueLetterContent = !isHoverByField || selectedFields.includes("uniqueLetter");
    var isUniqueLetterCSContent = !isHoverByField || selectedFields.includes("uniqueLetterCS");
    var isTenuaContent = !isHoverByField || selectedFields.includes("tenua");
    var isTenuosPerWordContent = !isHoverByField || selectedFields.includes("tenuosPerWord");
    var isTenuosPerLetterContent = !isHoverByField || selectedFields.includes("tenuosPerLetter");
    var isTaamContent = !isHoverByField || selectedFields.includes("taam");
    var isTaamimPerWordContent = !isHoverByField || selectedFields.includes("taamimPerWord");
    var isTaamimPerTenuaContent = !isHoverByField || selectedFields.includes("taamimPerTenua");
    var isGematriaContent = !isHoverByField || selectedFields.includes("standard");
    var isGematriaPerWordContent = !isHoverByField || selectedFields.includes("gematriaPerWord");
    var isGematriaPerLetterContent = !isHoverByField || selectedFields.includes("gematriaPerLetter");
    var isAtbashContent = !isHoverByField || selectedFields.includes("atbash");
    var isAtbashPerWordContent = !isHoverByField || selectedFields.includes("atbashPerWord");
    var isAtbashPerLetterContent = !isHoverByField || selectedFields.includes("atbashPerLetter");

    var teivaContent = isTeivaContent ? <><span>{words}: </span> <b>{data.teiva}</b>&nbsp;&nbsp;&nbsp;</> : <></>;
    var letterCoverageContent = isLetterCoverageContent ? <><span>{letterCoverage}: </span> <span dangerouslySetInnerHTML={{__html: util.showLetterCoverage(data.text, 14)}} /></> : <></>;
    var letterContent = (isLetterContent || isLettersPerWordContent) ? <><span>{letters}: </span> <b>{data.letter}</b></> : <></>;
    var lettersPerWordContent = (isLetterContent || isLettersPerWordContent) ? <>({perWord}: <b>{data.lettersPerWord?data.lettersPerWord.toFixed(2):null}</b>)</> : <></>;
    var uniqueLetterContent = isUniqueLetterContent ? <>&nbsp;&nbsp;<span>{uniqueLetter}: </span> <b>{data.uniqueLetter}</b></> : <></>;
    var uniqueLetterCSContent = isUniqueLetterCSContent ? <>&nbsp;&nbsp;<span>{uniqueLetterCS}: </span> <b>{data.uniqueLetterCS}</b></> : <></>;
    var tenuaContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <><span>{tenua}: </span> <b>{data.tenua}</b></> : <></>;
    var tenuosPerWordContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <>({perWord}: <b>{data.tenuosPerWord?data.tenuosPerWord.toFixed(2):null}</b>,</> : <></>;
    var tenuosPerLetterContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <>{perLetter}: <b>{data.tenuosPerLetter?data.tenuosPerLetter.toFixed(2):null}</b>)</> : <></>;
    var taamContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <><span>{taam}: </span> <b>{data.taam}</b></> : <></>;
    var taamimPerWordContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <>({perWord}: <b>{data.taamimPerWord?data.taamimPerWord.toFixed(2):null}</b>,</> : <></>;
    var taamimPerTenuaContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <>{perTenua}: <b>{data.taamimPerTenua?data.taamimPerTenua.toFixed(2):null}</b>)</> : <></>;
    var gematriaContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <><span>{gematria}: </span> <b>{data.standard}</b></> : <></>;
    var gematriaPerWordContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <>({perWord}: <b>{data.gematriaPerWord?data.gematriaPerWord.toFixed(1):null}</b>,</> : <></>;
    var gematriaPerLetterContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <>{perLetter}: <b>{data.gematriaPerLetter?data.gematriaPerLetter.toFixed(1):null}</b>)</> : <></>;
    var atbashContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <><span>{atbash}: </span> <b>{data.atbash}</b></> : <></>;
    var atbashPerWordContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>({perWord}: <b>{data.atbashPerWord?data.atbashPerWord.toFixed(1):null}</b>,</> : <></>;
    var atbashPerLetterContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>{perLetter}: <b>{data.atbashPerLetter?data.atbashPerLetter.toFixed(1):null}</b>)</> : <></>;
    var gematriaAtbashBuffer = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) && (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>&nbsp;&nbsp;&nbsp;</> : <></>;

/*
    var teivaContent = isTeivaContent ? <><span>{words}: </span> <b>{data.teiva}</b>&nbsp;&nbsp;&nbsp;</> : <></>;
    var letterCoverageContent = isLetterCoverageContent ? <><span>{letterCoverage}: </span> <span dangerouslySetInnerHTML={{__html: util.showLetterCoverage(data.text, 14)}} /></> : <></>;
    var letterContent = (isLetterContent || isLettersPerWordContent) ? <><span>{letters}: </span> <b>{data.letter}</b></> : <></>;
    var lettersPerWordContent = (isLetterContent || isLettersPerWordContent) ? <>({perWord}: <b>{lettersPerWord}</b>)</> : <></>;
    var uniqueLetterContent = isUniqueLetterContent ? <>&nbsp;&nbsp;<span>{uniqueLetter}: </span> <b>{data.uniqueLetter}</b></> : <></>;
    var uniqueLetterCSContent = isUniqueLetterCSContent ? <>&nbsp;&nbsp;<span>{uniqueLetterCS}: </span> <b>{data.uniqueLetterCS}</b></> : <></>;
    var tenuaContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <><span>{tenua}: </span> <b>{data.tenua}</b></> : <></>;
    var tenuosPerWordContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <>({perWord}: <b>{tenuosPerWord}</b>,</> : <></>;
    var tenuosPerLetterContent = (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent) ? <>{perLetter}: <b>{tenuosPerLetter}</b>)</> : <></>;
    var taamContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <><span>{taam}: </span> <b>{data.taam}</b></> : <></>;
    var taamimPerWordContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <>({perWord}: <b>{taamimPerWord}</b>,</> : <></>;
    var taamimPerTenuaContent = (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent) ? <>{perTenua}: <b>{taamimPerTenua}</b>)</> : <></>;
    var gematriaContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <><span>{gematria}: </span> <b>{data.standard}</b></> : <></>;
    var gematriaPerWordContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <>({perWord}: <b>{gematriaPerWord}</b>,</> : <></>;
    var gematriaPerLetterContent = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) ? <>{perLetter}: <b>{gematriaPerLetter}</b>)</> : <></>;
    var atbashContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <><span>{atbash}: </span> <b>{data.atbash}</b></> : <></>;
    var atbashPerWordContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>({perWord}: <b>{atbashPerWord}</b>,</> : <></>;
    var atbashPerLetterContent = (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>{perLetter}: <b>{atbashPerLetter}</b>)</> : <></>;
    var gematriaAtbashBuffer = (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent) && (isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent) ? <>&nbsp;&nbsp;&nbsp;</> : <></>;
*/

    var topRow;
      if (isHebrew) {
        topRow = <p style={{ direction: 'rtl' }}>
          <span className="pasukStatsTooltipLocation">{data.location}</span>&nbsp;&nbsp;&nbsp;
          {teivaContent}
          {letterCoverageContent}
        </p>
      }
      else {
        topRow = <p style={{ direction: 'ltr' }}>
          {letterCoverageContent}&nbsp;&nbsp;&nbsp;
          {teivaContent}
          <span className="pasukStatsTooltipLocation">{data.location}</span>
        </p>
      }

    var lettersRow;
    if (isLetterContent || isLettersPerWordContent || isUniqueLetterContent || isUniqueLetterCSContent)
        lettersRow =
            <p>
                {letterContent} {lettersPerWordContent} {uniqueLetterContent} {uniqueLetterCSContent}
            </p>;
    else
        lettersRow = <></>;

    var tenuaRow;
    if (isTenuaContent || isTaamimPerWordContent || isTenuosPerLetterContent)
        tenuaRow =
            <p>
                {tenuaContent} {tenuosPerWordContent} {tenuosPerLetterContent}
            </p>
    else
        tenuaRow = <></>;

    var taamRow;
    if (isTaamContent || isTaamimPerWordContent || isTaamimPerTenuaContent)
        taamRow =
            <p>
                {taamContent} {taamimPerWordContent} {taamimPerTenuaContent}
            </p>
    else
        taamRow = <></>;

    var gematriaRow;
    if (isGematriaContent || isGematriaPerWordContent || isGematriaPerLetterContent || isAtbashContent || isAtbashPerWordContent || isAtbashPerLetterContent)
        gematriaRow =
            <p>
                {gematriaContent} {gematriaPerWordContent} {gematriaPerLetterContent}{gematriaAtbashBuffer}{atbashContent} {atbashPerWordContent} {atbashPerLetterContent}
            </p>
    else
        gematriaRow = <></>;

    return (
      <div
        className={tooltipClassName}
        style={{ backgroundColor: this.props.color || 'white' }}
      >
      {topRow}
      {lettersRow}
      {tenuaRow}
      {taamRow}
      {gematriaRow}
      </div>
    );
  }
}

//export default withLocalize(PasukStatsTooltip);
export default PasukStatsTooltip; //wrapping in withLocalize doesn't work for some reason - does it have to do with how cutomTooltip in ag-grid uses a class???